import * as Yup from "yup";
import { VeiculoFormModel } from "../../../modelos/veiculo/veiculoFormModel";


export const CadastroFrotaSchema: Yup.SchemaOf<VeiculoFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    ano: Yup.number().notRequired(),
    idEmpresa: Yup.string().required("Item obrigatório"),
    numeroAssentos: Yup.number().required("Item obrigatório"),
    placa: Yup.string().required("Item obrigatório"),
    tipo: Yup.string().required("Item obrigatório"),
  });
