import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { Autocomplete, Box, Button, FormControl, TextField } from "@mui/material";
import { mdiArrowLeft } from "@mdi/js";
import { useSnackbar } from "notistack";
import { useEventosService } from "../../../servicos/eventos.service";
import { usePromotorService } from "../../../servicos/promotor.service";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";
import { EVENTO_FORM_INITIAL_STATE } from "../../../constantes/EventoFormInitialState";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import styles from "../ListarPromotores/styles/gridPromotores.module.scss";
import { Spinner } from "react-bootstrap";

const AssociarPromotorAoEventoSchema = Yup.object({
  idPromotor: Yup.string().required("Item obrigatório"),
});

export const AssociarEvento = () => {
  const { control, handleSubmit, setValue, formState: { errors }} = useForm({resolver: yupResolver(AssociarPromotorAoEventoSchema)});
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { idEvento } = useParams<{ idEvento: string }>();
  const { carregarEventoPorId } = useEventosService();
  const { buscarOpcoesPromotores, associarPromotorAoEvento } = usePromotorService();

  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [evento, setEvento] = useState<EventoFormModel>(EVENTO_FORM_INITIAL_STATE);
  const [promotores, setPromotores] = useState<any[]>([]);

  async function associarPromotor(formData: any) {
    setLoadingSubmit(true);
    try {
      await associarPromotorAoEvento({
        idEvento: evento.id ?? "",
        idPromotor: formData.idPromotor
      });

      enqueueSnackbar(`Promotor associado com sucesso!`, {variant: "success" });
      history.goBack()
    } finally {
      setLoadingSubmit(false);
    }
  }
  
  useEffect(() => {
    (async () => {
      const response = await carregarEventoPorId(idEvento) as unknown as EventoFormModel;
      const promotores = await buscarOpcoesPromotores() as unknown as any;
      setPromotores(promotores);
      setEvento(response);
      setLoading(false)
    })()
  }, []);

  return (
    <>
      <OptBackdrop open={loading} />
      <Box width={'98%'} flexWrap={'wrap'}>
        <div>
          <OptActionButton
            onClick={() => history.goBack()}
            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
          >
            <span>{evento.nome} - Promotores</span>
          </OptActionButton>
        </div>

        <Box className="px-4 mt-5">
          <form onSubmit={handleSubmit(associarPromotor)}>
            <Controller
              name="idPromotor"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: 1 }} size="medium">
                  <Autocomplete
                    id='select-label-empresa'
                    className={styles.StyledAsyncSelectPontoEmbarque}
                    options={promotores}
                    getOptionLabel={(option: any) => option.nome}
                    onChange={(e, value) => setValue('idPromotor', value.id)}
                    renderInput={(params) => <TextField {...params} label="Selecione um promotor" />}
                  />
                </FormControl>
              )}
            />
            {
              errors.idPromotor &&
              <ErrorMessage message={"Item obrigatório"} />
            }

            {
              loadingSubmit ? (
                <Spinner />
              ) : (
                <Button type="submit" sx={sxButtonSaveConfig} className="mt-3">
                  Associar
                </Button>
              )
            }
          </form>
        </Box>
      </Box>
    </>
  )
}