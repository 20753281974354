import { mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptGridRef,
  OptGridRequest,
  OptSearchField,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { SearchEmpresaRequest } from "../../../compartilhado/types/SearchEmpresaRequest";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { FiltroBusca } from "../../../componentes/filtro";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { EMPRESA_FORM_INITIAL_STATE } from "../../../constantes/EmpresaFormInitialState";
import { TipoInput } from "../../../enums/tipoInput";
import { EmpresaSearchResponse } from "../../../modelos/dtos/EmpresaSearchResponse";
import { EmpresaFormModel } from "../../../modelos/empresa/empresaFormModel";
import { EmpresaModel } from "../../../modelos/empresa/empresaModel";
import { PaginatedEmpresaRequest } from "../../../modelos/search/PaginatedEmpresaRequest";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { Rotas } from "../../../rotas";
import { useEmpresasService } from "../../../servicos/empresas.service";
import { GridEmpresasView } from "./GridEmpresasView";
import styles from "./styles/gridEmpresas.module.scss";
import { IGeneric } from "../Cadastro/FormularioEmpresa";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

export const GridEmpresas = ({
  isFromCadastroEmpresa,
  needToUpdateGrid,
}: IGeneric) => {
  const [drawerFormulario, setDrawerFormularioAberto] = useState(false);
  const [filtro, setFiltro] = useState<EmpresaFormModel>();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] =
    useState<boolean>(false);

  const [item, setItem] = useState<EmpresaFormModel>(
    EMPRESA_FORM_INITIAL_STATE
  );

  const { buscarGridEmpresasPaginated, removerEmpresa, editarEmpresa } =
    useEmpresasService();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  const opcoesFiltro = [
    { texto: "Cnpj", valor: "cnpj", tipoInput: TipoInput.Texto },
    { texto: "Telefone", valor: "telefone", tipoInput: TipoInput.Texto },
    { texto: "Descrição", valor: "descricao", tipoInput: TipoInput.Texto },
    { texto: "Nome", valor: "nome", tipoInput: TipoInput.Texto },
    { texto: "Cep", valor: "cep", tipoInput: TipoInput.Texto },
    { texto: "Endereço", valor: "endereco", tipoInput: TipoInput.Texto },
    { texto: "e-mail", valor: "email", tipoInput: TipoInput.Texto },
  ];

  function pesquisaComFiltro(filtro: EmpresaFormModel) {
    setFiltro(filtro);
  }

  const carregar = async (
    query: OptGridRequest,
    itemSearchRequest: PaginatedSearchRequest = {
      filtro: filtro,
    }
  ) => {
    const request: SearchRequest<PaginatedEmpresaRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro: filtro,
    };
    return buscarGridEmpresasPaginated(request);
  };

  function onRowClick(rowData?: EmpresaModel) {}

  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerEmpresa(item.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setDrawerFormularioAberto(false);
      setItem(EMPRESA_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }

  async function onClickDeleteItem(rowData: EmpresaSearchResponse) {
    setItem(rowData);
    setConfirmandoExclusao(true);
  }

  function onClickAddMotorista(rowData: EmpresaSearchResponse) {
    history.push("/motoristas/cadastro");
  }

  function procurarViagem(data?: string) {
    //setempresaSearch(data ?? "");
  }

  async function onClickEditItem(rowData: EmpresaSearchResponse) {
    if (rowData.id) {
      history.push(`/empresas/cadastro/${rowData.id}`);
    }
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  function cadastrarEmpresa() {
    history.push("/empresas/cadastro");
  }

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, [filtro]);

  useEffect(() => {
    if (needToUpdateGrid) {
      if (gridRef.current) {
        recarregar();
      }
    }
  }, [needToUpdateGrid]);

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao]);
    })()
  }, []);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir empresa"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão da empresa ? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>

      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        {!isFromCadastroEmpresa && (
          <>
            <OptActionToolbar
              goBackRoute={Rotas.Home}
              title="Empresa"
              background={Colors.white}
              color={Colors.gray1}
              clearMargin
            ></OptActionToolbar>
            <OptGridContainer>
              <FiltroBusca opcoes={opcoesFiltro} pesquisa={pesquisaComFiltro} />
              <div className={styles.ButtonSection}>
                <Tooltip title={"Cadastrar empresa"}>
                  <Button
                    variant="contained"
                    color="info"
                    style={{ marginRight: "5px", marginBottom: "11px" }}
                    placeholder="Cadastrar empresa"
                    size="large"
                    onClick={cadastrarEmpresa}
                  >
                    Cadastrar Empresa{" "}
                    <Icon
                      path={mdiPlusCircle}
                      size={1}
                      color={Colors.primary}
                    />
                  </Button>
                </Tooltip>
              </div>
            </OptGridContainer>
          </>
        )}
        <GridEmpresasView
          onRowClick={onRowClick}
          carregar={carregar}
          onClickDelete={onClickDeleteItem}
          onClickAddMotorista={onClickAddMotorista}
          onClickEdit={onClickEditItem}
          gridRef={gridRef}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
