import { mdiDeleteForever, mdiFileEdit, mdiAccountPlus } from "@mdi/js";
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { Colors } from "../../../compartilhado/colors";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { EmpresaSearchResponse } from "../../../modelos/dtos/EmpresaSearchResponse";

interface Props {
  onRowClick: (rowData?: EmpresaSearchResponse) => void;
  carregar: (
    data: OptGridRequest
  ) => Promise<OptGridResponse<EmpresaSearchResponse>>;
  onClickDelete: (rowData: EmpresaSearchResponse) => void;
  onClickEdit: (rowData: EmpresaSearchResponse) => void;
  onClickAddMotorista: (rowData: EmpresaSearchResponse) => void;
  gridRef: React.MutableRefObject<OptGridRef | null>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridEmpresasView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      carregar,
      onRowClick,
      onClickDelete,
      onClickEdit,
      onClickAddMotorista,
      gridRef,
    },
    ref
  ) => {
    const columns: OptGridColumn<EmpresaSearchResponse>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "CNPJ",
        field: "cnpj",
        align: "left",
        hidden: false,
        width: 200,
      },
      {
        title: "Nome",
        field: "nome",
        align: "left",
        hidden: false,
        width: 15,
      },
      {
        title: "Descrição",
        field: "descricao",
        align: "left",
        hidden: false,
        width: 300,
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={gridRef}
            columns={columns}
            data={carregar}
            options={options}
            onRowClick={onRowClick}
            actionsPosition={"end"}
            actions={[
              (rowData) => ({
                icon: {
                  path: mdiFileEdit,
                  size: 1,
                  color: Colors.gray,
                },
                tooltip: "Visualizar ou Editar empresa",
                onClick: () => onClickEdit(rowData),
                disabled: false,
              }),
              (rowData) => ({
                icon: {
                  path: mdiDeleteForever,
                  size: 1,
                  color: Colors.red2,
                },
                tooltip: "Exluir empresa",
                onClick: () => onClickDelete(rowData),
                disabled: false,
              }),
            ]}
          />
        </OptGridContainer>
      </>
    );
  }
);
