import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { PaginatedPessoaRequest } from "../modelos/search/PaginatedPessoaRequest";
import { protectedResources } from "../authConfig";
import { PromotorSearchResponse } from "../modelos/dtos/PromotorSearchResponse";

interface ParamsRemoverPromotor {
  idPromotor: string;
  idEvento: string;
  nomePontoEmbarque?: string;
}

interface ParamsAssociarPromotorEvento {
  idPromotor: string;
  idEvento: string;
}

interface ParamsAssociarPromotorPontoDeEmbarque {
  idPromotor: string;
  idEvento: string;
  nomePontoEmbarque: string;
}

export const usePromotorService = () => {
  const { get, post, gridPagedListSearch } = useApi({ scopes: protectedResources.apiGestao.scopes });

  const baseApi = `gestao/Promotoria`;

  const buscarOpcoesPromotores = async () => {
    return get(`${baseApi}/opcoesPromotor`);
  };

  const buscarOpcoesPontosEmbarque = async (idEvento: string) => {
    return get(`${baseApi}/opcoesPontoEmbarque/${idEvento}`);
  };

  const buscarGridPromotores = async (data: SearchRequest<PaginatedPessoaRequest>, idEvento: any) => {
    return gridPagedListSearch<PromotorSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, { ...data.filtro, idEvento });
  };

  const removerPromotor = async (params: ParamsRemoverPromotor) => {
    return post(`${baseApi}/deletar`, params);
  };

  const associarPromotorAoEvento = async (params: ParamsAssociarPromotorEvento) => {
    return post(`${baseApi}/evento`, params);
  };

  const associarPromotorAoPontoDeEmbarque = async (params: ParamsAssociarPromotorPontoDeEmbarque) => {
    return post(`${baseApi}/pontoEmbarque`, params);
  };

  return {
    buscarOpcoesPromotores,
    buscarOpcoesPontosEmbarque,
    buscarGridPromotores,
    removerPromotor,
    associarPromotorAoEvento,
    associarPromotorAoPontoDeEmbarque,
  };
};