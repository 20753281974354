import { mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptGridRef,
  OptGridRequest,
  OptSearchField,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { FiltroBusca } from "../../../componentes/filtro";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { PESSOA_FORM_INITIAL_STATE } from "../../../constantes/PessoaFormInitialState";
import { TipoInput } from "../../../enums/tipoInput";
import { PessoaSearchResponse } from "../../../modelos/dtos/PessoaSearchResponse";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";
import { PessoaModel } from "../../../modelos/pessoa/pessoaModel";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { Rotas } from "../../../rotas";
import { usePessoasService } from "../../../servicos/pessoas.service";
import { GridUsuariosView } from "./GridPessoasView";
import styles from "./styles/gridPessoas.module.scss";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

export const GridUsuarios = () => {
  const [drawerFormulario, setDrawerFormularioAberto] = useState(false);
  const [filtro, setFiltro] = useState<PessoaFormModel>();

  const { verificarAutorizacao } = useEventosService();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] =
    useState<boolean>(false);

  const [pessoa, setPessoa] = useState<PessoaFormModel>(PESSOA_FORM_INITIAL_STATE);

  const { buscarGridPessoasPaginated, removerPessoa, editarPessoa } =
    usePessoasService();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();



  const opcoesFiltro = [
    { texto: "Nome", valor: 'nome', tipoInput: TipoInput.Texto },
    { texto: "Cpf", valor: 'cpf', tipoInput: TipoInput.Texto },
    { texto: "Telefone", valor: 'telefone', tipoInput: TipoInput.Texto },
    { texto: "E-mail", valor: 'email', tipoInput: TipoInput.Texto },
  ];

  function pesquisaComFiltro(filtro: PessoaFormModel) {
    setFiltro(filtro);
  }

  const carregar = async (
    query: OptGridRequest,
    itemSearchRequest: PaginatedSearchRequest = {
      filtro,
    }
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro,
    };
    return buscarGridPessoasPaginated(request);
  };

  function onRowClick(rowData?: PessoaModel) {
    if (rowData) {
    }
  }

  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerPessoa(pessoa.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setDrawerFormularioAberto(false);
      setPessoa(PESSOA_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }

  async function onClickDeleteItem(rowData: PessoaSearchResponse) {
    setPessoa(rowData);
    setConfirmandoExclusao(true);
  }

  async function onClickEditarUsuario(rowData: PessoaSearchResponse) {
    if (rowData.id)
      history.push(`/usuarios/cadastro/${rowData.id}`);
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  function cadastrarUsuario() {
    history.push("/usuarios/cadastro");
  }

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, [filtro]);

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente]);
    })()
  }, []);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir Usuários"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão do usuário ? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>

      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Rotas.Home}
          title="Usuários"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <OptGridContainer>
          <FiltroBusca opcoes={opcoesFiltro} pesquisa={pesquisaComFiltro} />
          <div className={styles.ButtonSection}>
            <Tooltip title={"Cadastrar usuário"}>
              <Button
                variant="contained"
                color="info"
                style={{ marginRight: "5px", marginBottom: "11px" }}
                placeholder="Cadastrar usuário"
                size="large"
                onClick={cadastrarUsuario}
              >
                Cadastrar Usuário <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
              </Button>
            </Tooltip>
          </div>
        </OptGridContainer>
        <GridUsuariosView
          onRowClick={onRowClick}
          carregar={carregar}
          abrirDrawerFormulario={cadastrarUsuario}
          onClickDelete={onClickDeleteItem}
          onClickEdit={onClickEditarUsuario}
          gridRef={gridRef}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
