import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { EmpresaSearchResponse } from "../modelos/dtos/EmpresaSearchResponse";
import { EmpresaFormModel } from "../modelos/empresa/empresaFormModel";
import { PaginatedEmpresaRequest } from "../modelos/search/PaginatedEmpresaRequest";
import { protectedResources } from "../authConfig";
import { PaginatedRsvpRequest } from "../modelos/search/PaginatedRsvpRequest";
import { RsvpRelatorioModel } from "../modelos/rsvp";
import { RsvpSearchResponse } from "../modelos/dtos/RsvpSearchResponse";

export const useRsvpService = () => {
  const { post } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Rsvp`;

  const buscarGridRsvpPaginated = async (data: PaginatedRsvpRequest) => {
    return post<RsvpSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, data.filtro);
  };

  const obterExcel = async (data: any) => {
    return post(`${baseApi}/obterExcel`, data);
  };

  return {
    buscarGridRsvpPaginated,
    obterExcel
  };
};