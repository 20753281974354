import { mdiContentCopy } from "@mdi/js";
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { ReservaSearchResponse } from "../../../modelos/dtos/ReservaSearchResponse";
import { format } from "date-fns";
import { StatusReserva } from "../../../enums/StatusReserva";
import { formatarHora } from "../../../utils/claimUtils";

interface Props {
  carregar: (
    data: OptGridRequest
  ) => Promise<OptGridResponse<ReservaSearchResponse>>;
  gridRef: React.MutableRefObject<OptGridRef | null>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};


const formatarTelefone = (telefone: string) => {
  let telefone_limpo = telefone.replace(/\D/g,'');
  // (XX) X XXXX-XXXX

  if(telefone_limpo.length === 11)
      return `(${telefone_limpo.substring(0, 2)}) ${telefone_limpo[2]} ${telefone_limpo.substring(3, 7)}-${telefone_limpo.substring(7)}`

  if(telefone_limpo.length === 10)
      return `(${telefone_limpo.substring(0, 2)}) ${telefone_limpo.substring(2, 6)}-${telefone_limpo.substring(6)}`

  if(telefone_limpo.length === 9)
      return `${telefone_limpo[0]} ${telefone_limpo.substring(1, 5)}-${telefone_limpo.substring(5)}`

  if(telefone_limpo.length === 8)
      return `${telefone_limpo.substring(2, 6)}-${telefone_limpo.substring(6)}`
  
  return telefone
}

export const RelatorioPassageirosView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      carregar,
      gridRef,
    },
    ref
  ) => {
    const columns: OptGridColumn<ReservaSearchResponse>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "Reserva",
        field: "numeroReserva",
        align: "left",
        hidden: false,
        width: 15,
      },
      {
        title: "Data da Reserva",
        field: "criadoEm",
        align: "left",
        hidden: false,
        width: 200,
        render: (row) => {
            let  data = "";
            if (row.criadoEm) {
              data= format(new Date(row.criadoEm.replace('Z','')), 'dd/MM/yyyy');
            }
            
            return (
              <span>
                {data} 
              </span>
            );
          },
       },
      {
        title: "Passageiro",
        field: "usuario",
        align: "left",
        hidden: false,
        width: 350,
        render: (row) => {
            let  passageiro = "-";
            if (row.usuario) {
                passageiro = row.usuario.nome
            }
            
            return (
              <span>
                {passageiro} 
              </span>
            );
          },
      },
      {
        title: "Telefone",
        field: "usuario.telefone",
        align: "left",
        hidden: false,
        width: 400,
        render: (row) => {
            let  telefone = "-";
            if (row.usuario) {
              telefone = formatarTelefone(row.usuario.telefone)
            }
            return (
              <span>
                {telefone} 
              </span>
            );
          },
      },
      {
        title: "Ponto de embarque",
        field: "pontoEmbarque",
        align: "left",
        hidden: false,
        width: 350,
        render: (row) => {            
            return (
              <span>
                {row.pontoEmbarque.nome} {formatarHora(row.pontoEmbarque)} 
              </span>
            );
          },
      },
      {
        title: "Status",
        field: "status",
        align: "left",
        hidden: false,
        width: 200,
        render: (row) => {
            let  status = "";
            if (row.status) {
                status = StatusReserva[row.status].toString()
            }
            
            return (
              <span>
                {status} 
              </span>
            );
          },
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={gridRef}
            columns={columns}
            data={carregar}
            options={options}
            actionsPosition={"end"}
            actions={[
              
            ]}
          />
        </OptGridContainer>
      </>
    );
  }
);
