import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, Drawer, Grid, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { EmailSubmitModel } from "../../../modelos/email/EmailSubmitModel";
import styles from "./styles/formularioEmail.module.scss";
import { StyledDrawer } from "./styles";

interface Props {
  open: boolean;
  onClose: () => void;
  enviar: (data: EmailSubmitModel) => Promise<void>;
  defaultData: EmailSubmitModel;
  loading: boolean;
}

export const FormularioEmail = ({
  open,
  onClose,
  defaultData,
  enviar,
  loading,
}: Props) => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.info,
    color: Colors.white,
    height: "38px",
    width: "300px",
    "&:hover": {
      backgroundColor: Colors.blue2,
    },
    margin: "10px 35px"
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <form>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>{"Selecione o tipo de email a ser enviado"}</span>
          </OptActionButton>
        </div>
        <Divider />
        <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
          <Grid item xs={12}>
            <Button
              size="large"
              onClick={() => enviar({...defaultData, email: 'RESERVA'})}
              sx={sxButtonSaveConfig}
            >
              Confirmação de Reserva
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              onClick={() => enviar({...defaultData, email: 'PASSAGEM'})}
              sx={sxButtonSaveConfig}
            >
              Passagem
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              onClick={() => enviar({...defaultData, email: 'CANCELAMENTO'})}
              sx={sxButtonSaveConfig}
            >
              Cancelamento de Compra
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              onClick={() => enviar({...defaultData, email: 'REEMBOLSO'})}
              sx={sxButtonSaveConfig}
            >
              Reembolso de Compra
            </Button>
          </Grid>
        </Grid>
        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
            Cancelar
          </Button>
          </FooterToolbar>
        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <p style={{ textAlign: 'left', margin: '10px 0 20px 0' }}><strong>OBS:</strong> Emails de cancelamento/reembolso só serão enviados nos itens onde a compra já estiver cancelada/reembolsada.</p>
        </FooterToolbar>
      </form>
    </StyledDrawer>
  );
};
