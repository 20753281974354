import {
    mdiArrowLeft,
    mdiFileCancelOutline,
    mdiPointOfSale,
    mdiCashRefund
} from "@mdi/js";
import { Button, Divider, Grid } from "@mui/material";
import { OptActionButton, OptConfirmationDialog } from "@optsol/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Colors } from "../../../compartilhado/colors";
import { VendaSearchResponse } from "../../../modelos/dtos/VendaSearchResponse";
import styles from "./styles/verVendas.module.scss";
import { GridVendasView } from "../Lista/GridVendasView";
import { useVendasService } from "../../../servicos/vendas.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { StatusTransacao } from "../../../enums/StatusTransacao";
import { OcorrenciaSearchResponse } from "../../../modelos/dtos/OcorrenciaSearchResponse";
import { VendaTableItem } from "../../../modelos/dtos/VendaTableItem";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";
import { read, utils, writeFileXLSX } from 'xlsx';
import { TipoDocumento } from "../../../enums/tipoDocumento";
import { useSnackbar } from "notistack";
import { addHours, format } from "date-fns";

export const VerVendas = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { idEvento } = useParams<{ idEvento: string }>();
    const { idOcorrencia } = useParams<{ idOcorrencia: string }>();
    const [ocorrencia, setOcorrencia] = useState<OcorrenciaSearchResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [carregado, setCarregado] = useState<boolean>(false);
    const [confirmandoCaptura, setConfirmandoCaptura] = useState<boolean>(false);
    const [confirmandoCancelamento, setConfirmandoCancelamento] = useState<boolean>(false);
    const [confirmandoReembolso, setConfirmandoReembolso] = useState<boolean>(false);
    const [confirmandoCapturaAutorizadas, setConfirmandoCapturaAutorizadas] = useState<boolean>(false);
    const [vendas, setVendas] = useState<VendaTableItem[]>([]);
    const {
        carregarVendasPorOcorrencia,
        capturarVendas,
        capturarVendasAutorizadasOcorrencia,
        cancelarVendas,
        reembolsarVendas
    } = useVendasService();
    const { carregarOcorrenciaPorId } = useOcorrenciasService();
    const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
    const history = useHistory();
    const { verificarAutorizacao } = useEventosService();

    async function carregarOcorrencia(idOcorrencia: string) {
        const response = await carregarOcorrenciaPorId(idOcorrencia) as unknown as OcorrenciaSearchResponse;
        setOcorrencia(response);
        let test = await carregar();
        setVendas(test);
        setCarregado(true);
    }

    function onClose() {
        history.push(`/eventos/ver/${idEvento}`);
    }

    function selecionarVenda(idVenda: string) {
        let copiaVendas = [...vendas];
        for (let venda of copiaVendas) {
            if (venda.data.id === idVenda) {
                venda.selected = !venda.selected;
            }
        }
        setVendas(copiaVendas);
    }

    function ajustarTodas(valorSelecao: boolean) {
        let copiaVendas = [...vendas];
        for (let venda of copiaVendas)
            venda.selected = valorSelecao;
        setVendas(copiaVendas);
    }

    async function capturarVendasSelecionadas() {
        if(loading)
            return enqueueSnackbar("Por favor, aguarde o término da ação em execução.", { variant: "info" });

        setLoading(true);
        await capturarVendas(vendas.filter(v => v.selected).map(v => v.data.id));
        setConfirmandoCaptura(false);
        setCarregado(false);
        setVendas(await carregar());
        setCarregado(true);
        enqueueSnackbar("Vendas capturadas com sucesso!", { variant: "success" });
        setLoading(false);
    }

    async function cancelarVendasSelecionadas() {
        if(loading)
            return enqueueSnackbar("Por favor, aguarde o término da ação em execução.", { variant: "info" });
        setLoading(true);
        await cancelarVendas(vendas.filter(v => v.selected).map(v => v.data.id));
        setConfirmandoCancelamento(false);
        setCarregado(false);
        setVendas(await carregar());
        setCarregado(true);
        enqueueSnackbar("Vendas canceladas com sucesso!", { variant: "success" });
        setLoading(false);
    }

    async function reembolsarVendasSelecionadas() {
        if(loading)
            return enqueueSnackbar("Por favor, aguarde o término da ação em execução.", { variant: "info" });
        setLoading(true);
        await reembolsarVendas(vendas.filter(v => v.selected).map(v => v.data.id));
        setConfirmandoReembolso(false);
        setCarregado(false);
        setVendas(await carregar());
        setCarregado(true);
        enqueueSnackbar("Vendas reembolsadas com sucesso!", { variant: "success" });
        setLoading(false);
    }

    async function capturarVendasAutorizadas() {
        if(loading)
            return enqueueSnackbar("Por favor, aguarde o término da ação em execução.", { variant: "info" });
        setLoading(true);
        await capturarVendasAutorizadasOcorrencia(idOcorrencia);
        setConfirmandoCapturaAutorizadas(false);
        setCarregado(false);
        let test = await carregar();
        setVendas(test);
        setCarregado(true);
        enqueueSnackbar("Vendas capturadas com sucesso!", { variant: "success" });
        setLoading(false);
    }

    const exportarParaExcel = () => {
        const ws = utils.json_to_sheet(converterParaExcel(vendas));

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Transações");
        writeFileXLSX(wb, `Vendas ${ocorrencia?.nomeEvento || ''} - ${ocorrencia?.data ? format(addHours(new Date(ocorrencia.data), 3), "dd-MM-yyyy") : ''} - VouDeSquad.xlsx`);
    }

    const carregar = async () => {
        let vendas: VendaSearchResponse[] = await carregarVendasPorOcorrencia(idOcorrencia);
        return vendas.map(v => { return { data: v, selected: false } });
    };

    useEffect(() => {
        if (idOcorrencia) {
            carregarOcorrencia(idOcorrencia);
        }
    }, []);

    useEffect(() => {
        (async () => {
            verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao]);
        })()
    }, []);

    const converterParaExcel = (dados: VendaTableItem[]) => {
        return dados.map(v => {
            return {
                'Código do Evento': v.data.idEvento || 'Nenhum informado',
                'Nome do Evento': v.data.nomeEvento || 'Nenhum informado',
                'Data e hora IDA': v.data.horarioIdaEmbarqueStr || 'Nenhum informado',
                'Data e hora VOLTA': v.data.horarioVoltaEmbarqueStr || 'Nenhum informado',
                'Data do Evento': format(addHours(new Date(v.data.dataOcorrencia), 3), "dd/MM/yyyy HH:mm"),
                'Código da Transação': v.data.id || 'Nenhum informado',
                'Nome do Pagador': v.data.nomeUsuario || 'Nenhum informado',
                'Documento do Pagador': v.data.documentoUsuario || 'Nenhum informado',
                'CEP': v.data.cepCobranca || 'Nenhum informado',
                'Rua': `${v.data.logradouroCobranca??""} ${v.data.numeroCobranca??""}` || 'Nenhum informado',
                'Bairro': v.data.bairroCobranca || 'Nenhum informado',
                'Cidade': v.data.cidadeCobranca || 'Nenhum informado',
                'UF': v.data.estadoCobranca || 'Nenhum informado',
                'País': v.data.paisCobranca || 'Nenhum informado',
                'Local do Embarque': v.data.ruaEmbarque || 'Nenhum informado',
                'Data da Reserva': v.data.dataReserva ? format(addHours(new Date(v.data.dataReserva), 3), "dd/MM/yyyy HH:mm") : 'Nenhum informado',
                'Email': v.data.emailUsuario || 'Nenhum informado',
                'Telefone': v.data.telefoneUsuario || 'Nenhum informado',
                'Quantidade de Assentos': (v.data.idsPassageiros??"").split(',').length || 'Nenhum informado',
                'Preço por Assento': `R$ ${((v.data.precoFinal??0) / (v.data.idsPassageiros.split(',').length)).toString().replace(',', '').replace('.', ',')}` || 'Nenhum informado',
                'Cupom': v.data.codigoCupom || 'Nenhum informado',
                'Desconto do Cupom': `${(v.data.descontoCupom??0)}%` || 'Nenhum informado',
                'Total de Desconto': `R$ ${(v.data.totalDesconto??0).toString().replace(',', '').replace('.', ',')}` || 'Nenhum informado',
                'Add-on': '',
                'Qtd de Add-ons': '',
                'Preço por Add-on': '',
                'Subtotal': `R$ ${(v.data.precoFinal??0).toString().replace(',', '').replace('.', ',')}` || 'Nenhum informado',
                'Total Add-ons': '',
                'Total': `R$ ${(v.data.precoFinal??0).toString().replace(',', '').replace('.', ',')}` || 'Nenhum informado',
                'Status da Transação': v.data.statusTransacao ? StatusTransacao[v.data.statusTransacao] : 'Nenhum informado',
                'Fonte': v.data.fonte || 'Nenhum informado',
                'Chave Adyen': v.data.chaveAdyen || 'Nenhum informado',
                'Referência PSP': v.data.referenciaPsp || 'Nenhum informado'
            }
        });
    }

    let dataOcor = ocorrencia ? ocorrencia.data.split('T')[0].split('-') : ['0', '0', '0'];

    return (
        <>
            <OptConfirmationDialog
                open={confirmandoCaptura}
                title="Capturar Vendas"
                icon={{ path: mdiPointOfSale, color: Colors.green1 }}
                onCancel={() => setConfirmandoCaptura(false)}
                onClose={() => setConfirmandoCaptura(false)}
                onConfirm={capturarVendasSelecionadas} >
                Deseja realmente capturar {vendas.filter(v => v.selected).length} vendas?
            </OptConfirmationDialog>

            <OptConfirmationDialog
                open={confirmandoCancelamento}
                title="Cancelar Vendas"
                icon={{ path: mdiFileCancelOutline, color: Colors.green1 }}
                onCancel={() => setConfirmandoCancelamento(false)}
                onClose={() => setConfirmandoCancelamento(false)}
                onConfirm={cancelarVendasSelecionadas} >
                Deseja realmente cancelar {vendas.filter(v => v.selected).length} vendas?
            </OptConfirmationDialog>

            <OptConfirmationDialog
                open={confirmandoReembolso}
                title="Reembolsar Vendas"
                icon={{ path: mdiCashRefund, color: Colors.green1 }}
                onCancel={() => setConfirmandoReembolso(false)}
                onClose={() => setConfirmandoReembolso(false)}
                onConfirm={reembolsarVendasSelecionadas} >
                Deseja realmente reembolsar {vendas.filter(v => v.selected).length} vendas?
            </OptConfirmationDialog>

            <OptConfirmationDialog
                open={confirmandoCapturaAutorizadas}
                title="Capturar Vendas Autorizadas"
                icon={{ path: mdiPointOfSale, color: Colors.green1 }}
                onCancel={() => setConfirmandoCapturaAutorizadas(false)}
                onClose={() => setConfirmandoCapturaAutorizadas(false)}
                onConfirm={capturarVendasAutorizadas} >
                Deseja realmente capturar todas as vendas autorizadas do evento nesta data?
            </OptConfirmationDialog>
            <div className="col">
                <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                    <div className={styles.CabecalhoContainer}>
                        <OptActionButton
                            onClick={onClose}
                            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
                        >
                            <span className={styles.SpanText}>
                                Vendas: {ocorrencia?.nomeEvento} - {dataOcor[2]}/{dataOcor[1]}/{dataOcor[0]}
                            </span>
                        </OptActionButton>
                    </div>
                    <Divider />
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="info"
                            style={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            onClick={exportarParaExcel}
                            disabled={!carregado || vendas.length === 0}
                        >
                            Exportar para Excel
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                            style={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            onClick={() => ajustarTodas(true)}
                            disabled={!carregado || vendas.length === 0}
                        >
                            Marcar todas
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "1px", marginBottom: "10px" }}
                            size="small"
                            onClick={() => ajustarTodas(false)}
                            disabled={!carregado || vendas.length === 0}
                        >
                            Desmarcar todas
                        </Button>
                        <Divider />
                        <span className={styles.SpanText}>
                            AÇÕES:
                        </span>
                        <Divider />
                        <Button
                            variant="contained"
                            color="success"
                            style={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            onClick={() => setConfirmandoCaptura(true)}
                            disabled={
                                !carregado ||
                                !vendas.some(v => v.selected) ||
                                vendas.filter(v => v.selected)
                                    .some(v => v.data.statusTransacao !== StatusTransacao.Autorizada &&
                                        v.data.statusTransacao !== StatusTransacao.ErroCaptura)
                            }
                        >
                            Capturar
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            style={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            onClick={() => setConfirmandoCapturaAutorizadas(true)}
                            disabled={!carregado || vendas.length === 0 || !vendas.some(v => v.data.statusTransacao === StatusTransacao.Autorizada)}
                        >
                            Capturar autorizadas
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            style={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            onClick={() => setConfirmandoCancelamento(true)}
                            disabled={
                                !carregado ||
                                !vendas.some(v => v.selected) ||
                                vendas.filter(v => v.selected)
                                    .some(v => v.data.statusTransacao !== StatusTransacao.Autorizada &&
                                        v.data.statusTransacao !== StatusTransacao.Capturada &&
                                        v.data.statusTransacao !== StatusTransacao.ErroCaptura &&
                                        v.data.statusTransacao !== StatusTransacao.ErroCancelamento)
                            }
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginRight: "10px", marginBottom: "10px" }}
                            size="small"
                            onClick={() => setConfirmandoReembolso(true)}
                            disabled={
                                !carregado ||
                                !vendas.some(v => v.selected) ||
                                vendas.filter(v => v.selected)
                                    .some(v => v.data.statusTransacao !== StatusTransacao.Capturada &&
                                        v.data.statusTransacao !== StatusTransacao.ErroReembolso)
                            }
                        >
                            Reembolsar
                        </Button>
                        <Divider />
                        {carregado && <GridVendasView
                            itens={vendas}
                            selecionar={selecionarVenda}
                        />}
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
