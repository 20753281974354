import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, Drawer, Grid, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { SmsSubmitModel } from "../../../modelos/sms/SmsSubmitModel";
import { FormularioSmsSchema } from "./FormularioSms.validation";
import styles from "./styles/formularioSms.module.scss";
import { StyledDrawer } from "./styles";

interface Props {
  open: boolean;
  onClose: () => void;
  enviar: (data: SmsSubmitModel) => Promise<void>;
  defaultData: SmsSubmitModel;
  loading: boolean;
}

export const FormularioSms = ({
  open,
  onClose,
  defaultData,
  enviar,
  loading,
}: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<SmsSubmitModel>({
    defaultValues: defaultData,
    resolver: yupResolver(FormularioSmsSchema),
  });

  useEffect(() => {
    // const newData = defaultData.pontosEmbarque.filter((element, index) => {
    //   return defaultData.pontosEmbarque.indexOf(element) === index;
    // });

    // reset({ ...defaultData, pontosEmbarque: newData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData]);

  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <form>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>{"Enviar SMS"}</span>
          </OptActionButton>
        </div>
        <Divider />
        <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
          <Grid className={styles.GridItem} item xs={12}>
            <Controller
              name="mensagem"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Mensagem"
                  variant="outlined"
                  className={styles.TextFieldCustom}
                  multiline
                  rows={4}
                  onChange={onChange}
                  value={value}
                  inputProps={{ maxLength: 160 }}
                />
              )}
            />
            <ErrorMessage error={errors.mensagem} />
          </Grid>
        </Grid>
        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
            Cancelar
          </Button>
          <Button
            size="large"
            onClick={handleSubmit(enviar)}
            sx={sxButtonSaveConfig}
          >
            Enviar
          </Button>
        </FooterToolbar>
      </form>
    </StyledDrawer>
  );
};
