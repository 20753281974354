import { StatusEvento } from "../enums/StatusEvento";
import { TipoEvento } from "../enums/TipoEvento";
import { DataRecorrencia } from "../modelos/dtos/DataRecorrencia";
import { Endereco } from "../modelos/dtos/endereco";
import { EventoFormModel } from "../modelos/evento/eventoFormModel";

export const EVENTO_FORM_INITIAL_STATE: EventoFormModel = {
    nome: "",
    descricao: "",
    url: "",
    tipo: TipoEvento.Publico,
    status: StatusEvento.Aberto,
    destino: new Endereco(),
    recorrencia: new DataRecorrencia(),
  };
  