import { Autocomplete, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { DiaSemana } from "../../enums/DiaSemana";
import { MesAno } from "../../enums/MesAno";
import { SemanaRepeticao } from "../../enums/SemanaRepeticao";
import { DataRecorrencia } from "../../modelos/dtos/DataRecorrencia";
import { EventoFormModel } from "../../modelos/evento/eventoFormModel";

interface Props {
  control: Control<EventoFormModel, any>
  setEvento: Function,
  evento: EventoFormModel,
  setValue: Function
}

export const RecorrenciaAnualGrid = ({ control, evento, setEvento, setValue }: Props) => {

  const [listaDiaSemana, setListaDiaSemana] = useState(new Array<any>());
  const [listaSemanaRepeticao, setListaSemanaRepeticao] = useState(new Array<any>());
  const [listaMesAno, setListaMesAno] = useState(new Array<any>());
  const [recorrencia, setRecorrencia] = useState<DataRecorrencia>(evento.recorrencia!);
  const [desabilitarRecorrenciaMensalEm, setDesabilitarRecorrenciaMensalEm] = useState(false);
  const [desabilitarRecorrenciaMensalNa, setDesabilitarRecorrenciaMensalNa] = useState(true);
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 5);
  const minDateString = minDate.toJSON().split('T')[0];
  const maxDateString = maxDate.toJSON().split('T')[0];

  useEffect(() => {
    carregarListaDiaSemana();
    carregarListaSemanaRepeticao();
    carregarListaMesAno();
  }, []);

  function carregarListaDiaSemana() {
    const itens = Object.keys(DiaSemana).filter((item) => {
      return isNaN(Number(item));
    });
    setListaDiaSemana(itens);
  }

  function carregarListaSemanaRepeticao() {
    const itens = Object.keys(SemanaRepeticao).filter((item) => {
      return isNaN(Number(item));
    });
    setListaSemanaRepeticao(itens);
  }

  function carregarListaMesAno() {
    const itens = Object.keys(MesAno).filter((item) => {
      return isNaN(Number(item));
    });
    setListaMesAno(itens);
  }

  function handleOcorrencias(event: any, index: number, evento: EventoFormModel, setEvento: Function, setValue: Function) {
    let ocorrencias = evento.datasOcorrencias;
    let valor = event.target.value;
    if (!ocorrencias) {
      ocorrencias = new Array(valor);
    }
    ocorrencias[index] = valor;

    setEvento((eventoAtual: any) => {
      return {
        ...eventoAtual,
        datasOcorrencias: ocorrencias,
      }
    });

    setValue('datasOcorrencias', ocorrencias);
  }

  function atualizarRecorrencia(propriedade: string, valor: any) {

    switch (propriedade) {
      case "inicio":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, inicio: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, inicio: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, inicio: valor }));
        break;
      case "intervalo":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, intervalo: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, intervalo: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, intervalo: valor }));
        break;
      case "fim":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, fim: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, fim: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, fim: valor }));
        break;
      case "dia":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, dia: valor });
          return {
            ...eventoAtual,
            recorrenciaAnual: { ...eventoAtual.recorrencia, dia: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, dia: valor }));
        break;
      case "posicaoDaSemana":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, posicaoDaSemana: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, posicaoDaSemana: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, posicaoDaSemana: valor }));
        break;
      case "mes":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, mes: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, mes: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, mes: valor }));
        break;
      case "diaDaSemana":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, diaDaSemana: valor });
          return {
            ...eventoAtual,
            recorrenciaAnual: { ...eventoAtual.recorrencia, diaDaSemana: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, diaDaSemana: valor }));
        break;
    }

    return recorrencia;
  }

  function handleRecorrencia(event: any, propriedade: string) {
    let valor = "";
    if (propriedade == 'mes') {
      valor = MesAno[event]
    } else if (propriedade == 'posicaoDaSemana') {
      valor = SemanaRepeticao[event]
    } else if (propriedade == 'diaDaSemana') {
      valor = DiaSemana[event]
    } else {
      valor = event.target.value;
    }

    atualizarRecorrencia(propriedade, valor);
  }

  function handleOpcaoAnual(event: any) {
    const valor = event?.target.value;
    setDesabilitarRecorrenciaMensalEm(false);
    setDesabilitarRecorrenciaMensalNa(false);
    if (valor.toLowerCase() === 'em') {
      setDesabilitarRecorrenciaMensalNa(true);
      setRecorrencia({ ...recorrencia, padrao: true });
      setValue('recorrencia', { ...recorrencia, padrao: true });
    } else if (valor.toLowerCase() === 'na') {
      setDesabilitarRecorrenciaMensalEm(true);
      setRecorrencia({ ...recorrencia, padrao: false });
      setValue('recorrencia', { ...recorrencia, padrao: false });
    }
  }

  return (
    <>
      <Grid item xs={6} marginBottom={2} >
        <Controller
          name="recorrencia.inicio"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              style={{ marginRight: 5 }}
              type="date"
              label="Início"
              value={recorrencia.inicio}
              InputLabelProps={{ shrink: true }}
              onChange={event => (handleRecorrencia(event, 'inicio'))}
              inputProps={{ min: minDateString, max: maxDateString }}
            />
          )}
        />

        <Controller
          name="recorrencia.fim"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              type="date"
              label="Final"
              value={recorrencia.fim}
              InputLabelProps={{ shrink: true }}
              onChange={event => (handleRecorrencia(event, 'fim'))}
              inputProps={{ min: minDateString, max: maxDateString }}
            />
          )}
        />

      </Grid>
      <Grid item xs={6} marginBottom={2}>
        <Controller
          name="recorrencia.intervalo"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              style={{ marginRight: 10 }}
              type="number"
              inputProps={{ min: 0, max: 100, maxLength: 3 }}
              value={recorrencia.intervalo}
              label="A cada"
              onChange={event => (handleRecorrencia(event, 'intervalo'))}
            />
          )}
        />
        <div className="mt-3 d-inline-block"> Ano(s)</div>
      </Grid>
      <RadioGroup name="use-radio-group" defaultValue="em" onChange={handleOpcaoAnual}>
        <Grid item xs={12} marginBottom={2}>
          <FormControlLabel value="em" label="Em" control={<Radio />} />

          <Controller
            name="recorrencia.mes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 200, marginRight: 1 }} size="medium">
                <Autocomplete
                  id='select-label-mes-ano'
                  options={listaMesAno}
                  getOptionLabel={(option: any) => option}
                  onChange={(e, value) => handleRecorrencia(value, 'mes')}
                  renderInput={(params) => <TextField {...params} label="Mês" />}
                  disabled={desabilitarRecorrenciaMensalEm}
                  defaultValue={recorrencia?.mes || ''}
                />
              </FormControl>
            )}
          />

          <Controller
            name="recorrencia.dia"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                label="Dia"
                type="number"
                disabled={desabilitarRecorrenciaMensalEm}
                onChange={event => (handleRecorrencia(event, 'dia'))}
                inputProps={{ min: 1, max: 31, maxLength: 3 }}
                value={recorrencia?.dia} />
            )}
          />
        </Grid>
        <Grid item xs={12} className="d-flex flex-row">
          <FormControlLabel value="na" label="Na" control={<Radio />} />
          <Controller
            name="recorrencia.posicaoDaSemana"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 200, marginRight: 1 }} size="medium">
                <Autocomplete
                  options={listaSemanaRepeticao}
                  getOptionLabel={(option: any) => option}
                  onChange={(e, value) => handleRecorrencia(value, 'posicaoDaSemana')}
                  renderInput={(params) => <TextField {...params} label="Posição da semana" />}
                  disabled={desabilitarRecorrenciaMensalNa}
                  defaultValue={recorrencia?.posicaoDaSemana || ''}
                />
              </FormControl>
            )}
          />

          <Controller
            name="recorrencia.diaDaSemana"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 200, marginRight: 1 }} size="medium">
                <Autocomplete
                  options={listaDiaSemana}
                  getOptionLabel={(option: any) => option}
                  disabled={desabilitarRecorrenciaMensalNa}
                  onChange={(e, value) => handleRecorrencia(value, 'diaDaSemana')}
                  renderInput={(params) => <TextField {...params} label="Dia" />}
                  defaultValue={recorrencia?.diaDaSemana || ''}
                />
              </FormControl>
            )}
          />

          <label className="mt-3 mr-1">De</label>

          <Controller
            name="recorrencia.mes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 200, marginLeft: 1 }} size="medium">
                <Autocomplete
                  id='select-label-mes-ano'
                  options={listaMesAno}
                  getOptionLabel={(option: any) => option}
                  onChange={(e, value) => handleRecorrencia(value, 'mes')}
                  renderInput={(params) => <TextField {...params} label="Mês" />}
                  disabled={desabilitarRecorrenciaMensalNa}
                  defaultValue={recorrencia?.mes || ''}
                />
              </FormControl>
            )}
          />

        </Grid>
      </RadioGroup>
    </>
  );
};