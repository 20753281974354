import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { CategoriaFormModel } from "../modelos/categoria/categoriaFormModel";
import { CategoriaSearchResponse } from "../modelos/dtos/CategoriaSearchResponse";
import { PaginatedCategoriaRequest } from "../modelos/search/PaginatedCategoriaRequest";
import { protectedResources } from "../authConfig";

export const useCategoriasService = () => {
  const { post, remove, put, gridSearch, get, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Categoria`;

  const salvarCategoria = async (data: CategoriaFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerCategoria = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarCategoria = async (itemId: string, data: CategoriaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridCategoriasPaginated = async (data: SearchRequest<PaginatedCategoriaRequest>) => {
    return gridPagedListSearch<CategoriaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  const carregarCategoriaPorId = async (id :string) => {
    return get<CategoriaFormModel>(`${baseApi}/${id}`);
  };

  const carregarCategorias = async () => {
    return get<CategoriaFormModel>(`${baseApi}/todos`);
  };

  return {
    salvarCategoria,
    removerCategoria,
    editarCategoria,
    buscarGridCategoriasPaginated,
    carregarCategoriaPorId,
    carregarCategorias,
  };
};