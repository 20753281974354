import { useApi } from "../compartilhado/hooks/api";
import { ComboFormModel } from "../modelos/rota/comboFormModel";
import { protectedResources } from "../authConfig";
import { SeoArquivoModel, SeoEventoModel, SeoPaginaModel } from "../modelos/seo";

export const useSeoService = () => {
  const { post, get } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Seo`;

  const carregarInfosEvento = async (idEvento :string) => {
    return get<SeoEventoModel>(`${baseApi}/evento/${idEvento}`);
  };

  const salvarInfosEvento = async (params: SeoEventoModel) => {
    return post(`${baseApi}/evento`, params);
  };

  const carregarInfosPagina = async (idPagina :string) => {
    return get<SeoPaginaModel>(`${baseApi}/pagina/${idPagina}`);
  };

  const salvarInfosPagina = async (params: SeoPaginaModel) => {
    return post(`${baseApi}/pagina`, params);
  };

  const carregarInfosArquivo = async (idArquivo :string) => {
    return get<SeoArquivoModel>(`${baseApi}/global/${idArquivo}`);
  };

  const salvarInfosArquivo = async (params: SeoArquivoModel) => {
    return post(`${baseApi}/global`, params);
  };

  return {
    carregarInfosEvento,
    salvarInfosEvento,
    carregarInfosPagina,
    salvarInfosPagina,
    carregarInfosArquivo,
    salvarInfosArquivo
  };
};