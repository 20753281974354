import { Button } from "react-bootstrap"
import { StyledTableCell, StyledTableRow } from "../../styles"
import { useEffect, useState } from "react";
import { CORES_STATUS_PASSAGEIRO } from "../../associaPassageiros";

interface PassageirosTableRowProps {
    passageiro: any;
    corOriginal: number;
    corAtual: number;
    indexPassageiro: number;
    updatePassageiros: (index: number, cor: number) => void;
}

export const PassageiroTableRow = ({ passageiro, corOriginal, corAtual, indexPassageiro, updatePassageiros }: PassageirosTableRowProps) => {
    const [corAtualState, setCorAtualState] = useState<number>(corOriginal === corAtual ? corOriginal : corAtual);

    function applyBgColor(cor: number) {
        switch (cor) {
            case CORES_STATUS_PASSAGEIRO.branco:
                return "bg-white"
            case CORES_STATUS_PASSAGEIRO.verde:
                return "bg-success-passageiros"
            case CORES_STATUS_PASSAGEIRO.azul:
                return "bg-info-passageiros"
            case CORES_STATUS_PASSAGEIRO.vermelho:
                return "bg-danger-passageiros"
            case CORES_STATUS_PASSAGEIRO.cinza:
                return "bg-secondary-passageiros"
            case CORES_STATUS_PASSAGEIRO.amarelo:
                return "bg-warning-passageiros"
        }
    }

    function changeBgColor(corAtual: number) {
        switch (corAtual) {
            case CORES_STATUS_PASSAGEIRO.branco:
                setCorAtualState(CORES_STATUS_PASSAGEIRO.verde)
                updatePassageiros(indexPassageiro, CORES_STATUS_PASSAGEIRO.verde)
                break;
            case CORES_STATUS_PASSAGEIRO.verde:
                setCorAtualState(CORES_STATUS_PASSAGEIRO.branco)
                updatePassageiros(indexPassageiro, CORES_STATUS_PASSAGEIRO.branco)
                break;
            case CORES_STATUS_PASSAGEIRO.azul:
                setCorAtualState(CORES_STATUS_PASSAGEIRO.vermelho)
                updatePassageiros(indexPassageiro, CORES_STATUS_PASSAGEIRO.vermelho)
                break;
            case CORES_STATUS_PASSAGEIRO.vermelho:
                setCorAtualState(CORES_STATUS_PASSAGEIRO.azul)
                updatePassageiros(indexPassageiro, CORES_STATUS_PASSAGEIRO.azul)
                break;
            case CORES_STATUS_PASSAGEIRO.cinza:
                setCorAtualState(CORES_STATUS_PASSAGEIRO.amarelo)
                updatePassageiros(indexPassageiro, CORES_STATUS_PASSAGEIRO.amarelo)
                break;
            case CORES_STATUS_PASSAGEIRO.amarelo:
                setCorAtualState(CORES_STATUS_PASSAGEIRO.cinza)
                updatePassageiros(indexPassageiro, CORES_STATUS_PASSAGEIRO.cinza)
                break;
        }
    }

    return (
        <StyledTableRow className={applyBgColor(corAtualState)}>
            <StyledTableCell align="left">{passageiro.usuario.nome}</StyledTableCell>
            <StyledTableCell align="center">{passageiro.numeroPassageiro}</StyledTableCell>
            <StyledTableCell align="center">{passageiro.status}</StyledTableCell>
            <StyledTableCell align="center">
                <Button
                    className="float-start w-100"
                    color="primary"
                    onClick={() => changeBgColor(corAtualState)}
                >
                    {
                        (corAtualState === CORES_STATUS_PASSAGEIRO.verde || corAtualState === CORES_STATUS_PASSAGEIRO.azul || corAtualState === CORES_STATUS_PASSAGEIRO.amarelo)
                            ? "Desembarcar" : corAtualState === CORES_STATUS_PASSAGEIRO.cinza ? "Transferir" : "Embarcar"
                    }
                </Button>
            </StyledTableCell>
        </StyledTableRow>
    )
}