import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { ItemSearchResponse } from "../modelos/dtos/ItemSearchResponse";
import { ItemFormModel } from "../modelos/item/ItemFormModel";
import { protectedResources } from "../authConfig";

export const useItensService = () => {
  const { get, post, remove, put, gridPagedListSearch } = useApi({scopes: protectedResources.apiCupom.scopes});

  const baseApi = `cupom/Cupom`;

  const salvarItem = async (data: ItemFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerItem = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarItem = async (itemId: string, data: ItemFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridItensPaginated = async (data: SearchRequest<{}>) => {
    return gridPagedListSearch<ItemSearchResponse>(`${baseApi}/paginado/${ (data.page + 1) ?? 1}/${data.pageSize ?? 10}`, {...data.filtro});
  };

  const carregarCupomPorUsuario = async (id :string) => {
    return get<ItemFormModel>(`${baseApi}/${id}/usuario`);
  };

  const carregarCupomPorEvento = async (id :string) => {
    return get<ItemFormModel>(`${baseApi}/${id}/evento`);
  };

  return {
    salvarItem,
    removerItem,
    editarItem,
    buscarGridItensPaginated,
    carregarCupomPorUsuario,
    carregarCupomPorEvento
  };
};
