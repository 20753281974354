import { Autocomplete, Grid, MenuItem, Select, TextField } from "@mui/material";
import Icon from "@mdi/react";
import { mdiPlusCircle, mdiMinusCircle } from "@mdi/js";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Colors } from "../../compartilhado/colors";
import { Filtro } from "../../modelos/filtro/filtro";
import styles from "./styles/index.module.scss";
import { TipoInput } from "../../enums/tipoInput";

interface Props {
    opcoes: Array<any>;
    pesquisa?: Function | null;
}


export const FiltroBusca = ({ opcoes, pesquisa }: Props) => {

    const filtroForm = useForm();
    const [filtro, setFiltro] = useState<Array<Filtro>>([{ busca: '', coluna: opcoes[0].valor }]);
    const [opcoesFiltro, setOpcoesFiltro] = useState<Array<any>>(opcoes || new Array<any>());

    const {
        control
    } = filtroForm;

    const filtrar = (event: any) => {

        event.preventDefault();
        let jsonBusca: any;
        jsonBusca = {};
        filtro.forEach((item: Filtro) => {
            if (item.coluna && item.busca) {
                jsonBusca[item.coluna] = item.busca;
            }
        });

        if (pesquisa) {
            pesquisa(jsonBusca);
        }
    }

    function handleBusca(event: any, indice: number) {
        let valor = ""
        if (event.valor) {
            valor = event.valor;
        } else {
            valor = event.target.value;
        }

        const novoValor = filtro.map((item, i) => {
            if (i === indice) {
                item.busca = valor;
            }
            return item;
        });

        setFiltro(novoValor);
    }

    function handleColuna(event: any, indice: number) {
        const valor = event.valor;
        const novoValor = filtro.map((item, i) => {
            if (i === indice) {
                item.coluna = valor;
                item.busca = "";
            }
            return item;
        });

        const novoValorOpcoes = opcoesFiltro.map(item => {

            const opcoesSelecionadas = filtro.map((item) => {
                if (item.coluna) {
                    return item.coluna;
                }
                return false;
            })

            item.desabilitado = false;
            if (opcoesSelecionadas.includes(item.valor)) {
                item.desabilitado = true;
            }
            return item;
        })
        setOpcoesFiltro(novoValorOpcoes);
        setFiltro(novoValor);
    }

    function adicionarFiltro() {
        setFiltro([...filtro, { busca: '', coluna: '' }]);
    }

    function limparFiltros() {
        const novoValorOpcoes = opcoesFiltro.map(item => {
            delete item.desabilitado;
            return item;
        });
        setOpcoesFiltro(novoValorOpcoes);
        setFiltro([{ busca: '', coluna: '' }]);
    }

    function removerFiltro(indice: number) {
        const coluna = filtro[indice].coluna;

        const novoValorOpcoes = opcoesFiltro.map(item => {
            if (item.valor === coluna) {
                delete item.desabilitado;
            }
            return item;
        })
        setOpcoesFiltro(novoValorOpcoes);
        filtro.splice(indice, 1);
        setFiltro(filtro);
    }

    function podeAdicionar() {
        var opcoesDisponiveis = opcoesFiltro.filter(opcao => { return !opcao.desabilitado });
        return opcoesDisponiveis.length === 0;
    }

    function obterConfiguracaoInput(coluna: string) {
        if (coluna && opcoes && opcoes.length) {
            const opcaoSelecionada = opcoes.filter(opacao => { return opacao.valor === coluna });
            return opcaoSelecionada[0];
        }

        return null;

    }

    function inptimirInput(coluna: string, filtro: Filtro, indice: number) {
        let input = imprimirInputTexto(filtro, indice);
        const configuracao = obterConfiguracaoInput(coluna);
        if (configuracao) {
            switch (configuracao.tipoInput) {
                case TipoInput.Texto:
                    input = imprimirInputTexto(filtro, indice);
                    break;
                case TipoInput.Numero:
                    input = imprimirInputNumero(filtro, indice);
                    break;

                case TipoInput.Select:
                    input = imprimirInputSelect(filtro, indice, configuracao);
                    break;
                case TipoInput.Data:
                    input = imprimirInputData(filtro, indice, configuracao);
                    break;
            }
        }

        return input;
    }

    function imprimirInputTexto(filtro: Filtro, indice: number) {
        return (
            <Controller
                name="busca"
                control={control}
                render={({ field: { onChange, } }) => (
                    <TextField
                        className={styles.TextFieldCustom}
                        label={filtro.coluna}
                        variant="outlined"
                        onChange={((event) => { handleBusca(event, indice) })}
                        value={filtro.busca}
                    />
                )}
            />
        );
    }

    function imprimirInputNumero(filtro: Filtro, indice: number) {
        return (
            <Controller
                name="busca"
                control={control}
                render={({ field: { onChange, } }) => (
                    <TextField
                        type="number"
                        className={styles.TextFieldCustom}
                        label={filtro.coluna}
                        variant="outlined"
                        inputProps={{ maxLength: 3 }}
                        onChange={((event) => { handleBusca(event, indice) })}
                        value={filtro.busca}
                    />
                )}
            />
        );
    }

    function imprimirInputSelect(filtro: Filtro, indice: number, configuracao: any) {
        return (
            <Controller
                name="busca"
                control={control}
                render={({ field: { onChange, } }) => (
                    <Autocomplete
                        options={configuracao.opcoes}
                        getOptionLabel={(option: any) => option.texto}
                        getOptionDisabled={(option: any) => option.desabilitado && option.desabilitado === true}
                        className={styles.TextFieldCustom}
                        onChange={((event, value) => {
                            handleBusca(value, indice)
                        })}
                        renderInput={(params) => <TextField {...params} />}
                    />
                )}
            />
        );
    }

    function imprimirInputData(filtro: Filtro, indice: number, configuracao: any) {
        return (
            <>
                <Controller
                    name="busca"
                    control={control}
                    render={({ field: { onChange, } }) => (
                        <TextField
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            className={styles.TextFieldCustom}
                            label={filtro.coluna}
                            variant="outlined"
                            onChange={((event) => { handleBusca(event, indice) })}
                            value={filtro.busca}
                        />
                    )}
                />
            </>
        );
    }


    return (
        <>
            <form onSubmit={filtrar}>
                <Grid container rowSpacing={1} columns={12} px={1.5}>
                    {filtro?.map((filtro, indice) => {
                        return (
                            <span className="d-flex flex-row col-3 mb-3" key={indice} >
                                <Grid item xs={6} marginRight={2}>
                                    {inptimirInput(filtro.coluna!, filtro, indice)}

                                </Grid>

                                <Grid item xs={6}>
                                    <Controller
                                        name="coluna"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Autocomplete
                                                options={opcoesFiltro}
                                                getOptionLabel={(option) => option.texto}
                                                getOptionDisabled={(option) => option.desabilitado && option.desabilitado === true}
                                                className={styles.TextFieldCustom}
                                                onChange={((event, value) => {
                                                    handleColuna(value, indice)
                                                })}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4} >

                                    <Button
                                        variant="contained"
                                        color="info"
                                        style={{ marginRight: "5px" }}
                                        placeholder="adicionar"
                                        onClick={() => removerFiltro(indice)}
                                    >
                                        <Icon path={mdiMinusCircle} size={1} color={Colors.primary} />
                                    </Button>
                                </Grid>
                            </span>
                        )

                    })}
                    <Grid item xl={2} xs={3} >
                        <Button
                            disabled={podeAdicionar()}
                            variant="contained"
                            color="info"
                            style={{ marginRight: "5px" }}
                            placeholder="adicionar"
                            onClick={adicionarFiltro}>
                            <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
                        </Button>

                        <Button type="submit">Filtrar</Button>
                        <Button
                            color="primary"
                            type="button"
                            style={{ marginLeft: "5px" }}
                            placeholder="adicionar"
                            onClick={limparFiltros}>Limpar Filtros</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
