import React, { useEffect, useState } from "react";
import { mdiTrashCanOutline } from "@mdi/js";
import { Colors } from "../../compartilhado/colors";
import Icon from "@mdi/react";
import { Button } from "@mui/material";

const AdicionarImagem = ({ image, handleUpdateState, label, isDesktop }: any) => {
  const [preview, setPreview] = useState<string>("");
  const [base64Image, setBase64Image] = useState<string>("");

  useEffect(() => {
    if (image) {
      setPreview(image);
    }
  }, [image]);

  const handleImageUpload = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result as string);
        convertToBase64(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const convertToBase64 = (image: string | ArrayBuffer | null) => {
    if (image && typeof image === "string") {
      const imageBase64 = image.split(",")[1];
      setBase64Image(imageBase64);
    }
  };

  useEffect(() => {
    if (base64Image) {
      handleUpdateState(base64Image as any);
    }
  }, [base64Image]);

  return (
    <div
      style={{
        border: "1px solid #cccccc",
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
      }}
    >
      <label className="mb-2">{label}</label>
      {preview && (
        <div className="">
          <img
            style={{ marginBottom: "16px", marginRight: '5px', borderRadius: "8px" }}
            src={preview}
            alt="Preview"
            width={199}
            height={199}
          />
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setPreview("");
              handleUpdateState(null);
            }}
          >
            <Icon path={mdiTrashCanOutline} size={1} color={Colors.white} />
          </Button>
        </div>
      )}
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      <div style={{ fontSize: "12px", color: "gray" }}>
        {
          isDesktop ? '*Dimensão adequada da imagem 1325 x 340 px' : '*Dimensão adequada da imagem 320 x 480 px'
        }        
      </div>
      <div style={{ fontSize: "12px", color: "gray", marginBottom: "5px" }}>
        *Formatos suportados: (png, jpg, jpeg)
      </div>
    </div>
  );
};

export default AdicionarImagem;
