import React from "react";
import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import { OptGridRef } from "@optsol/react";
import { VendaTableItem } from "../../../modelos/dtos/VendaTableItem";
import { obterLegivel } from "../../../enums/StatusTransacao";
import styles from "./styles/gridVendas.module.scss";

interface Props {
  itens: Array<VendaTableItem>;
  selecionar: Function
}

export const GridVendasView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      itens,
      selecionar
    },
    ref
  ) => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        border: "1px solid #ccc",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

    return (
      <>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nome Usuário</StyledTableCell>
                <StyledTableCell align="left">Horários de Embarque</StyledTableCell>
                <StyledTableCell align="left">PSP Adyen</StyledTableCell>
                <StyledTableCell align="left">Preço Final</StyledTableCell>
                <StyledTableCell align="left">Status Transação</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                itens?.filter(venda => venda.data.ativo).length === 0 ?
                  <StyledTableRow><StyledTableCell colSpan={2} align="center"> Não há registros a serem exibidos </StyledTableCell></StyledTableRow> :
                  <StyledTableRow></StyledTableRow>
              }
              {itens?.filter(venda => venda.data.ativo).map((venda) => {
                return (
                  <StyledTableRow
                    key={venda.data.id}
                    onClick={() => selecionar(venda.data.id)}
                    className={styles.LinhaVenda}>
                    <StyledTableCell align="left" className={venda.selected ? styles.VendaSelecionada : ''}>{venda.data.nomeUsuario}</StyledTableCell>
                    <StyledTableCell align="left" className={venda.selected ? styles.VendaSelecionada : ''}>{!!venda.data.horarioIdaEmbarqueStr && `IDA: ${venda.data.horarioIdaEmbarqueStr}`} {!!venda.data.horarioVoltaEmbarqueStr && `VOLTA: ${venda.data.horarioVoltaEmbarqueStr}`}</StyledTableCell>
                    <StyledTableCell align="left" className={venda.selected ? styles.VendaSelecionada : ''}>{venda.data.referenciaPsp}</StyledTableCell>
                    <StyledTableCell align="left" className={venda.selected ? styles.VendaSelecionada : ''}>R$ {venda.data.precoFinal.toFixed(2)}</StyledTableCell>
                    <StyledTableCell align="left" className={venda.selected ? styles.VendaSelecionada : ''}>{obterLegivel(venda.data.statusTransacao)}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
