import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { CadastroEvento } from "../paginas/evento/Cadastro/CadastroEvento";
import { GridEventos } from "../paginas/evento/ListarEvento/GridEvento";
import { VerEvento } from "../paginas/evento/Visualizar/verEvento";
export const EventosRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Eventos.Principal}>
            <GridEventos />
          </Route>
          <Route exact path={Rotas.Eventos.Cadastro}>
            <CadastroEvento />
          </Route>
          <Route exact path={Rotas.Eventos.Ver}>
            <VerEvento />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
