import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { EmpresaFormModel } from "../../../modelos/empresa/empresaFormModel";
import { useEmpresasService } from "../../../servicos/empresas.service";
import styles from "./Styles/vincularMotorista.module.scss";
import { VincularMotoristaSchema } from "./VincularMotorista.validation";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";


export const VincularMotorista = () => {
  
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const loading = false;
  const { idEmpresa } = useParams<{idEmpresa?: string}>();
  const [salvarLoading, setSalvarLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { salvarEmpresa, editarEmpresa } =
  useEmpresasService();
  const edicao = Boolean(idEmpresa);
  console.log(idEmpresa, edicao);
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  async function salvar(value: EmpresaFormModel) {
    setSalvarLoading(true);
    try {
      const estaEditando = !!value.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (edicao) {
        await salvarEmpresa(value);
      } else {
        await editarEmpresa(value.id!, value);
      }

      setSalvarLoading(false);
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
    } catch {
      setSalvarLoading(false);
    }
  }
  const onClose = () => {history.push("/empresas");};
  const empresaForm = useForm<EmpresaFormModel>({
    resolver: yupResolver(VincularMotoristaSchema),
  });

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao]);
    })()
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = empresaForm;

  return (
    <form>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              Vincular motorista
            </span>
          </OptActionButton>
        </div>
        <Divider />
        <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="telefone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  className={styles.TextFieldCustom}
                  label="Telefone"
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <ErrorMessage error={errors.telefone} />
          </Grid>

        </Grid>

        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose}  sx={sxButtonCancelConfig} >
            Cancelar
          </Button>
          <Button
            size="large"
            onClick={handleSubmit(salvar)}
            sx={sxButtonSaveConfig}
          >
            Salvar
          </Button>
        </FooterToolbar>
    </form>
  );
};
