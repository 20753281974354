import { mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptGridRef,
  OptGridRequest,
  OptSearchField,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { FiltroBusca } from "../../../componentes/filtro";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { VEICULO_FORM_INITIAL_STATE } from "../../../constantes/VeiculoFormInitialState";
import { TipoInput } from "../../../enums/tipoInput";
import { VeiculoSearchResponse } from "../../../modelos/dtos/VeiculoSearchResponse";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { PaginatedVeiculoRequest } from "../../../modelos/search/PaginatedVeiculoRequest";
import { VeiculoFormModel } from "../../../modelos/veiculo/veiculoFormModel";
import { VeiculoModel } from "../../../modelos/veiculo/veiculoModel";
import { Rotas } from "../../../rotas";
import { useVeiculosService } from "../../../servicos/veiculos.service";
import { GridFrotasView } from "./GridFrotasView";
import styles from "./Styles/GridFrotas.module.scss";
import { IGeneric } from "../../empresa/Cadastro/FormularioEmpresa";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";

export const GridFrotas = ({
  isFromCadastroEmpresa,
  needToUpdateGrid,
  idEmpresa,
  dadosParaEditar
}: IGeneric) => {
  const [drawerFormulario, setDrawerFormularioAberto] = useState(false);
  const [filtro, setFiltro] = useState<VeiculoFormModel>();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] =
    useState<boolean>(false);

  const [Veiculo, setVeiculo] = useState<VeiculoFormModel>(
    VEICULO_FORM_INITIAL_STATE
  );

  const { buscarGridVeiculosPaginated, removerVeiculo } = useVeiculosService();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  const opcoesFiltro = [
    { texto: "Placa", valor: "placa", tipoInput: TipoInput.Texto },
    {
      texto: "Numero de Assentos",
      valor: "numeroAssentos",
      tipoInput: TipoInput.Texto,
    },
    { texto: "Ano", valor: "ano", tipoInput: TipoInput.Texto },
  ];

  function pesquisaComFiltro(filtro: VeiculoFormModel) {
    setFiltro(filtro);
  }

  const carregar = (
    query: OptGridRequest,
    VeiculoSearchRequest: PaginatedSearchRequest = {
      filtro,
    }
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro: VeiculoSearchRequest.filtro,
    };
    return buscarGridVeiculosPaginated(request,idEmpresa);
  };
  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  async function onClickDeleteVeiculoHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerVeiculo(Veiculo.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setDrawerFormularioAberto(false);
      setVeiculo(VEICULO_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }

  function onClickDeleteVeiculo(rowData: VeiculoSearchResponse) {
    setVeiculo(rowData);
    setConfirmandoExclusao(true);
  }

  function onClickEditVeiculo(rowData: VeiculoSearchResponse) {
    if (rowData.id) {
      console.log(rowData.id)
      dadosParaEditar(rowData.id)
      // history.push(`/frotas/cadastro/${rowData.id}`);
    }
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  function cadastrarVeiculo() {
    history.push("/frotas/cadastro");
  }

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, [filtro]);

  useEffect(() => {
    if (needToUpdateGrid) {
      if (gridRef.current) {
        recarregar();
      }
    }
  }, [needToUpdateGrid]);
  
  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao]);
    })()
  }, []);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir veículo"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteVeiculoHandler}
      >
        Deseja confirmar a exclusão do veículo ? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>

      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        {!isFromCadastroEmpresa && (
          <>
            <OptActionToolbar
              goBackRoute={Rotas.Home}
              title="veículo"
              background={Colors.white}
              color={Colors.gray1}
              clearMargin
            ></OptActionToolbar>
            <OptGridContainer>
              <FiltroBusca opcoes={opcoesFiltro} pesquisa={pesquisaComFiltro} />
              <div className={styles.ButtonSection}>
                <Tooltip title={"Cadastrar veículo"}>
                  <Button
                    variant="contained"
                    color="info"
                    style={{ marginRight: "5px", marginBottom: "11px" }}
                    placeholder="Cadastrar veículo"
                    size="large"
                    onClick={cadastrarVeiculo}
                  >
                    Cadastrar Veículo{" "}
                    <Icon
                      path={mdiPlusCircle}
                      size={1}
                      color={Colors.primary}
                    />
                  </Button>
                </Tooltip>
              </div>
            </OptGridContainer>
          </>
        )}
        <GridFrotasView
          carregar={carregar}
          abrirDrawerFormulario={cadastrarVeiculo}
          onClickDelete={onClickDeleteVeiculo}
          onClickEdit={onClickEditVeiculo}
          gridRef={gridRef}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
