import { RotaFormModel } from "../modelos/rota/rotaFormModel";

export const ROTA_FORM_INITIAL_STATE: RotaFormModel = {
  nome: "",
  horarioIdaPartida: null,
  horarioIdaChegada: null,
  horarioVoltaPartida: null,
  horarioVoltaChegada: null,
  idOcorrencia: ""
};
  