import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { MASCARA_NUMEROS } from "../../../constantes/mascaras/MascaraNumeros";
import { ROTA_FORM_INITIAL_STATE } from "../../../constantes/RotaFormInitialState";
import { RotaFormModel } from "../../../modelos/rota/rotaFormModel";
import { Rotas } from "../../../rotas";
import { useRotasService } from "../../../servicos/rotas.service";
import { FormularioRotaSchema } from "./formularioRota.validation";
import styles from "./styles/formularioRota.module.scss";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";

export const FormularioRota = () => {
  
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const { idOcorrencia } = useParams<{idOcorrencia: string}>();
  const { idRota } = useParams<{idRota?: string}>();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rota, setRota] = useState<RotaFormModel>(ROTA_FORM_INITIAL_STATE);
  const { salvarRota, editarRota, carregarRotaPorId } = useRotasService();
  const edicao = Boolean(idRota);
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  
  function handleNome (event: any) {
    const valor = event?.target.value;
    setRota({
      ...rota,
      nome: valor
    });
    setValue('nome', valor);
  }

  function handleHorarioIdaPartida (event: any) {
    const value = new Date(event)
    setRota({
      ...rota,
      horarioIdaPartida: value
    });
    setValue('horarioIdaPartida', value);
  }

  function handleHorarioIdaChegada (event: any) {
    const value = new Date(event)
    setRota({
      ...rota,
      horarioIdaChegada: value
    });
    setValue('horarioIdaChegada', value);
  }

  function handleHorarioVoltaPartida (event: any) {
    const value = new Date(event)
    setRota({
      ...rota,
      horarioVoltaPartida: value
    });
    setValue('horarioVoltaPartida', value);
  }

  function handleHorarioVoltaChegada (event: any) {
    const value = new Date(event)
    setRota({
      ...rota,
      horarioVoltaChegada: value
    });
    setValue('horarioVoltaChegada', value);
  }

  async function carregarRota(idRota: string) {
    const response = await carregarRotaPorId(idRota) as unknown as RotaFormModel;
    if (response) {
      setRota(response);
      setValue('id', response.id);
      setValue('nome', response.nome);
      setValue('idOcorrencia', response.idOcorrencia);
      setValue('horarioIdaPartida', response.horarioIdaPartida);
      setValue('horarioIdaChegada', response.horarioIdaChegada);
      setValue('horarioVoltaPartida', response.horarioVoltaPartida);
      setValue('horarioVoltaChegada', response.horarioVoltaChegada);
    }
  }

  useEffect(() => {
    if (idRota) {
      carregarRota(idRota);
    }
  }, [idRota]);

  useEffect(() => {
    if (idOcorrencia) {
      setRota({
        ...rota,
        idOcorrencia
      });
      setValue('idOcorrencia', idOcorrencia);
    }
  }, [idOcorrencia]);
  

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente]);
    })()
  }, []);


  async function salvar() {
    console.log('FUNÇÃO DE SALVAR DISPARADA')
    setLoading(true);
    try {
      const estaEditando = !!rota.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (edicao) {
        await editarRota(rota.id!, rota);
      } else {
        await salvarRota(rota);
      }

      setLoading(false);
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const onClose = () => {
    if(idRota)
      history.push(`/rotas/ver/${idOcorrencia}/${idRota}`)
    else
      history.push(`/ocorrencias/ver/${idOcorrencia}`)
  };
  const rotaForm = useForm<RotaFormModel>({
    resolver: yupResolver(FormularioRotaSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = rotaForm;

  console.log(errors)

  return (
    <form>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              {edicao ? "Editar rota" : "Cadastro de rota"}
            </span>
          </OptActionButton>
        </div>
        <Divider />
        <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
          
          <Grid className={styles.GridItem} item xs={12}>
            <Controller
              name="nome"
              control={control}
              render={({ field: { onChange } }) => (
                <TextField
                  className={styles.TextFieldCustom}
                  label="Nome"
                  required={true}
                  variant="outlined"
                  onChange={handleNome}
                  value={rota.nome}
                />
              )}
            />
           
            <ErrorMessage error={errors.nome} />
          </Grid>

          <Grid className={styles.GridItem} item xs={3}>
            <Controller
              name="horarioIdaPartida"
              control={control}
              render={({ field: { onChange } }) => (
                <DateTimePicker
                  label="Horário de Partida da Ida *"
                  value={rota.horarioIdaPartida ?? null}
                  onChange={handleHorarioIdaPartida}
                  minDate={new Date()}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />
           
            <ErrorMessage error={errors.horarioIdaPartida} />
          </Grid>
          
          <Grid className={styles.GridItem} item xs={3}>
            <Controller
              name="horarioIdaChegada"
              control={control}
              render={({ field: { onChange } }) => (
                <DateTimePicker
                  label="Horário de Chegada da Ida *"
                  value={rota.horarioIdaChegada ?? null}
                  onChange={handleHorarioIdaChegada}
                  minDate={rota.horarioIdaPartida ?? new Date()}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />
           
            <ErrorMessage error={errors.horarioIdaChegada} />
          </Grid>
          
          <Grid className={styles.GridItem} item xs={3}>
            <Controller
              name="horarioVoltaPartida"
              control={control}
              render={({ field: { onChange } }) => (
                <DateTimePicker
                  label="Horário de Partida da Volta *"
                  value={rota.horarioVoltaPartida ?? null}
                  onChange={handleHorarioVoltaPartida}
                  minDate={new Date()}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />
           
            <ErrorMessage error={errors.horarioVoltaPartida} />
          </Grid>
          
          <Grid className={styles.GridItem} item xs={3}>
            <Controller
              name="horarioVoltaChegada"
              control={control}
              render={({ field: { onChange } }) => (
                <DateTimePicker
                  label="Horário de Chegada da Volta *"
                  value={rota.horarioVoltaChegada ?? null}
                  onChange={handleHorarioVoltaChegada}
                  minDate={rota.horarioVoltaPartida ?? new Date()}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />
           
            <ErrorMessage error={errors.horarioVoltaChegada} />
          </Grid>

        </Grid>

        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose}  sx={sxButtonCancelConfig} >
            Cancelar
          </Button>
          <Button
            size="large"
            onClick={handleSubmit(salvar)}
            sx={sxButtonSaveConfig}
          >
            Salvar
          </Button>
        </FooterToolbar>
    </form>
  );
};
