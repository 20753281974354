
export const OBJETO_PLANILHA_IMPORTAR_EVENTO = {
  "Usuário/Acompanhante": "",
  "Nome do Usuário/Acompanhante": "",
  "DDD": "",
  "Telefone": "",
  "E-mail": "",
  "Tipo de Documento": "",
  "Documento": "",
  "Estado": "",
  "Órgão Expeditor": "",
  "ID Ponto de Embarque": "",
  "É passageiro": "",
}