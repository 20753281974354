import * as Yup from "yup";
import { Passageiro } from "../../../modelos/sms/Passageiro";
import { SmsPontosDeEmbarqueSubmit } from "../../../modelos/sms/SmsPontosDeEmbarqueSubmit";
import { SmsSubmitModel } from "../../../modelos/sms/SmsSubmitModel";
const PassageiroSchema: Yup.SchemaOf<Passageiro> = Yup.object().shape({
  passageiroId: Yup.number().required("Campo obrigatório"),
  telefone: Yup.string().required("Campo obrigatório"),
});

const PontosDeEmbarqueSchema: Yup.SchemaOf<SmsPontosDeEmbarqueSubmit> =
  Yup.object().shape({
    cidadeOrigemId: Yup.number().required("Campo obrigatório"),
    eventoId: Yup.number().required("Campo obrigatório"),
    dataHoraKey: Yup.string().required("Campo obrigatório"),
  });

export const FormularioSmsSchema: Yup.SchemaOf<SmsSubmitModel> =
  Yup.object().shape({
    mensagem: Yup.string().required("Campo obrigatório"),
    idsPassageiros: Yup.array().required("Campo obrigatório"),
  });
