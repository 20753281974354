import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { EmpresaSearchResponse } from "../modelos/dtos/EmpresaSearchResponse";
import { EmpresaFormModel } from "../modelos/empresa/empresaFormModel";
import { PaginatedEmpresaRequest } from "../modelos/search/PaginatedEmpresaRequest";
import { protectedResources } from "../authConfig";

export const useEmpresasService = () => {
  const { post, remove, put, gridSearch, get, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Empresa`;

  const salvarEmpresa = async (data: EmpresaFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerEmpresa = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarEmpresa = async (itemId: string, data: EmpresaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridEmpresasPaginated = async (data: SearchRequest<PaginatedEmpresaRequest>) => {
    return gridPagedListSearch<EmpresaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  const carregarEmpresaPorId = async (id :string) => {
    return get<EmpresaFormModel>(`${baseApi}/${id}`);
  };

  const carregarEmpresas = async () => {
    return get<EmpresaFormModel>(`${baseApi}/todos`);
  };

  return {
    salvarEmpresa,
    removerEmpresa,
    editarEmpresa,
    buscarGridEmpresasPaginated,
    carregarEmpresaPorId,
    carregarEmpresas,
  };
};