import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { CadastroOcorrencia } from "../paginas/ocorrencia/cadastro/cadastroOcorrencia";
import { GridOcorrencia } from "../paginas/ocorrencia/lista/GridOcorrencia";
import { VerOcorrencia } from "../paginas/ocorrencia/visualizar/verOcorrencia";
import { RelatorioPassageirosOcorrencia } from "../paginas/ocorrencia/relatorio/relatorioPassageirosOcorrencia";

export const OcorrenciaRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Ocorrencias.Principal}>
            <GridOcorrencia />
          </Route>
          <Route exact path={Rotas.Ocorrencias.Cadastro}>
            <CadastroOcorrencia />
          </Route>
          <Route exact path={Rotas.Ocorrencias.Ver}>
            <VerOcorrencia />
          </Route>
          <Route exact path={Rotas.Ocorrencias.Relatorio}>
            <RelatorioPassageirosOcorrencia />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
