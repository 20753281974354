import {
    mdiArrowLeft,
    mdiTrashCanOutline,
    mdiPencilOutline,
    mdiDeleteOutline,
    mdiArrowDown,
    mdiPlus,
    mdiContentSave
} from "@mdi/js";
import Icon from "@mdi/react";
import { Autocomplete, Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { OptActionButton, OptConfirmationDialog, OptDialog, OptGridRef } from "@optsol/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Colors } from "../../../compartilhado/colors";
import { useSnackbar } from "notistack";
import { useRotasService } from "../../../servicos/rotas.service";
import { Controller, useForm } from "react-hook-form";
import styles from "./styles/verRota.module.scss";
import { format } from "date-fns";
import { TrechoFormModel } from "../../../modelos/rota/trechoFormModel";
import { usePontosRotaService } from "../../../servicos/pontoRotas.service";
import { PontoRotaFormModel } from "../../../modelos/rota/PontoRotaFormModel";
import { MASCARA_DINHEIRO } from "../../../constantes/mascaras/MascaraDinheiro";
import { VeiculoFormModel } from "../../../modelos/veiculo/veiculoFormModel";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";
import { useVeiculosService } from "../../../servicos/veiculos.service";
import { useMotoristasService } from "../../../servicos/motorista.service";
import { RotaSearchResponse } from "../../../modelos/dtos/RotaSearchResponse";
import { RotaFormModel } from "../../../modelos/rota/rotaFormModel";
import { TrechoSearchResponse } from "../../../modelos/dtos/TrechoSearchResponse";
import { AssociacaoFormModel } from "../../../modelos/rota/associacaoFormModel";
import { AssociacaoSearchResponse } from "../../../modelos/dtos/AssociacaoSearchResponse";
import { FormularioAssociarVeiculosRota } from "../cadastro/FormularioAssociarVeiculosRota";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { formatarHora } from "../../../utils/claimUtils";

export const VerRota = () => {
    const { idRota } = useParams<{ idRota: string }>();
    const { idOcorrencia } = useParams<{ idOcorrencia: string }>();
    const gridRef = useRef<OptGridRef>(null);
    const [rota, setRota] = useState<RotaSearchResponse>();
    const [trechos, setTrechos] = useState<Array<any>>([]);
    const { editarRota, salvarRota, removerRota, carregarRotaPorId, buscarPassageirosRota } = useRotasService();
    const { carregarPontosRota } = usePontosRotaService();
    const { carregarVeiculos } = useVeiculosService();
    const { carregarMotoristas } = useMotoristasService();
    const [loading, setLoading] = useState<boolean>(false);
    const [bloquearAssociacaoPassageiros, setBloquearAssociacaoPassageiros] = useState<boolean>(false);
    const [carregouRota, setCarregouRota] = useState<boolean>(false);
    const [bloquearExclusaoRota, setBloquearExclusaoRota] = useState<boolean>(false);
    const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [exibirDialogAssosicacao, setExibirDialogAssosicacao] = useState<boolean>(false);
    const [comboPontosRota, setComboPontosRota] = useState<Array<PontoRotaFormModel>>([]);
    const [comboMotorista, setComboMotorista] = useState<Array<any>>([]);
    const [comboVeiculo, setComboVeiculo] = useState<Array<any>>([]);
    const [, setSalvarLoading] = useState<boolean>(false);
    const edicao = Boolean(idRota);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { verificarAutorizacao } = useEventosService();

    const trechoForm = useForm<RotaFormModel>();
    const { control, handleSubmit, setValue, formState: { errors } } = trechoForm;

    function recarregar() {
        gridRef.current?.refresh();
    }

    async function carregarRota(idRota: string) {
        const rota = await carregarRotaPorId(idRota) as unknown as RotaSearchResponse;

        if (rota && rota.trechos && rota.trechos.length > 0) {
            rota.trechos.map((trecho) => {
                if (trecho.associacoes.length === 0) {
                    setBloquearAssociacaoPassageiros(true)
                }
            })
            console.log("11111111111111")
            setTrechos(rota.trechos)
            setValue('trechos', rota.trechos);
        } else {
            rota.trechos = new Array<TrechoSearchResponse>()
        }

        setRota(rota);
        setValue('id', rota.id);
        setValue('nome', rota.nome);
        setValue('idOcorrencia', rota.idOcorrencia);
        setComboPontosRota([rota.destino])
        setCarregouRota(true)
    }

    async function onClickDeleteRotaHandler() {
        setConfirmandoExclusao(false);
        setDeleteLoading(true);
        try {
            if (rota && rota.id) {
                await removerRota(rota.id!);
                setDeleteLoading(false);
                enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
                history.push('/eventos');
            }
        } catch {
            setDeleteLoading(false);
        }
    }

    function cancelarExclusao() {
        setConfirmandoExclusao(false);
    }

    function edicaoDeRota() {
        if (rota && rota.id) {
            history.push(`/rotas/cadastro/${rota.idOcorrencia}/${rota.id}`);
        }
    }

    async function onClickDeleteRota() {
        setConfirmandoExclusao(true);
    }

    function onClicAssociarMotoristaEVeiculo() {
        setExibirDialogAssosicacao(true);
    }

    async function carregarComboPontosRota() {
        let pontosRota = await carregarPontosRota() as unknown as Array<PontoRotaFormModel>;
        let arr = pontosRota.filter(pontoRota => pontoRota.idOcorrencia == idOcorrencia);
        arr.push(comboPontosRota[0])
        setComboPontosRota(arr);
    }

    async function carregarComboMotorista() {
        const comboMotoristas = await carregarMotoristas() as unknown as Array<PessoaFormModel>;
        setComboMotorista(comboMotoristas);
    }

    async function carregarComboVeiculo() {
        const comboVeiculos = await carregarVeiculos() as unknown as Array<VeiculoFormModel>;
        setComboVeiculo(comboVeiculos);
    }

    function handlePontoRotaOrigem(event: any, trecho: TrechoFormModel, index: number) {
        trecho.idPontoRotaOrigem = event
        setValue('trechos', rota?.trechos);
    }

    function handlePontoRotaDestino(event: any, trecho: TrechoFormModel, index: number) {
        trecho.idPontoRotaDestino = event;
        setValue('trechos', rota?.trechos);
    }

    function handleHorarioPartida(event: any, trecho: TrechoFormModel, index: number) {
        trecho.horarioPartida = event;
        setValue('trechos', rota?.trechos);
    }

    function handleHorarioChegada(event: any, trecho: TrechoFormModel, index: number) {
        trecho.horarioChegada = event;
        setValue('trechos', rota?.trechos);
    }

    function handleMotorista(event: any, index: number, associacaoIndex?: number) {
        const idMotoristaNovo = event;
        const objMotoristaNovo = comboMotorista?.filter((x) => x.id === idMotoristaNovo)[0];
        let trechosTmp = trechos;

        if (associacaoIndex !== undefined) {
            trechosTmp[index].associacoes.forEach((associacao: any, j: number) => {
                if (j === associacaoIndex) {
                    associacao.idMotorista = idMotoristaNovo;
                    associacao.motorista = objMotoristaNovo;
                }
            })
        } else {
            let obj = {
                idMotorista: idMotoristaNovo,
                motorista: objMotoristaNovo,
            }
            trechosTmp[index].associacoes.push(obj)
        }

        console.log("222222222222222")
        setTrechos(trechosTmp)
        setValue('trechos', trechosTmp);
    }

    function handleVeiculo(event: any, index: number, associacaoIndex: number) {
        const idVeiculo = event;
        const objVeiculoNovo = comboVeiculo?.filter((x) => x.id === idVeiculo)[0];
        let trechosTmp = trechos;

        if (associacaoIndex !== undefined) {
            trechosTmp[index].associacoes.forEach((associacao: any, j: number) => {
                if (j === associacaoIndex) {
                    associacao.idVeiculo = idVeiculo;
                    associacao.veiculo = objVeiculoNovo;
                }
            })
        } else {
            let obj = {
                idVeiculo: idVeiculo,
                veiculo: objVeiculoNovo,
            }
            trechosTmp[index].associacoes.push(obj)
        }

        console.log("3333333333333")
        setTrechos(trechosTmp)
        setValue('trechos', trechosTmp);
    }

    function adicionarAssociacao(trecho: any) {
        let novaAssociacao: any = {}
        let trechosTmp = trechos
        let findTrech = trechosTmp.filter((trech) => trech.id === trecho.id)[0]
        let objIndex = trechosTmp.findIndex((obj => obj.id === trecho.id));

        novaAssociacao = {
            id: "",
            idMotorista: "",
            idVeiculo: "",
            motorista: {},
            veiculo: {},
        }

        findTrech.associacoes.push(novaAssociacao)

        trechosTmp[objIndex] = findTrech

        if (rota) {
            rota.trechos = trechosTmp
            setRota(rota)
        }

        console.log("4444444444444")
        setTrechos([...trechosTmp]);
        setValue('trechos', trechosTmp);
    }

    function removerAssociacao(trecho: any, associacaoIndex: number) {
        let trechosTmp = trechos
        let findTrech = trechosTmp.filter((trech) => trech.id === trecho.id)[0]
        let objIndex = trechosTmp.findIndex((obj => obj.id === trecho.id));
        let newAssociationTrech = findTrech.associacoes.filter((trech: any, index: number) => index !== associacaoIndex)

        findTrech.associacoes = newAssociationTrech
        trechosTmp[objIndex] = findTrech

        if (rota) {
            rota.trechos = trechosTmp
            setRota(rota)
        }

        setTrechos([...trechosTmp]);
        setValue('trechos', trechosTmp);
    }

    function adicionarTrecho() {
        let novoTrecho: TrechoFormModel = {
            ordem: 0,
            idPontoRotaOrigem: "",
            ativo: true,
            associacoes: new Array<AssociacaoSearchResponse>(),
            idPontoRotaDestino: "",
            idRota
        }

        let obj = {
            id: "",
            idMotorista: "",
            idVeiculo: "",
            motorista: {},
            veiculo: {},
        }
        novoTrecho.associacoes.push(obj)

        if (rota) {
            if (trechos && trechos.length > 0) {
                const ultimoTrecho = trechos[trechos.length - 1]
                novoTrecho.idPontoRotaOrigem = ultimoTrecho.idPontoRotaDestino;
                novoTrecho.ordem = ultimoTrecho.ordem + 1;
            }

            rota.trechos = [...trechos, novoTrecho]

            setRota(rota)
            console.log("6666666666666666")
            setTrechos([...trechos, novoTrecho]);
            setValue('trechos', trechos);
        }
    }

    function removerTrecho(index: number) {
        if (rota) {
            let arr = trechos
            let newTrechos = arr.filter((x, i) => i !== index)

            setRota({ ...rota, trechos: newTrechos })
            setValue('trechos', newTrechos);
            console.log("77777777777777777")
            setTrechos(newTrechos)
        }
    }

    async function salvar() {
        setSalvarLoading(true);
        try {
            if (rota) {
                const estaEditando = !!rota.id;
                const operacao = estaEditando ? "editado" : "criado";
                if (edicao) {
                    rota.trechos = trechos
                    await editarRota(rota.id!, rota);
                } else {
                    await salvarRota(rota);
                }

                setSalvarLoading(false);
                enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
                    variant: "success",
                });
            }
        } catch (error) {
            console.log(error);
            setSalvarLoading(false);
        }
    }

    function onCloseAssociar() {
        setExibirDialogAssosicacao(false);
    }

    function OnSaveAssociar(associacoes: AssociacaoFormModel[]) {
        setLoading(true)

        if (rota && associacoes.length > 0) {
            let trechosTmp = trechos;

            trechosTmp.map((trecho) => {
                trecho.associacoes = trecho.associacoes.concat(associacoes);
            })

            rota.trechos = trechosTmp

            setRota(rota)
            console.log("8888888888888")
            setTrechos(trechosTmp)
            setValue('trechos', trechosTmp);
        }

        setExibirDialogAssosicacao(false);
        setLoading(false)
    }

    useEffect(() => {
        (async () => {
            verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor]);
            if (idRota) {
                await carregarRota(idRota);
            }
        })()
    }, []);

    useEffect(() => {
        (async () => {
            if (carregouRota) {
                await carregarComboPontosRota();
                await carregarComboMotorista();
                await carregarComboVeiculo();
                const result = await buscarPassageirosRota(idRota) as any

                if (result.length > 0) {
                    result.map((passageiro: any) => {
                        if (passageiro.idRota === idRota) {
                            setBloquearExclusaoRota(true)
                            return
                        }
                    })
                }
                if (gridRef.current) {
                    recarregar();
                }
            }
        })()
    }, [carregouRota]);

    if (loading) {
        return <h1>Carregando...</h1>
    }

    return (
        <>
            <OptDialog
                open={exibirDialogAssosicacao}
                title={"Associação de Motorista e veículo da rota"}
            >
                <FormularioAssociarVeiculosRota
                    onSave={OnSaveAssociar}
                    onClose={onCloseAssociar} />
            </OptDialog>

            <OptConfirmationDialog
                open={confirmandoExclusao}
                title="Excluir Rota"
                icon={{ path: mdiDeleteOutline, color: Colors.red }}
                onCancel={cancelarExclusao}
                onClose={cancelarExclusao}
                onConfirm={onClickDeleteRotaHandler} >
                Deseja confirmar a exclusão da rota ? <br /> Essa operação não poderá ser desfeita.
            </OptConfirmationDialog>

            <div className="col">
                <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                    <div className={styles.CabecalhoContainer}>
                        <OptActionButton
                            onClick={() => history.push(`/ocorrencias/ver/${idOcorrencia}`)}
                            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
                        >
                            <span className={styles.SpanText}>
                                {rota?.nomeEvento.toUpperCase()} - {rota && rota.dataOcorrencia ? format(new Date(rota.dataOcorrencia.replace("Z", "")), 'dd/MM/yyyy') : '-'} - {rota?.nome.toUpperCase()}
                            </span>
                        </OptActionButton>
                    </div>
                    <Divider />
                    <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                        <Grid className={`${styles.GridItem} d-flex justify-content-center`} item xs={12}>
                            <Button
                                className="float-end"
                                variant="contained"
                                color="info"
                                style={{ marginRight: "5px" }}
                                placeholder="Associar motorista e veículo à rota"
                                onClick={onClicAssociarMotoristaEVeiculo}
                            >
                                Associar Motorista E Veículo
                            </Button>
                            {
                                !bloquearAssociacaoPassageiros &&
                                <Button
                                    className="float-end"
                                    variant="contained"
                                    color="info"
                                    style={{ marginRight: "5px" }}
                                    placeholder="Associar Passageiros"
                                    onClick={() => history.push(`/rotas/associar-passageiros/${idRota}`)}
                                >
                                    Associar Passageiros
                                </Button>
                            }
                        </Grid>
                        <Grid className={styles.GridItem} item xs={12}>
                            {
                                !bloquearExclusaoRota &&
                                <Tooltip title={"Remover rota"}>
                                    <Button
                                        className="float-end"
                                        variant="contained"
                                        color="inherit"
                                        style={{ marginRight: "5px" }}
                                        placeholder="Remover Rota"
                                        onClick={onClickDeleteRota}
                                    >
                                        <Icon path={mdiTrashCanOutline} size={1} color={Colors.red} />
                                    </Button>
                                </Tooltip>
                            }

                            <Tooltip title={"Editar rota"}>
                                <Button
                                    className="float-end"
                                    variant="contained"
                                    color="inherit"
                                    style={{ marginRight: "5px" }}
                                    placeholder="Editar Rota"
                                    onClick={edicaoDeRota}
                                >
                                    <Icon path={mdiPencilOutline} size={1} color={Colors.green1} />
                                </Button>
                            </Tooltip>
                        </Grid>

                        <Grid className={styles.GridItem} item xs={12}>
                            <form>
                                {trechos.map((trecho: any, index: number) => {
                                    return (
                                        <span key={index}>
                                            {index > 0 && <hr className="mt-5" />}
                                            <h5>Trecho {index + 1}</h5>
                                            <Grid className={`${styles.GridItem} ${index == 0 ? 'd-block' : 'd-none'}`} item xs={12} marginTop={2}>
                                                <Controller
                                                    name="trechos"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <FormControl sx={{ width: 1 }} size="medium">
                                                            <Autocomplete
                                                                id='select-label-origem'
                                                                className={styles.TextFieldCustom}
                                                                options={comboPontosRota}
                                                                getOptionLabel={(option: any) => option.nome + ' - ' + formatarHora(option)}
                                                                onChange={(e, value) => handlePontoRotaOrigem(value.id, trecho, index)}
                                                                defaultValue={trecho.pontoRotaOrigem || null}
                                                                renderInput={(params) => <TextField {...params} label="Ponto de embarque" required />}
                                                            />
                                                        </FormControl>
                                                    )}
                                                />
                                                {/* <ErrorMessage error={errors.trechos} /> */}
                                            </Grid>

                                            <Grid className={`${styles.GridItem} d-flex flex-row`} marginTop={2} marginBottom={2} item xs={12}>
                                                <Grid className={`${styles.GridItem} d-flex flex-row`} marginTop={2} marginBottom={2} item xs={2}>
                                                    <Icon path={mdiArrowDown} size={6} color={Colors.black1} />
                                                </Grid>

                                                <Grid className={`${styles.GridItem}`} marginTop={2} marginBottom={2} item xs={10}>
                                                    <Grid className={`${styles.GridItem} d-flex flex-row`} item xs={12}>
                                                        <Grid className={styles.GridItem} marginRight={2} item xs={4}>
                                                            <Controller
                                                                name="trechos"
                                                                control={control}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <DateTimePicker
                                                                        label="Partida"
                                                                        minDate={new Date()}
                                                                        onChange={(event) => handleHorarioPartida(event, trecho, index)}
                                                                        value={trecho.horarioPartida ?? null}
                                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                                    />
                                                                )}
                                                            />
                                                            {/* <ErrorMessage message={errors.trechos?[index].horarioPartida} /> */}
                                                        </Grid>

                                                        <Grid className={styles.GridItem} marginRight={2} item xs={4}>
                                                            <Controller
                                                                name="trechos"
                                                                control={control}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <DateTimePicker
                                                                        label="Chegada"
                                                                        minDate={new Date()}
                                                                        onChange={(event) => handleHorarioChegada(event, trecho, index)}
                                                                        value={trecho.horarioChegada ?? null}
                                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                                    />
                                                                )}
                                                            />
                                                            {/* <ErrorMessage error={errors.horarioChegada} />*/}
                                                        </Grid>
                                                    </Grid>

                                                    {
                                                        trecho.associacoes?.map((associacao: any, associacaoIndex: number) => {
                                                            return (
                                                                <Grid className={`${styles.GridItem} d-flex align-items-center flex-row`} marginTop={2} item xs={12} key={Math.random().toString(36).substring(2, 12)}>
                                                                    <Grid className={styles.GridItem} marginRight={2} marginTop={2} item xs={4}>
                                                                        <Controller
                                                                            name={`trechos.${index}.associacoes.${associacaoIndex}`}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <FormControl sx={{ width: 1 }} size="medium">
                                                                                    <Autocomplete
                                                                                        id='select-label-motorista'
                                                                                        className={styles.TextFieldCustom}
                                                                                        options={comboMotorista}
                                                                                        getOptionLabel={(option: any) => option.nome}
                                                                                        onChange={(e, value) => handleMotorista(value.id, index, associacaoIndex)}
                                                                                        defaultValue={associacao.motorista.id ? associacao.motorista : null}
                                                                                        renderInput={(params) => <TextField {...params} label="Motorista" required />}
                                                                                    />
                                                                                </FormControl>
                                                                            )}
                                                                        />
                                                                        {/* <ErrorMessage error={errors.idPontoRotaOrigem} /> */}
                                                                    </Grid>
                                                                    <Grid className={styles.GridItem} marginTop={2} marginRight={2} item xs={4}>
                                                                        <Controller
                                                                            name="trechos"
                                                                            control={control}
                                                                            render={({ field: { onChange, value } }) => (
                                                                                <FormControl sx={{ width: 1 }} size="medium">
                                                                                    <Autocomplete
                                                                                        id='select-label-veiculo'
                                                                                        className={styles.TextFieldCustom}
                                                                                        options={comboVeiculo}
                                                                                        getOptionLabel={(option: any) => option.placa}
                                                                                        onChange={(e, value) => handleVeiculo(value.id, index, associacaoIndex)}
                                                                                        defaultValue={associacao.veiculo.id ? associacao.veiculo : null}
                                                                                        renderInput={(params) => <TextField {...params} label="Veículo" required />}
                                                                                    />
                                                                                </FormControl>
                                                                            )}
                                                                        />
                                                                        {/* <ErrorMessage error={errors.idPontoRotaOrigem} /> */}
                                                                    </Grid>
                                                                    {
                                                                        trecho.associacoes.length > 1 &&
                                                                        <Grid xs={3}>
                                                                            <Button variant="contained" color="inherit" onClick={() => removerAssociacao(trecho, associacaoIndex)}>
                                                                                Remover <Icon path={mdiDeleteOutline} size={1} color={Colors.red} />
                                                                            </Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            )
                                                        })
                                                    }

                                                    <Grid className={`${styles.GridItem} d-flex justify-content-end flex-row`} marginTop={2} item xs={9}>
                                                        <Button
                                                            variant="contained"
                                                            color="inherit"
                                                            style={{ marginRight: "5px" }}
                                                            onClick={() => adicionarAssociacao(trecho)}
                                                        >
                                                            Adicionar associação <Icon path={mdiPlus} size={1} color={Colors.green1} />
                                                        </Button>
                                                    </Grid>

                                                    <Grid className={`${styles.GridItem} d-flex flex-row`} marginTop={2} item xs={12}>
                                                        <Grid className={styles.GridItem} item xs={12} marginTop={2}>
                                                            <Button
                                                                variant="contained"
                                                                color="inherit"
                                                                className="float-end"
                                                                style={{ marginRight: "5px" }}
                                                                placeholder="remover associação"
                                                                onClick={() => removerTrecho(index)}
                                                            >
                                                                Remover trecho <Icon path={mdiDeleteOutline} size={1} color={Colors.red} />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid className={styles.GridItem} item xs={12}>
                                                <Controller
                                                    name="trechos"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <FormControl sx={{ width: 1 }} size="medium">
                                                            <Autocomplete
                                                                id='select-label-destino'
                                                                className={styles.TextFieldCustom}
                                                                options={comboPontosRota}
                                                                getOptionLabel={(option: any) => option.nome + ' - ' + formatarHora(option)}
                                                                onChange={(e, value) => handlePontoRotaDestino(value.id, trecho, index)}
                                                                defaultValue={trecho.pontoRotaDestino || null}
                                                                renderInput={(params) => <TextField {...params} label="Ponto de desembarque" required />}
                                                            />
                                                        </FormControl>
                                                    )}
                                                />
                                                {/* <ErrorMessage error={errors.idPontoRotaDestino} /> */}
                                            </Grid>
                                        </span>
                                    )
                                })}
                                <Grid className={styles.GridItem} item xs={12} marginTop={4} marginBottom={4}>
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        className="float-end"
                                        style={{ marginRight: "5px" }}
                                        placeholder="adicionar trecho"
                                        onClick={adicionarTrecho}
                                    >
                                        Adicionar trecho <Icon path={mdiPlus} size={1} color={Colors.green1} />
                                    </Button>
                                </Grid>

                                <Grid className={styles.GridItem} item xs={12} marginTop={4} marginBottom={4}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        style={{ marginRight: "5px" }}
                                        placeholder="Salvar alterações"
                                        onClick={handleSubmit(salvar)}
                                    >
                                        Salvar alterações <Icon path={mdiContentSave} size={1} color={Colors.green1} />
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid >

            </div >
        </>
    );
}