import { mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptGridRef,
  OptGridRequest,
  OptSearchField,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../../compartilhado/colors";
import { SearchRequest } from "../../../../compartilhado/types/SearchRequest";
import { FiltroBusca } from "../../../../componentes/filtro";
import { OptGridContainer } from "../../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { PESSOA_FORM_INITIAL_STATE } from "../../../../constantes/PessoaFormInitialState";
import { TipoInput } from "../../../../enums/tipoInput";
import { PessoaSearchResponse } from "../../../../modelos/dtos/PessoaSearchResponse";
import { PessoaFormModel } from "../../../../modelos/pessoa/pessoaFormModel";
import { PaginatedSearchRequest } from "../../../../modelos/search/PaginatedSearchRequest";
import { Rotas } from "../../../../rotas";
import { useMotoristasService } from "../../../../servicos/motorista.service";
import { GridMotoristasView } from "./GridMotoristasView";
import styles from "./styles/gridPessoas.module.scss";
import { IGeneric } from "../../../empresa/Cadastro/FormularioEmpresa";
import { PerfilUsuario } from "../../../../enums/PerfilUsuario";
import { useEventosService } from "../../../../servicos/eventos.service";

export const GridMotoristas = ({
  isFromCadastroEmpresa,
  needToUpdateGrid,
  idEmpresa,
  dadosParaEditar,
}: IGeneric) => {
  const [drawerFormulario, setDrawerFormularioAberto] = useState(false);
  const [filtro, setFiltro] = useState<PessoaFormModel>();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] =
    useState<boolean>(false);

  const [pessoa, setPessoa] = useState<PessoaFormModel>(
    PESSOA_FORM_INITIAL_STATE
  );

  const { buscarGridMotoristasPaginated, removerMotorista } =
    useMotoristasService();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  const opcoesFiltro = [
    { texto: "Nome", valor: "nome", tipoInput: TipoInput.Texto },
    { texto: "Cpf", valor: "cpf", tipoInput: TipoInput.Texto },
    { texto: "Telefone", valor: "telefone", tipoInput: TipoInput.Texto },
    { texto: "e-mail", valor: "email", tipoInput: TipoInput.Texto },
  ];

  function pesquisaComFiltro(filtro: PessoaFormModel) {
    setFiltro(filtro);
  }

  const carregar = async (
    query: OptGridRequest,
    itemSearchRequest: PaginatedSearchRequest = {
      filtro,
    }
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro,
    };
    return buscarGridMotoristasPaginated(request, idEmpresa);
  };

  function onRowClick(rowData?: PessoaFormModel) { }

  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerMotorista(pessoa.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setDrawerFormularioAberto(false);
      setPessoa(PESSOA_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }

  async function onClickDeleteItem(rowData: PessoaSearchResponse) {
    setPessoa(rowData);
    setConfirmandoExclusao(true);
  }

  async function onClickEditItem(rowData: PessoaSearchResponse) {
    if (rowData.id) {
      console.log(rowData.id)
      dadosParaEditar(rowData.id)
      // history.push(`/motoristas/cadastro/${rowData.id}`);
    }
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  function cadastrarMotorista() {
    history.push("/motoristas/cadastro");
  }

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, [filtro]);

  useEffect(() => {
    if (needToUpdateGrid) {
      if (gridRef.current) {
        recarregar();
      }
    }
  }, [needToUpdateGrid]);


  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao]);
    })()
  }, []);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir Motoristas"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão do motorista ? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>

      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        {!isFromCadastroEmpresa && (
          <>
            <OptActionToolbar
              goBackRoute={Rotas.Home}
              title="Motoristas"
              background={Colors.white}
              color={Colors.gray1}
              clearMargin
            ></OptActionToolbar>
            <OptGridContainer>
              <FiltroBusca opcoes={opcoesFiltro} pesquisa={pesquisaComFiltro} />
              <div className={styles.ButtonSection}>
                <Tooltip title={"Cadastrar motorista"}>
                  <Button
                    variant="contained"
                    color="info"
                    style={{ marginRight: "5px", marginBottom: "11px" }}
                    placeholder="Cadastrar motorista"
                    size="large"
                    onClick={cadastrarMotorista}
                  >
                    Cadastrar Motorista{" "}
                    <Icon
                      path={mdiPlusCircle}
                      size={1}
                      color={Colors.primary}
                    />
                  </Button>
                </Tooltip>
              </div>
            </OptGridContainer>
          </>
        )}
        <GridMotoristasView
          onRowClick={onRowClick}
          carregar={carregar}
          abrirDrawerFormulario={cadastrarMotorista}
          onClickDelete={onClickDeleteItem}
          onClickEdit={onClickEditItem}
          gridRef={gridRef}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
