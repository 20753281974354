import * as Yup from "yup";
import { OcorrenciaEmpresaFormModel } from "../../../modelos/ocorrenciaEmpresa/ocorrenciaEmpresaFormModel";


export const FormularioOcorrenciaEmpresaSchema: Yup.SchemaOf<OcorrenciaEmpresaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    idOcorrencia: Yup.string().required("Item obrigatório"),
    idsEmpresas: Yup.array().of(Yup.string().required("Item obrigatório")),
    ativo: Yup.bool().notRequired(),
  });
