import { protectedResources } from "../authConfig";
import { useApi } from "../compartilhado/hooks/api";
import { GerenciaComplementoListProps, ObterRespostasResonse } from "../modelos/dtos/GerenciaComplemento";

export const useGerenciaComplementoService = () => {
  const { post, get } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = "/gestao";

  const obterOpcoesRespostaEvento = async (idEvento: string) => {
    return get<string[]>(`${baseApi}/ComplementoEvento/obterOpcoesRespostas/${idEvento}`);
  };

  const obterListaRespostas = async (data: GerenciaComplementoListProps) => {
    return post<ObterRespostasResonse>(`${baseApi}/ComplementoEvento/obterRespostasFiltro`, data);
  };

  return {
    obterOpcoesRespostaEvento,
    obterListaRespostas
  };
};
