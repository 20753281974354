import {
    mdiArrowLeft,
    mdiTrashCanOutline,
    mdiPencilOutline,
    mdiDeleteOutline,
    mdiArrowDown,
    mdiPlusCircle,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { OptActionButton, OptConfirmationDialog, OptGridRef, OptGridRequest } from "@optsol/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Colors } from "../../../compartilhado/colors";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { OcorrenciaSearchResponse } from "../../../modelos/dtos/OcorrenciaSearchResponse";
import styles from "./styles/verOcorrencia.module.scss";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { GridEmpresasPorOcorrencia } from "../../empresa/ListarEmpresas/GridEmpresasPorOcorrencia";
import { GridRotasView } from "../../rotas/listar/gridRotasView";
import { RotaSearchResponse } from "../../../modelos/dtos/RotaSearchResponse";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { PaginatedRotaRequest } from "../../../modelos/search/PaginatedRotaRequest";
import { useRotasService } from "../../../servicos/rotas.service";
import { GridCombosView } from "../../rotas/listar/gridCombosView";
import { GridPontosRotaView } from "../../rotas/listar/gridPontosRotaView";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export const VerOcorrencia = () => {
    const history = useHistory();
    const gridRef = useRef<OptGridRef>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { idOcorrencia } = useParams<{ idOcorrencia: string }>();
    const { buscarGridRotasPaginated } = useRotasService();
    const { verificarAutorizacao, obterUsuarioLogado } = useEventosService();
    const { carregarOcorrenciaPorId, removerOcorrencia } = useOcorrenciasService();

    const [ocorrencia, setOcorrencia] = useState<OcorrenciaSearchResponse>();
    const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
    const [pontosTrechosOcorrencia, setPontosTrechosOcorrencia] = useState<any[]>([]);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [usuarioLogado, setUsuarioLogado] = useState<any>(null);

    const carregarRotas = async (
        query: OptGridRequest,
    ) => {
        const request: SearchRequest<PaginatedRotaRequest> = {
            page: query.page ?? 1,
            pageSize: query.pageSize ?? 10,
            filtro: { idOcorrencia },
        };
        return buscarGridRotasPaginated(request);
    };

    function recarregar() {
        gridRef.current?.refresh();
    }

    async function carregarOcorrencia(idOcorrencia: string) {
        const response = await carregarOcorrenciaPorId(idOcorrencia) as unknown as OcorrenciaSearchResponse;

        let arr: any[] = []

        response.rotas.map((rota) => {
            rota.trechos.map((trecho: any) => {
                arr.push(trecho.pontoRotaOrigem)
                arr.push(trecho.pontoRotaDestino)
            })
        })

        arr = arr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        )

        setOcorrencia(response);
        setPontosTrechosOcorrencia(arr)
    }

    function onClose() {
        if (ocorrencia && ocorrencia.id) history.push(`/eventos/ver/${ocorrencia.idEvento}`);
    }

    async function onClickDeleteOcorrencia() {
        setConfirmandoExclusao(true);
    }

    async function onRowClickRotas(rowData?: RotaSearchResponse) {

    }

    async function onClickDeleteOcorrenciaHandler() {
        setConfirmandoExclusao(false);
        try {
            if (ocorrencia && ocorrencia.id) {
                await removerOcorrencia(ocorrencia.id!);

                enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
                history.push(`/eventos/ver/${ocorrencia.idEvento}`);
            }
        } catch (erro) {
            console.log(erro)
        }
    }

    function cancelarExclusao() {
        setConfirmandoExclusao(false);
    }

    async function editarOcorrencia() {
        if (ocorrencia && ocorrencia.id) {
            history.push(`/ocorrencias/cadastro/${ocorrencia.idEvento}/${ocorrencia.id}`);
        }
    }

    const handleAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    function cadastrarRota() {
        history.push(`/rotas/cadastro/${idOcorrencia}`);
    }

    function cadastrarCombo() {
        history.push(`/combos/cadastro/${idOcorrencia}`);
    }

    function cadastrarPontoRota() {
        history.push(`/PontosRota/cadastro/${idOcorrencia}`);
    }

    function editarCombo() {

    }

    function deletarCombo() {

    }

    function navegarParaVendasOcorrencia() {
        history.push(`/vendas/ver/${idOcorrencia}`);
    }

    useEffect(() => {
        if (idOcorrencia) {
            carregarOcorrencia(idOcorrencia);
            if (gridRef.current) {
                recarregar();
            }
        }
    }, []);


    useEffect(() => {
        (async () => {
            verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor]);
            setUsuarioLogado(await obterUsuarioLogado());
        })()
    }, []);


    const converterGmt = (data: Date) => {
        const dataCopy = new Date(data);
        dataCopy.setHours(dataCopy.getHours() - 3);
        return dataCopy;
    }

    return (
        <>
            <OptConfirmationDialog
                open={confirmandoExclusao}
                title="Excluir Ocorrência"
                icon={{ path: mdiDeleteOutline, color: Colors.red }}
                onCancel={cancelarExclusao}
                onClose={cancelarExclusao}
                onConfirm={onClickDeleteOcorrenciaHandler}
            >
                Deseja confirmar a exclusão da ocorrência? <br /> Essa operação não poderá ser desfeita.
            </OptConfirmationDialog>

            <div className="col">
                <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                    <div className={styles.CabecalhoContainer}>
                        <OptActionButton
                            onClick={onClose}
                            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
                        >
                            <span className={styles.SpanText}>
                                {ocorrencia?.nomeEvento} - {ocorrencia && ocorrencia.data ? format(new Date(ocorrencia?.data.replace('Z', '')), 'dd/MM/yyyy') : '-'}
                            </span>
                        </OptActionButton>
                    </div>
                    <Divider />
                    <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                        <Grid className={styles.GridItem} item xs={3}>
                            <b>Início do evento: </b>{ocorrencia && ocorrencia.horaInicio ? format(converterGmt(new Date(ocorrencia?.horaInicio.replace('Z', ''))), 'HH:mm') : '-'}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={3}>
                            <b>Fim do evento: </b>{ocorrencia && ocorrencia.horaFim ? format(converterGmt(new Date(ocorrencia?.horaFim.replace('Z', ''))), 'HH:mm') : '-'}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={3}>
                            <b>Chegada prevista: </b>{ocorrencia && ocorrencia.horaChegadaPrevista ? format(converterGmt(new Date(ocorrencia?.horaChegadaPrevista.replace('Z', ''))), 'HH:mm') : '-'}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={12}>
                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                onClick={() => history.push(`/sms/${ocorrencia?.idEvento}/${ocorrencia?.id}`)}
                            >
                                Enviar SMS
                            </Button>

                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                placeholder="Remover Ocorrência"
                                onClick={onClickDeleteOcorrencia}
                            >
                                <Icon path={mdiTrashCanOutline} size={1} color={Colors.red} />
                            </Button>

                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                placeholder="Remover Ocorrência"
                                onClick={editarOcorrencia}
                            >
                                <Icon path={mdiPencilOutline} size={1} color={Colors.green1} />
                            </Button>
                        </Grid>

                        <Grid className={styles.GridItem} item xs={12}>
                            <Accordion expanded={expanded === 'pontosRota'} onChange={handleAccordion('pontosRota')} className="ml-1">
                                <AccordionSummary
                                    expandIcon={<Icon path={mdiArrowDown} size={1} color={Colors.gray} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <b>Pontos de Embarque</b>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Lista com os Pontos de Embarque cadastrados para o evento</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="col">
                                        {ocorrencia && ocorrencia.pontosRota ?
                                            <GridPontosRotaView
                                                idEvento={ocorrencia.idEvento}
                                                idOcorrencia={ocorrencia.id}
                                                perfilUsuario={usuarioLogado?.perfil}
                                                itens={ocorrencia.pontosRota}
                                                recarregar={recarregar}
                                                gridRef={gridRef}
                                                pontosOcorrencia={pontosTrechosOcorrencia}
                                            /> : <span>Carregando evento nesta data...</span>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* <Grid className={styles.GridItem} item xs={12}>
                            <Accordion expanded={expanded === 'combos'} onChange={handleAccordion('combos')} className="ml-1">
                                <AccordionSummary
                                    expandIcon={<Icon path={mdiArrowDown} size={1} color={Colors.gray} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <b>Combos</b>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Lista com os combos de rota do evento</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={`${styles.ButtonSection} col text-end`}>
                                        <Tooltip title={"Cadastrar combo"}>
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                style={{ marginRight: "5px", marginBottom: "11px" }}
                                                placeholder="Cadastrar combo"
                                                size="large"
                                                onClick={cadastrarCombo}
                                            >
                                                <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                    <div className="col">
                                        {ocorrencia && ocorrencia.combos ?
                                            <GridCombosView
                                                itens={ocorrencia.combos}
                                                gridRef={gridRef}
                                            /> : <span>Carregando evento nesta data...</span>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Grid> */}

                        <Grid className={styles.GridItem} item xs={12}>
                            <Accordion expanded={expanded === 'empresas'} onChange={handleAccordion('empresas')} className="ml-1">
                                <AccordionSummary
                                    expandIcon={<Icon path={mdiArrowDown} size={1} color={Colors.gray} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <b>Empresas Contratadas</b>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Lista com as empresas contratadas do evento</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {ocorrencia ?
                                        <GridEmpresasPorOcorrencia
                                            ocorrencia={ocorrencia}
                                            recarregar={recarregar}
                                        /> : <span>Carregando evento nesta data...</span>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid className={styles.GridItem} item xs={12}>
                            <Accordion expanded={expanded === 'rotas'} onChange={handleAccordion('rotas')} className="ml-1">
                                <AccordionSummary
                                    expandIcon={<Icon path={mdiArrowDown} size={1} color={Colors.gray} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        <b>Rotas</b>
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Lista com as rotas do evento</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={`${styles.ButtonSection} col text-end`}>
                                        <Tooltip title={"Cadastrar rota"}>
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                style={{ marginRight: "5px", marginBottom: "11px" }}
                                                placeholder="Cadastrar rota"
                                                size="large"
                                                onClick={cadastrarRota}
                                            >
                                                <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                    <div className="col">
                                        {ocorrencia && ocorrencia.rotas ?
                                            <GridRotasView
                                                onRowClick={onRowClickRotas}
                                                itens={ocorrencia?.rotas!}
                                                gridRef={gridRef}
                                            /> : <span>Carregando evento nesta data...</span>
                                        }

                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="mt-5 px-3">
                        {
                            ocorrencia && ocorrencia.atualizacoes.length > 0 &&
                            <h5>Histórico de alterações</h5>
                        }
                        <Grid className="grid-timeline">
                            <Timeline className="p-0 mt-4">
                                {
                                    ocorrencia && ocorrencia.atualizacoes.length > 0 && ocorrencia.atualizacoes.map((atualizacao) => (
                                        <TimelineItem className="mb-2">
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <div className="mb-2">
                                                    <b>{format(converterGmt(new Date(atualizacao.criadoEm.replace('Z', ''))), 'dd/MM/yyy HH:mm')}</b>: <b>{atualizacao.atributo}</b> alterado por <b>{atualizacao.nomeAutor}</b>
                                                </div>
                                                <div className="mb-2">
                                                    <b>Valor antigo:</b> <br />{atualizacao.valorOriginal} <br />
                                                </div>
                                                <div>
                                                    <b>Valor novo:</b> <br />{atualizacao.valorNovo}
                                                </div>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))
                                }
                            </Timeline>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}