import * as Yup from "yup";
import { EmpresaFormModel } from "../../../modelos/empresa/empresaFormModel";


export const FormularioEmpresaSchema: Yup.SchemaOf<EmpresaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    cnpj: Yup.string().notRequired(),
    telefone:Yup.string().notRequired(),
    descricao: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    cep: Yup.string().notRequired(),
    endereco: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
    url: Yup.string().notRequired(),
  });
