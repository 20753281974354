import { useHistory, useParams } from "react-router-dom";
import { mdiSend, mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, FormControlLabel, Switch, Tooltip } from "@mui/material";
import {
  OptActionButton,
  OptBackdrop,
  OptGridRef,
  OptSelectionOption,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { GridEmailView } from "./GridEmailView";
import styles from "./styles/gridEmail.module.scss";
import { INIT_EMAIL_FORM_DATA } from "../../../constantes/EmailSubmitModel";
import { useEmailService } from "../../../servicos/email.service";
import { EmailSubmitModel } from "../../../modelos/email/EmailSubmitModel";
import { FormularioEmail } from "../FormularioEmail/FormularioEmail";
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";

import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { format } from "date-fns";
import Select from "react-select";
import { formatarHora } from "../../../utils/claimUtils";

export const GridEmail = () => {
  const history = useHistory();
  const { idEvento, idOcorrencia, idPontoEmbarque } = useParams<{ idEvento?: string, idOcorrencia?: string, idPontoEmbarque?: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [emailEventoSelected, setEmailEventoSelected] = useState<OptSelectionOption>();
  const [emailOcorrenciaSelected, setEmailOcorrenciaSelected] = useState<OptSelectionOption | null>(null);
  const [emailPontosEmbarque, setEmailPontosEmbarque] = useState<OptSelectionOption[]>([]);

  const [optionsEvento, setOptionsEvento] = useState<OptSelectionOption[]>([]);
  const [optionsOcorrencia, setOptionsOcorrencia] = useState<OptSelectionOption[]>([]);
  const [optionsPontoEmbarque, setOptionsPontoEmbarque] = useState<OptSelectionOption[]>([]);

  const [data, setData] = useState<any[]>([]);
  const [selecionarTodos, setSelecionarTodos] = useState(false);
  const [selecoesDoGrid, setSelecoesDoGrid] = useState(INIT_EMAIL_FORM_DATA);
  const [drawerFormularioAberto, setDrawerFormularioAberto] = useState(false);
  const [enviarLoading, setEnviarLoading] = useState<boolean>(false);
  const [eventosCarregados, setEventosCarregados] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { enviarEmail, obterPassageiros } = useEmailService();
  const { carregarEventos } = useEventosService();
  const { carregarOcorrenciasPorEvento, carregarOcorrenciaPorId } = useOcorrenciasService();

  const gridRef = useRef<OptGridRef>(null);

  function recarregarGrid() {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
    setLoading(false);
  }

  // Listar opções para EventosSelect
  async function obterSelectEventos() {
    if (!eventosCarregados) {
      let data: any = []

      await carregarEventos().then((response: any) => {
        response.map((x: any) => {
          if (idEvento === x.id) {
            setEmailEventoSelected({
              value: x.id,
              label: `${x.nome}`,
            })

            obterSelectOcorrecias(x.id)
          }

          data.push(
            {
              value: x.id,
              label: `${x.nome}`,
            }
          )
        });
      })

      setOptionsEvento(data);
      setEventosCarregados(true);
    }
  }

  // Listar opções para OcorrenciasSelect
  async function obterSelectOcorrecias(idEvento: string) {
    if (idEvento) {
      let data: any[] = []

      await carregarOcorrenciasPorEvento(idEvento).then((response: any) => {
        response.sort((a: any, b: any) => (new Date(a.data.replace('Z', ''))).getTime() - (new Date(b.data.replace('Z', ''))).getTime()).map((x: any) => {
          if (idOcorrencia === x.id) {
            setEmailOcorrenciaSelected({
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            })
          }

          data.push(
            {
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            }
          )
        });
      })

      setOptionsOcorrencia(data)
    }
  }

  // Listar opções para PontosEmbarqueSelect
  async function obterSelectPontos(idOcorrencia: string) {
    let arr: any = []
    let arrPontosSelecionados: any = []

    optionsOcorrencia.map((ocorrencia) => {
      if (ocorrencia.value === idOcorrencia) {
        carregarOcorrenciaPorId(idOcorrencia).then((response: any) => {
          response.pontosRota.map((x: any) => {
            if (idPontoEmbarque === x.id) {
              arrPontosSelecionados.push({
                value: x.id,
                label: x.nome,
              })
            }

            arr.push(
              {
                value: x.id,
                label: `${x.nome} ${formatarHora(x)}`,
              }
            )
          })
        }).finally(() => {
          setOptionsPontoEmbarque(arr)
          setEmailPontosEmbarque(arrPontosSelecionados)
        })
      }
    })
  }

  const atualizarSelecoesDoGrid = (passageiros: any[]) => {
    setSelecoesDoGrid({
      ...selecoesDoGrid,
      idsPassageiros: passageiros.map(p => p.id),
      idsVendas: passageiros.map(p => p.reserva.idVenda),
    });
  };

  function abrirDrawerFormulario(): void {
    setDrawerFormularioAberto(true);
  }

  function fecharDrawerFormulario(): void {
    if (selecionarTodos) {
      const element = document.getElementById("switch-selecionar-todos");
      element?.click();
    }
    setDrawerFormularioAberto(false);
  }

  const enviar = async (data: EmailSubmitModel) => {
    if (data.idsPassageiros.length === 0) {
      enqueueSnackbar(`Nenhum passageiro selecionado. Favor selecionar ao menos um passageiro`, {
        variant: "error",
      });
    } else {
      setEnviarLoading(true);
      try {
        await enviarEmail(data);
        enqueueSnackbar(`Email enviado com sucesso!`, {
          variant: "success",
        });
        setEnviarLoading(false);
        setDrawerFormularioAberto(false);
        recarregarGrid();
      } catch {
        setEnviarLoading(false);
      }
    }
  };

  useEffect(() => {
    obterSelectEventos();
  }, [optionsEvento]);

  useEffect(() => {
    if (idOcorrencia) {
      obterSelectPontos(idOcorrencia)
    }
  }, [optionsOcorrencia]);

  useEffect(() => {
    setLoading(true);
    if (emailPontosEmbarque && emailOcorrenciaSelected && emailEventoSelected && emailEventoSelected.value.length && emailOcorrenciaSelected.value.length && emailPontosEmbarque.length) {
      let pontosEmbarque: string[] = []

      emailPontosEmbarque.map((pontoEmbarque) => {
        pontosEmbarque.push(pontoEmbarque.value)
      })

      let params = {
        idEvento: emailEventoSelected.value,
        idOcorrencia: emailOcorrenciaSelected.value,
        idsPontosEmbarque: pontosEmbarque
      }

      obterPassageiros(params).then((response) => {
        setData(response)
        recarregarGrid();
        return
      })
    } else if (emailEventoSelected && emailOcorrenciaSelected && emailEventoSelected.value.length && emailOcorrenciaSelected.value.length) {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        idsPassageiros: [],
        idsVendas: [],
      });

      let params = {
        idEvento: emailEventoSelected.value,
        idOcorrencia: emailOcorrenciaSelected.value
      }

      obterPassageiros(params).then((response) => {
        setData(response)
        recarregarGrid();
        return
      })
    } else if (emailEventoSelected && emailEventoSelected.value.length) {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        idsPassageiros: [],
        idsVendas: [],
      });

      let params = {
        idEvento: emailEventoSelected.value
      }

      obterPassageiros(params).then((response) => {
        setData(response)
        recarregarGrid();
        return
      })
    }
    else{
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailEventoSelected, emailOcorrenciaSelected, emailPontosEmbarque]);

  useEffect(() => {
    if (selecionarTodos) {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        idsPassageiros: data.map((passageiro) => passageiro.id),
        idsVendas: data.map((passageiro) => passageiro.reserva.idVenda),
      });
      abrirDrawerFormulario();
    } else {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        idsPassageiros: [],
        idsVendas: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecionarTodos]);

  return (
    <>
      {
        drawerFormularioAberto &&
        <FormularioEmail
          open={drawerFormularioAberto}
          onClose={() => fecharDrawerFormulario()}
          enviar={enviar}
          defaultData={selecoesDoGrid}
          loading={enviarLoading}
        />
      }
      <Box width={'98%'} flexWrap={'wrap'}>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={() => history.goBack()}
            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              Enviar Email
            </span>
          </OptActionButton>
        </div>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControlLabel
            sx={{ marginBottom: "6px", paddingLeft: 4 }}
            label="Enviar para todos"
            control={
              <Switch
                disabled={!data || data.length === 0}
                value={selecionarTodos}
                id={"switch-selecionar-todos"}
                onChange={(e) => {
                  setSelecionarTodos(selecionarTodosAtual => !selecionarTodosAtual);
                }}
              />
            }
          />

          <div className={styles.ButtonSection}>
            <Select className={styles.StyledAsyncSelect}
              placeholder="Eventos"
              noOptionsMessage={() => "Sem opções pré-definidas"}
              value={emailEventoSelected}
              options={optionsEvento}
              onChange={(e: any) => {
                setEmailEventoSelected(e);
                setEmailOcorrenciaSelected(null);
                setEmailPontosEmbarque([]);
                obterSelectOcorrecias(e.value);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelect}
              placeholder="Ocorrência"
              noOptionsMessage={() => "Nenhuma ocorrência encontrada"}
              isDisabled={!emailEventoSelected?.value.length ? true : false}
              value={emailOcorrenciaSelected}
              options={optionsOcorrencia}
              onChange={(e: any) => {
                setEmailOcorrenciaSelected(e);
                setEmailPontosEmbarque([]);
                obterSelectPontos(e.value);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelect}
              placeholder="Pontos de Embarque"
              noOptionsMessage={() => "Nenhum ponto de embarque encontrado"}
              isMulti
              isDisabled={!emailOcorrenciaSelected?.value.length ? true : false}
              value={emailPontosEmbarque}
              options={optionsPontoEmbarque}
              onChange={(e: any) => {
                setEmailPontosEmbarque(e as OptSelectionOption[]);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Tooltip title={"Enviar Email"}>
              <Button
                style={{ marginRight: "-15px", marginBottom: "11px" }}
                placeholder="Enviar Email"
                size="large"
                disabled={!selecoesDoGrid.idsPassageiros.length}
                onClick={selecoesDoGrid.idsPassageiros.length ? abrirDrawerFormulario : () => { }}
              >
                <Icon path={mdiSend} size={1} color={selecoesDoGrid.idsPassageiros.length ? Colors.primary : Colors.gray5} />
              </Button>
            </Tooltip>
          </div>
        </Box>

        <GridEmailView
          ref={gridRef}
          data={data}
          atualizarSelecoesDoGrid={atualizarSelecoesDoGrid}
          selecionarTodos={selecionarTodos}
        />
      </Box>
    </>
  );
};
