import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { GridRsvp } from "../paginas/rsvp/ListarRsvp/GridRsvp";

export const RsvpRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Rsvp.Principal}>
            <GridRsvp />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
