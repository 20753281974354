import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { PatternFormat } from "react-number-format";
import { Real } from "../../../componentes/currency/Real";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { PagamentoLabel } from "../../../constantes/PagamentoLabel";
import { VeiculoLabel } from "../../../constantes/VeiculoLabel";
import { ViagemSearchResponse } from "../../../modelos/dtos/ViagemSearchResponse";
import style from "./styles/gridViagens.module.scss";

interface Props {
  carregar: (
    data: OptGridRequest
  ) => Promise<OptGridResponse<ViagemSearchResponse>>;
}

export const GridViagensView = React.forwardRef<OptGridRef | undefined, Props>(
  ({ carregar }, ref) => {
    const options: OptGridOptions = {
      search: true,
      pageSize: 10,
    };

    const columns: OptGridColumn<ViagemSearchResponse>[] = [
      {
        title: "Viagem",
        field: "nomeViagem",
        align: "left",
        hidden: false,
        width: 150,
      },
      {
        title: "Contato",
        field: "contatoNome",
        align: "left",
        hidden: false,
        width: 150,
        render: (row) => {
          return (
            <ul className={style.UlCuston}>
              <li>{row.contatoNome} </li>
              <li>{row.contatoEmail}</li>
              <li>
                <PatternFormat
                  displayType="text"
                  value={row.contatoTelefone}
                  format="(##) ##### - ####"
                />
              </li>
            </ul>
          );
        },
      },
      {
        title: "Email",
        field: "contatoEmail",
        hidden: true,
      },
      {
        title: "Telefone",
        field: "contatoTelefone",
        hidden: true,
      },
      {
        title: "Saída",
        field: "dataHoraSaida",
        align: "left",
        hidden: false,
      },
      {
        title: "Retorno",
        field: "dataHoraRetorno",
        align: "left",
        hidden: false,
      },
      {
        title: "Origem",
        field: "rota",
        align: "left",
        hidden: false,
        width: 250,
        render: (row) => {
          const enderecos = [...row.rota];
          enderecos.sort((a, b) => a.ordem - b.ordem).pop();
          return (
            <ul className={style.UlCuston}>
              {enderecos.map(function (item, i) {
                return (
                  <p key={item.id}>
                    {item.endereco}
                    {enderecos.length - 1 !== i && (
                      <FontAwesomeIcon
                        style={{ marginLeft: "5px" }}
                        icon={faArrowRight}
                        color={"black"}
                      />
                    )}
                  </p>
                );
              })}
            </ul>
          );
        },
      },
      {
        title: "Destino",
        field: "destino.endereco",
        align: "left",
        hidden: false,
        width: 250,
        render: (row) => {
          const enderecos = [...row.rota];
          const destino = enderecos.sort((a, b) => a.ordem - b.ordem).pop();
          return <span>{destino?.endereco}</span>;
        },
      },
      {
        title: "Veículo",
        field: "veiculo",
        align: "left",
        hidden: false,
        width: 80,
        render: (row) => {
          return <span>{VeiculoLabel.get(row.veiculo)}</span>;
        },
      },
      {
        title: "Valor",
        field: "valorTotal",
        align: "left",
        hidden: false,
        width: 120,
        render: (row) => {
          return <Real value={row.valorTotal} />;
        },
      },
      {
        title: "Valor total",
        field: "valorFinal",
        align: "left",
        hidden: false,
        width: 115,
        render: (row) => {
          return <Real value={row.valorFinal} />;
        },
      },
      {
        title: "Cupom",
        field: "cupomCodigo",
        align: "left",
        hidden: false,
      },
      {
        title: "Distância",
        field: "distanciaTotal",
        align: "left",
        hidden: false,
      },
      {
        title: "Pagamento",
        field: "tipoPagamento",
        align: "left",
        hidden: false,
        render: (row) => {
          return (
            <ul className={style.UlCuston}>
              <li>{PagamentoLabel.get(row.tipoPagamento)}</li>
              <li>
                <b>{row.situacaoPagamentoDescricao}</b>
              </li>
            </ul>
          );
        },
      },
      {
        title: "descrição",
        field: "situacaoPagamentoDescricao",
        hidden: true,
      },

      {
        title: "Cliente",
        field: "usuario.nome",
        align: "left",
        hidden: false,
        width: 190,
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            columns={columns}
            data={carregar}
            options={options}
          />
        </OptGridContainer>
      </>
    );
  }
);
