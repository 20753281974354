import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { ObterRespostasResonse } from "../../../modelos/dtos/GerenciaComplemento";

interface Props {
  data: any[];
}

export const GridGerenciaView = React.forwardRef<OptGridRef | undefined, Props>(
  ({ data}, ref) => {
    const options: OptGridOptions = {
      search: true,
      selection: false,
      pageSize: 100,
    };

    const columns: OptGridColumn<ObterRespostasResonse>[] = [
      {
        title: "Id",
        field: "idPassageiro",
        align: "left",
        hidden: true,
      },
      {
        title: "Nome do Passageiro",
        field: "passageiro",
        align: "left",
      },
      {
        title: "Nº de Embarque",
        field: "numeroPassageiro",
        align: "center",
      },
      {
        title: "Resposta",
        field: "resposta",
        align: "center",
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            columns={columns}
            data={data}
            options={options}
            actionsPosition={"end"}
          />
        </OptGridContainer>
      </>
    );
  }
);
