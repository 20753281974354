import { OptSideLayoutContent } from "@optsol/react";
import styled from "styled-components";
import { Colors } from "../../compartilhado/colors";

export const OptSideLayoutContentCustom = styled(OptSideLayoutContent)`
  padding: 0;

  & .MuiToolbar-root {
    padding: 15px 10px 15px 10px;
    min-height: 69px;
    contain: size style;
    border-color: ${Colors.gray9};
  }

  & .MuiToolbar-root > span {
    font-size: 16px;
  }
`;
