import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { FormularioEmpresa } from "../paginas/empresa/Cadastro/FormularioEmpresa";
import { GridEmpresas } from "../paginas/empresa/ListarEmpresas/GridEmpresas";
import { VincularMotorista } from "../paginas/empresa/VincularMotorista/VincularMotorista";
import ContainerCadastro from "../paginas/empresa/Cadastro/ContainerCadastro";

export const EmpresasRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Empresas.Principal}>
            <GridEmpresas />
          </Route>
          <Route path={Rotas.Empresas.Cadastro}>
            <ContainerCadastro />
          </Route>
          <Route path={Rotas.Empresas.VincularMotorista}>
            <VincularMotorista />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
