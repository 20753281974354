import { useHistory, useParams } from "react-router-dom";
import { mdiArrowLeft } from "@mdi/js";
import { Box, Button } from "@mui/material";
import {
  OptActionButton,
  OptBackdrop,
  OptGridRef,
  OptGridRequest,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { GridPromotoresView } from "./GridPromotoresView";
import styles from "./styles/gridPromotores.module.scss";
import { Colors } from "../../../compartilhado/colors";

import { useEventosService } from "../../../servicos/eventos.service";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";
import { EVENTO_FORM_INITIAL_STATE } from "../../../constantes/EventoFormInitialState";
import { usePromotorService } from "../../../servicos/promotor.service";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";

export const GridPromotores = () => {
  const gridRef = useRef<OptGridRef>(null);
  const history = useHistory();
  const { idEvento } = useParams<{ idEvento: string }>();
  const { carregarEventoPorId } = useEventosService();
  const { buscarGridPromotores } = usePromotorService();
  
  const [evento, setEvento] = useState<EventoFormModel>(EVENTO_FORM_INITIAL_STATE);
  const [loading, setLoading] = useState<boolean>(true);

  function recarregarGrid() {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }

  const carregarPromotores = async (query: OptGridRequest) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro: "",
    };
    return buscarGridPromotores(request, idEvento);
  };

  useEffect(() => {
    (async () => {
      const response = await carregarEventoPorId(idEvento) as unknown as EventoFormModel;
      setEvento(response);
      setLoading(false)
    })()
  }, []);

  return (
    <>
      <OptBackdrop open={loading} />
      <Box width={'98%'} flexWrap={'wrap'}>
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={() => history.goBack()}
            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              {evento.nome} - Promotores
            </span>
          </OptActionButton>
        </div>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <div className={styles.ButtonSection}>
            <Button
              className="float-end"
              variant="contained"
              color="inherit"
              style={{ marginRight: "5px" }}
              onClick={() => history.push(`/promotores/associar-evento/${evento?.id}`)}
            >
              Associar ao evento
            </Button>
            <Button
              className="float-end"
              variant="contained"
              color="inherit"
              style={{ marginRight: "5px" }}
              onClick={() => history.push(`/promotores/associar-ponto-embarque/${evento?.id}`)}
            >
              Associar ao ponto de embarque
            </Button>
          </div>
        </Box>

        <GridPromotoresView
          ref={gridRef}
          data={carregarPromotores}
          recarregarGrid={recarregarGrid}
          idEvento={idEvento}
        />
      </Box>
    </>
  );
};
