import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, Grid, InputLabel, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { EMPRESA_FORM_INITIAL_STATE } from "../../../constantes/EmpresaFormInitialState";
import { MASCARA_CNPJ } from "../../../constantes/mascaras/MascaraCnpj";
import { MASCARA_TELEFONE } from "../../../constantes/mascaras/MascaraTelefone";
import { EmpresaFormModel } from "../../../modelos/empresa/empresaFormModel";
import { Rotas } from "../../../rotas";
import { useEmpresasService } from "../../../servicos/empresas.service";
import { FormularioEmpresaSchema } from "./FormularioEmpresa.validation";
import styles from "./styles/formularioEmpresa.module.scss";
import { useFormik } from "formik";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";

export interface IGeneric {
  isFromCadastroEmpresa?: any;
  handleUpdateGrid?: any;
  needToUpdateGrid?: boolean;
  obterDadosEmpresa?: any;
  idEmpresa?: string;
  nomeEmpresa?: string;
  idFromParent?: string;
  dadosParaEditar?: any;
  idPessoa?: any;
  limparCampos?: any;
  idFrota?: any;
}

export const FormularioEmpresa = ({
  isFromCadastroEmpresa,
  handleUpdateGrid,
  idFromParent,
  obterDadosEmpresa,
}: IGeneric) => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const { idEmpresa } = useParams<{ idEmpresa?: string }>();
  const [loading, setSalvarLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [empresa, setempresa] = useState<EmpresaFormModel>(
    EMPRESA_FORM_INITIAL_STATE
  );
  const { salvarEmpresa, editarEmpresa, carregarEmpresaPorId } =
    useEmpresasService();
  const edicao = Boolean(idEmpresa);
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  const { values, handleSubmit, handleChange, errors, setFieldValue, touched } =
    useFormik({
      initialValues: {
        id: "",
        nome: "",
        descricao: "",
        cnpj: "",
        telefone: "",
        cep: "",
        endereco: "",
        url: "",
        email: "",
      },
      enableReinitialize: false,
      validationSchema: FormularioEmpresaSchema,
      onSubmit: (values) => {
        onSubmit(values);
      },
    });

  useEffect(() => {
    if (idEmpresa || idFromParent) {
      carregarEmpresa(idFromParent ? idFromParent : idEmpresa);
    }
  }, []);

  async function carregarEmpresa(idEmpresa: any) {
    const response = (await carregarEmpresaPorId(
      idEmpresa
    )) as unknown as EmpresaFormModel;
    if (response) {
      obterDadosEmpresa(response);
      setempresa(response);
      setFieldValue("id", response.id);
      setFieldValue("nome", response.nome);
      setFieldValue("descricao", response.descricao);
      setFieldValue("cnpj", response.cnpj);
      setFieldValue("telefone", response.telefone);
      setFieldValue("cep", response.cep);
      setFieldValue("endereco", response.endereco);
      setFieldValue("email", response.email);
      setFieldValue("url", response.url);
    }
  }

  async function onSubmit(values: EmpresaFormModel) {
    setSalvarLoading(true);
    try {
      let res: any = "";
      const estaEditando = !!empresa.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (edicao) {
        res = await editarEmpresa(empresa.id!, values);
      } else {
        res = await salvarEmpresa(values);
      }
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      obterDadosEmpresa(res);
    } catch (error) {
      console.log("erro");
    } finally {
      setSalvarLoading(false);
    }
  }

  const onClose = () => {
    history.push(Rotas.Empresas.Principal);
  };

  useEffect(() => {
    (async () => {
      verificarAutorizacao([
        PerfilUsuario.Administrador,
        PerfilUsuario.Operacao,
      ]);
    })();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <OptBackdrop open={loading} />
      {!isFromCadastroEmpresa && (
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              {edicao ? "Editar empresa" : "Cadastro de empresa"}
            </span>
          </OptActionButton>
        </div>
      )}
      <Divider />
      <Grid
        className={styles.GridContent}
        container
        rowSpacing={1}
        columns={12}
        px={1.5}
      >
        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>Nome</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            variant="outlined"
            name="nome"
            onChange={handleChange}
            value={values.nome}
          />
          <span style={{ color: "red" }}>
            {touched.nome ? errors.nome : ""}
          </span>
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>Descrição</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            variant="outlined"
            name="descricao"
            onChange={handleChange}
            value={values.descricao}
          />
          <span style={{ color: "red" }}>
            {touched.descricao ? errors.descricao : ""}
          </span>
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>CNPJ</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            name="cnpj"
            variant="outlined"
            onChange={handleChange}
            value={MASCARA_CNPJ(values.cnpj)}
          />
          <span style={{ color: "red" }}>
            {touched.cnpj ? errors.cnpj : ""}
          </span>
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>Telefone</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            name="telefone"
            variant="outlined"
            onChange={handleChange}
            value={MASCARA_TELEFONE(values.telefone)}
          />
          <span style={{ color: "red" }}>
            {touched.telefone ? errors.telefone : ""}
          </span>
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>Cep</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            name="cep"
            variant="outlined"
            onChange={handleChange}
            value={values.cep}
          />
          <span style={{ color: "red" }}>{touched.cep ? errors.cep : ""}</span>
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>Endereço</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            name="endereco"
            variant="outlined"
            onChange={handleChange}
            value={values.endereco}
          />
          <span style={{ color: "red" }}>
            {touched.endereco ? errors.endereco : ""}
          </span>
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>E-mail</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            name="email"
            variant="outlined"
            onChange={handleChange}
            value={values.email}
          />
          <span style={{ color: "red" }}>
            {touched.email ? errors.email : ""}
          </span>
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <InputLabel>Url</InputLabel>

          <TextField
            className={styles.TextFieldCustom}
            name="url"
            variant="outlined"
            onChange={handleChange}
            value={values.url || ""}
          />
          <span style={{ color: "red" }}>{touched.url ? errors.url : ""}</span>
        </Grid>
      </Grid>

      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        {!isFromCadastroEmpresa && (
          <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
            Cancelar
          </Button>
        )}
        <Button type="submit" size="large" sx={sxButtonSaveConfig}>
          {"Salvar empresa"}
        </Button>
      </FooterToolbar>
    </form>
  );
};
