import {
  OptBackdrop,
  OptGridRef,
} from "@optsol/react";
import { useEffect, useRef, useState } from "react";

import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { OcorrenciaFormModel } from "../../../modelos/ocorrencia/ocorrenciaFormModel";
import { GridEmpresasPorOcorrenciasView } from "./GridEmpresasPorOcorrenciaView";


interface Props {
  ocorrencia?: OcorrenciaFormModel;
  recarregar: Function;
}

export const GridEmpresasPorOcorrencia = ({
  ocorrencia,
  recarregar,
}: Props) => {

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    
  const gridRef = useRef<OptGridRef>(null);
  

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, []);

  return (
    <>
      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        <GridEmpresasPorOcorrenciasView
          idOcorrencia={ocorrencia?.id}
          items={ocorrencia?.empresasContratadas}
          onDelete={recarregar}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};

