import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { OcorrenciaSearchResponse } from "../modelos/dtos/OcorrenciaSearchResponse";
import { CopiarOcorrenciaFormModel } from "../modelos/ocorrencia/copiarOcorrenciaFormModel";
import { OcorrenciaFormModel } from "../modelos/ocorrencia/ocorrenciaFormModel";
import { protectedResources } from "../authConfig";

export const useOcorrenciasService = () => {
  const { get, post, remove, put, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Ocorrencia`;

  const salvarOcorrencia = async (data: OcorrenciaFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerOcorrencia = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarOcorrencia = async (itemId: string, data: OcorrenciaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridOcorrenciaPaginated = async (data: SearchRequest<{}>) => {
    return gridPagedListSearch<OcorrenciaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  const carregarOcorrenciaPorId = async (id :string) => {
    return get<OcorrenciaFormModel>(`${baseApi}/${id}`);
  };

  const carregarOcorrencias = async () => {
    return get<OcorrenciaFormModel>(`${baseApi}/todos`);
  };

  const carregarOcorrenciasPorEvento = async (eventoId: string) => {
    return get<OcorrenciaFormModel>(`${baseApi}/evento/${eventoId}`);
  };

  const copiarOcorrencia = async (data: CopiarOcorrenciaFormModel) => {
    return post(`${baseApi}/copiar`, data);
  }

  return {
    salvarOcorrencia,
    removerOcorrencia,
    editarOcorrencia,
    buscarGridOcorrenciaPaginated,
    carregarOcorrenciaPorId,
    carregarOcorrencias,
    carregarOcorrenciasPorEvento,
    copiarOcorrencia,
  };
};