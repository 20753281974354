import { protectedResources } from "../authConfig";
import { useApi } from "../compartilhado/hooks/api";
import { ObterPassageirosTransferenciasModel, TransferirPassageirosParams } from "../modelos/transferirPassageiros/submitModel";

export const useTransferirPassageirosService = () => {
  const { post, get } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = "/gestao";

  const obterReservas = async (idPontoEmbarque: string, data: ObterPassageirosTransferenciasModel) => {
    return post(`${baseApi}/Transferencia/obterReservas/${idPontoEmbarque}`, data);
  };

  const obterHistoricoTransferencias = async (idPontoEmbarque: string) => {
    return get(`${baseApi}/Transferencia/historico/${idPontoEmbarque}`);
  };

  const transferirPassageiros = async (data: TransferirPassageirosParams) => {
    return post(`${baseApi}/Transferencia`, data);
  };

  return {
    obterReservas,
    obterHistoricoTransferencias,
    transferirPassageiros,
  };
};
