import { protectedResources } from "../authConfig";
import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { ListarEventosSearchResonse } from "../modelos/dtos/ListarEventosSearchResonse";
import { ListarPassageirosSearchResponse } from "../modelos/dtos/ListarPassageirosSearchResponse";
import { ListarPontosEmbarqueSearchResonse } from "../modelos/dtos/ListarPontosEmbarqueSearchResonse";
import { PaginatedSearchRequest } from "../modelos/search/PaginatedSearchRequest";
import { PontosDeEmbarqueSearchRequest } from "../modelos/search/PontosDeEmbarqueSearchRequest";
import { SmsSubmitModel } from "../modelos/sms/SmsSubmitModel";

interface PassageirosListProps {
  idEvento: string; 
  idOcorrencia?: string;
  idsPontosEmbarque?: string[];
}

export const useSmsService = () => {
  const { post } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = "/gestao";

  const obterPassageiros = async (data: PassageirosListProps) => {
    return post(`${baseApi}/Sms/obterPassageirosFiltro`, data);
  };

  const enviarSms = async (data: SmsSubmitModel) => {
    return post(`${baseApi}/Sms/passageiros`, data);
  };

  return {
    obterPassageiros,
    enviarSms,
  };
};
