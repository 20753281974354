import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { ViagemSearchResponse } from "../modelos/dtos/ViagemSearchResponse";

export const useViagensService = () => {
  const { gridSearch } = useApi();

  const baseApi = `/v1/viagem`;

  const buscarGridViagensPaginated = async (data: SearchRequest<{}>) => {
    return gridSearch<ViagemSearchResponse>(`${baseApi}/buscar-viagens`, data);
  };

  return {
    buscarGridViagensPaginated,
  };
};
