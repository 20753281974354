import * as Yup from "yup";
import { CategoriaFormModel } from "../../../modelos/categoria/categoriaFormModel";

export const CadastroCategoriaSchema: Yup.SchemaOf<CategoriaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    descricao: Yup.string().notRequired(),
    imagemMobilePadrao: Yup.string().notRequired(),
    imagemPadrao: Yup.string().notRequired(),
    blobImagemPadrao: Yup.string().notRequired(),
    blobImagemPadraoName: Yup.string().notRequired(),
    blobImagemMobilePadrao: Yup.string().notRequired(),
    blobImagemMobilePadraoName: Yup.string().notRequired(),
  });
