import { protectedResources } from "../authConfig";
import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { ListarEventosSearchResonse } from "../modelos/dtos/ListarEventosSearchResonse";
import { ListarPassageirosSearchResponse } from "../modelos/dtos/ListarPassageirosSearchResponse";
import { ListarPontosEmbarqueSearchResonse } from "../modelos/dtos/ListarPontosEmbarqueSearchResonse";
import { PaginatedSearchRequest } from "../modelos/search/PaginatedSearchRequest";
import { PontosDeEmbarqueSearchRequest } from "../modelos/search/PontosDeEmbarqueSearchRequest";
import { SmsSubmitModel } from "../modelos/sms/SmsSubmitModel";

interface BagagensListProps {
  idEvento: string; 
  idOcorrencia?: string;
  idPontoEmbarque?: string;
  idPassageiro?: string;
}

export const useBagagensService = () => {
  const { post, get, gridSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = "/gestao";

  const obterListaBagagens = async (data: BagagensListProps) => {
    return post(`${baseApi}/Bagagem/paginado/1/100`, data);
  };

  return {
    obterListaBagagens,
  };
};
