import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { GridItens } from "../paginas/item/ListarItens/GridItens";
import { FormularioItem } from "../paginas/item/FormularioItem/FormularioItem";

export const ItensRoutes = () => {

  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Itens.Principal}>
            <GridItens />
          </Route>
          <Route exact path={Rotas.Itens.Cadastro}>
            <FormularioItem />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
