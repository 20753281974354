import { useHistory, useParams } from "react-router-dom";
import { mdiSend, mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, FormControlLabel, Switch, Tooltip } from "@mui/material";
import {
  OptActionButton,
  OptBackdrop,
  OptGridRef,
  OptSelectionOption,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { GridGerenciaView } from "./GridGerenciaView";
import styles from "./styles/gridSms.module.scss";
import { INIT_SMS_FORM_DATA } from "../../../constantes/SmsSubmitModel";
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";

import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { format } from "date-fns";
import Select from "react-select";
import { useGerenciaComplementoService } from "../../../servicos/gerenciaComplemento.service";
import { GerenciaComplementoListProps } from "../../../modelos/dtos/GerenciaComplemento";
import { formatarHora } from "../../../utils/claimUtils";

export const GridGerencia = () => {
  const history = useHistory();
  const { idEvento, idOcorrencia, idPontoEmbarque } = useParams<{ idEvento?: string, idOcorrencia?: string, idPontoEmbarque?: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [eventoSelected, setEventoSelected] = useState<OptSelectionOption>();
  const [ocorrenciaSelected, setOcorrenciaSelected] = useState<OptSelectionOption | null>(null);
  const [pontosEmbarque, setPontosEmbarque] = useState<OptSelectionOption[]>([]);
  const [respostas, setRespostas] = useState<OptSelectionOption[]>([]);

  const [optionsEvento, setOptionsEvento] = useState<OptSelectionOption[]>([]);
  const [optionsOcorrencia, setOptionsOcorrencia] = useState<OptSelectionOption[]>([]);
  const [optionsPontoEmbarque, setOptionsPontoEmbarque] = useState<OptSelectionOption[]>([]);
  const [optionsResposta, setOptionsResposta] = useState<OptSelectionOption[]>([]);

  const [data, setData] = useState<any[]>([]);
  const [eventosCarregados, setEventosCarregados] = useState<boolean>(false);
  const [pergunta, setPergunta] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();
  const { obterListaRespostas, obterOpcoesRespostaEvento } = useGerenciaComplementoService();
  const { carregarEventos, carregarEventoPorId } = useEventosService();
  const { carregarOcorrenciasPorEvento, carregarOcorrenciaPorId } = useOcorrenciasService();

  const gridRef = useRef<OptGridRef>(null);

  function recarregarGrid() {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
    setLoading(false);
  }

  // Listar opções para EventosSelect
  async function obterSelectEventos() {
    if (!eventosCarregados) {
      let data: any = []

      await carregarEventos().then((response: any) => {
        response.map((x: any) => {
          if (idEvento === x.id) {
            setPergunta(x.complementos[0].pergunta)
            setEventoSelected({
              value: x.id,
              label: `${x.nome}`,
            })

            obterSelectOcorrecias(x.id)
          }

          data.push(
            {
              value: x.id,
              label: `${x.nome}`,
            }
          )
        });
      })

      setOptionsEvento(data);
      setEventosCarregados(true);
    }
  }

  // Listar opções para OcorrenciasSelect
  async function obterSelectOcorrecias(idEvento: string) {
    if (idEvento) {
      let data: any[] = []

      await carregarOcorrenciasPorEvento(idEvento).then((response: any) => {
        response.sort((a: any, b: any) => (new Date(a.data.replace('Z', ''))).getTime() - (new Date(b.data.replace('Z', ''))).getTime()).map((x: any) => {
          if (idOcorrencia === x.id) {
            setOcorrenciaSelected({
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            })
          }

          data.push(
            {
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            }
          )
        });
      })

      setOptionsOcorrencia(data)
    }
  }

  // Listar opções para PontosEmbarqueSelect
  async function obterSelectPontos(idOcorrencia: string) {
    let arr: any = []
    let arrPontosSelecionados: any = []

    optionsOcorrencia.map((ocorrencia) => {
      if (ocorrencia.value === idOcorrencia) {
        carregarOcorrenciaPorId(idOcorrencia).then((response: any) => {
          response.pontosRota.map((x: any) => {
            if (idPontoEmbarque === x.id) {
              arrPontosSelecionados.push({
                value: x.id,
                label: x.nome,
              })
            }

            arr.push(
              {
                value: x.id,
                label: `${x.nome} ${formatarHora(x)}`,
              }
            )
          })
        }).finally(() => {
          setOptionsPontoEmbarque(arr)
          setPontosEmbarque(arrPontosSelecionados)
        })
      }
    })
  }

  // Listar opções para RespostasSelect
  async function obterSelectRespostas(idEvento: string) {
    let data: any = []

    await obterOpcoesRespostaEvento(idEvento).then((response: any) => {
      response.map((x: any) => {
        data.push(
          {
            value: x,
            label: x,
          }
        )
      });
    })

    setOptionsResposta(data);
  }

  useEffect(() => {
    obterSelectEventos();
  }, [optionsEvento]);

  useEffect(() => {
    if (idOcorrencia) {
      obterSelectPontos(idOcorrencia)
    }
  }, [optionsOcorrencia]);

  useEffect(() => {
    setLoading(true);
    if (eventoSelected && ocorrenciaSelected && eventoSelected.value.length && ocorrenciaSelected.value.length) {
      let params: GerenciaComplementoListProps = {
        pergunta: pergunta,
        idOcorrencia: ocorrenciaSelected.value
      }

      if (pontosEmbarque && pontosEmbarque.length) {
        let pontosEmbarqueArr: string[] = []

        pontosEmbarque.map((pontoEmbarque) => {
          pontosEmbarqueArr.push(pontoEmbarque.value)
        })

        params.idsPontosEmbarque = pontosEmbarqueArr
      }

      if (respostas && respostas.length) {
        let respostasArr: string[] = []

        respostas.map((resposta) => {
          respostasArr.push(resposta.value)
        })

        params.respostas = respostasArr
      }

      obterListaRespostas(params).then((response) => {
        setData(response)
        recarregarGrid();
        return
      })
    } else if (eventoSelected && eventoSelected.value.length && !ocorrenciaSelected) {
      carregarEventoPorId(eventoSelected.value).then((response: any) => {
        setPergunta(response.complementos[0].pergunta)
        obterSelectRespostas(eventoSelected.value)
        recarregarGrid();
      })
    }
    else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventoSelected, ocorrenciaSelected, pontosEmbarque, respostas]);

  return (
    <Box width={'98%'} flexWrap={'wrap'}>
      <OptBackdrop open={loading} />
      <div className={styles.CabecalhoContainer}>
        <OptActionButton
          onClick={() => history.goBack()}
          startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
        >
          <span className={styles.SpanText}>
            Gerência de complemento
          </span>
        </OptActionButton>
      </div>

      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <div className={styles.ButtonSection}>
          <Select className={styles.StyledAsyncSelect}
            placeholder="Eventos"
            noOptionsMessage={() => "Sem opções pré-definidas"}
            value={eventoSelected}
            options={optionsEvento}
            onChange={(e: any) => {
              setEventoSelected(e);
              setOcorrenciaSelected(null);
              setPontosEmbarque([]);
              obterSelectOcorrecias(e.value);
            }}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? theme.light?.primary
                  : Colors.gray10,
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${theme.light?.primary}`
                  : "",
                "&:hover": {
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray8,
                },
              }),
            }}
          />

          <Select className={styles.StyledAsyncSelect}
            placeholder="Ocorrência"
            noOptionsMessage={() => "Nenhuma ocorrência encontrada"}
            isDisabled={!eventoSelected?.value.length ? true : false}
            value={ocorrenciaSelected}
            options={optionsOcorrencia}
            onChange={(e: any) => {
              setOcorrenciaSelected(e);
              setPontosEmbarque([]);
              obterSelectPontos(e.value);
            }}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? theme.light?.primary
                  : Colors.gray10,
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${theme.light?.primary}`
                  : "",
                "&:hover": {
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray8,
                },
              }),
            }}
          />

          <Select className={styles.StyledAsyncSelectPontoEmbarque}
            placeholder="Pontos de Embarque"
            noOptionsMessage={() => "Nenhum ponto de embarque encontrado"}
            isMulti
            isDisabled={!ocorrenciaSelected?.value.length ? true : false}
            value={pontosEmbarque}
            options={optionsPontoEmbarque}
            onChange={(e: any) => {
              setPontosEmbarque(e as OptSelectionOption[]);
            }}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? theme.light?.primary
                  : Colors.gray10,
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${theme.light?.primary}`
                  : "",
                "&:hover": {
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray8,
                },
              }),
            }}
          />

          {/* <Select className={styles.StyledAsyncSelect}
            placeholder="Respostas"
            noOptionsMessage={() => "Nenhuma resposta encontrada"}
            isMulti
            isDisabled={!ocorrenciaSelected?.value.length ? true : false}
            value={respostas}
            options={optionsResposta}
            onChange={(e: any) => {
              setRespostas(e as OptSelectionOption[]);
            }}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? theme.light?.primary
                  : Colors.gray10,
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${theme.light?.primary}`
                  : "",
                "&:hover": {
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray8,
                },
              }),
            }}
          /> */}
        </div>
      </Box>

      <GridGerenciaView
        ref={gridRef}
        data={data}
      />
    </Box>
  );
};
