import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { ComboSearchResponse } from "../modelos/dtos/ComboSearchResponse";
import { ComboFormModel } from "../modelos/rota/comboFormModel";
import { protectedResources } from "../authConfig";

export const useCombosService = () => {
  const { post, remove, put, gridSearch, get, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Combo`;

  const salvarCombo = async (data: ComboFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerCombo = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarCombo = async (itemId: string, data: ComboFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const carregarComboPorId = async (id :string) => {
    return get<ComboFormModel>(`${baseApi}/${id}`);
  };

  const carregarCombos = async () => {
    return get<ComboFormModel>(`${baseApi}/todos`);
  };

  return {
    salvarCombo,
    removerCombo,
    editarCombo,
    carregarComboPorId,
    carregarCombos,
  };
};