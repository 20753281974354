import { OptSelectionOption } from "@optsol/react";

export const OPTIONS_PAGINAS: OptSelectionOption[] = [
  {
    label: "Home",
    value: "home"
  },
  {
    label: "Painel",
    value: "painel"
  },
  {
    label: "Cadastro",
    value: "cadastro"
  },
  {
    label: "Redefinir Senha",
    value: "redefinirSenha"
  },
  {
    label: "Como você quer viajar",
    value: "comoVoceQuerViajar"
  },
  {
    label: "Forma de Pagamento",
    value: "formaPagamento"
  },
  {
    label: "Checkout",
    value: "checkout"
  },
  {
    label: "Busca",
    value: "busca"
  },
  {
    label: "Políticas de Privacidade",
    value: "politicaPrivacidade"
  },
  {
    label: "Portal de Privacidade",
    value: "portalPrivacidade"
  },
]

export const OPTIONS_ARQUIVO: OptSelectionOption[] = [
  {
    label: "Sitemap.xml",
    value: "sitemap.xml"
  },
  {
    label: "Robots.txt",
    value: "robots.txt"
  },
]