import { useHistory, useParams } from "react-router-dom";
import { mdiSend, mdiArrowLeft, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import { OptActionButton, OptBackdrop, OptGridRef, OptSelectionOption } from "@optsol/react";
import Select from "react-select";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { GridTransferirPassageirosView } from "./GridTransferirPassageirosView";
import styles from "./styles/gridTransferirPassageiros.module.scss";
import { INIT_TRANSFERIR_PASSAGEIROS_FORM_DATA } from "../../../constantes/TransferirPassageirosSubmitModel";
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";

import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { format } from "date-fns";
import { FormularioTransferirPassageiros } from "../FormularioTransferirPassageiros/FormularioTransferirPassageiros";
import { useTransferirPassageirosService } from "../../../servicos/transferir-passageiros.service";
import { TransferirPassageirosParams, TransferirPassageirosSubmitModel } from "../../../modelos/transferirPassageiros/submitModel";
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@material-ui/lab";
import { converterGmt, formatarHora } from "../../../utils/claimUtils";

export const GridTransferirPassageiros = () => {
  const gridRef = useRef<OptGridRef>(null);
  const history = useHistory();
  const { idEvento, idOcorrencia } = useParams<{ idEvento?: string, idOcorrencia?: string }>();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [optionsPontoEmbarque, setOptionsPontoEmbarque] = useState<OptSelectionOption[]>([]);
  const [pontoEmbarqueSelected, setPontoEmbarqueSelected] = useState<OptSelectionOption | null>(null);

  const [filtroPassageiro, setFiltroPassageiro] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const [dadosHistorico, setDadosHistorico] = useState<any[]>([]);
  const [selecoesDoGrid, setSelecoesDoGrid] = useState(INIT_TRANSFERIR_PASSAGEIROS_FORM_DATA);
  const [drawerFormularioAberto, setDrawerFormularioAberto] = useState(false);
  const [enviarLoading, setEnviarLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { transferirPassageiros, obterReservas, obterHistoricoTransferencias } = useTransferirPassageirosService();
  const { carregarOcorrenciaPorId } = useOcorrenciasService();

  function recarregarGrid() {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
    setLoading(false);
  }

  // Listar opções para PontosEmbarqueSelect
  async function obterSelectPontos(idOcorrencia: string) {
    let arr: any = []

    carregarOcorrenciaPorId(idOcorrencia).then((response: any) => {
      response.pontosRota.map((x: any) => {
        console.log(x)
        arr.push(
          {
            value: x.id,
            label: `${x.nome} ${formatarHora(x)}`,
          }
        )
      })
    }).finally(() => {
      setOptionsPontoEmbarque(arr)
    })
  }

  const atualizarSelecoesDoGrid = (passageiros: string[]) => {
    setSelecoesDoGrid({
      ...selecoesDoGrid,
      idsReservas: passageiros,
    });
  };

  function abrirDrawerFormulario(): void {
    setDrawerFormularioAberto(true);
  }

  function fecharDrawerFormulario(): void {
    setDrawerFormularioAberto(false);
  }

  const transferir = async (data: TransferirPassageirosSubmitModel) => {
    if (data.idsReservas.length === 0) {
      enqueueSnackbar(`Nenhuma reserva selecionada. Favor selecionar ao menos uma reserva`, { variant: "error" });
    } else {
      setEnviarLoading(true);
      try {
        let params: TransferirPassageirosParams = {
          idPontoEmbarqueDestino: data.idPontoEmbarque,
          idsReservas: data.idsReservas,
        }
        await transferirPassageiros(params);
        enqueueSnackbar(`Passageiros transferidos com sucesso!`, {
          variant: "success",
        });
        setEnviarLoading(false);
        setDrawerFormularioAberto(false);

        if (pontoEmbarqueSelected) {
          obterReservas(pontoEmbarqueSelected.value, {termoBusca: filtroPassageiro}).then((response: any) => {
            setData(response)
            recarregarGrid();
            return
          })
        }
      } catch {
        setEnviarLoading(false);
      }
    }
  };

  useEffect(() => {
    if (idOcorrencia) {
      obterSelectPontos(idOcorrencia)
    }
  }, [idOcorrencia]);

  const obterReservasTransferencia = () => {
    if (pontoEmbarqueSelected && idOcorrencia && idEvento && pontoEmbarqueSelected.value.length) {
      obterReservas(pontoEmbarqueSelected.value, {termoBusca: filtroPassageiro}).then((response: any) => {
        setData(response)
        recarregarGrid();
      })
    }
  }

  useEffect(() => {
    setLoading(true);
    if (pontoEmbarqueSelected && idOcorrencia && idEvento && pontoEmbarqueSelected.value.length) {
      obterHistoricoTransferencias(pontoEmbarqueSelected.value).then((response: any) => {
        setDadosHistorico(response)
      })
      obterReservasTransferencia();
    }
    else{
      setLoading(false);
    }
  }, [pontoEmbarqueSelected]);
  

  return (
    <>
      {
        drawerFormularioAberto &&
        <FormularioTransferirPassageiros
          open={drawerFormularioAberto}
          onClose={() => fecharDrawerFormulario()}
          transferir={transferir}
          defaultData={selecoesDoGrid}
          loading={enviarLoading}
        />
      }
      <Box width={'98%'} flexWrap={'wrap'}>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={() => history.goBack()}
            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              Transferir Passageiros
            </span>
          </OptActionButton>
        </div>

        <Box sx={{ display: "flex", justifyContent: "start", marginLeft: '20px' }} className="mt-1">
          <div className={styles.ButtonSection}>
            <TextField
              className={styles.TextFieldCustom}
              label="Nome do Passageiro ou N° da Reserva"
              variant="outlined"
              inputProps={{ maxLength: 255 }}
              onChange={e => setFiltroPassageiro(e.target.value)}
              value={filtroPassageiro}
              style={{width: '600px'}}
            />

            <Tooltip title={"Filtrar"}>
              <Button
                placeholder="Transferir Passageiros"
                size="large"
                onClick={() => obterReservasTransferencia()}
              >
                <Icon path={mdiMagnify} size={1} color={Colors.secondary} /> Filtrar
              </Button>
            </Tooltip>
          </div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "start", marginLeft: '20px' }} className="mt-4">
          <div className={styles.ButtonSection}>
            <Select className={styles.StyledAsyncSelect}
              placeholder="Ponto de Embarque"
              noOptionsMessage={() => "Nenhum ponto de embarque encontrado"}
              value={pontoEmbarqueSelected}
              options={optionsPontoEmbarque}
              onChange={(e: any) => {
                setPontoEmbarqueSelected(e);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Tooltip title={"Transferir Passageiros"}>
              <Button
                style={{ marginRight: "-15px", marginBottom: "11px" }}
                placeholder="Transferir Passageiros"
                size="large"
                onClick={pontoEmbarqueSelected?.value.length ? abrirDrawerFormulario : () => { }}
              >
                <Icon path={mdiSend} size={1} color={Colors.primary} /> Transferir
              </Button>
            </Tooltip>
          </div>
        </Box>

        <GridTransferirPassageirosView
          ref={gridRef}
          data={data}
          atualizarSelecoesDoGrid={atualizarSelecoesDoGrid}
        />

        <Grid item xs={12} className="mt-5 px-3">
          {
            dadosHistorico.length > 0 &&
            <h5>Histórico de alterações</h5>
          }
          <Grid className="grid-timeline">
            <Timeline className="p-0 mt-4">
              {
                dadosHistorico.length > 0 && dadosHistorico.map((atualizacao) => (
                  <TimelineItem className="mb-2">
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className="mb-2">
                        <b>{format(converterGmt(new Date(atualizacao.criadoEm.replace('Z', ''))), 'dd/MM/yyy HH:mm')}</b>: <b>{atualizacao.atributo}</b> alterado por <b>{atualizacao.autor}</b>
                      </div>
                      <div className="mb-2">
                        <b>Descrição:</b> <br />{atualizacao.descricao} <br />
                      </div>
                      <div className="mb-2">
                        <b>Passageiros:</b> <br />{atualizacao.passageiros} <br />
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                ))
              }
            </Timeline>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
