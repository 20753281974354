import { PontoRotaFormModel } from "../modelos/rota/PontoRotaFormModel";

export const PONTO_ROTA_FORM_INITIAL_STATE: PontoRotaFormModel = {
    nome: "",
    bairro: "",
    cidade: "",
    estado: "",
    idOcorrencia: "",
    lat: 0,
    lon: 0,
    numero: "",
    pontoReferencia: "",
    rua: "", 
    pontoEmbarque: true,
    idaEVolta: true,
    destaque: false,
    aberto: true,
    horarioIdaPartida: null,
    horarioIdaChegada: null,
    horarioVoltaPartida: null,
    horarioVoltaChegada: null,
    precoPassagem: 0.00,
    passagensDisponiveis: null,
    passagensVendidas: null
  };
  