import {
    OptSideLayoutPortalContainer,
    OptSideLayoutPortalContent,
  } from "@optsol/react";
  import { Route, Switch } from "react-router-dom";
  import { Rotas } from ".";
import { CadastroPessoa } from "../paginas/usuario/Cadastro/CadastroPessoa";
import { GridUsuarios } from "../paginas/usuario/ListarUsuarios/GridPessoas";
  export const UsuariosRoutes = () => {
    return (
      <OptSideLayoutPortalContainer>
        <OptSideLayoutPortalContent>
          <Switch>
            <Route exact path={Rotas.Usuarios.Principal}>
              <GridUsuarios />
            </Route>
            <Route exact path={Rotas.Usuarios.Cadastro}>
              <CadastroPessoa />
            </Route>
          </Switch>
        </OptSideLayoutPortalContent>
      </OptSideLayoutPortalContainer>
    );
  };
  