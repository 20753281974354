import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { Passageiros } from "../paginas/passageiros";


export const PassageirosRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Passageiros.Index}>
            <Passageiros />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
