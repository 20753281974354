import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Divider, Grid } from "@mui/material"
import Icon from "@mdi/react";
import { mdiEarth, mdiNoteTextOutline, mdiCalendar } from "@mdi/js";
import { SeoEvento } from "./evento";
import { SeoPagina } from "./paginas";
import { SeoGlobal } from "./global";

export const Seo = () => {
    const [showConfigurar, setShowConfigurar] = useState<boolean>(false);
    const [tipoConfiguracao, setTipoConfiguracao] = useState<"evento" | "global" | "pagina" | "" >("");
    
    return (
        <>
            <div className="col">
                <Grid className="mt-5 px-5" container rowSpacing={1} columns={12} px={1.5}>
                    <Grid xs={12}>
                        <div>
                            <h4 className="text-black"><b>Configurações de SEO</b></h4>
                        </div>
                    </Grid>
                    <Grid xs={12} className="mb-4">
                        <div>
                            <h6>Escolha um dos itens para ser redirecionado</h6>
                        </div>
                    </Grid>
                    <Divider />
                    <Grid container gap={1}>
                        <Grid className={`card-select-item-redirect ${tipoConfiguracao === 'evento' ? 'card-active' : ''}`} item xs={12} md={6} lg={3}>
                            <Card onClick={() => {
                                if (showConfigurar && tipoConfiguracao === 'evento') {
                                    setTipoConfiguracao('');
                                    setShowConfigurar(false)
                                } else {
                                    setTipoConfiguracao('evento');
                                    setShowConfigurar(true)
                                }
                            }}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiCalendar} size={1.7} />
                                        <h4 className="mb-0"><b>Evento</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid className={`card-select-item-redirect ${tipoConfiguracao === 'pagina' ? 'card-active' : ''}`} item xs={12} md={6} lg={3}>
                            <Card onClick={() => {
                                if (showConfigurar && tipoConfiguracao === 'pagina') {
                                    setTipoConfiguracao('');
                                    setShowConfigurar(false)
                                } else {
                                    setTipoConfiguracao('pagina');
                                    setShowConfigurar(true)
                                }
                            }}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiNoteTextOutline} size={1.7} />
                                        <h4 className="mb-0"><b>Página</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid className={`card-select-item-redirect ${tipoConfiguracao === 'global' ? 'card-active' : ''}`} item xs={12} md={6} lg={3}>
                            <Card onClick={() => {
                                if (showConfigurar && tipoConfiguracao === 'global') {
                                    setTipoConfiguracao('');
                                    setShowConfigurar(false)
                                } else {
                                    setTipoConfiguracao('global');
                                    setShowConfigurar(true)
                                }
                            }}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiEarth} size={1.7} />
                                        <h4 className="mb-0"><b>Global</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    
                    {
                        showConfigurar &&
                        <Grid container gap={1} className="mt-4">
                            {
                                tipoConfiguracao === 'evento' &&
                                <SeoEvento/>
                            }
                            {
                                tipoConfiguracao === 'pagina' &&
                                <SeoPagina/>
                            }
                            {
                                tipoConfiguracao === 'global' &&
                                <SeoGlobal/>
                            }
                        </Grid>
                    }
                </Grid>
            </div>
        </>
    )
}