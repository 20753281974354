import * as Yup from "yup";
import { ComboFormModel } from "../../../modelos/rota/comboFormModel";

export const FormularioComboSchema: Yup.SchemaOf<ComboFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    idOcorrencia: Yup.string().required("Item obrigatório"),
    idsRotas: Yup.array().notRequired(),
    valor: Yup.number().required("Item obrigatório"),
    valorStr: Yup.string().notRequired(),
  });
