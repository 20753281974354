import { styled } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { OptLayoutProvider } from "@optsol/react";
import { ptBR } from "date-fns/locale";
import { SnackbarProvider } from "notistack";
import { PropsWithChildren } from "react";
import { AuthProvider } from "../../contexts/auth";
import { theme } from "../../compartilhado/theme";

const CustomSnackBarProvider = styled(SnackbarProvider)`
  &.success {
    background-color: "purple";
  }
  &.error {
    background-color: "blue";
  }
  &.warning {
    background-color: "green";
  }
  &.info {
    background-color: "yellow";
  }
`;
export const AppProviders = ({ children }: PropsWithChildren<{}>) => {
  return (
    <CustomSnackBarProvider
      maxSnack={4}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={4000}
    >
      <AuthProvider>
        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
          <OptLayoutProvider theme={theme}>{children}</OptLayoutProvider>
        </LocalizationProvider>
      </AuthProvider>
    </CustomSnackBarProvider>
  );
};
