import { protectedResources } from "../authConfig";
import { useApi } from "../compartilhado/hooks/api";
import { EmailSubmitModel } from "../modelos/email/EmailSubmitModel";

interface PassageirosListProps {
  idEvento: string; 
  idOcorrencia?: string;
  idsPontosEmbarque?: string[];
}

export const useEmailService = () => {
  const { post: postGestao } = useApi({scopes: protectedResources.apiGestao.scopes});
  const { post: postVendas } = useApi({scopes: protectedResources.apiVenda.scopes});

  const baseApiGestao = "/gestao";
  const baseApiVendas = "/venda";

  const obterPassageiros = async (data: PassageirosListProps) => {
    return postGestao(`${baseApiGestao}/Sms/obterPassageirosFiltro`, data);
  };

  const enviarEmail = async (data: EmailSubmitModel) => {
    if(data.email === 'RESERVA' || data.email === 'PASSAGEM'){
      return postGestao(`${baseApiGestao}/Email`, {...data, idsVendas: undefined});
    }
    else if(data.email === 'CANCELAMENTO' || data.email === 'REEMBOLSO'){
      return postVendas(`${baseApiVendas}/Email`, {...data, idsPassageiros: undefined});
    }
    else{
      console.log('ERRO: Tipo de email não identificado')
      return null;
    }
  };

  return {
    obterPassageiros,
    enviarEmail,
  };
};
