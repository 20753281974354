import {
  OptConfirmationDialog,
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import { forwardRef, useState } from "react";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { ListaDePassageirosSearchRequest } from "../../../modelos/search/ListaDePassageirosSearchRequest";
import { Passageiro } from "../../../modelos/sms/Passageiro";
import { SmsPontosDeEmbarqueSubmit } from "../../../modelos/sms/SmsPontosDeEmbarqueSubmit";
import { format } from "date-fns";
import { formatarHora } from "../../../utils/claimUtils";
import { Colors } from "../../../compartilhado/colors";
import { mdiTrashCanOutline, mdiDeleteOutline } from "@mdi/js";
import { PromotorSearchResponse } from "../../../modelos/dtos/PromotorSearchResponse";
import { usePromotorService } from "../../../servicos/promotor.service";
import { useSnackbar } from "notistack";

interface Props {
  idEvento: string;
  data: (data: OptGridRequest) => Promise<OptGridResponse<PromotorSearchResponse>>;
  recarregarGrid: () => void;
}

export const GridPromotoresView = forwardRef<OptGridRef | undefined, Props>(({ idEvento, data, recarregarGrid }, ref) => {
  const { removerPromotor } = usePromotorService();
  const { enqueueSnackbar } = useSnackbar();

  const [showModalConfirmarExclusao, setShowModalConfirmarExclusao] = useState<boolean>(false);
  const [promotorToRemove, setPromotorToRemove] = useState<PromotorSearchResponse>();

  const options: OptGridOptions = {
    search: true,
    selection: false,
    pageSize: 100,
  };

  const columns: OptGridColumn<PromotorSearchResponse>[] = [
    {
      title: "Id",
      field: "idPromotor",
      align: "left",
      hidden: true,
    },
    {
      title: "Id",
      field: "nomePontoEmbarque",
      align: "left",
      hidden: true,
    },
    {
      title: "Promotor",
      field: "promotor",
      align: "left",
    },
    {
      title: "Escopo",
      field: "escopo",
      align: "left",
    },
  ];

  let acoes = [
    (rowData: PromotorSearchResponse) => ({
      icon: {
        path: mdiTrashCanOutline,
        size: 1,
        color: Colors.red,
      },
      tooltip: "Remover promotor",
      onClick: () => {
        console.log(rowData)
        setPromotorToRemove(rowData)
        setShowModalConfirmarExclusao(true)
      },
      disabled: false,
    }),
  ]

  async function handleDeletePromotor() {
    try {
        let params = {
          idEvento: idEvento,
          idPromotor: promotorToRemove?.idPromotor ?? "",
          nomePontoEmbarque: promotorToRemove?.nomePontoEmbarque
        }
        removerPromotor(params).then(() => {
          enqueueSnackbar(`Promotor removido com sucesso!`, {
            variant: "success",
          });
          recarregarGrid();
        })
    } finally {
      setShowModalConfirmarExclusao(false);
    }
  }

  return (
    <>
      <OptGridContainer>
        <OptGrid
          title=""
          ref={ref as any}
          columns={columns}
          data={data}
          options={options}
          actionsPosition={"end"}
          actions={acoes}
        />
      </OptGridContainer>

      <OptConfirmationDialog
        open={showModalConfirmarExclusao}
        title="Excluir Promotor"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={() => setShowModalConfirmarExclusao(false)}
        onClose={() => setShowModalConfirmarExclusao(false)}
        onConfirm={handleDeletePromotor} >
        Deseja confirmar a exclusão deste promotor? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>
    </>
  )}
);
