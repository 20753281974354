import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { OCORRENCIA_EMPRESA_FORM_INITIAL_STATE } from "../../../constantes/OcorrenciaEmpresaFormInitialState";
import { EmpresaFormModel } from "../../../modelos/empresa/empresaFormModel";
import { OcorrenciaEmpresaFormModel } from "../../../modelos/ocorrenciaEmpresa/ocorrenciaEmpresaFormModel";
import { useEmpresasService } from "../../../servicos/empresas.service";
import { useOcorrenciaEmpresasService } from "../../../servicos/ocorrenciaEmpresas.service";
import { FormularioOcorrenciaEmpresaSchema } from "./FormularioEmpresaPorOcorrencia.validation";
import styles from "./styles/formularioEmpresa.module.scss";
import { Theme, useTheme } from "@mui/material/styles";

interface Props {
  visivel: boolean;
  onClose: () => void;
  adicionarEmpresa: Function;
  idOcorrencia?: string;
  vinculadas?: any;
}

export const FormularioEmpresaPorOcorrencia = ({
  visivel,
  onClose,
  adicionarEmpresa,
  idOcorrencia,
  vinculadas,
}: Props) => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { idEmpresa } = useParams<{ idEmpresa?: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const [ocorrenciaEmpresa, setOcorrenciaEmpresa] = useState<OcorrenciaEmpresaFormModel>(OCORRENCIA_EMPRESA_FORM_INITIAL_STATE);
  const {
    salvarOcorrenciaEmpresa,
    editarOcorrenciaEmpresa,
    carregarOcorrenciaEmpresaPorId,
  } = useOcorrenciaEmpresasService();
  const edicao = Boolean(idEmpresa);
  const { carregarEmpresas } = useEmpresasService();
  const [empresas, setEmpresas] = useState<Array<EmpresaFormModel>>(new Array<EmpresaFormModel>());
  const [selectOptions, setSelectOptions] = useState<any>();

  useEffect(() => {
    carregarComboEmpresas();
    setOcorrenciaEmpresa({
      ...ocorrenciaEmpresa,
      idOcorrencia: idOcorrencia!,
    });

    console.log(ocorrenciaEmpresa.idsEmpresas)
    setValue("idOcorrencia", idOcorrencia!);
  }, []);

  useEffect(() => {
    if (vinculadas.length && empresas.length) {
      const filteredData = empresas.filter(
        (obj) => !vinculadas.includes(obj.id)
      );
      setSelectOptions(filteredData);
    }
  }, [vinculadas, empresas]);

  async function carregarComboEmpresas() {
    const response =
      (await carregarEmpresas()) as unknown as Array<EmpresaFormModel>;
    if (response) {
      setEmpresas(response);
      setSelectOptions(response)
      setLoading(false)
    }
  }

  function handleEmpresa(value: any) {
    let arr: any = []
    if (value && value.length > 0) {
      value.map((empresa: any) => {
        arr.push(empresa.id)
      })
    }
    
    setOcorrenciaEmpresa({
      ...ocorrenciaEmpresa,
      idsEmpresas: arr,
    });
    setValue("idsEmpresas", arr);
  }

  async function salvar() {
    if(!ocorrenciaEmpresa.idsEmpresas.length)
      return
    setLoading(true);
    try {
      const operacao = "criado";
      const novaOcorrencia = await salvarOcorrenciaEmpresa(ocorrenciaEmpresa);
      setLoading(false);
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      adicionarEmpresa(novaOcorrencia);
      onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const empresaForm = useForm<OcorrenciaEmpresaFormModel>({
    resolver: yupResolver(FormularioOcorrenciaEmpresaSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = empresaForm;

  if (loading) {
    return (<div>Carregando...</div>)
  }

  return (
    <form style={{ width: "600px", display: "flex" }}>
      <OptBackdrop open={loading} />
      <Divider />
      <Grid
        className={styles.GridContent}
        container
        rowSpacing={1}
        columns={12}
        px={1.5}
      >
        <Grid className={styles.GridItem} item xs={12}>
          <Controller
            name="idsEmpresas"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <FormControl sx={{ m: 1, width: "310px" }}>
                  <Autocomplete
                    multiple
                    id='select-label-empresa'
                    className={styles.TextFieldCustom}
                    options={selectOptions}
                    getOptionLabel={(option: any) => option.nome}
                    onChange={(e, value) => handleEmpresa(value)}
                    defaultValue={ocorrenciaEmpresa.idsEmpresas.length > 0 ? [ocorrenciaEmpresa.idsEmpresas.map((id) => {return id})] : undefined}
                    renderInput={(params) => <TextField {...params} label="Empresa" required />}
                  />
                </FormControl>
              </>
            )}
          />
          {/* <ErrorMessage error={errors.idsEmpresas} /> */}
        </Grid>
      </Grid>

      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
          Cancelar
        </Button>
        <Button
          size="large"
          onClick={salvar}
          sx={sxButtonSaveConfig}
          disabled={!ocorrenciaEmpresa.idsEmpresas.length}
        >
          Salvar
        </Button>
      </FooterToolbar>
    </form>
  );
};
