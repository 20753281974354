import { mdiContentCopy, mdiSale, mdiFileChart, mdiTransitTransfer } from "@mdi/js";
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../compartilhado/colors";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OcorrenciaSearchResponse } from "../../../modelos/dtos/OcorrenciaSearchResponse";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useHistory } from "react-router-dom";

interface Props {
  onRowClick: (rowData?: OcorrenciaSearchResponse) => void;
  carregar: (
    data: OptGridRequest
  ) => Promise<OptGridResponse<OcorrenciaSearchResponse>>;
  onClickCopiarOcorrencia: (rowData: OcorrenciaSearchResponse) => void;
  onClickRelatorio: (rowData: OcorrenciaSearchResponse) => void;
  onClickVenda: (rowData: OcorrenciaSearchResponse) => void;
  gridRef: React.MutableRefObject<OptGridRef | null>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridOcorrenciaView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      carregar,
      onRowClick,
      onClickCopiarOcorrencia,
      onClickRelatorio,
      onClickVenda,
      gridRef,
    },
    ref
  ) => {
    const history = useHistory();
    const [usuarioLogado, setUsuarioLogado] = useState<any>(null);
    const { obterUsuarioLogado } = useEventosService();
    const columns: OptGridColumn<OcorrenciaSearchResponse>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "Empresas Contratadas",
        field: "empresasContratadas",
        align: "left",
        hidden: true,
      },
      {
        title: "Id",
        field: "idEvento",
        align: "left",
        hidden: true,
      },
      {
        title: "Data do Evento",
        field: "data",
        align: "center",
        hidden: false,
        width: 15,
        render: (row) => {
          let data = "";
          if (row.data) {
            data = format(new Date(row.data.replace('Z', '')), 'dd/MM/yyyy');
          }

          return (
            <span>
              {data}
            </span>
          );
        },
      },
      {
        title: "Início do Evento",
        field: "horaInicio",
        align: "center",
        hidden: false,
        width: 15,
        render: (row) => {
          let data = "";
          if (row.horaInicio) {
            data = format(converterGmt(new Date(row.horaInicio.replace('Z', ''))), 'HH:mm');
          }

          return (
            <span>
              {data}
            </span>
          );
        },
      },
      {
        title: "Total de Passagens Vendidas",
        field: "totalReservasFeitas",
        align: "center",
        hidden: false,
        width: 15,
        render: (row) => {
          return (
            <span>
              {row.totalReservasFeitas !== null && row.totalReservasFeitas !== undefined ? row.totalReservasFeitas : 'N/A'}
            </span>
          );
        },
      },
    ];

    const converterGmt = (data: Date) => {
      const dataCopy = new Date(data);
      dataCopy.setHours(dataCopy.getHours() - 3);
      return dataCopy;
    }


    useEffect(() => {
      (async () => {
        setUsuarioLogado(await obterUsuarioLogado());
      })()
    }, []);

    let acoes = [
      (rowData: OcorrenciaSearchResponse) => ({
        icon: {
          path: mdiContentCopy,
          size: 1,
          color: Colors.gray,
        },
        tooltip: "Copiar Data do Evento",
        onClick: () => onClickCopiarOcorrencia(rowData),
        disabled: false,
      }),
    ];

    if (usuarioLogado && [PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente].indexOf(usuarioLogado.perfil) != -1)
      acoes.push((rowData: OcorrenciaSearchResponse) => {
        return ({
          icon: {
            path: mdiFileChart,
            size: 1,
            color: Colors.gray,
          },
          tooltip: "Relatório de passageiros por data do evento",
          onClick: () => onClickRelatorio(rowData),
          disabled: false,
        });
      })

    if (usuarioLogado && [PerfilUsuario.Administrador, PerfilUsuario.Operacao].indexOf(usuarioLogado.perfil) != -1)
      acoes.push((rowData: OcorrenciaSearchResponse) => {
        return ({
          icon: {
            path: mdiSale,
            size: 1,
            color: Colors.gray,
          },
          tooltip: "Relatório de vendas por data do evento",
          onClick: () => onClickVenda(rowData),
          disabled: false,
        });
      })
    if (usuarioLogado && [PerfilUsuario.Administrador, PerfilUsuario.Operacao].indexOf(usuarioLogado.perfil) != -1)
      acoes.push((rowData: OcorrenciaSearchResponse) => {
        return ({
          icon: {
            path: mdiTransitTransfer,
            size: 1,
            color: Colors.gray,
          },
          tooltip: "Transferir Passageiros",
          onClick: () => history.push(`/transferir-passageiros/${rowData.idEvento}/${rowData.id}`),
          disabled: false,
        });
      })

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={gridRef}
            columns={columns}
            data={carregar}
            options={options}
            onRowClick={onRowClick}
            actionsPosition={"end"}
            actions={acoes}
          />
        </OptGridContainer>
      </>
    );
  }
);
