import { OptActionButton, OptGridRequest } from "@optsol/react";
import { Colors } from "../../../compartilhado/colors";
import { mdiChevronLeft } from "@mdi/js";
import { useHistory, useParams } from "react-router-dom";
import styles from "./styles/relatorioPassageirosOcorrencia.module.scss";
import { Autocomplete, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useReservasService } from "../../../servicos/reserva.service";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";

import {
    OptGridRef,
} from "@optsol/react";
import { PaginatedReservaRequest } from "../../../modelos/search/PaginatedReservaRequest";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { RelatorioPassageirosView } from "./relatorioPassageiroView";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";
import { read, utils, writeFileXLSX } from 'xlsx';
import { ReservaSearchResponse } from "../../../modelos/dtos/ReservaSearchResponse";
import { StatusReserva } from "../../../enums/StatusReserva";
import { addHours, format } from "date-fns";
import { OcorrenciaModel } from "../../../modelos/ocorrencia/ocorrenciaModel";
import { TipoDocumento } from "../../../enums/tipoDocumento";
import { TipoDeficiencia } from "../../../enums/tipoDeficiencia";
import { useSnackbar } from "notistack";
import { formatarHora } from "../../../utils/claimUtils";


export const RelatorioPassageirosOcorrencia = () => {

    const { idOcorrencia } = useParams<{ idOcorrencia: string }>();
    const { idEvento } = useParams<{ idEvento: string }>();
    const [idPontoEmbarque, setIdPontoEmbarque] = useState<string>();
    const history = useHistory();
    const { carregarOcorrenciaPorId } = useOcorrenciasService();
    const { buscarGridReservasPaginated } = useReservasService();
    const [reservas, setReservas] = useState<ReservaSearchResponse[]>([]);
    const [numeroTotalReservas, setNumeroTotalReservas] = useState<number>(100);
    const [carregandoExcel, setCarregandoExcel] = useState<boolean>(false);
    const [ocorrenciaCarregada, setOcorrenciaCarregada] = useState<OcorrenciaModel>();
    const gridRef = useRef<OptGridRef>(null);
    const { enqueueSnackbar } = useSnackbar();

    const { verificarAutorizacao } = useEventosService();

    function recarregar() {
        gridRef.current?.refresh();
    }

    async function carregarOcorrencia(idOcorrencia: string) {
        const ocorrencia = await carregarOcorrenciaPorId(idOcorrencia) as unknown as OcorrenciaModel;
        setOcorrenciaCarregada(ocorrencia);
    }

    const carregar = async (
        query: OptGridRequest,
        filtro: PaginatedReservaRequest = {
            idEvento,
            idOcorrencia,
            idPontoEmbarque,
        }
    ) => {
        const request: SearchRequest<PaginatedReservaRequest> = {
            page: query.page,
            pageSize: query.pageSize,
            filtro,
        };
        let result = await buscarGridReservasPaginated(request);
        setReservas(result.data);
        setNumeroTotalReservas(result.totalCount);
        return buscarGridReservasPaginated(request);
    };

    function handlePontoEmbarque(event: any) {
        setIdPontoEmbarque(event);
    }

    const filtroForm = useForm();

    const {
        control
    } = filtroForm;


    useEffect(() => {
        carregarOcorrencia(idOcorrencia);

        if (gridRef.current) {
            recarregar();
        }

    }, [idOcorrencia]);

    useEffect(() => {
        recarregar();
    }, [idPontoEmbarque]);


    useEffect(() => {
        (async () => {
            verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente]);
        })()
    }, []);

    const onClose = () => {
        history.push(`/eventos/ver/${idEvento}`);
    };


    const formatarTelefone = (telefone: string) => {
        let telefone_limpo = telefone.replace(/\D/g,'');
        // (XX) X XXXX-XXXX
      
        if(telefone_limpo.length === 11)
            return `(${telefone_limpo.substring(0, 2)}) ${telefone_limpo[2]} ${telefone_limpo.substring(3, 7)}-${telefone_limpo.substring(7)}`
      
        if(telefone_limpo.length === 10)
            return `(${telefone_limpo.substring(0, 2)}) ${telefone_limpo.substring(2, 6)}-${telefone_limpo.substring(6)}`
      
        if(telefone_limpo.length === 9)
            return `${telefone_limpo[0]} ${telefone_limpo.substring(1, 5)}-${telefone_limpo.substring(5)}`
      
        if(telefone_limpo.length === 8)
            return `${telefone_limpo.substring(2, 6)}-${telefone_limpo.substring(6)}`
        
        return telefone
      }

    const converterParaExcel = (dados: ReservaSearchResponse[]) => {
        return dados.map(r => {
            return {
                'Reserva': r.numeroReserva,
                'Data da Reserva': format(new Date(r.criadoEm), "dd/MM/yyyy HH:mm"),
                'Passageiro': r.usuario.nome,
                'Telefone': formatarTelefone(r.usuario.telefone),
                'Email': r.usuario.email,
                'Menor de 12 anos': r.usuario.maiorDeIdade ? 'Não' : 'Sim',
                'Tipo de Documentação': TipoDocumento[r.usuario.tipoDocumento],
                'N° do Documento': r.usuario.documento,
                'Tipo de Deficiência': TipoDeficiencia[r.usuario.tipoDeficiencia],
                'Descrição da Deficiência': r.usuario.descricaoDeficiencia,
                'Id Venda': r.idVenda,
                'Ponto de Embarque': r.pontoEmbarque.nome,
                'Data e Hora IDA': r.pontoEmbarque.horarioIdaPartida ? format(new Date(r.pontoEmbarque.horarioIdaPartida), 'dd/MM/yyyy HH:mm') : null,
                'Data e Hora VOLTA': r.pontoEmbarque.horarioVoltaPartida ? format(new Date(r.pontoEmbarque.horarioVoltaPartida), 'dd/MM/yyyy HH:mm') : null,
                'Status': StatusReserva[r.status]
            }
        });
    }

    const exportarParaExcel = async () => {
        setCarregandoExcel(true);
        const request: SearchRequest<PaginatedReservaRequest> = {
            page: 0,
            pageSize: numeroTotalReservas,
            filtro: {
                idEvento,
                idOcorrencia,
                idPontoEmbarque
            },
        };
        let result = await buscarGridReservasPaginated(request);

        const ws = utils.json_to_sheet(converterParaExcel(result.data));
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Passageiros");
        
        writeFileXLSX(wb, `Passageiros ${ocorrenciaCarregada?.nomeEvento || ''} - ${ocorrenciaCarregada?.data ? format(addHours(new Date(ocorrenciaCarregada.data), 3), "dd-MM-yyyy") : ''} - VouDeSquad.xlsx`);
        enqueueSnackbar(`Arquivo excel gerado com sucesso! Seu download iniciará em instantes...`, {
            variant: "success",
        });
        setCarregandoExcel(false);
    }

    return (
        <>

            <OptSideLayoutContentCustom>
                <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                    {ocorrenciaCarregada && ocorrenciaCarregada.pontosRota &&
                        <form>

                            <div className={styles.CabecalhoContainer}>
                                <OptActionButton
                                    onClick={onClose}
                                    startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
                                >
                                    <span className={styles.SpanText}>
                                        Ver eventos
                                    </span>
                                </OptActionButton>
                            </div>
                            <Divider />

                            <Grid container rowSpacing={1} columns={12} px={1.5}>
                                <Grid item xs={6} marginRight={2}>
                                    <Controller
                                        name="coluna"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: 1 }} size="medium">
                                                <Autocomplete
                                                    id='select-label-rota'
                                                    className={styles.TextFieldCustom}
                                                    options={ocorrenciaCarregada!.pontosRota!}
                                                    getOptionLabel={(option: any) => `${option.nome} ${formatarHora(option)}`}
                                                    onChange={(e, value) => handlePontoEmbarque(value?.id)}
                                                    renderInput={(params) => <TextField {...params} label="Pontos de embarque" required/>}
                                                />
                                            </FormControl>
                                        )} />
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                color="info"
                                style={{ margin: "10px 10px 10px 13px" }}
                                size="small"
                                onClick={exportarParaExcel}
                                disabled={carregandoExcel}
                            >
                                {carregandoExcel ? 'Exportando...':'Exportar para Excel'}
                            </Button>
                        </form>
                    }
                    <RelatorioPassageirosView
                        carregar={carregar}
                        gridRef={gridRef}
                    />
                </Grid>
            </OptSideLayoutContentCustom>



        </>
    );
}