import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { OcorrenciaEmpresaSearchResponse } from "../modelos/dtos/OcorrenciaEmpresaSearchResponse";
import { OcorrenciaEmpresaFormModel } from "../modelos/ocorrenciaEmpresa/ocorrenciaEmpresaFormModel";
import { PaginatedEmpresaRequest } from "../modelos/search/PaginatedEmpresaRequest";
import { protectedResources } from "../authConfig";

export const useOcorrenciaEmpresasService = () => {
  const { post, remove, put, get, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Contratacao`;

  const salvarOcorrenciaEmpresa = async (data: OcorrenciaEmpresaFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerOcorrenciaEmpresa = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarOcorrenciaEmpresa = async (itemId: string, data: OcorrenciaEmpresaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridOcorrenciaEmpresasPaginated = async (data: SearchRequest<PaginatedEmpresaRequest>) => {
    return gridPagedListSearch<OcorrenciaEmpresaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  const carregarOcorrenciaEmpresaPorId = async (id :string) => {
    return get<OcorrenciaEmpresaFormModel>(`${baseApi}/${id}`);
  };

  const carregarOcorrenciaEmpresas = async () => {
    return get<OcorrenciaEmpresaFormModel>(`${baseApi}/todos`);
  };

  return {
    salvarOcorrenciaEmpresa,
    removerOcorrenciaEmpresa,
    editarOcorrenciaEmpresa,
    buscarGridOcorrenciaEmpresasPaginated,
    carregarOcorrenciaEmpresaPorId,
    carregarOcorrenciaEmpresas,
  };
};