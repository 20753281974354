import { mdiDeleteOutline, mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Grid, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import {
  OptConfirmationDialog,
  OptGridRef,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../compartilhado/colors";
import { RotaSearchResponse } from "../../../modelos/dtos/RotaSearchResponse";
import { useRotasService } from "../../../servicos/rotas.service";
import { Controller, useForm } from "react-hook-form";
import styles from "../cadastro/styles/formularioRota.module.scss"
import { CopiarRotaFormModel } from "../../../modelos/rota/copiarRotaFormModel";
import { COPIAR_ROTA_FORM_INITIAL_STATE } from "../../../constantes/CopiarRotaFormInitialState";
import { useHistory } from "react-router-dom";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";

interface Props {
  onRowClick: (rowData?: RotaSearchResponse) => void;
  itens: Array<RotaSearchResponse>;
  gridRef: React.MutableRefObject<OptGridRef | null>;
}

export const GridRotasView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      onRowClick,
      itens,
      gridRef,
    },
    ref
  ) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
    const [exibirModalCopia, setExibirModalCopia] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [rotas, setRotas] = useState<Array<RotaSearchResponse>>(itens);
    const [rotaSelecionada, setRotaSelecionada] = useState<RotaSearchResponse>();
    const [rotaCopia, setRotaCopia] = useState<CopiarRotaFormModel>(COPIAR_ROTA_FORM_INITIAL_STATE);
    const { removerRota, copiarRota } = useRotasService();
    const history = useHistory();

    const copiaRotaForm = useForm<any>();
    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
    } = copiaRotaForm;

    async function onClickCopiaRotaHandler() {
      if (loading)
        return enqueueSnackbar("Por favor, aguarde o término da ação em execução.", { variant: "info" });

      setLoading(true);
      try {
        if (rotaSelecionada && rotaSelecionada.id) {
          const eventoCopiaPost = { ...rotaCopia, idRota: rotaSelecionada.id };
          const novaRota = await copiarRota(eventoCopiaPost);
          setExibirModalCopia(false);
          enqueueSnackbar(`Registro copiado com sucesso!`, { variant: "success" });
          setRotas([...rotas, novaRota]);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }

    function handleNovaDescricao(event: any) {
      const novaDescricao = event.target.value;
      setRotaCopia({ ...rotaCopia, novaDescricao });
      setValue('novaDescricao', novaDescricao);

    }

    function abrirModalCopia(rowData: RotaSearchResponse) {
      setRotaSelecionada(rowData);
      setExibirModalCopia(true);
    }

    function fecharModalCopia() {
      setExibirModalCopia(false);
    }

    function removerItem(rowData: RotaSearchResponse) {
      setConfirmandoExclusao(true);
      setRotaSelecionada(rowData);
    }

    function cancelarExclusao() {
      setConfirmandoExclusao(false);
    }

    async function onClickDeleteItemHandler() {
      setConfirmandoExclusao(false);
      try {
        await removerRota(rotaSelecionada?.id!);
        enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
        const novoValor = rotas?.filter(rota => rota.id != rotaSelecionada?.id);
        setRotas(novoValor);
      } catch {

      }
    }

    function verOcorrencia(rota: RotaSearchResponse) {
      history.push(`/rotas/ver/${rota.idOcorrencia}/${rota.id}`);
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        border: "1px solid #ccc",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

    return (
      <>
        <OptConfirmationDialog
          open={confirmandoExclusao}
          title="Excluir rota"
          icon={{ path: mdiDeleteOutline, color: Colors.red }}
          onCancel={cancelarExclusao}
          onClose={cancelarExclusao}
          onConfirm={onClickDeleteItemHandler}
        >
          Deseja confirmar a exclusão da rota ? <br /> Essa operação não poderá ser desfeita.
        </OptConfirmationDialog>
        <OptConfirmationDialog
          open={exibirModalCopia}
          title="Copiar Rota"
          icon={{ path: mdiContentCopy, color: Colors.green1 }}
          onCancel={fecharModalCopia}
          onClose={fecharModalCopia}
          onConfirm={onClickCopiaRotaHandler} >
          Insira o nome da nova rota
          <form onSubmit={async (e) => { e.preventDefault(); await onClickCopiaRotaHandler() }}>
            <Grid className={styles.GridItem} item xs={12}>
              <Controller
                name="novaDescicao"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    className={styles.TextFieldCustom}
                    label="Nome"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    onChange={handleNovaDescricao}
                    value={rotaCopia.novaDescricao}
                  />
                )}
              />
            </Grid>
          </form>
        </OptConfirmationDialog>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nome</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rotas?.filter(rota => rota.ativo).length == 0 ?
                  <StyledTableRow><StyledTableCell colSpan={2} align="center"> Não há registros a serem exibidos </StyledTableCell></StyledTableRow> :
                  <StyledTableRow></StyledTableRow>
              }
              {rotas?.filter(rota => rota.ativo).map((rota) => {
                return (
                  <StyledTableRow key={rota.id}>
                    <StyledTableCell align="left" role="button" className="col-10" onClick={() => { verOcorrencia(rota) }} >{rota.nome}</StyledTableCell>
                    <StyledTableCell align="left" className="col-2">
                      <Tooltip title={"Copiar Rota"}>
                        <Button
                          variant="contained"
                          color="inherit"
                          style={{ marginRight: "5px", marginBottom: "11px" }}
                          placeholder="Copiar Rota"
                          size="large"
                          onClick={() => { abrirModalCopia(rota) }}
                        >
                          <Icon path={mdiContentCopy} size={1} color={Colors.black1} />
                        </Button>
                      </Tooltip>

                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
