import Select from "react-select";
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, Grid } from "@mui/material";
import { OptActionButton, OptBackdrop, OptSelectionOption } from "@optsol/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { FormularioTransferirPassageirosSchema } from "./Formulario.validation";
import styles from "./styles/formularioSms.module.scss";
import { StyledDrawer } from "./styles";

import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { theme } from "../../../compartilhado/theme";
import { TransferirPassageirosSubmitModel } from "../../../modelos/transferirPassageiros/submitModel";
import { formatarHora } from "../../../utils/claimUtils";

interface Props {
  open: boolean;
  loading: boolean;
  defaultData: TransferirPassageirosSubmitModel;
  onClose: () => void;
  transferir: (data: TransferirPassageirosSubmitModel) => Promise<void>;
}

export const FormularioTransferirPassageiros = ({ open, onClose, defaultData, transferir, loading}: Props) => {
  const { handleSubmit, setValue, formState: { errors } } = useForm<TransferirPassageirosSubmitModel>({ defaultValues: defaultData, resolver: yupResolver(FormularioTransferirPassageirosSchema) });
  const { carregarEventos } = useEventosService();
  const { carregarOcorrenciasPorEvento, carregarOcorrenciaPorId } = useOcorrenciasService();

  const [optionsEvento, setOptionsEvento] = useState<OptSelectionOption[]>([]);
  const [optionsOcorrencia, setOptionsOcorrencia] = useState<OptSelectionOption[]>([]);
  const [optionsPontoEmbarque, setOptionsPontoEmbarque] = useState<OptSelectionOption[]>([]);
  const [eventoSelected, setEventoSelected] = useState<OptSelectionOption>();
  const [ocorrenciaSelected, setOcorrenciaSelected] = useState<OptSelectionOption | null>(null);
  const [pontoEmbarqueSelected, setPontoEmbarqueSelected] = useState<OptSelectionOption | null>(null);

  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  async function obterSelectEventos() {
    let data: any = []

    await carregarEventos().then((response: any) => {
      response.map((x: any) => {  
        data.push(
          {
            value: x.id,
            label: `${x.nome}`,
          }
        )
      });
    })

    setOptionsEvento(data);
  }

  // Listar opções para OcorrenciasSelect
  async function obterSelectOcorrecias(idEvento: string) {
    let data: any[] = []

    await carregarOcorrenciasPorEvento(idEvento).then((response: any) => {
      response.sort((a: any, b: any) => (new Date(a.data.replace('Z', ''))).getTime() - (new Date(b.data.replace('Z', ''))).getTime()).map((x: any) => {
        data.push(
          {
            value: x.id,
            label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
          }
        )
      });
    })

    setOptionsOcorrencia(data)
  }

  // Listar opções para PontosEmbarqueSelect
  async function obterSelectPontos(idOcorrencia: string) {
    let arr: any = []
    let arrPontosSelecionados: any = []

    carregarOcorrenciaPorId(idOcorrencia).then((response: any) => {
      response.pontosRota.map((x: any) => {
        arr.push(
          {
            value: x.id,
            label: `${x.nome} ${formatarHora(x)}`,
          }
        )
      })
    }).finally(() => {
      setOptionsPontoEmbarque(arr)
    })
  }

  useEffect(() => {
    obterSelectEventos();
  }, []);

  useEffect(() => {
    if (eventoSelected?.value) {
      obterSelectOcorrecias(eventoSelected?.value)
    }
  }, [eventoSelected]);

  useEffect(() => {
    if (ocorrenciaSelected?.value) {
      obterSelectPontos(ocorrenciaSelected?.value)
    }
  }, [ocorrenciaSelected]);

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <form>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>Transferir Passageiros</span>
          </OptActionButton>
        </div>
        <Divider />
        <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
          <Grid className={styles.GridItem} item xs={12}>
            <Select className={styles.StyledAsyncSelect}
              placeholder="Evento"
              noOptionsMessage={() => "Sem opções pré-definidas"}
              value={eventoSelected}
              options={optionsEvento}
              onChange={(e: any) => {
                setEventoSelected(e);
                setOcorrenciaSelected(null);
                setPontoEmbarqueSelected(null);
                obterSelectOcorrecias(e.value);
                setValue('idEvento', e.value)
                setValue('idOcorrencia', "")
                setValue('idPontoEmbarque', "")
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />
            <ErrorMessage error={errors.idEvento} />
          </Grid>

          <Grid className={styles.GridItem} item xs={12}>
            <Select className={styles.StyledAsyncSelect}
              placeholder="Ocorrência"
              noOptionsMessage={() => "Nenhuma ocorrência encontrada"}
              isDisabled={!eventoSelected?.value.length ? true : false}
              value={ocorrenciaSelected}
              options={optionsOcorrencia}
              onChange={(e: any) => {
                setOcorrenciaSelected(e);
                setPontoEmbarqueSelected(null);
                obterSelectPontos(e.value);
                setValue('idOcorrencia', e.value)
                setValue('idPontoEmbarque', "")
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />
            <ErrorMessage error={errors.idOcorrencia} />
          </Grid>

          <Grid className={styles.GridItem} item xs={12}>
            <Select className={styles.StyledAsyncSelect}
              placeholder="Ponto de Embarque"
              noOptionsMessage={() => "Nenhum ponto de embarque encontrado"}
              isDisabled={!ocorrenciaSelected?.value.length ? true : false}
              value={pontoEmbarqueSelected}
              options={optionsPontoEmbarque}
              onChange={(e: any) => {
                setPontoEmbarqueSelected(e);
                setValue('idPontoEmbarque', e.value)
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />
            <ErrorMessage error={errors.idPontoEmbarque} />
          </Grid>
        </Grid>
        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
            Cancelar
          </Button>
          <Button
            size="large"
            onClick={handleSubmit(transferir)}
            sx={sxButtonSaveConfig}
          >
            Enviar
          </Button>
        </FooterToolbar>
      </form>
    </StyledDrawer>
  );
};
