import { OptSideLayoutContentCustom } from "../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';

const Home = () => {
  const { instance } = useMsal();
  let activeAccount: AccountInfo | null = null;

  if (instance) {
      activeAccount = instance.getActiveAccount();
  }

  return (
    <OptSideLayoutContentCustom>
      
      <AuthenticatedTemplate>
        <div style={{ margin: "50px"}}>Bem vindo <strong>{activeAccount?.name}</strong></div>
      </AuthenticatedTemplate>

    </OptSideLayoutContentCustom>
  );
};

export default Home;
