import { Autocomplete, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Icon from "@mdi/react";
import { mdiMinus, mdiPlus } from "@mdi/js";
import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { Colors } from "../../compartilhado/colors";
import { TipoRecorrencia } from "../../enums/TipoRecorrencia";
import { EventoFormModel } from "../../modelos/evento/eventoFormModel";
import styles from "./styles/periodo.module.scss";
import { RecorrenciaMensalGrid } from "./recorrenciaMensalGrid";
import { RecorrenciaAnualGrid } from "./recorrenciaAnualGrid";
import { RecorrenciaSemanalGrid } from "./recorrenciaSemanalGrid";
import { RecorrenciaDiariaGrid } from "./recorrenciaDiariaGrid";
import { TipoOcorrencia } from "../../enums/TipoOcorrencia";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";

interface Props {
  control: Control<EventoFormModel, any>
  evento: EventoFormModel,
  setEvento: Function,
  setValue: Function,
  setDisableSalvar: Function,
}

export const Periodo = ({ control, evento, setEvento, setValue, setDisableSalvar }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tipoOcorrencia, setTipoOcorrencia] = useState(TipoOcorrencia.PorData);
  const [tipoRecorrencia, setTipoRecorrencia] = useState(0);
  const [tiposRecorrencia, setTiposRecorrencia] = useState(new Array<any>());
  const [datasOcorrencias, setDatasOcorrencias] = useState(new Array<Date>(new Date()));
  const [conteudoRecorrencia, setConteudoRecorrencia] = useState<any>(null);
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 5);
  const minDateString = minDate.toJSON().split('T')[0];
  const maxDateString = maxDate.toJSON().split('T')[0];

  useEffect(() => {
    carregarTiposRecorrencia();
  }, []);

  const selecionarTipoOcorrencia = (event: any) => {
    const tipoOcorrencia = event.target.value;
    setTipoOcorrencia(tipoOcorrencia);
    setEvento((eventoAtual: any) => ({ ...eventoAtual, tipoOcorrencia }))
    setValue('tipoOcorrencia', tipoOcorrencia);
    setValue('noDia', true)

    if (TipoOcorrencia.PorRecorrencia.toString() === tipoOcorrencia) {
      setDisableSalvar(false)
    }
  }

  function adicionarData() {
    setDatasOcorrencias([
      ...datasOcorrencias,
      new Date(),
    ]);
  }

  function RemoverData(index: number) {
    const datas = [...datasOcorrencias];
    datas.splice(index, 1);
    setDatasOcorrencias([...datas]);

    if (datas.length === 1) {
      setDisableSalvar(false)
    }
  }

  function carregarTiposRecorrencia() {
    const itens = Object.keys(TipoRecorrencia).filter((item) => {
      return isNaN(Number(item));
    });
    setTiposRecorrencia(itens);
  }

  function handleOcorrencias(event: any, index: number, evento: EventoFormModel, setEvento: Function, setValue: Function) {
    let ocorrencias = evento.datasOcorrencias;
    let valor = event.target.value;
    event.preventDefault();

    if (!ocorrencias) {
      ocorrencias = new Array(valor);
    } else {
      if (ocorrencias.some((x) => x === valor) && ocorrencias[index] !== valor) {
        setDisableSalvar(true)
        enqueueSnackbar(`Esta data já foi preenchida em outro campo, favor alterar. Não é possível criar datas repetidas`, { variant: "error", autoHideDuration: 3000 });
        return false
      } else {
        ocorrencias[index] = valor;
        setDisableSalvar(false)
      }
    }

    setEvento((eventoAtual: any) => ({
      ...eventoAtual,
      datasOcorrencias: ocorrencias,
    }));

    setValue('datasOcorrencias', ocorrencias);
  }

  function handleTipoRecorrencia(event: any) {
    const valor = parseInt(TipoRecorrencia[event]);
    setTipoRecorrencia(valor);
    setEvento((eventoAtual: { recorrencia: any; }) => {
      let retorno = {
        ...eventoAtual,
        recorrencia: { ...eventoAtual.recorrencia, tipo: valor },
      };
      return retorno;
    });
    setConteudoRecorrencia(obterConteudoRecorrencia(valor, control, evento, setEvento, setValue));
  }

  function imprimirOcorrencias(tipoOcorrencia: TipoOcorrencia, control: Control<EventoFormModel, any>, evento: EventoFormModel, setEvento: Function, setValue: Function) {
    let input = null;
    switch (Number(tipoOcorrencia)) {
      case TipoOcorrencia.PorData:
        input = imprimirOcorrenciasPorData(control, evento, setEvento, setValue);
        break;
      case TipoOcorrencia.PorRecorrencia:
        input = imprimirOcorrenciasPorRecorrencia(control, evento, setEvento, setValue);
        break;
    }
    return input;
  }

  function imprimirOcorrenciasPorData(control: Control<EventoFormModel, any>, evento: EventoFormModel, setEvento: Function, setValue: Function) {
    return (
      <span className="d-flex flex-row col-3 mb-3">
        {datasOcorrencias.map((ocorrencia, index) => {
          return (
            <div className="d-flex" key={index}>
              <Grid item xs={4} marginRight={7}>
                <Controller
                  name="datasOcorrencias"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      type="date"
                      onChange={(event) => { handleOcorrencias(event, index, evento, setEvento, setValue) }}
                      inputProps={{ min: minDateString, max: maxDateString }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={5} marginLeft={1} marginRight={1} marginTop={2} className={`${index > 0 ? '' : 'd-none'}`}>
                <Button
                  disabled={false}
                  variant="contained"
                  color="error"
                  style={{ marginRight: "2px" }}
                  onClick={() => { RemoverData(index) }}
                  placeholder="Remover">
                  <Icon path={mdiMinus} size={1} color={Colors.white} />
                </Button>
              </Grid>

              <Grid item xs={5} marginLeft={1} marginRight={1} marginTop={2}>
                <Button
                  disabled={false}
                  variant="contained"
                  color="success"
                  style={{ marginRight: "2px" }}
                  onClick={adicionarData}
                  placeholder="Adicionar">
                  <Icon path={mdiPlus} size={1} color={Colors.white} />
                </Button>
              </Grid>
            </div>
          )
        })}
      </span>
    );
  }

  function obterConteudoRecorrencia(tipoRecorrencia: TipoRecorrencia, control: Control<EventoFormModel, any>, evento: EventoFormModel, setEvento: Function, setValue: Function) {
    let input;
    switch (tipoRecorrencia) {
      case TipoRecorrencia.Diaria:
        input = <RecorrenciaDiariaGrid control={control} evento={evento} setEvento={setEvento} setValue={setValue} />;
        break;
      case TipoRecorrencia.Semanal:
        input = <RecorrenciaSemanalGrid control={control} evento={evento} setEvento={setEvento} setValue={setValue} />;
        break;
      case TipoRecorrencia.Mensal:
        input = <RecorrenciaMensalGrid control={control} evento={evento} setEvento={setEvento} setValue={setValue} />;
        break;
      case TipoRecorrencia.Ano:
        input = <RecorrenciaAnualGrid control={control} evento={evento} setEvento={setEvento} setValue={setValue} />;
        break;
    }
    return input;
  }

  function imprimirOcorrenciasPorRecorrencia(control: Control<EventoFormModel, any>, evento: EventoFormModel, setEvento: Function, setValue: Function) {
    return (
      <>

        <Grid item xs={4} marginRight={1} marginBottom={1}>
          <FormControl sx={{ width: 200 }} size="medium">
            <Autocomplete
              id="select-label-tipo-recorrencia"
              options={tiposRecorrencia}
              getOptionLabel={(option: any) => option}
              className={styles.TextFieldCustom}
              onChange={(e, value) => handleTipoRecorrencia(value)}
              renderInput={(params) => <TextField {...params} label="Tipo de recorrência" />}
              defaultValue={tipoRecorrencia || ''}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} marginBottom={2}>
          {conteudoRecorrencia}
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid className={styles.GridItem} item xs={12} >
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Data(s) do evento</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={TipoOcorrencia.PorData}
          >
            <FormControlLabel value={TipoOcorrencia.PorData} control={<Radio />} onChange={selecionarTipoOcorrencia} label="Por dia" />
            <FormControlLabel value={TipoOcorrencia.PorRecorrencia} control={<Radio />} onChange={selecionarTipoOcorrencia} label="Recorrencia" />
          </RadioGroup>
        </FormControl>
        {imprimirOcorrencias(tipoOcorrencia, control, evento, setEvento, setValue)}
      </Grid>
    </>
  );
};