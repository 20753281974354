import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { FormularioRota } from "../paginas/rotas/cadastro/FormularioRota";
import { GridRotas } from "../paginas/rotas/listar/gridRotas";
import { VerRota } from "../paginas/rotas/visualizar/verRota";
import { AssociarPassageiros } from "../paginas/rotas/associar-passageiros/associaPassageiros";

export const RotasRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Rotas.Principal}>
            <GridRotas />
          </Route>
          <Route exact path={Rotas.Rotas.Cadastro}>
            <FormularioRota />
          </Route>
          <Route exact path={Rotas.Rotas.Ver}>
            <VerRota />
          </Route>
          <Route exact path={Rotas.Rotas.AssociarPassageiros}>
            <AssociarPassageiros />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
