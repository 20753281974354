import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop, OptGridRef } from "@optsol/react";
import { useSnackbar } from "notistack";
import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { MASCARA_NUMEROS } from "../../../constantes/mascaras/MascaraNumeros";
import { PONTO_ROTA_FORM_INITIAL_STATE } from "../../../constantes/PontoRotaFormInitialState";
import { PontoRotaFormModel } from "../../../modelos/rota/PontoRotaFormModel";
import { usePontosRotaService } from "../../../servicos/pontoRotas.service";
import { FormularioPontoRotaSchema } from "./formularioPontoRota.validation";
import styles from "./styles/formularioRota.module.scss";
import { MASCARA_DINHEIRO } from "../../../constantes/mascaras/MascaraDinheiro";
import { useErrorSnackbar } from "../../../compartilhado/hooks/errorSnackbar";
import { PontoRotaSearchResponse } from "../../../modelos/dtos/PontoRotaSearchResponse";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

interface Props {
  idOcorrencia: string;
  onClose: () => void;
  recarregar: Function;
  onSave: Function;
  pontoEditado?: PontoRotaSearchResponse | null;
}

export const FormularioPontoRota = ({
  idOcorrencia,
  onClose,
  recarregar,
  onSave,
  pontoEditado
}: Props) => {


  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [carregandoEdicao, setCarregandoEdicao] = useState<boolean>(true);
  const [enderecoValido, setEnderecoValido] = useState<boolean>(pontoEditado ? true : false);
  const { enqueueSnackbar } = useSnackbar();
  const [ponto, setPonto] = useState<PontoRotaFormModel>(pontoEditado || PONTO_ROTA_FORM_INITIAL_STATE);
  const [pontoEditadoCarregado, setPontoEditadoCarregado] = useState<boolean>(false);
  const [precoStr, setPrecoStr] = useState<string | null>(pontoEditado?.precoPassagem ? (Math.round(pontoEditado.precoPassagem * 100) / 100).toFixed(2) : '0.00');
  const { salvarPontoRota } = usePontosRotaService();

  const { verificarAutorizacao } = useEventosService();


  const formatarPreco = (valor: number) => {
    return (Math.round(valor * 100) / 100).toFixed(2);
  }

  const obterComplementoPorChave = (chave: string, enderecoDetalhes: Array<any>) => {
    const result = enderecoDetalhes.filter(detalhe => detalhe.types.includes(chave));
    if (result && result[0] && result[0]['short_name']) return result[0]['short_name'];
    else return null;
  }

  const obterDetalhesEndereco = (nome: string, enderecoDetalhes: Array<any>, lat: number, lng: number) => {
    ponto.nome = nome;
    ponto.numero = obterComplementoPorChave("street_number", enderecoDetalhes);
    ponto.bairro = obterComplementoPorChave("sublocality_level_1", enderecoDetalhes) || '';
    ponto.rua = obterComplementoPorChave("route", enderecoDetalhes);
    ponto.cidade = obterComplementoPorChave("administrative_area_level_2", enderecoDetalhes);
    ponto.estado = obterComplementoPorChave("administrative_area_level_1", enderecoDetalhes);
    ponto.lat = lat;
    ponto.lon = lng;
    return ponto;
  }

  async function handleNome(event: any) {
    const value = event.label;
    const nome = event.value.structured_formatting.main_text;
    const [endereco] = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(endereco);
    const ponto = obterDetalhesEndereco(nome, endereco.address_components, Number(lat.toFixed(6)), Number(lng.toFixed(6)));

    let valido = false
    if (ponto.rua == null)
      ponto.rua = ponto.nome
    if (ponto.cidade == null)
      ponto.cidade = 'N/A'
    if (ponto.estado == null)
      ponto.estado = 'N/A'

    if (ponto.lat == null || ponto.lon == null) {
      enqueueSnackbar("Endereço inválido. Não foi possível obter as coordenadas do endereço")
    }
    else {
      valido = true
      setPonto(ponto);
      setValue('nome', ponto.nome);
      setValue('numero', ponto.numero);
      setValue('bairro', ponto.bairro);
      setValue('rua', ponto.rua);
      setValue('cidade', ponto.cidade);
      setValue('estado', ponto.estado);
      setValue('lat', ponto.lat);
      setValue('lon', ponto.lon);
      setValue('pontoEmbarque', ponto.pontoEmbarque)
    }
    setEnderecoValido(valido)
    handlePrecoPassagem({ target: { value: precoStr || "0.00" } });
  }

  function handleComplemento(event: any) {
    const complemento = event.target.value;
    setPonto({ ...ponto, complemento });
    setValue('complemento', complemento);
  }

  function handlePontoReferencia(event: any) {
    const pontoReferencia = event.target.value;
    setPonto({ ...ponto, pontoReferencia });
    setValue('pontoReferencia', pontoReferencia);
  }

  function handlePontoEmbarque(event: any) {
    const pontoEmbarque = !event.target.checked;
    setPonto({ ...ponto, pontoEmbarque });
    setValue('pontoEmbarque', pontoEmbarque);
  }

  function handleDestaque(event: any) {
    const destaque = event.target.checked;
    setPonto({ ...ponto, destaque });
    setValue('destaque', destaque);
  }

  function handleAberto(event: any) {
    const aberto = event.target.checked;
    setPonto({ ...ponto, aberto });
    setValue('aberto', aberto);
  }

  function handleIdaEVolta(event: any) {
    const idaEVolta = event.target.checked;
    setPonto({ ...ponto, idaEVolta });
    setValue('idaEVolta', idaEVolta);
  }

  function handlePrecoPassagem(event: any) {
    let precoPassagem = mascaraDinheiroCustomizada(event.target.value);
    setPrecoStr(precoPassagem);
    setPonto({ ...ponto, precoPassagem: precoPassagem === null ? null : parseFloat(precoPassagem) });
    setValue('precoPassagem', precoPassagem === null ? null : parseFloat(precoPassagem));
  }

  const mascaraDinheiroCustomizada = (valor: string): string | null => {
    if (valor.length === 0 || isNaN(parseFloat(valor)))
      return null;

    let novoValorString = valor.replaceAll('.', '').replaceAll(',', '').replaceAll(/\D/g, '');

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(novoValorString) / 100
    )

    return result === 'NaN' ? null : result.replaceAll('.', '').replaceAll(',', '.')
  }

  function handlePassagensDisponiveis(event: any) {
    const passagensDisponiveis = event.target.value;
    setPonto(pontoAtual => ({ ...pontoAtual, passagensDisponiveis: isNaN(Number(passagensDisponiveis)) || passagensDisponiveis.length === 0 ? null : passagensDisponiveis }));
    setValue('passagensDisponiveis', isNaN(Number(passagensDisponiveis)) || passagensDisponiveis.length === 0 ? null : passagensDisponiveis);
  }

  function handleHorarioIdaPartida(event: any) {
    const horarioIdaPartida = new Date(event);
    setPonto({ ...ponto, horarioIdaPartida });
    setValue('horarioIdaPartida', horarioIdaPartida);
  }

  function handleHorarioIdaChegada(event: any) {
    const horarioIdaChegada = new Date(event);
    setPonto({ ...ponto, horarioIdaChegada });
    setValue('horarioIdaChegada', horarioIdaChegada);
  }

  function handleHorarioVoltaPartida(event: any) {
    const horarioVoltaPartida = new Date(event);
    setPonto({ ...ponto, horarioVoltaPartida });
    setValue('horarioVoltaPartida', horarioVoltaPartida);
  }

  function handleHorarioVoltaChegada(event: any) {
    const horarioVoltaChegada = new Date(event);
    setPonto({ ...ponto, horarioVoltaChegada });
    setValue('horarioVoltaChegada', horarioVoltaChegada);
  }

  useEffect(() => {
    setPonto({
      ...ponto,
      idOcorrencia
    });
    setValue('idOcorrencia', idOcorrencia);
  }, [idOcorrencia]);

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor]);
    })()
  }, []);

  useEffect(() => {
    setPonto(pontoAtual => ({
      ...pontoAtual,
      idOcorrencia
    }));
    setValue('idOcorrencia', idOcorrencia);
  }, [idOcorrencia]);

  async function salvar() {
    setLoading(true);
    try {
      let pontoRotaCadastrado = { ...ponto };

      if (!pontoRotaCadastrado.pontoEmbarque) {
        pontoRotaCadastrado = {
          ...pontoRotaCadastrado,
          pontoEmbarque: false,
          idaEVolta: false,
          destaque: false,
          precoPassagem: null,
          passagensDisponiveis: null,
          passagensVendidas: null,
          horarioIdaPartida: null,
          horarioIdaChegada: null,
          horarioVoltaPartida: null,
          horarioVoltaChegada: null,
        }
      }
      else if (!pontoRotaCadastrado.idaEVolta) {
        pontoRotaCadastrado = {
          ...pontoRotaCadastrado,
          idaEVolta: false,
          horarioVoltaPartida: null,
          horarioVoltaChegada: null,
        }
      }
      const pontoRota = await salvarPontoRota(pontoRotaCadastrado);
      onSave(pontoRota);

      setLoading(false);
      enqueueSnackbar(`Registro criado com sucesso!`, { variant: "success" });
      recarregar();
      onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const horariosValidos = () => {
    if (!!ponto.pontoEmbarque && !!ponto.idaEVolta)
      return !!ponto.horarioIdaChegada && !!ponto.horarioIdaPartida && !!ponto.horarioVoltaChegada && !!ponto.horarioVoltaPartida;
    else if (!!ponto.pontoEmbarque && !ponto.idaEVolta)
      return !!ponto.horarioIdaChegada && !!ponto.horarioIdaPartida;
    return true
  }

  const rotaForm = useForm<PontoRotaFormModel>({
    resolver: yupResolver(FormularioPontoRotaSchema),
  });

  useEffect(() => {
    if (pontoEditado && !pontoEditadoCarregado) {
      if (pontoEditado.idaEVolta == null)
        pontoEditado.idaEVolta = true;

      if (pontoEditado.destaque == null)
        pontoEditado.destaque = false;

      if (pontoEditado.aberto == null)
        pontoEditado.aberto = true;

      setPonto(pontoEditado);
      setValue('nome', pontoEditado.nome);
      setValue('bairro', pontoEditado.bairro);
      setValue('rua', pontoEditado.rua);
      setValue('cidade', pontoEditado.cidade);
      setValue('estado', pontoEditado.estado);
      setValue('lat', pontoEditado.lat);
      setValue('lon', pontoEditado.lon);
      setEnderecoValido(true)
      if (pontoEditado.numero)
        setValue('numero', pontoEditado.numero);
      setValue('precoPassagem', pontoEditado.precoPassagem || 0);
      setValue('idaEVolta', pontoEditado.idaEVolta);
      //setValue('destaque', pontoEditado.destaque);
      setValue('destaque', false);
      setValue('aberto', pontoEditado.aberto);
      setValue('pontoEmbarque', pontoEditado.pontoEmbarque);
      setPontoEditadoCarregado(true)
    }
  }, [pontoEditado, ponto, pontoEditadoCarregado]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = rotaForm;

  return (
    <form>
      <OptBackdrop open={loading} />
      <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>

        <Grid className={styles.GridItem} item xs={12}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControl sx={{ width: 1, zIndex: 2 }} size="medium">
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  apiOptions={{ language: 'pt-br', region: 'br' }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["br"],
                    },
                  }}
                  key={ponto.nome}
                  selectProps={{
                    defaultInputValue: ponto.nome,
                    onChange: handleNome,
                    placeholder: 'Local *',
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        height: 43,
                      }) as unknown as any,
                    },
                  }}
                />
              </FormControl>
            )}
          />

          <ErrorMessage error={errors.nome} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="complemento"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Complemento"
                variant="outlined"
                onChange={handleComplemento}
                value={ponto.complemento}
              />
            )}
          />
          <ErrorMessage error={errors.complemento} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="pontoReferencia"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Ponto de Referência"
                variant="outlined"
                onChange={handlePontoReferencia}
                value={ponto.pontoReferencia}
              />
            )}
          />
          <ErrorMessage error={errors.pontoReferencia} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="idaEVolta"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControlLabel
                control={<Checkbox
                  checked={!ponto.pontoEmbarque ? false : !!ponto.idaEVolta}
                  disabled={!ponto.pontoEmbarque}
                  onChange={handleIdaEVolta} />}
                label="Ida e volta" />
            )}
          />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="aberto"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControlLabel
                control={<Checkbox
                  checked={!!ponto.aberto}
                  onChange={handleAberto} />}
                label="Aberto" />
            )}
          />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="pontoEmbarque"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControlLabel
                control={<Checkbox
                  checked={!ponto.pontoEmbarque}
                  onChange={handlePontoEmbarque} />}
                label="Ponto de Parada" />
            )}
          />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="destaque"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControlLabel
                control={<Checkbox
                  //checked={!ponto.pontoEmbarque ? false : !!ponto.destaque}
                  //disabled={!ponto.pontoEmbarque}
                  checked={false}
                  disabled={true}
                  onChange={handleDestaque} />}
                label="Destaque" />
            )}
          />
        </Grid>
        {!!ponto.pontoEmbarque && <>
          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="precoPassagem"
              control={control}
              render={({ field: { onChange } }) => (
                <TextField
                  className={styles.TextFieldCustom}
                  label="Preço da passagem *"
                  variant="outlined"
                  onChange={handlePrecoPassagem}
                  value={precoStr}
                />
              )}
            />
            <ErrorMessage error={errors.precoPassagem} />
          </Grid>

          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="passagensDisponiveis"
              control={control}
              render={({ field: { onChange } }) => (
                <TextField
                  className={styles.TextFieldCustom}
                  label="Passagens disponíveis"
                  variant="outlined"
                  onChange={handlePassagensDisponiveis}
                  value={ponto.passagensDisponiveis}
                />
              )}
            />
            <ErrorMessage error={errors.passagensDisponiveis} />
          </Grid>

          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="horarioIdaPartida"
              control={control}
              render={({ field: { onChange } }) => (
                <DateTimePicker
                  label="Horário de Partida da Ida *"
                  value={ponto.horarioIdaPartida}
                  onChange={handleHorarioIdaPartida}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />

            <ErrorMessage error={errors.horarioIdaPartida} />
          </Grid>

          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="horarioIdaChegada"
              control={control}
              render={({ field: { onChange } }) => (
                <DateTimePicker
                  label="Horário de Chegada da Ida *"
                  value={ponto.horarioIdaChegada}
                  onChange={handleHorarioIdaChegada}
                  minDate={ponto.horarioIdaPartida}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />

            <ErrorMessage error={errors.horarioIdaChegada} />
          </Grid>

          {!!ponto.idaEVolta && <>
            <Grid className={styles.GridItem} item xs={6}>
              <Controller
                name="horarioVoltaPartida"
                control={control}
                render={({ field: { onChange } }) => (
                  <DateTimePicker
                    label="Horário de Partida da Volta *"
                    value={ponto.horarioVoltaPartida}
                    onChange={handleHorarioVoltaPartida}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                )}
              />

              <ErrorMessage error={errors.horarioVoltaPartida} />
            </Grid>

            <Grid className={styles.GridItem} item xs={6}>
              <Controller
                name="horarioVoltaChegada"
                control={control}
                render={({ field: { onChange } }) => (
                  <DateTimePicker
                    label="Horário de Chegada da Volta *"
                    value={ponto.horarioVoltaChegada}
                    onChange={handleHorarioVoltaChegada}
                    minDate={ponto.horarioVoltaPartida}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                )}
              />

              <ErrorMessage error={errors.horarioVoltaChegada} />
            </Grid>
          </>}
        </>}
      </Grid >


      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig} >
          Cancelar
        </Button>
        <Button
          size="large"
          disabled={!enderecoValido || !horariosValidos()}
          onClick={handleSubmit(salvar)}
          sx={sxButtonSaveConfig}
        >
          Salvar
        </Button>
      </FooterToolbar>
    </form >
  );
};
