import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { mdiTrashCanOutline, mdiPlus } from "@mdi/js";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { Periodo } from "../../../componentes/periodo";
import { StatusEvento } from "../../../enums/StatusEvento";
import { TipoEvento } from "../../../enums/TipoEvento";
import { TipoOcorrencia } from "../../../enums/TipoOcorrencia";
import { CategoriaFormModel } from "../../../modelos/categoria/categoriaFormModel";
import { DataRecorrencia } from "../../../modelos/dtos/DataRecorrencia";
import { Endereco } from "../../../modelos/dtos/endereco";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";
import { Rotas } from "../../../rotas";
import { useCategoriasService } from "../../../servicos/categorias.service";
import { useEventosService } from "../../../servicos/eventos.service";
import { usePessoasService } from "../../../servicos/pessoas.service";
import { CadastroEventoSchema } from "./CadastroEvento.validation";
import styles from "./styles/cadastroEvento.module.scss";
import { UsuarioLoginResponse } from "../../../modelos/dtos/UsuarioLoginResponse";
import { useUsuarioLocalStorageService } from "../../../servicos/usuario.localstorage.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import AdicionarImagem from "../../../componentes/adicionarImagem";
import { addHours } from "date-fns";
import Icon from "@mdi/react";

export const CadastroEvento = () => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const EVENTO_FORM_INITIAL_STATE_FALLBACK: EventoFormModel = {
    nome: "",
    descricao: "",
    url: "",
    imagem: "",
    imagemMobile: "",
    tipo: TipoEvento.Publico,
    status: StatusEvento.Aberto,
    destino: new Endereco(),
    recorrencia: new DataRecorrencia(),
  };

  const { idEvento } = useParams<{ idEvento?: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const edicao = Boolean(idEvento);
  const history = useHistory();
  const { carregarConsultores } = usePessoasService();
  const { verificarAutorizacao, obterListaClientes } = useEventosService();
  const { carregarCategorias } = useCategoriasService();
  const { obterUsuario } = useUsuarioLocalStorageService();
  const { salvarEvento, salvarRecorrenciaEvento, editarEvento, carregarEventoPorId, obterUsuarioLogado } = useEventosService();
  const usuarioLogado = obterUsuario();

  const [loading, setLoading] = useState<boolean>(true);
  const [disableSalvar, setDisableSalvar] = useState<boolean>(false);
  const [disableAddResposta, setDisableAddResposta] = useState<boolean>(true);
  const [evento, setEvento] = useState<EventoFormModel>(EVENTO_FORM_INITIAL_STATE_FALLBACK);
  const [categorias, setCategorias] = useState<Array<any>>(new Array<any>());
  const [pessoas, setPessoas] = useState<Array<any>>(new Array<any>());
  const [listaClientes, setListaClientes] = useState<Array<any>>(new Array<any>());
  const [local, setLocal] = useState<string>();
  const [tipos, setTipos] = useState(new Array<any>());
  const [respostasComplemento, setRespostasComplemento] = useState<Array<string>>([]);
  const [listaStatus, setListaStatus] = useState(new Array<any>());
  const [user] = useState<UsuarioLoginResponse | null>(usuarioLogado);
  const [pessoaLogada, setPessoaLogada] = useState<PessoaFormModel>();
  const [imagem, setImagem] = useState("");
  const [imagem2, setImagem2] = useState("");
  const [imagem3, setImagem3] = useState("");
  const [imagemMobile, setImagemMobile] = useState("");
  const [imagemMobile2, setImagemMobile2] = useState("");
  const [imagemMobile3, setImagemMobile3] = useState("");
  const [desabilitarCampoOwner, setDesabilitaCampoOwner] = useState<boolean>(false);
  const [capturaAutomatica, setCapturaAutomatica] = useState<boolean>(false);
  const [fechamentoAutomatico, setFechamentoAutomatico] = useState<boolean>(false);
  const [rsvp, setRsvp] = useState<boolean>(false);

  function handleNome(event: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      nome: event?.target.value,
    }));
    setValue("nome", event.target.value);
  }

  function handleDescricao(event: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      descricao: event?.target.value,
    }));
    setValue("descricao", event.target.value);
  }

  function handleTipo(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      tipo: value,
    }));
    setValue("tipo", value);
  }

  function handleStatus(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      status: value,
    }));
    setValue("status", value);
  }

  async function handleLocal(event: any) {
    const value = event.label;
    const nome = event.value.structured_formatting.main_text;
    setLocal(nome);

    const [endereco] = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(endereco);

    const destino = montarDestino(nome, endereco.address_components, lat, lng);

    setEvento((eventoAtual) => ({
      ...eventoAtual,
      destino,
      nomeDestino: nome,
    }));

    setValue("destino", destino);
    setValue("nomeDestino", nome);
  }

  function handleComplemento(event: any) {
    let destino = evento.destino;
    destino!.complemento = event.target.value;

    setEvento((eventoAtual) => ({
      ...eventoAtual,
      destino,
    }));

    setValue("destino", destino);
  }

  function handlePontoReferencia(event: any) {
    let destino = evento.destino;
    destino!.pontoReferencia = event.target.value;

    setEvento((eventoAtual) => ({
      ...eventoAtual,
      destino,
    }));

    setValue("destino", destino);
  }

  function handleUrl(event: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      url: event?.target.value,
    }));
    setValue("url", event.target.value);
  }

  const obterComplementoPorChave = (
    chave: string,
    enderecoDetalhes: Array<any>
  ) => {
    const result = enderecoDetalhes.filter((detalhe) =>
      detalhe.types.includes(chave)
    );
    if (result && result[0] && result[0]["short_name"])
      return result[0]["short_name"];
    else return null;
  };

  const montarDestino = (
    nome: string,
    enderecoDetalhes: Array<any>,
    lat: number,
    lng: number
  ) => {
    const destino = new Endereco();
    destino.nome = nome;
    destino.numero = obterComplementoPorChave(
      "street_number",
      enderecoDetalhes
    );
    destino.bairro = obterComplementoPorChave(
      "sublocality_level_1",
      enderecoDetalhes
    );

    destino.rua = obterComplementoPorChave("route", enderecoDetalhes);
    if (!destino.rua)
      destino.rua = nome

    destino.cidade = obterComplementoPorChave(
      "administrative_area_level_2",
      enderecoDetalhes
    );
    destino.estado = obterComplementoPorChave(
      "administrative_area_level_1",
      enderecoDetalhes
    );
    if (destino.cidade == null) {
      enqueueSnackbar("Por favor, informe um endereço que possua uma cidade")
      return;
    }
    else if (destino.estado == null) {
      enqueueSnackbar("Por favor, informe um endereço que possua um estado")
      return;
    }

    destino.lat = Number(lat.toFixed(6));
    destino.lon = Number(lng.toFixed(6));
    destino.horarioIdaPartida = null;
    destino.horarioIdaChegada = null;
    destino.horarioVoltaPartida = null;
    destino.horarioVoltaChegada = null;
    destino.pontoEmbarque = false;
    destino.passagensDisponiveis = null;
    destino.passagensVendidas = null;
    destino.precoPassagem = null;
    return destino;
  };

  const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function handleImage(s: any) {
    try {
      setEvento((eventoAtual) => ({
        ...eventoAtual,
        blobImagem: s,
        AlterarImagem: true,
      }));
      setValue("blobImagem", s);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImage2(s: any) {
    try {
      setEvento((eventoAtual) => ({
        ...eventoAtual,
        blobImagem2: s,
        AlterarImagem2: true,
      }));
      setValue("blobImagem2", s);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImage3(s: any) {
    try {
      setEvento((eventoAtual) => ({
        ...eventoAtual,
        blobImagem3: s,
        AlterarImagem3: true,
      }));
      setValue("blobImagem3", s);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImagemMobile(s: any) {
    try {
      setEvento((eventoAtual) => ({
        ...eventoAtual,
        blobImagemMobile: s,
        AlterarImagemMobile: true
      }));
      setValue("blobImagemMobile", s);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImagemMobile2(s: any) {
    try {
      setEvento((eventoAtual) => ({
        ...eventoAtual,
        blobImagemMobile2: s,
        AlterarImagemMobile2: true
      }));
      setValue("blobImagemMobile2", s);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImagemMobile3(s: any) {
    try {
      setEvento((eventoAtual) => ({
        ...eventoAtual,
        blobImagemMobile3: s,
        AlterarImagemMobile3: true
      }));
      setValue("blobImagemMobile3", s);
    } catch (error) {
      console.log(error);
    }
  }

  function handleCategoria(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      idCategoria: value,
    }));
    setValue("idCategoria", value);
  }

  function handleOwner(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      idOwner: value,
    }));
    setValue("idOwner", value);
  }

  function handleClienteDono(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      idClienteDono: value,
    }));
    setValue("idClienteDono", value);
  }

  function handleCapturaAutomatica(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      capturaAutomatica: value,
    }));
    setValue("capturaAutomatica", value);
  }

  function handleFechamentoAutomatico(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      fechamentoAutomatico: value,
    }));
    setValue("fechamentoAutomatico", value);
  }

  function handleRsvp(value: any) {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      rsvp: value,
    }));
    setValue("rsvp", value);
  }

  function handlePerguntaComplementar(event: any) {
    if (event.target.value) {
      setDisableAddResposta(false)
      let obj = {
        id: evento.complementos && evento.complementos.length > 0 ? (edicao ? evento.complementos[0].id : null) : null,
        pergunta: event.target.value,
        descricao: null,
        obrigatorio: true,
        aberta: false,
        aceitaOutra: false,
        respostas: respostasComplemento
      }

      setEvento((eventoAtual) => ({
        ...eventoAtual,
        complementos: [obj],
      }));
      setValue("complementos", [obj]);
    } else {
      setEvento((eventoAtual) => ({
        ...eventoAtual,
        complementos: [],
      }));
      setValue("complementos", []);
      setDisableAddResposta(true)
    }
  }

  function handleResposta(e: any, index: number) {
    let arr = respostasComplemento
    arr[index] = e.target.value

    setEvento((eventoAtual) => ({
      ...eventoAtual,
      complementos: eventoAtual.complementos && [{ ...eventoAtual.complementos[0], respostas: arr }],
    }));
    setRespostasComplemento([...arr])
  }

  function handleAddResposta() {
    setEvento((eventoAtual) => ({
      ...eventoAtual,
      complementos: eventoAtual.complementos && [{ ...eventoAtual.complementos[0], respostas: [...respostasComplemento, ""] }],
    }));
    setRespostasComplemento([...respostasComplemento, ""])
  }

  function handleRemoverResposta(index: number) {
    let newRespostas = respostasComplemento.filter((x, i) => i !== index)

    setEvento((eventoAtual) => ({
      ...eventoAtual,
      complementos: eventoAtual.complementos && [{ ...eventoAtual.complementos[0], respostas: [...newRespostas] }],
    }));
    setRespostasComplemento([...newRespostas])
  }

  function carregarTipos() {
    const tps = Object.keys(TipoEvento).filter((item) => {
      return isNaN(Number(item));
    });
    setTipos(tps);
  }

  function carregarListaStatus() {
    const itens = Object.keys(StatusEvento).filter((item) => {
      return isNaN(Number(item));
    });
    setListaStatus(itens);
  }

  async function obterPessoa() {
    const pessoa = (await obterUsuarioLogado()) as unknown as PessoaFormModel;
    setPessoaLogada(pessoa);

    if (pessoa.perfil) {
      if (pessoa.perfil == PerfilUsuario.Consultor) {
        setEvento((eventoAtual) => ({
          ...evento,
          idOwner: pessoa.id,
        }));
        setValue("idOwner", pessoa.id);
      } else {
        const desabilitarCampoOwner =
          [PerfilUsuario.Administrador, PerfilUsuario.Gerente].includes(
            pessoa.perfil ?? PerfilUsuario.Sistema
          ) == false;
        setDesabilitaCampoOwner(desabilitarCampoOwner);
      }
    }
  }

  async function carregarComboCategorias() {
    const response =
      (await carregarCategorias()) as unknown as Array<CategoriaFormModel>;
    if (response) {
      setCategorias(response);
    }
  }

  async function carregarComboOwners() {
    const response =
      (await carregarConsultores()) as unknown as Array<PessoaFormModel>;
    if (response) {
      setPessoas(response);
    }
  }

  async function carregarComboClientes() {
    const response =
      (await obterListaClientes()) as unknown as Array<PessoaFormModel>;
    if (response) {
      setListaClientes(response);
    }
  }

  async function carregarEvento(idEvento: string) {
    const response = (await carregarEventoPorId(idEvento)) as unknown as EventoFormModel;
    if (response) {
      const status = Object.keys(StatusEvento)[Object.values(StatusEvento).indexOf(response.status as any)];
      const tipoEvento = Object.keys(TipoEvento)[Object.values(TipoEvento).indexOf(response.tipo as any)];

      setValue("nome", response.nome);
      setValue("url", response.url);
      setValue("descricao", response.descricao);
      setValue("blobImagem", response.blobImagem);
      setValue("blobImagem2", response.blobImagem2);
      setValue("blobImagem3", response.blobImagem3);
      setValue("tipo", response.tipo);
      setValue("status", response.status);
      setValue("idCategoria", response.idCategoria);
      setValue("idOwner", response.idOwner);
      setValue("idClienteDono", response.idClienteDono);
      setValue("destino", response.destino);
      setValue("categoria", response.categoria);
      setValue("owner", response.owner);
      setValue("capturaAutomatica", response.capturaAutomatica);
      setValue("fechamentoAutomatico", response.fechamentoAutomatico);
      setValue("rsvp", response.rsvp);
      setCapturaAutomatica(response.capturaAutomatica ?? false)
      setFechamentoAutomatico(response.fechamentoAutomatico ?? false)
      setRsvp(response.rsvp ?? false)
      console.log(response)
      setEvento(response);

      if (response.imagem) {
        setImagem(`${response.imagem}?t=${new Date().getTime()}`);
      }

      if (response.imagem2) {
        setImagem2(`${response.imagem2}?t=${new Date().getTime()}`);
      }

      if (response.imagem3) {
        setImagem3(`${response.imagem3}?t=${new Date().getTime()}`);
      }

      if (response.imagemMobile) {
        setImagemMobile(`${response.imagemMobile}?t=${new Date().getTime()}`);
      }

      if (response.imagemMobile2) {
        setImagemMobile2(`${response.imagemMobile2}?t=${new Date().getTime()}`);
      }

      if (response.imagemMobile3) {
        setImagemMobile3(`${response.imagemMobile3}?t=${new Date().getTime()}`);
      }

      if (response.destino && response.destino.nome) {
        setLocal(response.destino.nome);
        setValue("nomeDestino", response.destino.nome);
      }

      if (response.complementos && response.complementos.length > 0) {
        setDisableAddResposta(false)
        setRespostasComplemento([...response.complementos[0].respostas])
      }
    }
  }

  function transformarRecorrenciaParaSalvar(evento: EventoFormModel) {
    for (const prop in evento.recorrencia) {
      const value = evento.recorrencia[prop as keyof DataRecorrencia];
      if (!value) delete evento.recorrencia[prop as keyof DataRecorrencia];
    }
    return evento;
  }

  async function salvar() {
    setLoading(true);
    try {
      const estaEditando = !!evento.id;
      const operacao = estaEditando ? "editado" : "criado";

      if (evento.complementos && evento.complementos.length > 0) {
        if (evento.complementos[0].respostas.length === 0) {
          if (respostasComplemento.length === 0) {
            enqueueSnackbar(`Você adicionou uma pergunta complementar sem respostas. Favor adicionar ao menos uma resposta.`, { variant: "error" });
          }
          return
        } else {
          if (evento.complementos[0].respostas.some(x => x === "")) {
            return
          }
        }
      }

      if (edicao) {
        await editarEvento(evento.id!, evento);
      } else {
        if (evento.tipoOcorrencia == TipoOcorrencia.PorRecorrencia) {
          const eventoToSave: EventoFormModel = transformarRecorrenciaParaSalvar(evento);
          await salvarRecorrenciaEvento(eventoToSave);
        } else {
          let eventoTipado = evento as EventoFormModel;
          let novoEvento: EventoFormModel = {
            ...eventoTipado,
            datasOcorrencias: (eventoTipado!.datasOcorrencias ?? []).map(
              (x) => {
                addHours(x, 3);
                return x;
              }
            ),
          };

          await salvarEvento(novoEvento);
        }
      }
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, { variant: "success" });
      if (idEvento) {
        carregarEvento(idEvento);
      }
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onClose = () => {
    history.push(`${Rotas.Eventos.Principal}${edicao ? `/ver/${idEvento}` : ""}`);
  };

  const eventoForm = useForm<EventoFormModel>({ resolver: yupResolver(CadastroEventoSchema) });
  const { control, handleSubmit, setValue, formState: { errors } } = eventoForm;

  useEffect(() => {
    (async () => {
      await verificarAutorizacao([
        PerfilUsuario.Administrador,
        PerfilUsuario.Operacao,
        PerfilUsuario.Gerente,
        PerfilUsuario.Consultor,
      ]);

      await carregarTipos();
      await carregarListaStatus();
      await carregarComboCategorias();
      await carregarComboOwners();
      await carregarComboClientes();

      if (idEvento) {
        await carregarEvento(idEvento);
        setLoading(false)
      } else {
        setLoading(false)
      }
    })();
  }, []);

  useEffect(() => {
    obterPessoa();
  }, [user]);

  if (loading) {
    return (
      <OptBackdrop open={loading} />
    )
  }

  return (
    <form>
      <div className={styles.CabecalhoContainer}>
        <OptActionButton
          onClick={onClose}
          startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
        >
          <span className={styles.SpanText}>
            {edicao ? "Editar evento" : "Cadastro de evento"}
          </span>
        </OptActionButton>
      </div>
      <Divider />
      <Grid
        className={styles.GridContent}
        container
        rowSpacing={1}
        columns={12}
        px={1.5}
      >
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Nome"
                required={true}
                variant="outlined"
                onChange={handleNome}
                value={evento.nome}
              />
            )}
          />
          <ErrorMessage error={errors.nome} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="descricao"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Descrição"
                variant="outlined"
                onChange={handleDescricao}
                value={evento.descricao}
              />
            )}
          />
          <ErrorMessage error={errors.descricao} />
        </Grid>
        <Grid className={`${styles.GridItem} d-flex`} gap={'15px'} item xs={12}>
          <AdicionarImagem
            label="Imagem Desktop"
            handleUpdateState={handleImage}
            image={imagem || ""}
            isDesktop
          />
          <AdicionarImagem
            label="Imagem Desktop 2"
            handleUpdateState={handleImage2}
            image={imagem2 || ""}
            isDesktop
          />
          <AdicionarImagem
            label="Imagem Desktop 3"
            handleUpdateState={handleImage3}
            image={imagem3 || ""}
            isDesktop
          />
        </Grid>
        <Grid className={`${styles.GridItem} d-flex mb-2`} gap={'15px'} item xs={12}>
          <AdicionarImagem
            label="Imagem Mobile"
            handleUpdateState={handleImagemMobile}
            image={imagemMobile || ""}
          />
          <AdicionarImagem
            label="Imagem Mobile 2"
            handleUpdateState={handleImagemMobile2}
            image={imagemMobile2 || ""}
          />
          <AdicionarImagem
            label="Imagem Mobile 3"
            handleUpdateState={handleImagemMobile3}
            image={imagemMobile3 || ""}
          />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="tipo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  id='select-label-tipo'
                  options={tipos}
                  getOptionLabel={(option: any) => option}
                  onChange={(e, value) => handleTipo(TipoEvento[value])}
                  defaultValue={TipoEvento[evento.tipo]}
                  renderInput={(params) => <TextField {...params} label="Tipo" required />}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.tipo} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="status"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  id='select-label-status'
                  options={listaStatus}
                  getOptionLabel={(option: any) => option}
                  onChange={(e, value) => handleStatus(StatusEvento[value])}
                  defaultValue={StatusEvento[evento.status]}
                  renderInput={(params) => <TextField {...params} label="Status" required />}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.status} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="idCategoria"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  id='select-label-categorias'
                  options={categorias}
                  getOptionLabel={(option: any) => option.nome}
                  onChange={(e, value) => handleCategoria(value.id)}
                  defaultValue={evento.categoria}
                  renderInput={(params) => <TextField {...params} label="Categoria" />}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.idCategoria} />
        </Grid>
        <Grid className={`${styles.GridItem} ${pessoaLogada && pessoaLogada.perfil === PerfilUsuario.Consultor ? "d-none" : ""}`} item xs={6}>
          <Controller
            name="idOwner"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  id='select-label-owner'
                  options={pessoas}
                  getOptionLabel={(option: any) => option.nome}
                  onChange={(e, value) => handleOwner(value.id)}
                  defaultValue={evento.owner}
                  renderInput={(params) => <TextField {...params} label="Owner" />}
                  disabled={desabilitarCampoOwner}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.idOwner} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="destino.nome"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControl
                sx={{ width: 1, zIndex: 2 }}
                required={true}
                size="medium"
              >
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  apiOptions={{ language: "pt-br", region: "br" }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["br"],
                    },
                  }}
                  key={evento.destino?.nome}
                  selectProps={{
                    defaultInputValue: evento.destino?.nome,
                    onChange: handleLocal,
                    placeholder: "Destino *",
                    required: true,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        height: 43,
                      }) as unknown as any,
                    },
                  }}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.nomeDestino} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="destino.complemento"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Complemento do destino"
                variant="outlined"
                onChange={handleComplemento}
                value={evento.destino?.complemento}
              />
            )}
          />
          <ErrorMessage error={errors.destino?.complemento} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="destino.pontoReferencia"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Ponto de Referência"
                variant="outlined"
                onChange={handlePontoReferencia}
                value={evento.destino?.pontoReferencia}
              />
            )}
          />
          <ErrorMessage error={errors.destino?.pontoReferencia} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="url"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Url do Evento"
                required={true}
                variant="outlined"
                onChange={handleUrl}
                value={evento.url}
              />
            )}
          />
          <ErrorMessage error={errors.url} />
        </Grid>

        <Grid className={`${styles.GridItem} mt-2`} xs={6}>
          <FormControlLabel
            sx={{ marginBottom: "6px" }}
            label="Captura automática de transações"
            control={
              <Switch
                color="success"
                checked={capturaAutomatica}
                id={"switch-capturaAutomatica"}
                onChange={(e) => {
                  handleCapturaAutomatica(!capturaAutomatica)
                  setCapturaAutomatica(!capturaAutomatica);
                }}
              />
            }
          />
        </Grid>
        <Grid className={`${styles.GridItem} mt-2`} xs={6}>
          <FormControlLabel
            sx={{ marginBottom: "6px" }}
            label="Fechamento automático do evento"
            control={
              <Switch
                color="success"
                checked={fechamentoAutomatico}
                id={"switch-fechamentoAutomatico"}
                onChange={(e) => {
                  handleFechamentoAutomatico(!fechamentoAutomatico)
                  setFechamentoAutomatico(!fechamentoAutomatico);
                }}
              />
            }
          />
        </Grid>
        <Grid className={`${styles.GridItem} mt-2`} xs={6}>
          <FormControlLabel
            sx={{ marginBottom: "6px" }}
            label="Solicitar confirmação de presença"
            control={
              <Switch
                color="success"
                checked={rsvp}
                id={"switch-rsvp"}
                onChange={(e) => {
                  handleRsvp(!rsvp)
                  setRsvp(!rsvp);
                }}
              />
            }
          />
        </Grid>

        <Grid className={`${styles.GridItem} mt-2`} xs={6}>
          <Controller
            name="idClienteDono"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  id='select-label-dono'
                  options={listaClientes}
                  getOptionLabel={(option: any) => option.nome}
                  onChange={(e, value) => handleClienteDono(value.id)}
                  defaultValue={listaClientes.filter((x) => x.id === evento.idClienteDono)[0]}
                  renderInput={(params) => <TextField {...params} label="Cliente/Dono" />}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.idClienteDono} />
        </Grid>

        <Grid className={styles.GridItem} item xs={12}>
          <h6 className="mt-4">Informações complementares</h6>
          <Divider className="mb-4" />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <TextField
            className={styles.TextFieldCustom}
            label="Pergunta complementar"
            variant="outlined"
            onChange={handlePerguntaComplementar}
            value={evento.complementos && evento.complementos?.length > 0 ? evento.complementos[0].pergunta : ""}
          />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Button
            variant="contained"
            color="success"
            onClick={handleAddResposta}
            disabled={disableAddResposta}
          >
            <Icon path={mdiPlus} size={1} color={Colors.white} /> Adicionar resposta
          </Button>
        </Grid>

        {
          respostasComplemento.map((resposta, index) => (
            <Grid key={`resposta_complemento_${index}`} className={`${styles.GridItem} mt-3`} item xs={12} md={4}>
              <div className="d-flex" style={{ gap: '10px' }}>
                <TextField
                  className={`${styles.TextFieldCustom}`}
                  label={`Resposta ${index + 1}`}
                  required={true}
                  variant="outlined"
                  onChange={(e) => handleResposta(e, index)}
                  value={resposta}
                />

                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoverResposta(index)}
                >
                  <Icon path={mdiTrashCanOutline} size={1} color={Colors.white} />
                </Button>
              </div>
              {
                resposta.length === 0 &&
                <ErrorMessage message={'Item obrigatório'} />
              }
            </Grid>
          ))
        }

        <Grid className={styles.GridItem} item xs={12}>
          <Divider className="mt-3 mb-3" />
        </Grid>

        {idEvento == null ? (
          <Periodo
            control={control}
            evento={evento}
            setEvento={setEvento}
            setValue={setValue}
            setDisableSalvar={setDisableSalvar}
          />
        ) : (
          ""
        )}
      </Grid>

      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
          Cancelar
        </Button>
        <Button
          size="large"
          onClick={handleSubmit(salvar)}
          sx={sxButtonSaveConfig}
          disabled={disableSalvar}
        >
          Salvar
        </Button>
      </FooterToolbar>
    </form>
  );
};
