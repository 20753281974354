import {
    mdiArrowLeft,
    mdiTrashCanOutline,
    mdiPencilOutline,
    mdiDeleteOutline,
    mdiContentCopy,
    mdiPlus
} from "@mdi/js";
import Icon from "@mdi/react";
import { styled, Button, Divider, Grid, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, tableCellClasses, TextField } from "@mui/material";
import { OptActionButton, OptConfirmationDialog, OptDialog, OptDialogActions, OptGridRef, OptGridRequest } from "@optsol/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Colors } from "../../../compartilhado/colors";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { EventoSearchResponse } from "../../../modelos/dtos/EventoSearchResponse";
import { OcorrenciaSearchResponse } from "../../../modelos/dtos/OcorrenciaSearchResponse";
import { OcorrenciaModel } from "../../../modelos/ocorrencia/ocorrenciaModel";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { GridOcorrenciaView } from "../../ocorrencia/lista/GridOcorrenciaView";
import styles from "./styles/verEvento.module.scss";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { CopiarOcorrenciaFormModel } from "../../../modelos/ocorrencia/copiarOcorrenciaFormModel";
import { COPIAR_OCORRENCIA_FORM_INITIAL_STATE } from "../../../constantes/CopiarOcorrenciaFormInitialState";
import { yupResolver } from "@hookform/resolvers/yup";
import { CopiarOcorrenciaSchema } from "../Cadastro/CopiarOcorrencia.validation";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { addDays, differenceInDays, format } from "date-fns";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { converterGmt, formatDateDB } from "../../../utils/claimUtils";
import { OcorrenciaFormModel } from "../../../modelos/ocorrencia/ocorrenciaFormModel";

interface DadosTabelaCopiarOcorrencia {
    pontoEmbarque: string,
    horarioIdaAntigo: string,
    horarioIdaNovo: string,
    horarioVoltaAntigo: string,
    horarioVoltaNovo: string,
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        border: "1px solid #ccc",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const VerEvento = () => {
    const { idEvento } = useParams<{ idEvento: string }>();
    const gridRef = useRef<OptGridRef>(null);
    const [evento, setEvento] = useState<EventoSearchResponse>();
    const { removerEvento, carregarEventoPorId } = useEventosService();
    const { buscarGridOcorrenciaPaginated, copiarOcorrencia, carregarOcorrenciaPorId } = useOcorrenciasService();
    const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
    const [confirmandoCopia, setConfirmandoCopia] = useState<boolean>(false);
    const [ocorrenciaCopia, setOcorrenciaCopia] = useState<CopiarOcorrenciaFormModel>(COPIAR_OCORRENCIA_FORM_INITIAL_STATE);
    const [ocorrenciaSelecionada, setOcorrenciaSelecionada] = useState<OcorrenciaSearchResponse>();
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [dadosTabelaCopiarOcorrencia, setDadosTabelaCopiarOcorrencia] = useState<DadosTabelaCopiarOcorrencia[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { verificarAutorizacao } = useEventosService();

    const copiaOcorrenciaForm = useForm<CopiarOcorrenciaFormModel>({
        resolver: yupResolver(CopiarOcorrenciaSchema),
    });
    const {
        control,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = copiaOcorrenciaForm;


    function recarregar() {
        gridRef.current?.refresh();
    }

    async function carregarEvento(idEvento: string) {
        const response = await carregarEventoPorId(idEvento) as unknown as EventoSearchResponse;
        setEvento(response);
    }

    function onClose() {
        history.push('/eventos');
    }

    async function onClickDeleteEventoHandler() {
        setConfirmandoExclusao(false);
        setDeleteLoading(true);
        try {
            if (evento && evento.id) {
                await removerEvento(evento.id!);
                setDeleteLoading(false);
                enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
                history.push('/eventos');
            }
        } catch {
            setDeleteLoading(false);
        }
    }

    function cancelarExclusao() {
        setConfirmandoExclusao(false);
    }

    function editarEvento() {
        if (evento && evento.id) {
            history.push(`/eventos/cadastro/${evento.id}`);
        }
    }

    function cadastrarOcorrencia() {
        if (evento && evento.id) {
            history.push(`/ocorrencias/cadastro/${evento.id}`);
        }
    }

    async function onClickCopiaOcorrenciaHandler() {
        if (evento?.ocorrencias.map(x => x.data.split('T')[0]).indexOf(ocorrenciaCopia.novaData as unknown as string) !== -1) {
            setDeleteLoading(false);
            enqueueSnackbar(`Não é possível cadastrar uma data que já exista para esse evento!`, { variant: "error" });
            return;
        }
        setConfirmandoCopia(false);
        try {
            await copiarOcorrencia(ocorrenciaCopia);
            setDeleteLoading(false);
            enqueueSnackbar(`Evento copiado com sucesso!`, { variant: "success" });
            recarregar();

        } catch (error) {
            setDeleteLoading(false);
            enqueueSnackbar(`Não foi possível copiar o evento!`, { variant: "error" });
        }
    }

    function cancelarCopia() {
        setOcorrenciaCopia({ ...ocorrenciaCopia, novaData: null });
        setDadosTabelaCopiarOcorrencia([])
        setConfirmandoCopia(false);
    }

    const carregar = async (query: OptGridRequest) => {
        const request: SearchRequest<PaginatedSearchRequest> = {
            page: query.page,
            pageSize: query.pageSize,
            filtro: { idEvento }
        };
        return buscarGridOcorrenciaPaginated(request);
    };

    function onRowClick(rowData?: OcorrenciaModel) {
        if (rowData) {
            history.push(`/ocorrencias/ver/${rowData.id}`);
        }
    }

    function onClickCopiarOcorrencia(rowData: OcorrenciaSearchResponse) {
        setOcorrenciaSelecionada(rowData);
        setConfirmandoCopia(true);
    }

    function onClickRelatorio(rowData: OcorrenciaSearchResponse) {
        if (rowData) {
            history.push(`/ocorrencias/relatorio/${rowData.id}/${rowData.idEvento}`);
        }
    }

    function onClickVenda(rowData: OcorrenciaSearchResponse) {
        if (rowData) {
            history.push(`/vendas/ver/${rowData.idEvento}/${rowData.id}`);
        }
    }

    async function onClickDeleteEvento() {
        setConfirmandoExclusao(true);
    }

    function handleNovaData(event: any) {
        const novaData = event.target.value;
        if (ocorrenciaSelecionada) {
            setOcorrenciaCopia({ ...ocorrenciaCopia, novaData, idOcorrencia: ocorrenciaSelecionada.id });
            if (novaData >= formatDateDB(new Date())) {
                setValue('novaData', novaData);
                setValue('idOcorrencia', ocorrenciaSelecionada.id);
                handlePreencherDadosTabelaCopiarOcorrencia(novaData, ocorrenciaSelecionada.id)
                clearErrors("novaData") 
            } else {
                setDadosTabelaCopiarOcorrencia([])
                setError('novaData', {message: 'Data inválida'})
            }
        }
    }

    async function handlePreencherDadosTabelaCopiarOcorrencia(dataSelecionada: string, idOcorrencia: string) {
        try {
            let ocorrenciaCarregada = await carregarOcorrenciaPorId(idOcorrencia) as unknown as OcorrenciaFormModel

            if (ocorrenciaCarregada && ocorrenciaCarregada.data && ocorrenciaCarregada.pontosRota && ocorrenciaCarregada.pontosRota.length > 0) {
                let arrDados: DadosTabelaCopiarOcorrencia[] = []
                let diasDeDiferenca = differenceInDays(new Date(dataSelecionada), new Date(ocorrenciaCarregada.data.split('T')[0]));
                
                ocorrenciaCarregada.pontosRota.map((pontoEmbarque) => {
                    let novoHorarioIda = pontoEmbarque?.horarioIdaPartida ? format(addDays(new Date(pontoEmbarque?.horarioIdaPartida.toString()), diasDeDiferenca), 'dd/MM/yyyy HH:mm') : '-'
                    let novoHorarioVolta = pontoEmbarque?.horarioVoltaPartida ? format(addDays(new Date(pontoEmbarque?.horarioVoltaPartida.toString()), diasDeDiferenca), 'dd/MM/yyyy HH:mm') : '-'
                    arrDados.push({
                        pontoEmbarque: pontoEmbarque.nome,
                        horarioIdaAntigo: pontoEmbarque.horarioIdaPartida ? format(converterGmt(new Date(pontoEmbarque?.horarioIdaPartida.toString().replace('Z', ''))), 'dd/MM/yyyy HH:mm') : '-',
                        horarioIdaNovo: novoHorarioIda,
                        horarioVoltaAntigo: pontoEmbarque.horarioVoltaPartida ? format(converterGmt(new Date(pontoEmbarque?.horarioVoltaPartida.toString().replace('Z', ''))), 'dd/MM/yyyy HH:mm') : '-',
                        horarioVoltaNovo: novoHorarioVolta
                    })
                })

                setDadosTabelaCopiarOcorrencia(arrDados)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        (async () => {
            await verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor]);
            if (idEvento) {
                carregarEvento(idEvento);
                if (gridRef.current) {
                    recarregar();
                }
            }
        })()
    }, []);

    const navigateToEvent = () => {
        if (window.location.href.split('.com')[0].includes('hmg') || window.location.href.split('.com')[0].includes('calm'))
            window.open(`https://hmg.voudesquad.com.br/evento/${evento?.url}`, '_blank')
        else
            window.open(`https://www.voudesquad.com.br/evento/${evento?.url}`, '_blank')
    }

    return (
        <>
            <OptConfirmationDialog
                open={confirmandoExclusao}
                title="Excluir Evento"
                icon={{ path: mdiDeleteOutline, color: Colors.red }}
                onCancel={cancelarExclusao}
                onClose={cancelarExclusao}
                onConfirm={onClickDeleteEventoHandler} >
                Deseja confirmar a exclusão do evento? <br /> Essa operação não poderá ser desfeita.
            </OptConfirmationDialog>

            <OptDialog
                title="Copiar Ocorrência"
                open={confirmandoCopia}
                onClose={cancelarCopia}
            >
                Insira a data da cópia da ocorrência
                <form className="mt-4 form-modal-copiar-ocorrencia">
                    <Grid className={styles.GridItem} item xs={6}>
                        <Controller
                            name="novaData"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    className={styles.TextFieldCustom}
                                    label="Data"
                                    InputProps={{inputProps: { min: formatDateDB(new Date())} }}
                                    InputLabelProps={{ shrink: true }}
                                    type='date'
                                    variant="outlined"
                                    onChange={handleNovaData}
                                    value={ocorrenciaCopia.novaData}
                                />
                            )}
                        />
                        <ErrorMessage error={errors.novaData} />
                    </Grid>

                    {
                        dadosTabelaCopiarOcorrencia?.length > 0 &&
                        <TableContainer className="mt-4 w-100">
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Ponto de Embarque</StyledTableCell>
                                        <StyledTableCell align="left">Horário de IDA antigo</StyledTableCell>
                                        <StyledTableCell align="left">Horário de IDA novo</StyledTableCell>
                                        <StyledTableCell align="left">Horário de VOLTA antigo</StyledTableCell>
                                        <StyledTableCell align="left">Horário de VOLTA novo</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dadosTabelaCopiarOcorrencia.map((item, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left" className="col">
                                                        {item.pontoEmbarque}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" className="col">
                                                        {item.horarioIdaAntigo}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" className="col">
                                                        {item.horarioIdaNovo}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" className="col">
                                                        {item.horarioVoltaAntigo}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" className="col">
                                                        {item.horarioVoltaNovo}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    <OptDialogActions style={{ height: "65px" }}>
                        <Button
                            onClick={cancelarCopia}
                            color="error"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleSubmit(onClickCopiaOcorrenciaHandler)}
                            color="success"
                            disabled={errors.novaData ? true : false}
                        >
                            Confirmar
                        </Button>
                    </OptDialogActions>
                </form>
            </OptDialog>

            <div className="col">
                <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                    <div className={styles.CabecalhoContainer}>
                        <OptActionButton
                            onClick={onClose}
                            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
                        >
                            <span className={styles.SpanText}>
                                {evento?.nome.toLowerCase()}
                            </span>
                        </OptActionButton>
                    </div>
                    <Divider />
                    <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                        <Grid className={styles.GridItem} item xs={6}>
                            <b>Descrição:</b> {evento?.descricao}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={2}>
                            <b>Tipo:</b> {evento?.tipo}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={2}>
                            <b>Status:</b> {evento?.status}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={2}>
                            <b>Categoria:</b> {evento?.categoria.nome}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={6}>
                            <b>Owner:</b> {evento?.owner.nome}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={12}>
                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                onClick={navigateToEvent}
                            >
                                Ir para o evento
                            </Button>
                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                onClick={() => history.push(`/importar-passageiros-evento/${evento?.id}`)}
                            >
                                Importar Passageiros
                            </Button>
                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                onClick={() => history.push(`/sms/${evento?.id}`)}
                            >
                                Enviar SMS
                            </Button>
                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                disabled={!evento?.id}
                                onClick={() => history.push(`/promotores/${evento?.id}`)}
                            >
                                Promotores
                            </Button>

                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                placeholder="Remover Evento"
                                onClick={onClickDeleteEvento}
                            >
                                <Icon path={mdiTrashCanOutline} size={1} color={Colors.red} />
                            </Button>

                            <Button
                                className="float-end"
                                variant="contained"
                                color="inherit"
                                style={{ marginRight: "5px" }}
                                placeholder="Remover Evento"
                                onClick={editarEvento}
                            >
                                <Icon path={mdiPencilOutline} size={1} color={Colors.green1} />
                            </Button>
                        </Grid>

                        <Grid className={styles.GridItem} item xs={12}>
                            <Button
                                className="float-end"
                                variant="contained"
                                color="success"
                                style={{ marginRight: "5px" }}
                                placeholder="Cadastrar nova data"
                                onClick={cadastrarOcorrencia} >
                                Cadastrar Nova Data <Icon path={mdiPlus} size={1} color={Colors.white} />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <GridOcorrenciaView
                            onRowClick={onRowClick}
                            carregar={carregar}
                            onClickCopiarOcorrencia={onClickCopiarOcorrencia}
                            onClickRelatorio={onClickRelatorio}
                            onClickVenda={onClickVenda}
                            gridRef={gridRef}
                        />
                    </Grid>
                    <Grid item xs={12} className="mt-5 px-3">
                        {
                            evento && evento.atualizacoes.length > 0 &&
                            <h5>Histórico de alterações</h5>
                        }
                        <Grid className="grid-timeline">
                            <Timeline className="p-0 mt-4">
                                {
                                    evento && evento.atualizacoes.length > 0 && evento.atualizacoes.map((atualizacao) => (
                                        <TimelineItem className="mb-2">
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <div className="mb-2">
                                                    <b>{format(converterGmt(new Date(atualizacao.criadoEm.replace('Z', ''))), 'dd/MM/yyy HH:mm')}</b>: <b>{atualizacao.atributo}</b> alterado por <b>{atualizacao.nomeAutor}</b>
                                                </div>
                                                <div className="mb-2">
                                                    <b>Valor antigo:</b> <br />{atualizacao.valorOriginal} <br />
                                                </div>
                                                <div>
                                                    <b>Valor novo:</b> <br />{atualizacao.valorNovo}
                                                </div>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))
                                }
                            </Timeline>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}