import { mdiDeleteOutline, mdiPlusCircle, mdiPencilOutline, mdiClipboardTextOutline, mdiEmailOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import {
  OptConfirmationDialog,
  OptDialog,
  OptGridRef,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Colors } from "../../../compartilhado/colors";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { PontoRotaSearchResponse } from "../../../modelos/dtos/PontoRotaSearchResponse";
import { usePontosRotaService } from "../../../servicos/pontoRotas.service";
import { FormularioPontoRota } from "../cadastro/FormularioPontoRota";
import styles from "./styles/gridRotas.module.scss";
import { useHistory } from "react-router-dom";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { format } from "date-fns";
import { converterGmt, formatarHora } from "../../../utils/claimUtils";

interface Props {
  idEvento: string;
  idOcorrencia: string;
  perfilUsuario: number;
  itens: Array<PontoRotaSearchResponse>;
  pontosOcorrencia: any;
  gridRef: React.MutableRefObject<OptGridRef | null>;
  recarregar: Function;
}

export const GridPontosRotaView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      idEvento,
      idOcorrencia,
      perfilUsuario,
      pontosOcorrencia,
      itens,
      gridRef,
      recarregar
    },
    ref
  ) => {
    const history = useHistory();
    const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [pontosRota, setPontosRota] = useState<Array<PontoRotaSearchResponse>>(itens);
    const [pontoSelecionado, setPontoSelecionado] = useState<PontoRotaSearchResponse>();
    const { removerPontoRota } = usePontosRotaService();
    const [visivel, setVisivel] = useState<boolean>(false);

    function abrirCadastro() {
      setPontoSelecionado(undefined);
      setVisivel(true);
    }

    function fecharCadastro() {
      setVisivel(false);
    }

    function removerItem(rowData: PontoRotaSearchResponse) {
      setConfirmandoExclusao(true);
      setPontoSelecionado(rowData);
    }

    function cancelarExclusao() {
      setConfirmandoExclusao(false);
    }

    function importarPassageiros(pontoEmbarque: PontoRotaSearchResponse) {
      history.push(`/importar-passageiros/${idOcorrencia}/${pontoEmbarque.id}`);
    }

    function verificarSePodeExcluir(idPontoEmbarque: string): boolean {
      if (pontosOcorrencia.some((x: any) => x.id === idPontoEmbarque)) {
        return false
      } else {
        return true
      }
    }

    async function onClickDeleteItemHandler() {
      setConfirmandoExclusao(false);
      //setDeleteLoading(true);
      try {
        await removerPontoRota(pontoSelecionado?.id!);
        //setDeleteLoading(false);
        enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
        const novoValor = pontosRota?.filter(ponto => ponto.id != pontoSelecionado?.id);
        setPontosRota(novoValor);
      } catch (erro) {
        console.log(erro);
      }
    }

    function incluirPonto(pontoRota: PontoRotaSearchResponse) {
      if (pontoSelecionado) {
        let novosPontos = [...pontosRota]
        novosPontos = pontosRota?.filter(ponto => ponto.id !== pontoSelecionado?.id);
        setPontosRota([...novosPontos, pontoRota]);
      }
      else {
        setPontosRota([...pontosRota, pontoRota]);
      }
    }

    function abrirEdicao(ponto: PontoRotaSearchResponse) {
      setPontoSelecionado(ponto);
      setVisivel(true);
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        border: "1px solid #ccc",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

    return (
      <>
        <OptGridContainer>
          <div className={`${styles.ButtonSection}`}>
            <Tooltip title={"Cadastrar ponto de embarque"}>
              <Button
                variant="contained"
                color="inherit"
                style={{ marginRight: "5px", marginBottom: "11px" }}
                placeholder="Cadastrar ponto de rota"
                size="large"
                onClick={abrirCadastro}
              >
                <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
              </Button>
            </Tooltip>
          </div>
        </OptGridContainer>
        <OptDialog
          open={visivel} title={"Cadastro de ponto de embarque"}>
          <FormularioPontoRota
            idOcorrencia={idOcorrencia}
            onClose={fecharCadastro}
            onSave={incluirPonto}
            recarregar={recarregar}
            pontoEditado={pontoSelecionado}
          />
        </OptDialog>
        <OptConfirmationDialog
          open={confirmandoExclusao}
          title="Excluir ponto"
          icon={{ path: mdiDeleteOutline, color: Colors.red }}
          onCancel={cancelarExclusao}
          onClose={cancelarExclusao}
          onConfirm={onClickDeleteItemHandler}
        >
          Deseja confirmar a exclusão do ponto de rota ? <br /> Essa operação não poderá ser desfeita.
        </OptConfirmationDialog>

        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nome</StyledTableCell>
                <StyledTableCell align="left">Horários de Embarque</StyledTableCell>
                <StyledTableCell align="left">Passagens Disponíveis</StyledTableCell>
                <StyledTableCell align="left">Passagens Vendidas</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                pontosRota?.filter(ponto => ponto.ativo).length == 0 ?
                  <StyledTableRow><StyledTableCell colSpan={2} align="center"> Não há registros a serem exibidos </StyledTableCell></StyledTableRow> :
                  <StyledTableRow></StyledTableRow>
              }
              {pontosRota?.filter(ponto => ponto.ativo).map((ponto) => {
                return (
                  <StyledTableRow key={ponto.id}>
                    <StyledTableCell align="left" className="col-7">{ponto.nome}</StyledTableCell>
                    <StyledTableCell align="left" className="col-10">{formatarHora(ponto)}</StyledTableCell>
                    <StyledTableCell align="left" className="col-10">{ponto.passagensDisponiveis ?? 'N/A'}</StyledTableCell>
                    <StyledTableCell align="left" className="col-10">{ponto.passagensVendidas ?? 0}</StyledTableCell>
                    <StyledTableCell align="left" className="col-2 d-flex">
                      <Tooltip title={"Editar ponto"}>
                        <Button
                          variant="contained"
                          color="inherit"
                          style={{ marginRight: "5px", marginBottom: "11px" }}
                          size="large"
                          onClick={() => { abrirEdicao(ponto) }}
                        >
                          <Icon path={mdiPencilOutline} size={1} color={Colors.green1} />
                        </Button>
                      </Tooltip>

                      <Tooltip title={"Enviar SMS"}>
                        <Button
                          variant="contained"
                          color="inherit"
                          style={{ marginRight: "5px", marginBottom: "11px" }}
                          size="large"
                          onClick={() => history.push(`/sms/${idEvento}/${idOcorrencia}/${ponto.id}`)}
                        >
                          <Icon path={mdiEmailOutline} size={1} color={Colors.gray2} />
                        </Button>
                      </Tooltip>

                      {
                        (PerfilUsuario.Administrador === perfilUsuario || PerfilUsuario.Operacao === perfilUsuario) &&
                        <Tooltip title={"Importar passageiros"}>
                          <Button
                            variant="contained"
                            color="inherit"
                            style={{ marginRight: "5px", marginBottom: "11px" }}
                            size="large"
                            onClick={() => { importarPassageiros(ponto) }}
                          >
                            <Icon path={mdiClipboardTextOutline} size={1} color={Colors.blue1} />
                          </Button>
                        </Tooltip>
                      }

                      {
                        verificarSePodeExcluir(ponto.id) ?
                          <Tooltip title={"Remover ponto de embarque"}>
                            <Button
                              variant="contained"
                              color="inherit"
                              style={{ marginRight: "5px", marginBottom: "11px" }}
                              size="large"
                              onClick={() => { removerItem(ponto) }}
                            >
                              <Icon path={mdiDeleteOutline} size={1} color={Colors.red} />
                            </Button>
                          </Tooltip>
                          : <></>
                      }

                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
