export enum StatusTransacao {
  AutorizacaoSolicitada = 1,
  Autorizada = 2,
  ErroAutorizacao = 3,
  CapturaSolicitada = 4,
  Capturada = 5,
  ErroCaptura = 6,
  CancelamentoSolicitado = 7,
  Cancelada = 8,
  ErroCancelamento = 9,
  ReembolsoSolicitado = 10,
  Reembolsada = 11,
  ErroReembolso = 12,
  Erro = 13,
  Pendente3DS2 = 14,
  Expirada = 15
}

export function obterLegivel(status: StatusTransacao) {
  switch (status) {
    case StatusTransacao.AutorizacaoSolicitada:
      return "Autorização Solicitada"

    case StatusTransacao.Autorizada:
      return "Autorizada"

    case StatusTransacao.ErroAutorizacao:
      return "Erro na Autorização"

    case StatusTransacao.CapturaSolicitada:
      return "Captura Solicitada"

    case StatusTransacao.Capturada:
      return "Capturada"

    case StatusTransacao.ErroCaptura:
      return "Erro na Captura"

    case StatusTransacao.CancelamentoSolicitado:
      return "Cancelamento Solicitada"

    case StatusTransacao.Cancelada:
      return "Cancelada"

    case StatusTransacao.ErroCancelamento:
      return "Erro no Cancelamento"

    case StatusTransacao.ReembolsoSolicitado:
      return "Reembolso Solicitada"

    case StatusTransacao.Reembolsada:
      return "Reembolsado"

    case StatusTransacao.ErroReembolso:
      return "Erro na Reembolso"

    case StatusTransacao.Pendente3DS2:
      return "Antifraude Pendente"

    case StatusTransacao.Expirada:
      return "Expirada"

    default:
      return "Não identificado"
  }
}