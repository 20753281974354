import { FieldError } from 'react-hook-form';

interface Props {
  error?: FieldError;
  message?: string;
}

export const ErrorMessage = ({ error, message }: Props) => {
  return error || message ? <p style={{ color: 'red', marginTop: '4px' }}>{error?.message || message}</p> : null;
};
