import {
    mdiArrowLeft,
    mdiTrashCanOutline,
    mdiPencilOutline,
    mdiDeleteOutline,
    mdiArrowDown,
    mdiPlus,
    mdiContentSave
} from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { OptActionButton, OptGridRef } from "@optsol/react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Colors } from "../../../compartilhado/colors";
import { format } from "date-fns";
import { useRotasService } from "../../../servicos/rotas.service";
import { usePontosRotaService } from "../../../servicos/pontoRotas.service";
import { useEventosService } from "../../../servicos/eventos.service";
import { RotaSearchResponse } from "../../../modelos/dtos/RotaSearchResponse";
import { TrechoSearchResponse } from "../../../modelos/dtos/TrechoSearchResponse";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { StyledTableCell, StyledTableRow } from "./styles";
import { Spinner } from "react-bootstrap";
import { PontoEmbarqueDto } from "../../../modelos/dtos/PontoEmbarqueDto";
import { PassageiroTableRow } from "./components/PassageiroTableRow";
import { useSnackbar } from "notistack";
import { TablePassageiros } from "./components/TablePassageiros";

export const CORES_STATUS_PASSAGEIRO = {
    branco: 1,
    azul: 2,
    cinza: 3,
    verde: 4,
    vermelho: 5,
    amarelo: 6,
}

export const AssociarPassageiros = () => {
    const { idRota } = useParams<{ idRota: string }>();
    const history = useHistory();
    const gridRef = useRef<OptGridRef>(null);

    const { carregarPontosRota } = usePontosRotaService();
    const { editarRota, salvarRota, carregarRotaPorId, buscarPassageirosRota } = useRotasService();
    const { verificarAutorizacao } = useEventosService();

    const [rota, setRota] = useState<RotaSearchResponse>();
    const [pontosEmbarque, setPontosEmbarque] = useState<PontoEmbarqueDto[]>([]);
    const [totalPassagensDisponiveis, setTotalPassagensDisponiveis] = useState<number>(0);
    const [totalPassagensVendidas, setTotalPassagensVendidas] = useState<number>(0);
    const [totalPassagensConfirmadas, setTotalPassagensConfirmadas] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    function recarregar() {
        gridRef.current?.refresh();
    }

    useEffect(() => {
        (async () => {

            try {
                verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor]);

                if (idRota) {
                    const rota = await carregarRotaPorId(idRota) as unknown as RotaSearchResponse;
                    const result = await buscarPassageirosRota(idRota) as any

                    if (rota && rota.trechos && rota.trechos.length > 0) {
                        let pontosEmbarqueTmp: PontoEmbarqueDto[] = []
                        let totalPassagensDisponivensTmp: number = 0;
                        let totalPassagensVendidasTmp: number = 0;
                        let totalPassagensConfirmadasTmp: number = 0;

                        rota.trechos.map((trecho) => {
                            if (trecho.pontoRotaOrigem) {
                                totalPassagensDisponivensTmp += trecho.pontoRotaOrigem.passagensDisponiveis
                                totalPassagensVendidasTmp += trecho.pontoRotaOrigem.passagensVendidas
                                pontosEmbarqueTmp.push(trecho.pontoRotaOrigem)
                            }
                        })

                        pontosEmbarqueTmp.map((pontoEmbarque) => {
                            pontoEmbarque.passageiros = new Array();
                            result.map((passageiro: any) => {
                                if (pontoEmbarque.id === passageiro.reserva.idPontoEmbarque) {
                                    let objPassageiro = passageiro

                                    if (passageiro.idRota === null) {
                                        objPassageiro.corOriginal = CORES_STATUS_PASSAGEIRO.branco
                                        objPassageiro.corAtual = CORES_STATUS_PASSAGEIRO.branco
                                    } else if (passageiro.idRota === idRota) {
                                        objPassageiro.corOriginal = CORES_STATUS_PASSAGEIRO.azul
                                        objPassageiro.corAtual = CORES_STATUS_PASSAGEIRO.azul
                                    } else {
                                        objPassageiro.corOriginal = CORES_STATUS_PASSAGEIRO.cinza
                                        objPassageiro.corAtual = CORES_STATUS_PASSAGEIRO.cinza
                                    }

                                    pontoEmbarque?.passageiros?.push(passageiro)
                                }
                            })
                            totalPassagensConfirmadasTmp += pontoEmbarque.passageiros.length;
                        })

                        setTotalPassagensDisponiveis(totalPassagensDisponivensTmp)
                        setTotalPassagensVendidas(totalPassagensVendidasTmp)
                        setTotalPassagensConfirmadas(totalPassagensConfirmadasTmp)
                        setPontosEmbarque(pontosEmbarqueTmp)
                    } else {
                        rota.trechos = new Array<TrechoSearchResponse>()
                    }

                    setRota(rota);

                    if (gridRef.current) {
                        recarregar();
                    }
                }
            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false)
            }
        })()
    }, []);

    if (loading) {
        return (
            <div className="mt-5 text-center w-100">
                <Spinner />
            </div>
        )
    }

    return (
        <>
            <div className="col mt-3">
                <Grid className="" container rowSpacing={1} columns={12} px={1.5}>
                    <div className="">
                        <OptActionButton
                            onClick={() => history.goBack()}
                            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
                        >
                            <span className="">
                                {rota?.nomeEvento.toUpperCase()} - {rota && rota.dataOcorrencia ? format(new Date(rota.dataOcorrencia.replace("Z", "")), 'dd/MM/yyyy') : '-'} - {rota?.nome.toUpperCase()} - Associar passageiros
                            </span>
                        </OptActionButton>
                    </div>

                    <Divider />

                    <Grid className="mt-2" container rowSpacing={1} columns={12} px={1.5}>
                        <Grid className={`text-center`} item xs={12} md={4} marginTop={2}>
                            <p className="mb-1">Total de passagens disponíveis</p>
                            <p className="mb-0"><b>{totalPassagensDisponiveis}</b></p>
                        </Grid>
                        <Grid className={`text-center`} item xs={12} md={4} marginTop={2}>
                            <p className="mb-1">Total de passagens vendidas</p>
                            <p className="mb-0"><b>{totalPassagensVendidas}</b></p>
                        </Grid>
                        <Grid className={`text-center`} item xs={12} md={4} marginTop={2}>
                            <p className="mb-1">Total de passagens confirmadas</p>
                            <p className="mb-0"><b>{totalPassagensConfirmadas}</b></p>
                        </Grid>
                    </Grid>

                    {
                        pontosEmbarque && pontosEmbarque.length > 0 ?
                            <>
                                {
                                    pontosEmbarque.map((pontoEmbarque) => (
                                        <TablePassageiros
                                            key={pontoEmbarque.id}
                                            nomePontoEmbarque={pontoEmbarque.nome}
                                            idPontoEmbarque={pontoEmbarque.id}
                                            passageirosPontoEmbarque={pontoEmbarque.passageiros}
                                            idRota={idRota}
                                        />
                                    ))
                                }
                            </>
                            :
                            <div className="text-center mt-5">
                                <p className="mb-0">Nenhum ponto de embarque encontrado</p>
                            </div>
                    }
                </Grid>
            </div>
        </>
    );
}