import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { OptActionToolbar, OptGridRef, OptGridRequest } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Colors } from "../../../compartilhado/colors";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { PaginatedViagemSearchRequest } from "../../../modelos/search/PaginatedViagemSearchRequest";
import { Rotas } from "../../../rotas";
import { useViagensService } from "../../../servicos/viagens.service";
import { GridViagensView } from "./GridViagensView";
import styles from "./styles/gridViagens.module.scss";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

export const GridViagens = () => {
  const { buscarGridViagensPaginated } = useViagensService();
  const [dataInicio, setDataInicio] = useState<Date | null>(null);
  const [dataFim, setDataFim] = useState<Date | null>(null);
  const { verificarAutorizacao } = useEventosService();

  const carregar = (
    query: OptGridRequest,
    viagemSearchRequest: PaginatedViagemSearchRequest = {
      dataInicio: dataInicio,
      dataFim: dataFim,
      usuarioId: null,
    }
  ) => {
    const request: SearchRequest<PaginatedViagemSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro: viagemSearchRequest,
    };

    return buscarGridViagensPaginated(request);
  };

  const gridRef = useRef<OptGridRef>(null);
  const { enqueueSnackbar } = useSnackbar();

  function procurarViagem() {
    if (!dataInicio && dataFim) {
      enqueueSnackbar("Informe uma data de saída", {
        variant: "warning",
      });
    } else if (!dataFim && dataInicio) {
      enqueueSnackbar("Informe uma data de retorno", {
        variant: "warning",
      });
    } else {
      gridRef.current?.refresh();
    }
  }

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao]);
    })()
  }, []);

  return (
    <>
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Rotas.Home}
          title="Visualização de viagens"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <form>
          <OptGridContainer>
            <div className={styles.ButtonSection} style={{marginBottom: "12px" }}>
              <Tooltip title={"Filtrar viagens"}>
                <Button
                  style={{ marginRight: "-15px", marginBottom: "-6.5px" }}
                  placeholder="Filtrar viagem"
                  size="large"
                  onClick={procurarViagem}
                >
                  <Icon path={mdiMagnify} size={1} color={Colors.primary} />
                </Button>
              </Tooltip>
              <DatePicker
                maxDate={dataFim}
                label="Data de Saída"
                value={dataInicio}
                onChange={(d) => setDataInicio(d)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    sx={{
                      "& .MuiInputBase-root > fieldset": {
                        borderColor: Colors.gray10,
                      },
                      "& .MuiInputBase-root:hover:not(.Mui-focused) > fieldset":
                        {
                          borderColor: Colors.gray8,
                        },
                    }}
                    {...params}
                  />
                )}
              />
              <DatePicker
                minDate={dataInicio}
                label="Data de Retorno"
                value={dataFim}
                onChange={(d) => setDataFim(d)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    sx={{
                      "& .MuiInputBase-root > fieldset": {
                        borderColor: Colors.gray10,
                      },
                      "& .MuiInputBase-root:hover:not(.Mui-focused) > fieldset":
                        {
                          borderColor: Colors.gray8,
                        },
                    }}
                    {...params}
                  />
                )}
              />
            </div>
          </OptGridContainer>
        </form>
        <GridViagensView carregar={carregar} ref={gridRef} />
      </OptSideLayoutContentCustom>
    </>
  );
};
