import { yupResolver } from "@hookform/resolvers/yup";
import { mdiDeleteOutline, mdiPlusCircle, mdiCalendarMultiple, mdiAccount } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptDialog,
  OptGridRef,
  OptGridRequest,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Colors } from "../../../compartilhado/colors";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { ITEM_FORM_INITIAL_STATE } from "../../../constantes/ItemFormInitialState";
import { ItemSearchResponse } from "../../../modelos/dtos/ItemSearchResponse";
import { ItemFormModel } from "../../../modelos/item/ItemFormModel";
import { ItemModel } from "../../../modelos/item/itemModel";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { Rotas } from "../../../rotas";
import { useItensService } from "../../../servicos/itens.service";
import { FormularioItemSchema } from "../FormularioItem/FormularioItem.validation";
import { GridItensView } from "./GridItensView";
import styles from "./styles/gridItens.module.scss";
import { FiltroBusca } from "../../../componentes/filtro";
import { TipoInput } from "../../../enums/tipoInput";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";
import { useHistory } from "react-router-dom";
import { TipoCupom } from "../../../enums/TipoCupom";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

export const GridItens = () => {
  const useItemForm = useForm<ItemFormModel>({
    resolver: yupResolver(FormularioItemSchema),
  });
  const [salvarLoading, setSalvarLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [exibirDialogEditarCupom, setExibirDialogEditarCupom] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
  const [item, setItem] = useState<ItemFormModel>(ITEM_FORM_INITIAL_STATE);
  const { buscarGridItensPaginated, removerItem } = useItensService();
  const { enqueueSnackbar } = useSnackbar();
  const [filtro, setFiltro] = useState<EventoFormModel>();
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  const opcoesFiltro = [
    { texto: "Código", valor: 'Codigo', tipoInput: TipoInput.Texto },
    { texto: "Descrição", valor: 'Descricao', tipoInput: TipoInput.Texto},
  ];

  function pesquisaComFiltro(filtro: EventoFormModel) {
    setFiltro(filtro);
  }

  const carregar = async (
    query: OptGridRequest,
    itemSearchRequest: PaginatedSearchRequest = {
      filtro: filtro,
    }
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro: itemSearchRequest.filtro,
    };
    return buscarGridItensPaginated(request);
  };

  function onRowClick(rowData?: ItemModel) {
    if (rowData) {
    }
  }

  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  function cadastrarCupom(): void {
    history.push("/itens/cadastro");
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerItem(item.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setItem(ITEM_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }

  async function onClickDeleteItem(rowData: ItemSearchResponse) {
    setItem(rowData);
    setConfirmandoExclusao(true);
  }

  const sxButtonConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  async function onClickEditItem(rowData: ItemSearchResponse) {
    // if (rowData.id) {
    //   history.push(`/itens/cadastro/${rowData.id}`);
    // }
    setItem(rowData);
    setExibirDialogEditarCupom(true);
  }

  function editarCupom(tipoCupom: TipoCupom) {
    if (item.id) {
      history.push(`/itens/cadastro/${item.id}/${tipoCupom}`);
    }
  }

  function cancelarEdicao() {
    setExibirDialogEditarCupom(false);
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, [filtro]);
  

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador]);
    })()
  }, []);

  return (
    <>
      <OptDialog
        open={exibirDialogEditarCupom} title={"Como você deseja editar o cupom ?"} onClose={cancelarEdicao}>
        <form>
            <Button
              variant="contained"
              color="info"
              style={{ marginRight: "5px", marginBottom: "25px" }}
              placeholder="Por usuário"
              size="large"
              onClick={() => {editarCupom(TipoCupom.PorUsuario)}}
            >
              Por usuário <Icon path={mdiAccount} size={1} color={Colors.white} />
            </Button>

            <Button
              variant="contained"
              color="info"
              style={{ marginRight: "5px", marginBottom: "25px" }}
              placeholder="Por Evento"
              size="large"
              onClick={() => {editarCupom(TipoCupom.PorEvento)}}
            >
              Por evento <Icon path={mdiCalendarMultiple} size={1} color={Colors.white} />
            </Button>
        </form>
      </OptDialog>

      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir cupom"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão do cupom? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>
      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Rotas.Home}
          title="Cupom"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <OptGridContainer>
          <FiltroBusca opcoes={opcoesFiltro} pesquisa={pesquisaComFiltro} />
          <div className={styles.ButtonSection}>
            <Tooltip title={"Cadastrar cupom"}>
              <Button
                variant="contained"
                color="info"
                style={{ marginRight: "5px", marginBottom: "11px" }}
                placeholder="Cadastrar cupom"
                size="large"
                onClick={cadastrarCupom}
              >
                Cadastrar Cupom <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
              </Button>
            </Tooltip>
          </div>
        </OptGridContainer>
        <GridItensView
          onRowClick={onRowClick}
          carregar={carregar}
          onClickDelete={onClickDeleteItem}
          onClickEdit={onClickEditItem}
          gridRef={gridRef}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
