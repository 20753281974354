import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { formatarHora, formatarTelefone } from "../../../utils/claimUtils";
import { RsvpRelatorioModel } from "../../../modelos/rsvp";

interface Props {
  data: any[];
}

export const GridRsvpView = React.forwardRef<OptGridRef | undefined, Props>(
  ({ data }, ref) => {
    const options: OptGridOptions = {
      search: true,
      pageSize: 100,
    };

    const columns: OptGridColumn<any>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "Nome do Passageiro",
        field: "nomePassageiro",
        align: "left",
      },
      {
        title: "Nº de Embarque",
        field: "numeroPassageiro",
        align: "center",
      },
      {
        title: "Telefone do Passageiro",
        field: "telefonePassageiro",
        align: "left",
        render: (row) => {   
          return (
            <span>
              {formatarTelefone(row.telefonePassageiro)}
            </span>
          );
        },
      },
      {
        title: "Ponto de Embarque",
        field: "pontoEmbarque",
        align: "center",
      },
      {
        title: "RSVP",
        field: "rsvp",
        align: "center",
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            columns={columns}
            data={data}
            options={options}
          />
        </OptGridContainer>
      </>
    );
  }
);
