import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { PontoEmbarqueSearchResponse } from "../modelos/dtos/PontoEmbarqueSearchResponse";
import { PontoEmbarqueFormModel } from "../modelos/pontoEmbarque/pontoEmbarqueFormModel";
import { PaginatedPontoEmbarqueRequest } from "../modelos/search/PaginatedPontoEmbarqueRequest";
import { ImportarPassageirosPontoEmbarqueModel } from "../modelos/pontoEmbarque/importarPassageirosPontoEmbarqueModel";
import { protectedResources } from "../authConfig";

export const usePontoEmbarqueService = () => {
  const { post, remove, put, get, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/PontoEmbarque`;

  const importarPassageiros = async (data: ImportarPassageirosPontoEmbarqueModel) => {
    return post(`/gestao/Usuario/importarUsuarios`, data);
  };

  const salvarPontoEmbarque = async (data: PontoEmbarqueFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerPontoEmbarque = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarPontoEmbarque = async (itemId: string, data: PontoEmbarqueFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridPontosEmbarquePaginated = async (data: SearchRequest<PaginatedPontoEmbarqueRequest>) => {
    return gridPagedListSearch<PontoEmbarqueSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  const carregarPontoEmbarquePorId = async (id :string) => {
    return get<PontoEmbarqueFormModel>(`${baseApi}/${id}`);
  };

  const carregarPontosEmbarque = async () => {
    return get<PontoEmbarqueFormModel>(`${baseApi}/todos`);
  };

  return {
    importarPassageiros,
    salvarPontoEmbarque,
    removerPontoEmbarque,
    editarPontoEmbarque,
    buscarGridPontosEmbarquePaginated,
    carregarPontoEmbarquePorId,
    carregarPontosEmbarque,
  };
};