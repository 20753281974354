import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { DataRecorrencia } from "../../modelos/dtos/DataRecorrencia";
import { EventoFormModel } from "../../modelos/evento/eventoFormModel";

interface Props {
  control: Control<EventoFormModel, any>
  setEvento: Function,
  evento: EventoFormModel,
  setValue: Function
}

export const RecorrenciaDiariaGrid = ({ control, evento, setEvento, setValue }: Props) => {

  const [recorrencia, setRecorrencia] = useState<DataRecorrencia>(evento.recorrencia!);
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 5);
  const minDateString = minDate.toJSON().split('T')[0];
  const maxDateString = maxDate.toJSON().split('T')[0];

  function atualizarRecorrencia(propriedade: string, valor: any) {

    switch (propriedade) {
      case "inicio":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, inicio: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, inicio: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, inicio: valor }));
        break;
      case "intervalo":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, intervalo: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, intervalo: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, intervalo: valor }));
        break;
      case "fim":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, fim: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, fim: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, fim: valor }));
        break;
    }

    return recorrencia;
  }

  function handleRecorrencia(event: any, propriedade: string) {
    const valor = event.target.value;
    atualizarRecorrencia(propriedade, valor);
  }

  return (
    <>
      <Grid item xs={6} marginBottom={2} >
        <Controller
          name="recorrencia.inicio"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              style={{ marginRight: 5 }}
              type="date"
              label="Início"
              value={recorrencia.inicio}
              InputLabelProps={{ shrink: true }}
              onChange={event => (handleRecorrencia(event, 'inicio'))}
              inputProps={{ min: minDateString, max: maxDateString }}
            />
          )}
        />

        <Controller
          name="recorrencia.fim"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              type="date"
              label="Final"
              value={recorrencia.fim}
              InputLabelProps={{ shrink: true }}
              onChange={event => (handleRecorrencia(event, 'fim'))}
              inputProps={{ min: minDateString, max: maxDateString }}
            />
          )}
        />

      </Grid>
      <Grid item xs={6} marginBottom={2}>
        <Controller
          name="recorrencia.intervalo"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              style={{ marginRight: 10 }}
              type="number"
              inputProps={{ min: 0, max: 100, maxLength: 3 }}
              value={recorrencia.intervalo}
              label="A cada"
              onChange={event => (handleRecorrencia(event, 'intervalo'))}
            />
          )}
        />
        <div className="mt-3 d-inline-block"> Dia(s)</div>
      </Grid>
    </>
  );
};