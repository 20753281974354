import { useApi } from "../compartilhado/hooks/api";
import { PontoRotaFormModel } from "../modelos/rota/PontoRotaFormModel";
import { protectedResources } from "../authConfig";

export const usePontosRotaService = () => {
  const { post, remove, put, gridSearch, get, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/PontoRota`;

  const salvarPontoRota = async (data: PontoRotaFormModel) => {
    if(data.id)
      return put(`${baseApi}/${data.id}`, data);
    return post(`${baseApi}`, data);
  };

  const removerPontoRota = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarPontoRota = async (itemId: string, data: PontoRotaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const carregarPontoRotaPorId = async (id :string) => {
    return get<PontoRotaFormModel>(`${baseApi}/${id}`);
  };

  const carregarPontosRota = async () => {
    return get<PontoRotaFormModel>(`${baseApi}/todos`);
  };

  return {
    salvarPontoRota,
    removerPontoRota,
    editarPontoRota,
    carregarPontoRotaPorId,
    carregarPontosRota,
  };
};