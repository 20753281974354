import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { PessoaSearchResponse } from "../modelos/dtos/PessoaSearchResponse";
import { PessoaFormModel } from "../modelos/pessoa/pessoaFormModel";
import { PaginatedPessoaRequest } from "../modelos/search/PaginatedPessoaRequest";
import { protectedResources } from "../authConfig";

export const usePessoasService = () => {
  const { get, post, remove, put, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Usuario`;

  const salvarPessoa = async (data: PessoaFormModel) => {
    return post(`${baseApi}`, data);
  };

  const cadastrarNovoUsuarioBackoffice = async (data: any) => {
    return post(`${baseApi}/cadastroNovoUsuarioBackoffice`, data);
  };

  const removerPessoa = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarPessoa = async (itemId: string, data: PessoaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridPessoasPaginated = async (data: SearchRequest<PaginatedPessoaRequest>) => {
    return gridPagedListSearch<PessoaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  const carregarPessoaPorId = async (id :string) => {
    return get<PessoaFormModel>(`${baseApi}/${id}`);
  };

  const carregarPessoas = async () => {
    return get<PessoaFormModel>(`${baseApi}/all`);
  };

  const carregarConsultores = async () => {
    return get<PessoaFormModel>(`${baseApi}/consultores`);
  };  

  return {
    salvarPessoa,
    removerPessoa,
    editarPessoa,
    buscarGridPessoasPaginated,
    carregarPessoaPorId,
    carregarPessoas,
    carregarConsultores,
    cadastrarNovoUsuarioBackoffice
  };

};
