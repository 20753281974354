import { mdiCalendar, mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptGridRef,
  OptGridRequest,
  OptSearchField,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { FiltroBusca } from "../../../componentes/filtro";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { COPIAR_EVENTO_FORM_INITIAL_STATE } from "../../../constantes/CopiarEventoFormInitialState";
import { EVENTO_FORM_INITIAL_STATE } from "../../../constantes/EventoFormInitialState";
import { StatusEvento } from "../../../enums/StatusEvento";
import { TipoEvento } from "../../../enums/TipoEvento";
import { TipoInput } from "../../../enums/tipoInput";
import { EventoSearchResponse } from "../../../modelos/dtos/EventoSearchResponse";
import { CopiarEventoFormModel } from "../../../modelos/evento/copiarEventoFormModel";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";
import { EventoModel } from "../../../modelos/evento/eventoModel";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { Rotas } from "../../../rotas";
import { useEventosService } from "../../../servicos/eventos.service";
import { GridEventosView } from "./GridEventosView";
import styles from "./styles/gridEvento.module.scss";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

export const GridEventos = () => {
  const [filtro, setFiltro] = useState<EventoFormModel>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
  const [tipos, setTipos] = useState(new Array<any>());
  const [listaStatus, setListaStatus] = useState(new Array<any>());
  const [confirmandoCopia, setConfirmandoCopia] = useState<boolean>(false);
  const [eventoCopia, setEventoCopia] = useState<CopiarEventoFormModel>(COPIAR_EVENTO_FORM_INITIAL_STATE);
  const [evento, setEvento] = useState<EventoFormModel>(EVENTO_FORM_INITIAL_STATE);
  const { buscarGridEventoPaginated, removerEvento, copiarEvento } = useEventosService();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  const opcoesFiltro = [
    { texto: "Nome", valor: 'nome', tipoInput: TipoInput.Texto },
    { texto: "Descrição", valor: 'descricao', tipoInput: TipoInput.Texto },
    { texto: "Tipo", valor: 'tipo', tipoInput: TipoInput.Select, opcoes: tipos },
    { texto: "Status", valor: 'status', tipoInput: TipoInput.Select, opcoes: listaStatus }
  ];

  const copiaEventoForm = useForm<any>();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = copiaEventoForm;

  function pesquisaComFiltro(filtro: EventoFormModel) {
    setFiltro(filtro);
  }

  const carregar = async (
    query: OptGridRequest,
    itemSearchRequest: PaginatedSearchRequest = {
      filtro: filtro,
    }
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro: itemSearchRequest.filtro,
    };
    return buscarGridEventoPaginated(request);
  };

  function onRowClick(rowData?: EventoModel) {
    if (rowData) {
      history.push(`/eventos/ver/${rowData.id}`);
    }
  }

  async function onClickCopiaEventoHandler() {
    setConfirmandoCopia(false);
    try {
      if (evento && evento.id) {
        const eventoCopiaPost = { ...eventoCopia, idEvento: evento.id };
        await copiarEvento(eventoCopiaPost);
        enqueueSnackbar(`Registro copiado com sucesso!`, { variant: "success" });
        recarregar();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onClickCopiarEvento(rowData: EventoModel) {
    setEvento(rowData);
    setConfirmandoCopia(true);
  }

  function cancelarCopia() {
    setConfirmandoCopia(false);
  }

  function handleUrl(event: any) {
    const novaUrl = event.target.value;
    setEventoCopia({ ...eventoCopia, novaUrl });
  }

  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerEvento(evento.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setEvento(EVENTO_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }


  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  function cadastrarEvento() {
    history.push("/eventos/cadastro");
  }

  function carregarTipos() {

    const resultado = new Array<any>();
    const itens = Object.keys(TipoEvento).filter((item) => {
      return isNaN(Number(item));
    });

    itens.map((item, index) => {
      resultado.push({ valor: index + 1, texto: item })
    });

    setTipos(resultado);
  }

  function carregarListaStatus() {
    const resultado = new Array<any>();
    const itens = Object.keys(StatusEvento).filter((item) => {
      return isNaN(Number(item));
    });

    itens.map((item, index) => {
      resultado.push({ valor: index + 1, texto: item })
    });

    setListaStatus(resultado);
  }

  useEffect(() => {
    carregarTipos();
    carregarListaStatus();

    if (gridRef.current) {
      recarregar();
    }
  }, [filtro]);



  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor]);
    })()
  }, []);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoCopia}
        title="Copiar Evento"
        icon={{ path: mdiCalendar, color: Colors.green1 }}
        onCancel={cancelarCopia}
        onClose={cancelarCopia}
        onConfirm={onClickCopiaEventoHandler} >
        Insira a URL da cópia da evento
        <form>
          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="novaUrl"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  className={styles.TextFieldCustom}
                  label="Url"
                  variant="outlined"
                  onChange={handleUrl}
                  value={eventoCopia?.novaUrl}
                />
              )}
            />
          </Grid>
        </form>
      </OptConfirmationDialog>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir Eventos"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão do evento? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>

      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Rotas.Home}
          title="Eventos"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <OptGridContainer>
          <FiltroBusca opcoes={opcoesFiltro} pesquisa={pesquisaComFiltro} />
          <div className={styles.ButtonSection}>
            <Tooltip title={"Cadastrar evento"}>
              <Button
                variant="contained"
                color="info"
                style={{ marginRight: "35px", marginBottom: "11px" }}
                placeholder="Cadastrar evento"
                size="large"
                onClick={cadastrarEvento}
              >
                Cadastrar Evento <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
              </Button>
            </Tooltip>
          </div>
        </OptGridContainer>
        <GridEventosView
          onRowClick={onRowClick}
          carregar={carregar}
          onClickCopiarEvento={onClickCopiarEvento}
          gridRef={gridRef}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
