import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { OcorrenciaSearchResponse } from "../../modelos/dtos/OcorrenciaSearchResponse";
import { useOcorrenciasService } from "../../servicos/ocorrencias.service";
import { usePontoEmbarqueService } from "../../servicos/pontoEmbarque.service";
import { OptActionButton, OptBackdrop, OptDialog } from "@optsol/react";
import { format } from "date-fns";
import styles from "./styles/importarPassageiros.module.scss";
import { Colors } from "../../compartilhado/colors";
import { mdiArrowLeft } from "@mdi/js";
import { Divider, Grid, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, styled, Button, TextField } from "@mui/material";
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx';
import Icon from "@mdi/react";
import { mdiDownload, mdiImport, mdiHelpCircle } from "@mdi/js";
import { ImportarPassageirosPontoEmbarqueModel, UsuarioImportado } from "../../modelos/pontoEmbarque/importarPassageirosPontoEmbarqueModel";
import { ErrorMessage } from "../../componentes/errorMessage/ErrorMessage";
import { Spinner } from "react-bootstrap";
import { TipoDocumento } from "../../enums/tipoDocumento";
import { MASCARA_TELEFONE } from "../../constantes/mascaras/MascaraTelefone";
import { useSnackbar } from "notistack";

interface PassageirosImportProps {
    usuario: string;
    nomeUsuario: string;
    ddd: string;
    telefone: string;
    email: string;
    documento: string;
    estado: string;
    orgaoExpeditor: string;
    passageiro: boolean;
    tipoDocumento: 'RG' | 'CNH' | 'PASSAPORTE' | 'CERTIDÃO DE NASCIMENTO' | 'CN';
    acompanhantes?: PassageirosImportProps[];
    telefoneConcat: string;
    documentoConcat: string;
    erroUsuario: boolean;
    erroNome: boolean;
    erroDdd: boolean;
    erroTelefone: boolean;
    erroEmail: boolean;
    erroDocumento: boolean;
    erroEstado: boolean;
    erroOrgaoExpeditor: boolean;
    erroTipoDocumento: boolean;
}

export const ImportarPassageiros = () => {
    const { idOcorrencia, idPontoEmbarque } = useParams<{ idEvento: string, idOcorrencia: string, idPontoEmbarque: string }>();
    const { carregarOcorrenciaPorId } = useOcorrenciasService();
    const { importarPassageiros } = usePontoEmbarqueService();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [temErroNaPlanilha, setTemErroNaPlanilha] = useState<boolean>(false);
    const [showModalAjuda, setShowModalAjuda] = useState<boolean>(false);
    const [ocorrencia, setOcorrencia] = useState<OcorrenciaSearchResponse>();
    const [errosDropzone, setErrosDropzone] = useState<string[]>([]);
    const [tableHeader, setTableHeader] = useState<string[]>([]);
    const [passageiros, setPassageiros] = useState<PassageirosImportProps[]>([]);
    const [senhaPadrao, setSenhaPadrao] = useState<string>('');
    const [erroSenhaPadrao, setErroSenhaPadrao] = useState<boolean>(false);
    const [usuariosImportados, setUsuariosImportados] = useState<any[]>([]);
    const [usuariosRejeitados, setUsuariosRejeitados] = useState<any[]>([]);

    const onDrop = useCallback((acceptedFiles) => {
        setErrosDropzone([])
        setTemErroNaPlanilha(false);

        acceptedFiles.forEach((file: any) => {
            var reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = function (e: any) {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

                let tmp_passageiros = new Array();

                dataParse.map((item: any, index: number) => {
                    if (item.length > 0) {
                        if (index === 0) {
                            setTableHeader(item)
                        } else {
                            let obj: PassageirosImportProps = {
                                usuario: item[0] ? item[0].toString() : '',
                                nomeUsuario: item[1] ? item[1].toString() : '',
                                ddd: item[2] ? item[2].toString() : '',
                                telefone: item[3] ? item[3].toString() : '',
                                telefoneConcat: item[2] && item[3] ? item[2].toString() + item[3].toString() : '',
                                email: item[4] ? item[4].toString() : '',
                                tipoDocumento: item[5] ? item[5].toString().toUpperCase() : '',
                                documento: item[6] ? item[6].toString() : '',
                                documentoConcat: item[6] ? item[6].toString() : '',
                                estado: item[7] ? item[7].toString() : '',
                                orgaoExpeditor: item[8] ? item[8].toString() : '',
                                passageiro: true,
                                acompanhantes: [],
                                erroUsuario: false,
                                erroNome: false,
                                erroDdd: false,
                                erroTelefone: false,
                                erroEmail: false,
                                erroDocumento: false,
                                erroEstado: false,
                                erroOrgaoExpeditor: false,
                                erroTipoDocumento: false
                            }

                            if (!obj.usuario) {
                                setTemErroNaPlanilha(true);
                                obj.erroUsuario = true;
                            }

                            if (!obj.nomeUsuario) {
                                setTemErroNaPlanilha(true);
                                obj.erroNome = true;
                            }

                            if (!obj.documento) {
                                setTemErroNaPlanilha(true);
                                obj.erroDocumento = true;
                            }

                            if (!obj.documento) {
                                setTemErroNaPlanilha(true);
                                obj.erroDocumento = true;
                            }

                            if (!obj.usuario.includes('-p')) {
                                if (!obj.ddd) {
                                    setTemErroNaPlanilha(true);
                                    obj.erroDdd = true;
                                }

                                if (!obj.telefone) {
                                    setTemErroNaPlanilha(true);
                                    obj.erroTelefone = true;
                                }

                                if (!obj.email) {
                                    setTemErroNaPlanilha(true);
                                    obj.erroEmail = true
                                }


                                let isPassageiro = item[9]
                                if (isPassageiro == undefined) {
                                    obj.passageiro = true
                                } else {
                                    let strPassageiro = isPassageiro.toString().toUpperCase()

                                    if (strPassageiro == 'SIM') {
                                        obj.passageiro = true
                                    } else {
                                        obj.passageiro = false
                                    }
                                }
                            }

                            if (obj.tipoDocumento === 'RG') {
                                if (!obj.estado) {
                                    setTemErroNaPlanilha(true);
                                    obj.erroEstado = true;
                                }

                                if (!obj.orgaoExpeditor) {
                                    setTemErroNaPlanilha(true);
                                    obj.erroOrgaoExpeditor = true;
                                }

                                obj.documentoConcat = `${obj.documento} - ${obj.orgaoExpeditor} - ${obj.estado}`;
                            }

                            tmp_passageiros.push(obj)
                        }
                    }
                })

                let acompanhantes = tmp_passageiros.filter((item: PassageirosImportProps) => item.usuario.includes('-p'))
                acompanhantes.map((acompanhante: PassageirosImportProps) => {
                    let usuarioId = acompanhante.usuario.split('-')[0]

                    let objIndex = tmp_passageiros.findIndex((obj => obj.usuario === usuarioId));
                    tmp_passageiros[objIndex].acompanhantes.push(acompanhante)
                })

                tmp_passageiros = tmp_passageiros.sort((a, b) => (a.usuario > b.usuario) ? 1 : ((b.usuario > a.usuario) ? -1 : 0))
                
                setPassageiros(tmp_passageiros);
                setUsuariosImportados([])
                setUsuariosRejeitados([])
            };
            reader.readAsArrayBuffer(file)
        })
    }, [])

    const onDropRejected = useCallback((errors) => {
        if (errors.length > 0) {
            errors.map((err: any) => {
                err.errors.map((erro: any) => {
                    if (erro.code === 'too-many-files') {
                        setErrosDropzone([...errosDropzone, 'Muitos arquivos selecionados. Máximo: 1'])
                    } else if (erro.code === 'file-invalid-type') {
                        setErrosDropzone([...errosDropzone, 'Tipo do arquivo inválido. Tipos permitidos: .xls, .xlsx'])
                    }
                })
            })
        }
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        maxFiles: 1,
        accept: ['.xls', '.xlsx']
    })

    async function carregarOcorrencia(idOcorrencia: string) {
        const response = await carregarOcorrenciaPorId(idOcorrencia) as unknown as OcorrenciaSearchResponse;
        setOcorrencia(response);
    }

    async function handleImportarPassageiros() {
        if (senhaPadrao) {
            try {
                setIsLoadingSubmit(true);
                let passageirosToImport = new Array<UsuarioImportado>();

                passageiros.map((passageiro) => {
                    if (!passageiro.usuario.includes('-p')) {
                        let acompanhantesPassageiro = new Array<UsuarioImportado>();

                        if (passageiro.acompanhantes && passageiro.acompanhantes.length > 0) {
                            passageiro.acompanhantes.map((acomp) => {
                                let objAcompanhante: UsuarioImportado = {
                                    nome: acomp.nomeUsuario,
                                    email: acomp.email,
                                    telefone: acomp.telefoneConcat,
                                    documento: acomp.documentoConcat,
                                    passageiro: true,
                                    tipoDocumento: convertTipoDocumento(acomp.tipoDocumento),
                                    acompanhantes: [],
                                }

                                acompanhantesPassageiro.push(objAcompanhante);
                            })
                        } else {
                            acompanhantesPassageiro = []
                        }


                        let obj: UsuarioImportado = {
                            nome: passageiro.nomeUsuario,
                            email: passageiro.email,
                            telefone: passageiro.telefoneConcat,
                            documento: passageiro.documentoConcat,
                            tipoDocumento: convertTipoDocumento(passageiro.tipoDocumento),
                            passageiro: passageiro.passageiro,
                            acompanhantes: acompanhantesPassageiro,
                        }

                        passageirosToImport.push(obj);
                    }
                })

                let params: ImportarPassageirosPontoEmbarqueModel = {
                    senha: senhaPadrao,
                    idPontoEmbarque: idPontoEmbarque,
                    usuarios: passageirosToImport
                }

                const response = await importarPassageiros(params)
                let arrUsuariosImportados: any[] = []
                let arrUsuariosRejeitados: any[] = []

                response.resultados.map((result: any) => {
                    result.usuario['passageiro'] = result.passageiro ? 'Sim' : 'Não'
                    if (result.sucesso) {
                        arrUsuariosImportados.push(result.usuario)
                    } else {
                        arrUsuariosRejeitados.push({...result.usuario, motivo: result.motivo})
                    }
                })

                setUsuariosImportados(arrUsuariosImportados)
                setUsuariosRejeitados(arrUsuariosRejeitados)
                enqueueSnackbar(`Importação finalizada! Verifique os usuários que foram cadastrados com sucesso na tabela.`, {
                    variant: "success",
                });
                setIsLoadingSubmit(false)
            } catch (err) {
                enqueueSnackbar(`Ocorreu um erro ao importar passageiros. Verifique os dados e tente novamente mais tarde.`, {
                    variant: "error",
                });
                setIsLoadingSubmit(false)
            }
        }
    }

    const convertTipoDocumento = (tipoDocumento: PassageirosImportProps["tipoDocumento"]): TipoDocumento => {
        switch (tipoDocumento) {
            case 'RG':
                return TipoDocumento.Rg;
            case 'PASSAPORTE':
                return TipoDocumento.Passaporte;
            case 'CNH':
                return TipoDocumento.Cnh;
            case 'CERTIDÃO DE NASCIMENTO':
                return TipoDocumento.CertidaoNascimento;
            case 'CN':
                return TipoDocumento.CertidaoNascimento;
            default:
                return TipoDocumento.Rg;
        }
    }

    const convertTipoDocumentoTexto = (tipoDocumento: TipoDocumento): string => {
        switch (tipoDocumento) {
            case TipoDocumento.Rg:
                return 'RG';
            case TipoDocumento.Passaporte:
                return 'PASSAPORTE';
            case TipoDocumento.Cnh:
                return 'CNH';
            case TipoDocumento.CertidaoNascimento:
                return 'CERTIDÃO DE NASCIMENTO';
            default:
                return 'RG';
        }
    }

    const converterGmt = (data: Date) => {
        const dataCopy = new Date(data);
        dataCopy.setHours(dataCopy.getHours() - 3);
        return dataCopy;
    }

    const handleSenha = (event: any) => {
        const { value } = event.target;
        const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        const result = regex.test(value);
        setErroSenhaPadrao(!result);
        setSenhaPadrao(value);
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            border: "1px solid #ccc",
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        carregarOcorrencia(idOcorrencia);
        setIsLoading(false);
    }, []);

    return (
        <>
            <OptBackdrop open={isLoading} />
            <div className="col">
                <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                    <div className={styles.CabecalhoContainer}>
                        <OptActionButton
                            onClick={() => history.push(`/ocorrencias/ver/${idOcorrencia}`)}
                            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
                        >
                            <span className={styles.SpanText}>
                                Importar passageiros para o ponto de embarque: <b>{ocorrencia?.nomeEvento} - {ocorrencia && ocorrencia.data ? format(new Date(ocorrencia?.data.replace('Z', '')), 'dd/MM/yyyy') : '-'}</b>
                            </span>
                        </OptActionButton>
                    </div>
                    <Divider />
                    <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
                        <Grid className={styles.GridItem} item xs={3}>
                            <b>Início do evento: </b>{ocorrencia && ocorrencia.horaInicio ? format(converterGmt(new Date(ocorrencia?.horaInicio.replace('Z', ''))), 'HH:mm') : '-'}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={3}>
                            <b>Fim do evento: </b>{ocorrencia && ocorrencia.horaFim ? format(converterGmt(new Date(ocorrencia?.horaFim.replace('Z', ''))), 'HH:mm') : '-'}
                        </Grid>
                        <Grid className={styles.GridItem} item xs={3}>
                            <b>Chegada prevista: </b>{ocorrencia && ocorrencia.horaChegadaPrevista ? format(converterGmt(new Date(ocorrencia?.horaChegadaPrevista.replace('Z', ''))), 'HH:mm') : '-'}
                        </Grid>

                        <Grid className={`${styles.GridItem} mt-4`} item xs={12}>
                            <div className="d-flex justify-content-center mb-4">
                                <Button
                                    color="info"
                                    type="button"
                                    size="large"
                                    variant="contained"
                                    style={{ marginRight: '10px' }}
                                    className="mt-4"
                                    onClick={() => setShowModalAjuda(true)}
                                >
                                    <Icon path={mdiHelpCircle} size={1} color={Colors.white} style={{ marginRight: '4px' }} /> Ajuda
                                </Button>

                                <a className={`${styles.DownloadBtn} mt-4`} href="/planilha_padrao_importacao_passageiros.xlsx" target="_blank" download="planilha_padrao_importacao_passageiros">
                                    <Icon path={mdiDownload} size={1} color={Colors.white} /> Download da planilha de importação
                                </a>
                            </div>

                            <div className={`${styles.DropzonePassageiros} dropzone mt-3`} {...getRootProps()}>
                                <input {...getInputProps()} accept=".xls, .xlsx" />
                                <p className="mb-0">Arraste e solte alguns arquivos aqui ou clique para selecionar os arquivos</p>
                            </div>
                            {errosDropzone.length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    {errosDropzone.join(', ')}
                                </div>
                            )}
                        </Grid>

                        {
                            tableHeader.length > 0 &&
                            <Grid className={`${styles.GridItem} mt-5 p-0 text-center`} item xs={12}>
                                {
                                    usuariosImportados.length === 0 && usuariosRejeitados.length === 0 &&
                                    <h6 className="text-start"><b>Passageiros que serão importados:</b></h6>
                                }


                                {
                                    usuariosImportados.length === 0 && usuariosRejeitados.length === 0 &&
                                    <TableContainer>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        tableHeader.map((item: string) => (
                                                            <StyledTableCell align="left" key={item}>
                                                                {item}
                                                            </StyledTableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    passageiros.length === 0 &&
                                                    <StyledTableRow><StyledTableCell colSpan={7} align="center"> Não há registros a serem exibidos </StyledTableCell></StyledTableRow>
                                                }
                                                {
                                                    passageiros.map((passageiro, index) => (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell align="left" className={`col-1 ${passageiro.erroUsuario ? styles.BgError : ''}`}>{passageiro.erroUsuario ? 'Campo obrigatório' : passageiro.usuario}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-4 ${passageiro.erroNome ? styles.BgError : ''}`}>{passageiro.erroNome ? 'Campo obrigatório' : passageiro.nomeUsuario}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${passageiro.erroDdd ? styles.BgError : ''}`}>{passageiro.erroDdd ? 'Campo obrigatório' : passageiro.ddd}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-2 ${passageiro.erroTelefone ? styles.BgError : ''}`}>{passageiro.erroTelefone ? 'Campo obrigatório' : passageiro.telefone}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-2 ${passageiro.erroEmail ? styles.BgError : ''}`}>{passageiro.erroEmail ? 'Campo obrigatório' : passageiro.email}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${passageiro.erroTipoDocumento ? styles.BgError : ''}`}>{passageiro.erroTipoDocumento ? 'Campo obrigatório' : passageiro.tipoDocumento}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${passageiro.erroDocumento ? styles.BgError : ''}`}>{passageiro.erroDocumento ? 'Campo obrigatório' : passageiro.documento}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${passageiro.erroEstado ? styles.BgError : ''}`}>{passageiro.erroEstado ? 'Campo obrigatório' : passageiro.estado}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${passageiro.erroOrgaoExpeditor ? styles.BgError : ''}`}>{passageiro.erroOrgaoExpeditor ? 'Campo obrigatório' : passageiro.orgaoExpeditor}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1`}>{passageiro.passageiro ? 'Sim' : 'Não'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }

                                {
                                    usuariosImportados.length > 0 &&
                                    <TableContainer>
                                        <h6 className="text-start"><b>Usuários Importados:</b></h6>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left">Nome</StyledTableCell>
                                                    <StyledTableCell align="left">E-mail</StyledTableCell>
                                                    <StyledTableCell align="left">Telefone</StyledTableCell>
                                                    <StyledTableCell align="left">Tipo de Documento</StyledTableCell>
                                                    <StyledTableCell align="left">Documento</StyledTableCell>
                                                    <StyledTableCell align="left">É passageiro</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    usuariosImportados.map((passageiro, index) => (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell align="left" className={`col-2 ${styles.BgSuccess}`}>{passageiro.nome}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-2 ${styles.BgSuccess}`}>{passageiro.email}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-2 ${styles.BgSuccess}`}>{MASCARA_TELEFONE(passageiro.telefone)}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${styles.BgSuccess}`}>{convertTipoDocumentoTexto(passageiro.tipoDocumento)}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${styles.BgSuccess}`}>{passageiro.documento}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${styles.BgSuccess}`}>{passageiro.passageiro}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }

                                {
                                    usuariosRejeitados.length > 0 &&
                                    <TableContainer>
                                        <h6 className="text-start"><b>Usuários Rejeitados:</b></h6>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left">Nome</StyledTableCell>
                                                    <StyledTableCell align="left">E-mail</StyledTableCell>
                                                    <StyledTableCell align="left">Telefone</StyledTableCell>
                                                    <StyledTableCell align="left">Documento</StyledTableCell>
                                                    <StyledTableCell align="left">Motivo</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    usuariosRejeitados.map((passageiro, index) => (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell align="left" className={`col-2 ${styles.BgError}`}>{passageiro.nome}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-2 ${styles.BgError}`}>{passageiro.email}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-2 ${styles.BgError}`}>{MASCARA_TELEFONE(passageiro.telefone)}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-1 ${styles.BgError}`}>{passageiro.documento}</StyledTableCell>
                                                            <StyledTableCell align="left" className={`col-2 ${styles.BgError}`}>{passageiro.motivo}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }

                                {
                                    usuariosImportados.length === 0 && usuariosRejeitados.length === 0 &&
                                    <>
                                        <div className="text-start mt-4">
                                            <TextField
                                                className="text-start"
                                                label="Senha padrão"
                                                required={true}
                                                variant="outlined"
                                                onChange={handleSenha}
                                                value={senhaPadrao}
                                            />
                                            {
                                                senhaPadrao && erroSenhaPadrao &&
                                                <ErrorMessage message={'A senha deve ter ao menos 8 caracteres e conter letras maiúsculas e minúsculas, números e um caractere não alfanumérico.'} />
                                            }
                                            <ErrorMessage message={!senhaPadrao ? 'Campo obrigatório' : ''} />
                                        </div>

                                        {
                                            isLoadingSubmit ? (
                                                <Spinner />
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    size="large"
                                                    variant="contained"
                                                    className="mt-4 mb-3"
                                                    onClick={handleImportarPassageiros}
                                                    disabled={temErroNaPlanilha || erroSenhaPadrao || !senhaPadrao}
                                                >
                                                    <Icon path={mdiImport} size={1} color={Colors.white} style={{ marginRight: '4px' }} /> Importar Passageiros
                                                </Button>
                                            )
                                        }
                                    </>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </div>

            <OptDialog
                open={showModalAjuda}
                title={"Ajuda - Importação de passageiros"}
                onClose={() => setShowModalAjuda(false)}
            >
                <div className="p-3 text-start">
                    <h6>Passo a passo para realizar a importação dos passageiros</h6>
                    <ol>
                        <li>Caso não possua a planilha padrão, faça o download da mesma clicando no botão <b>"Download da planilha de importação"</b></li>
                        <li>Abra a planilha padrão e preencha as informações nas colunas correspondentes</li>
                        <li>Salve a planilha e envie o arquivo na zona de arquivos <b>"Arraste e solte alguns arquivos aqui ou clique para selecionar os arquivos"</b></li>
                        <li>Confira as informações preenchidas na tabela que aparecerá após a inserção do arquivo</li>
                        <li>Clique no botão <b>"Importar passageiros"</b></li>
                    </ol>
                </div>
                <div>
                    <Button
                        color="primary"
                        type="button"
                        size="large"
                        variant="contained"
                        className="mt-4 mb-3"
                        onClick={() => setShowModalAjuda(false)}
                    >
                        <Icon path={mdiImport} size={1} color={Colors.white} style={{ marginRight: '4px' }} /> OK, entendi
                    </Button>
                </div>
            </OptDialog>
        </>
    );
};