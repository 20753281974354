import { useHistory, useParams } from "react-router-dom";
import { mdiSend, mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, FormControlLabel, Switch, Tooltip } from "@mui/material";
import {
  OptActionButton,
  OptActionToolbar,
  OptBackdrop,
  OptGridRef,
  OptGridRequest,
  OptSelectionOption,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { GridBagagesView } from "./GridBagagesView";
import styles from "./styles/gridSms.module.scss";
import AsyncSelect from "react-select/async";
import { INIT_SMS_FORM_DATA } from "../../../constantes/SmsSubmitModel";
import { useSmsService } from "../../../servicos/sms.service";
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";

import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { format } from "date-fns";
import Select from "react-select";
import { useBagagensService } from "../../../servicos/bagagens.service";
import { formatarHora } from "../../../utils/claimUtils";

export const GridBagages = () => {
  const history = useHistory();
  const { idEvento, idOcorrencia, idPontoEmbarque } = useParams<{ idEvento?: string, idOcorrencia?: string, idPontoEmbarque?: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [eventoSelected, setEventoSelected] = useState<OptSelectionOption>();
  const [ocorrenciaSelected, setOcorrenciaSelected] = useState<OptSelectionOption | null>(null);
  const [pontoEmbarqueSelected, setPontoEmbarqueSelected] = useState<OptSelectionOption>();
  const [passageiroSelected, setPassageiroSelected] = useState<OptSelectionOption>();

  const [optionsEvento, setOptionsEvento] = useState<OptSelectionOption[]>([]);
  const [optionsOcorrencia, setOptionsOcorrencia] = useState<OptSelectionOption[]>([]);
  const [optionsPontoEmbarque, setOptionsPontoEmbarque] = useState<OptSelectionOption[]>([]);
  const [optionsPassageiros, setOptionsPassageiros] = useState<OptSelectionOption[]>([]);

  const [data, setData] = useState<any[]>([]);
  const [selecoesDoGrid, setSelecoesDoGrid] = useState(INIT_SMS_FORM_DATA);
  const [eventosCarregados, setEventosCarregados] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { obterListaBagagens } = useBagagensService();
  const { carregarEventos } = useEventosService();
  const { obterPassageiros } = useSmsService();
  const { carregarOcorrenciasPorEvento, carregarOcorrenciaPorId } = useOcorrenciasService();

  const gridRef = useRef<OptGridRef>(null);

  function recarregarGrid() {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
    setLoading(false);
  }

  // Listar opções para EventosSelect
  async function obterSelectEventos() {
    if(!eventosCarregados){
      let data: any = []

      await carregarEventos().then((response: any) => {
        response.map((x: any) => {
          if (idEvento === x.id) {
            setEventoSelected({
              value: x.id,
              label: `${x.nome}`,
            })
  
            obterSelectOcorrecias(x.id)
          }
  
          data.push(
            {
              value: x.id,
              label: `${x.nome}`,
            }
          )
        });
      })
  
      setOptionsEvento(data);
      setEventosCarregados(true);
    }
  }

  // Listar opções para OcorrenciasSelect
  async function obterSelectOcorrecias(idEvento: string) {
    if (idEvento) {
      let data: any[] = []

      await carregarOcorrenciasPorEvento(idEvento).then((response: any) => {
        response.sort((a: any, b: any) => (new Date(a.data.replace('Z', ''))).getTime() - (new Date(b.data.replace('Z', ''))).getTime()).map((x: any) => {
          if (idOcorrencia === x.id) {
            setOcorrenciaSelected({
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            })
          }

          data.push(
            {
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            }
          )
        });
      })

      setOptionsOcorrencia(data)
    }
  }

  // Listar opções para PontosEmbarqueSelect
  async function obterSelectPontos(idOcorrencia: string) {
    let arr: any = []
    let pontoSelecionado: any = undefined

    optionsOcorrencia.map((ocorrencia) => {
      if (ocorrencia.value === idOcorrencia) {
        carregarOcorrenciaPorId(idOcorrencia).then((response: any) => {
          response.pontosRota.map((x: any) => {
            if (idPontoEmbarque === x.id) {
              pontoSelecionado = {
                value: x.id,
                label: x.nome,
              }
            }

            arr.push(
              {
                value: x.id,
                label: `${x.nome} ${formatarHora(x)}`,
              }
            )
          })
        }).finally(() => {
          setOptionsPontoEmbarque(arr)
          setPontoEmbarqueSelected(pontoSelecionado)
        })
      }
    })
  }

  // Listar opções para PassageirosSelect
  async function obterOptionsPassageiros(pontoEmbarque: OptSelectionOption) {
    if (eventoSelected && ocorrenciaSelected) {
      let arr: any = []
  
      let params = {
        idEvento: eventoSelected?.value,
        idOcorrencia: ocorrenciaSelected?.value,
        idsPontosEmbarque: [pontoEmbarque.value]
      }
  
      obterPassageiros(params).then((response) => {
        response.map((x: any) => {  
          arr.push(
            {
              value: x.id,
              label: x.usuario.nome,
            }
          )
        });
      })
  
      setOptionsPassageiros(arr)
    }
  }

  useEffect(() => {
    if (ocorrenciaSelected) {
      obterSelectPontos(ocorrenciaSelected.value)
    }
  }, [ocorrenciaSelected]);

  useEffect(() => {
    obterSelectEventos();
  }, [optionsEvento]);

  useEffect(() => {
    if (pontoEmbarqueSelected) {
      obterOptionsPassageiros(pontoEmbarqueSelected)
    }
  }, [pontoEmbarqueSelected]);

  useEffect(() => {
    setLoading(true);
    if (pontoEmbarqueSelected && ocorrenciaSelected && eventoSelected && passageiroSelected && eventoSelected.value.length && ocorrenciaSelected.value.length && pontoEmbarqueSelected.value.length && passageiroSelected.value.length) {
      let params = {
        idEvento: eventoSelected.value,
        idOcorrencia: ocorrenciaSelected.value,
        idPontoEmbarque: pontoEmbarqueSelected.value,
        idPassageiro: pontoEmbarqueSelected.value,
      }

      obterListaBagagens(params).then((response) => {
        setData(response.lista)
        recarregarGrid();
        return
      })
    } else if (pontoEmbarqueSelected && ocorrenciaSelected && eventoSelected && eventoSelected.value.length && ocorrenciaSelected.value.length && pontoEmbarqueSelected.value.length) {
      let params = {
        idEvento: eventoSelected.value,
        idOcorrencia: ocorrenciaSelected.value,
        idPontoEmbarque: pontoEmbarqueSelected.value
      }

      obterListaBagagens(params).then((response) => {
        setData(response.lista)
        recarregarGrid();
        return
      })
    } else if (eventoSelected && ocorrenciaSelected && eventoSelected.value.length && ocorrenciaSelected.value.length) {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        idsPassageiros: [],
      });

      let params = {
        idEvento: eventoSelected.value,
        idOcorrencia: ocorrenciaSelected.value
      }

      obterListaBagagens(params).then((response) => {
        setData(response.lista)
        recarregarGrid();
        return
      })
    } else if (eventoSelected && eventoSelected.value.length) {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        idsPassageiros: [],
      });

      let params = {
        idEvento: eventoSelected.value
      }

      obterListaBagagens(params).then((response) => {
        setData(response.lista)
        recarregarGrid();
        return
      })
    }
    else{
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventoSelected, ocorrenciaSelected, pontoEmbarqueSelected, passageiroSelected]);

  return (
    <>
      <Box width={'98%'} flexWrap={'wrap'}>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={() => history.goBack()}
            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              Relatório de Bagagens
            </span>
          </OptActionButton>
        </div>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <div className={styles.ButtonSection}>
            <Select className={styles.StyledAsyncSelect}
              placeholder="Eventos"
              noOptionsMessage={() => "Sem opções pré-definidas"}
              value={eventoSelected}
              options={optionsEvento}
              onChange={(e: any) => {
                setEventoSelected(e);
                setOcorrenciaSelected(null);
                setPontoEmbarqueSelected(undefined);
                setPassageiroSelected(undefined);
                obterSelectOcorrecias(e.value);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelect}
              placeholder="Ocorrência"
              noOptionsMessage={() => "Nenhuma ocorrência encontrada"}
              isDisabled={!eventoSelected?.value.length ? true : false}
              value={ocorrenciaSelected}
              options={optionsOcorrencia}
              isClearable
              onChange={(e: any) => {
                setOcorrenciaSelected(e);
                setPontoEmbarqueSelected(undefined);
                setPassageiroSelected(undefined);
                obterSelectPontos(e.value);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelectPontoEmbarque}
              placeholder="Pontos de Embarque"
              noOptionsMessage={() => "Nenhum ponto de embarque encontrado"}
              isDisabled={!ocorrenciaSelected?.value.length ? true : false}
              isClearable
              value={pontoEmbarqueSelected}
              options={optionsPontoEmbarque}
              onChange={(e: any) => {
                setPassageiroSelected(undefined);
                setPontoEmbarqueSelected(e as OptSelectionOption);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelect}
              placeholder="Passageiros"
              noOptionsMessage={() => "Nenhum passageiro encontrado"}
              isDisabled={!pontoEmbarqueSelected?.value.length ? true : false}
              value={passageiroSelected}
              options={optionsPassageiros}
              isClearable
              onChange={(e: any) => {
                setPassageiroSelected(e as OptSelectionOption);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />
          </div>
        </Box>

        <GridBagagesView
          ref={gridRef}
          data={data}
        />
      </Box>
    </>
  );
};
