import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { VeiculoSearchResponse } from "../modelos/dtos/VeiculoSearchResponse";
import { PaginatedVeiculoRequest } from "../modelos/search/PaginatedVeiculoRequest";
import { VeiculoFormModel } from "../modelos/veiculo/veiculoFormModel";
import { protectedResources } from "../authConfig";

export const useVeiculosService = () => {
  const { get, post, remove, put, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Veiculo`;

  const salvarVeiculo = async (data: VeiculoFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerVeiculo = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarVeiculo = async (itemId: string, data: VeiculoFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridVeiculosPaginated = async (data: SearchRequest<PaginatedVeiculoRequest>, idEmpresa: any) => {
    return gridPagedListSearch<VeiculoSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro, idEmpresa});
  };

  const carregarVeiculoPorId = async (id :string) => {
    return get<VeiculoFormModel>(`${baseApi}/${id}`);
  };

  const carregarVeiculos = async () => {
    return get<VeiculoFormModel>(`${baseApi}/todos`);
  };

  return {
    salvarVeiculo,
    removerVeiculo,
    editarVeiculo,
    buscarGridVeiculosPaginated,
    carregarVeiculoPorId,
    carregarVeiculos
  };
};
