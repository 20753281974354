import { mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptGridRef,
  OptGridRequest
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { SearchRequest } from "../../../compartilhado/types/SearchRequest";
import { FiltroBusca } from "../../../componentes/filtro";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../../componentes/optSideLayoutContentCustom/OptSideLayoutContentCustom";
import { ROTA_FORM_INITIAL_STATE } from "../../../constantes/RotaFormInitialState";
import { TipoInput } from "../../../enums/tipoInput";
import { RotaSearchResponse } from "../../../modelos/dtos/RotaSearchResponse";
import { RotaFormModel } from "../../../modelos/rota/rotaFormModel";
import { RotaModel } from "../../../modelos/rota/rotaModel";
import { PaginatedRotaRequest } from "../../../modelos/search/PaginatedRotaRequest";
import { PaginatedSearchRequest } from "../../../modelos/search/PaginatedSearchRequest";
import { Rotas } from "../../../rotas";
import { useRotasService } from "../../../servicos/rotas.service";
import styles from "./styles/gridRotas.module.scss";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

export const GridRotas = () => {
  const [filtro, setFiltro] = useState<RotaFormModel>();


  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] =
    useState<boolean>(false);

  const [item, setItem] = useState<RotaFormModel>(ROTA_FORM_INITIAL_STATE);

  const { buscarGridRotasPaginated, removerRota } =
    useRotasService();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const opcoesFiltro = [
    { texto: "Nome", valor: 'nome', tipoInput: TipoInput.Texto}
  ];

  function pesquisaComFiltro(filtro: RotaFormModel) {
    setFiltro(filtro);
  }

  const carregar = async (
    query: OptGridRequest,
    itemSearchRequest: PaginatedSearchRequest = {
      filtro: filtro,
    }
  ) => {
    const request: SearchRequest<PaginatedRotaRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      filtro: filtro,
    };
    return buscarGridRotasPaginated(request);
  };

  function onRowClick(rowData?: RotaModel) {
  }

  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerRota(item.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setItem(ROTA_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }

  async function onClickDeleteItem(rowData: RotaSearchResponse) {
    setItem(rowData);
    setConfirmandoExclusao(true);
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  function cadastrarRota() {
    history.push("/rotas/cadastro/");
  }

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, [filtro]);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir rota"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão da rota ? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>

      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Rotas.Home}
          title="Rota"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <OptGridContainer>
          <FiltroBusca opcoes={opcoesFiltro} pesquisa={pesquisaComFiltro} />
          <div className={styles.ButtonSection}>
            <Tooltip title={"Cadastrar rota"}>
              <Button
                variant="contained"
                color="info"
                style={{ marginRight: "5px", marginBottom: "11px" }}
                placeholder="Cadastrar rota"
                size="large"
                onClick={cadastrarRota}
              >
                Cadastrar Rota <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
              </Button>
            </Tooltip>
          </div>
        </OptGridContainer>
      </OptSideLayoutContentCustom>
    </>
  );
};
