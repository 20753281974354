import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { MASCARA_NUMEROS } from "../../../constantes/mascaras/MascaraNumeros";
import { VEICULO_FORM_INITIAL_STATE } from "../../../constantes/VeiculoFormInitialState";
import { EmpresaFormModel } from "../../../modelos/empresa/empresaFormModel";
import { VeiculoFormModel } from "../../../modelos/veiculo/veiculoFormModel";
import { useEmpresasService } from "../../../servicos/empresas.service";
import { useVeiculosService } from "../../../servicos/veiculos.service";
import { CadastroFrotaSchema } from "./CadastroFrota.validation";
import styles from "./Styles/CadastroFrota.module.scss";
import { IGeneric } from "../../empresa/Cadastro/FormularioEmpresa";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";

export const CadastroFrota = ({
  isFromCadastroEmpresa,
  handleUpdateGrid,
  nomeEmpresa,
  idEmpresa,
  idFrota,
}: IGeneric) => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setSalvarLoading] = useState<boolean>(false);
  const [veiculo, setVeiculo] = useState<VeiculoFormModel>(VEICULO_FORM_INITIAL_STATE);
  const [empresas, setEmpresas] = useState<Array<any>>(new Array<any>());
  const { salvarVeiculo, editarVeiculo, carregarVeiculoPorId } = useVeiculosService();
  const { carregarEmpresas } = useEmpresasService();
  const { verificarAutorizacao } = useEventosService();

  const optionsTipoVeiculo = [
    "Executivo",
    "Micro-ônibus",
    "Van",
    "DD",
    "Semi-Leito",
    "Leito/Semi-Leito",
    "Leito-Total",
  ]

  useEffect(() => {
    carregarComboEmpresas();
    if (idFrota) {
      carregarVeiculo(idFrota);
    }
  }, [idFrota]);

  useEffect(() => {
    setVeiculo({
      ...veiculo,
      idEmpresa: idEmpresa,
    });
    setValue("idEmpresa", idEmpresa);
  }, [idEmpresa]);

  async function carregarVeiculo(id: string) {
    const response = (await carregarVeiculoPorId(id)) as unknown as VeiculoFormModel;
    if (response) {
      setValue("id", response.id);
      setValue("placa", response.placa);
      setValue("numeroAssentos", response.numeroAssentos);
      setValue("ano", response.ano);
      setValue("idEmpresa", response.idEmpresa);

      if (
        optionsTipoVeiculo.indexOf(response.tipo) !== -1
      )
        setValue("tipo", response.tipo);

      setVeiculo(response);
    }
  }

  async function carregarComboEmpresas() {
    const response =
      (await carregarEmpresas()) as unknown as Array<EmpresaFormModel>;
    if (response) {
      setEmpresas(response);
    }
  }

  function handlePlaca(event: any) {
    setVeiculo((veiculoAtual) => ({
      ...veiculoAtual,
      placa: event?.target.value,
    }));
    setValue("placa", event.target.value);
  }

  function handleTipo(value: any) {
    setVeiculo((veiculoAtual) => ({
      ...veiculoAtual,
      tipo: value,
    }));
    setValue("tipo", value);
  }

  function handleNumerosAssentos(event: any) {
    const value = Number(MASCARA_NUMEROS(event?.target.value));
    setVeiculo((veiculoAtual) => ({
      ...veiculoAtual,
      numeroAssentos: value,
    }));
    setValue("numeroAssentos", value);
  }

  function handleAno(event: any) {
    const value = Number(MASCARA_NUMEROS(event?.target.value));
    setVeiculo((veiculoAtual) => ({
      ...veiculoAtual,
      ano: value,
    }));
    setValue("ano", value);
  }

  const limpeza = () => {
    setVeiculo((veiculoAtual) => ({
      ...veiculoAtual,
      placa: "",
      tipo: "Executivo",
      numeroAssentos: 0,
      ano: 0,
      id: "",
    }));
  };

  function handleEmpresa(value: any) {
    setVeiculo((veiculoAtual) => ({
      ...veiculoAtual,
      idEmpresa: value,
    }));
    setValue("idEmpresa", value);
  }

  async function salvar(value: VeiculoFormModel) {
    setSalvarLoading(true);
    try {
      const estaEditando = !!value.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (idFrota) {
        await editarVeiculo(value.id!, value);
      } else {
        await salvarVeiculo(value);
      }

      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      handleUpdateGrid(true);
      limpeza();
    } catch {
      console.log("erro");
    } finally {
      setSalvarLoading(false);
      handleUpdateGrid(false);
    }
  }

  const onClose = () => {
    history.goBack();
  };

  const empresaForm = useForm<VeiculoFormModel>({
    resolver: yupResolver(CadastroFrotaSchema),
  });

  useEffect(() => {
    (async () => {
      verificarAutorizacao([
        PerfilUsuario.Administrador,
        PerfilUsuario.Operacao,
      ]);
    })();
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = empresaForm;

  return (
    <form>
      <OptBackdrop open={loading} />
      {!isFromCadastroEmpresa && (
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              {idFrota ? "Atualizar" : "Cadastro de veículo"}
            </span>
          </OptActionButton>
        </div>
      )}
      <Divider />
      <Grid
        className={styles.GridContent}
        container
        rowSpacing={1}
        columns={12}
        px={1.5}
      >
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="placa"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Placa"
                required={true}
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                onChange={handlePlaca}
                value={veiculo.placa}
              />
            )}
          />
          <ErrorMessage error={errors.placa} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="tipo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id='select-label-tipo'
                className={styles.TextFieldCustom}
                options={optionsTipoVeiculo}
                getOptionLabel={(option: any) => option}
                onChange={(e, value) => handleTipo(value)}
                defaultValue={veiculo.tipo}
                renderInput={(params) => <TextField {...params} label="Tipo do veículo" required />}
              />
            )}
          />
          <ErrorMessage error={errors.tipo} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="numeroAssentos"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Número de Assentos"
                required={true}
                variant="outlined"
                inputProps={{ maxLength: 3 }}
                onChange={handleNumerosAssentos}
                value={veiculo.numeroAssentos}
              />
            )}
          />
          <ErrorMessage error={errors.numeroAssentos} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="ano"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Ano"
                required={false}
                variant="outlined"
                inputProps={{ maxLength: 4 }}
                onChange={handleAno}
                value={veiculo.ano || ""}
              />
            )}
          />
          <ErrorMessage error={errors.ano} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="idEmpresa"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ minWidth: 120, width: 1 }} size="medium">
                <Autocomplete
                  disabled
                  id='select-label-empresa'
                  className={styles.TextFieldCustom}
                  options={empresas}
                  getOptionLabel={(option: any) => option.nome || nomeEmpresa}
                  onChange={(e, value) => handleEmpresa(value.id)}
                  defaultValue={idEmpresa}
                  renderInput={(params) => <TextField {...params} label="Empresa" required />}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.idEmpresa} />
        </Grid>
      </Grid>
      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        {!isFromCadastroEmpresa && (
          <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
            Cancelar
          </Button>
        )}
        <Button
          size="large"
          onClick={handleSubmit(salvar)}
          sx={sxButtonSaveConfig}
        >
          {idFrota ? "Atualizar" : "Cadastrar frota"}
        </Button>
      </FooterToolbar>
    </form>
  );
};
