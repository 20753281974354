import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { Button, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { StyledTableCell, StyledTableRow } from "../../styles"
import { useSnackbar } from "notistack"
import Icon from "@mdi/react"
import { mdiContentSave } from "@mdi/js";

import { PassageiroTableRow } from "../PassageiroTableRow"
import { CORES_STATUS_PASSAGEIRO } from "../../associaPassageiros"
import { Colors } from "../../../../../compartilhado/colors"
import { useRotasService } from "../../../../../servicos/rotas.service"

export const TablePassageiros = ({ nomePontoEmbarque, idPontoEmbarque, passageirosPontoEmbarque, idRota }: any) => {
    const { enqueueSnackbar } = useSnackbar();
    const { associarPassageiros } = useRotasService();

    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [passageiros, setPassageiros] = useState<any[]>([]);

    function embarcarTodos() {
        setLoadingSubmit(true)

        let arr = passageiros;

        arr.map((passageiro) => {
            if (passageiro.corOriginal === CORES_STATUS_PASSAGEIRO.branco) {
                passageiro.corAtual = CORES_STATUS_PASSAGEIRO.verde
            }
        })

        setPassageiros(arr)

        setTimeout(() => {
            setLoadingSubmit(false)
        }, 300)
    }

    function desembarcarTodos() {
        setLoadingSubmit(true)

        let arr = passageiros;

        arr.map((passageiro) => {
            if (passageiro.corOriginal === CORES_STATUS_PASSAGEIRO.azul) {
                passageiro.corAtual = CORES_STATUS_PASSAGEIRO.vermelho
            }
        })

        setPassageiros(arr)

        setTimeout(() => {
            setLoadingSubmit(false)
        }, 300)
    }

    function desfazerEmbarquesEDesembarques() {
        setLoadingSubmit(true)

        let arr = passageiros;

        arr.map((passageiro) => {
            passageiro.corAtual = passageiro.corOriginal
        })

        setPassageiros(arr)

        setTimeout(() => {
            setLoadingSubmit(false)
        }, 300)
    }

    function updatePassageiros(index: number, cor: number) {
        let arr = passageiros;
        arr[index].corAtual = cor;

        setPassageiros(arr)
    }

    function updateOriginalColor(corAtual: number) {
        switch (corAtual) {
            case CORES_STATUS_PASSAGEIRO.branco:
                return CORES_STATUS_PASSAGEIRO.branco
            case CORES_STATUS_PASSAGEIRO.verde:
                return CORES_STATUS_PASSAGEIRO.azul
            case CORES_STATUS_PASSAGEIRO.azul:
                return CORES_STATUS_PASSAGEIRO.azul
            case CORES_STATUS_PASSAGEIRO.vermelho:
                return CORES_STATUS_PASSAGEIRO.branco
            case CORES_STATUS_PASSAGEIRO.cinza:
                return CORES_STATUS_PASSAGEIRO.cinza
            case CORES_STATUS_PASSAGEIRO.amarelo:
                return CORES_STATUS_PASSAGEIRO.azul
            default:
                return CORES_STATUS_PASSAGEIRO.branco
        }
    }

    async function salvarAlteracoes() {
        setLoadingSubmit(true)
        let arr = passageiros
        let idsPassageiros: Array<string> = []

        arr.map((passageiro) => {
            if (passageiro.corAtual === CORES_STATUS_PASSAGEIRO.verde || passageiro.corAtual === CORES_STATUS_PASSAGEIRO.azul || passageiro.corAtual === CORES_STATUS_PASSAGEIRO.amarelo) {
                idsPassageiros.push(passageiro.id)
            }
            passageiro.corOriginal = updateOriginalColor(passageiro.corAtual)
            passageiro.corAtual = updateOriginalColor(passageiro.corAtual)
        })

        let params = {
            idRota: idRota,
            idPontoEmbarque: idPontoEmbarque,
            idsPassageiros: idsPassageiros
        }

        associarPassageiros(params).then((passageiro) => {
            setPassageiros(arr);
            enqueueSnackbar(`Alterações salvas com sucesso!`, {
                variant: "success",
            });
        }).finally(() => {
            setTimeout(() => {
                setLoadingSubmit(false)
            }, 1000)
        })
    }

    useEffect(() => {
        setPassageiros(passageirosPontoEmbarque)
    }, [])

    return (
        <Grid className="mt-4 card-ponto-embarque" container rowSpacing={1} columns={12} px={1.5}>
            <h5><b>{nomePontoEmbarque}</b></h5>
            <Grid className={`text-center`} item xs={12} marginBottom={2}>
                <Button
                    className="float-start"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "5px" }}
                    onClick={embarcarTodos}
                >
                    Embarcar todos
                </Button>
                <Button
                    className="float-start"
                    variant="contained"
                    color="error"
                    onClick={desembarcarTodos}
                >
                    Desembarcar todos
                </Button>
                <Button
                    className="float-end"
                    variant="contained"
                    color="inherit"
                    onClick={desfazerEmbarquesEDesembarques}
                >
                    Desfazer alterações
                </Button>
            </Grid>

            {
                loadingSubmit ? (
                    <div className="mt-5 text-center w-100">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" className="col-4">Passageiro</StyledTableCell>
                                        <StyledTableCell align="left" className="col-4">Número do passageiro</StyledTableCell>
                                        <StyledTableCell align="left" className="col-3">Status da reserva</StyledTableCell>
                                        <StyledTableCell align="center" className="col-1">Ações</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        passageiros?.length > 0 && passageiros.map((passageiro, index) => (
                                            <PassageiroTableRow
                                                indexPassageiro={index}
                                                passageiro={passageiro}
                                                corOriginal={passageiro.corOriginal}
                                                corAtual={passageiro.corAtual}
                                                updatePassageiros={updatePassageiros}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button
                            className="float-start mt-4"
                            variant="contained"
                            color="success"
                            onClick={salvarAlteracoes}
                        >
                            Salvar alterações <Icon path={mdiContentSave} size={1} color={Colors.green1} />
                        </Button>
                    </>
                )
            }
        </Grid>
    )
}