import { UsuarioLoginResponse } from "../modelos/dtos/UsuarioLoginResponse";

export const useUsuarioLocalStorageService = () => {
  const obterToken = (): string => {
    const usuario = localStorage.getItem("usuario");
    const usuarioParsed: UsuarioLoginResponse = usuario
      ? JSON.parse(usuario)
      : null;

    return usuarioParsed?.token;
  };

  const salvarDadosCache = (data: UsuarioLoginResponse | null) => {
    data ? localStorage.setItem("usuario", JSON.stringify(data)) : localStorage.setItem("usuario", '');
  };

  const obterUsuario = (): UsuarioLoginResponse => {
    const usuario = localStorage.getItem("usuario");
    const usuarioParsed: UsuarioLoginResponse = usuario
      ? JSON.parse(usuario)
      : null;

    return usuarioParsed;
  };

  return {
    obterToken,
    salvarDadosCache,
    obterUsuario
  };
};
