import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft, mdiEye, mdiEyeOff } from "@mdi/js";
import { Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import Icon from "@mdi/react";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { MASCARA_CPF } from "../../../constantes/mascaras/MascaraCpf";
import { MASCARA_TELEFONE } from "../../../constantes/mascaras/MascaraTelefone";
import { PESSOA_FORM_INITIAL_STATE } from "../../../constantes/PessoaFormInitialState";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";
import { Rotas } from "../../../rotas";
import { usePessoasService } from "../../../servicos/pessoas.service";
import { CadastroPessoaSchema } from "./CadastroPessoa.validation";
import styles from "./Styles/cadastroPessoa.module.scss";
import { TipoDocumento } from "../../../enums/tipoDocumento";
import { useEventosService } from "../../../servicos/eventos.service";
import { TipoSexo } from "../../../enums/tipoSexo";
import { ListaTiposSexo } from "../../../constantes/ListaTiposSexo";

export const CadastroPessoa = () => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const { idPessoa } = useParams<{ idPessoa?: string }>();
  const [loading, setSalvarLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { cadastrarNovoUsuarioBackoffice, editarPessoa, carregarPessoaPorId } = usePessoasService();
  const [pessoa, setPessoa] = useState<PessoaFormModel>({ ...PESSOA_FORM_INITIAL_STATE, perfil: undefined });
  const [listaPerfil, setListaPerfil] = useState(new Array<any>());
  const [listaTipoDocumento, setListaTipoDocumento] = useState(new Array<any>());
  const [listaTipoSexo, setListaTipoSexo] = useState(new Array<any>());
  const [senha, setSenha] = useState("");
  const [erroSenha, setErroSenha] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const edicao = Boolean(idPessoa);
  const history = useHistory();
  const { obterUsuarioLogado } = useEventosService();
  const { verificarAutorizacao } = useEventosService();

  function handleNome(event: any) {
    setPessoa({
      ...pessoa,
      nome: event?.target.value
    });
    setValue('nome', event.target.value);
  }

  function handleCpf(event: any) {
    const value = MASCARA_CPF(event.target.value);
    setPessoa({
      ...pessoa,
      cpf: value
    });
    setValue('cpf', value);
  }

  function handleTelefone(event: any) {
    const value = MASCARA_TELEFONE(event.target.value);
    setPessoa({
      ...pessoa,
      telefone: value
    });
    setValue('telefone', value);
  }

  function handleEmail(event: any) {
    setPessoa({
      ...pessoa,
      email: event.target.value
    });
    setValue('email', event.target.value);
  }

  function handleDocumento(event: any) {
    setPessoa({
      ...pessoa,
      documento: event.target.value
    });
    setValue('documento', event.target.value);
  }

  function handleTipoDocumento(value: any) {
    setPessoa({
      ...pessoa,
      tipoDocumento: value
    });
    setValue('tipoDocumento', value);
  }

  function handleTipoSexo(value: any) {
    setPessoa({
      ...pessoa,
      sexo: value
    });
    setValue('sexo', value);
  }

  function handlePerfil(value: any) {
    const perfil = value;
    setValue('perfil', perfil);
  }

  const handleSenha = (event: any) => {
    const { value } = event.target;
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    const result = regex.test(value);
    setErroSenha(!result);
    setSenha(value);
  }

  async function carregarPerfis() {
    let usuarioLogado = await obterUsuarioLogado() as any;
    let perfis = [];
    if (usuarioLogado.perfil == PerfilUsuario.Administrador)
      perfis.push('Administrador')
    if (usuarioLogado.perfil == PerfilUsuario.Administrador || usuarioLogado.perfil == PerfilUsuario.Operacao)
      perfis = [...perfis, 'Operacao', 'Gerente']
    perfis.push('Consultor')
    if (usuarioLogado.perfil == PerfilUsuario.Cliente) {
      perfis.push('Cliente')
    }
    if (idPessoa) {
      perfis.push('Motorista')
    }
    perfis.push('Sistema')
    perfis.push('Promotor')


    setListaPerfil(perfis);
  }

  function carregarTiposDeDocumento() {
    const tiposDocumentos = Object.keys(TipoDocumento).filter((tipoDocumento) => {
      return isNaN(Number(tipoDocumento));
    });
    setListaTipoDocumento(tiposDocumentos);
  }

  function carregarTiposSexo() {
    const tiposSexo = Object.keys(TipoSexo).filter((sexo) => {
      return isNaN(Number(sexo));
    });
    setListaTipoSexo(tiposSexo);
  }

  async function carregarPessoa(idPessoa: string) {
    const response = await carregarPessoaPorId(idPessoa) as unknown as PessoaFormModel;
    if (response) {
      setPessoa(response);
      setValue('id', response.id);
      setValue('nome', response.nome);
      setValue('cpf', response.cpf);
      setValue('telefone', response.telefone);
      setValue('email', response.email);
      setValue('tipoDocumento', response.tipoDocumento || undefined);
      setValue('sexo', response.sexo || undefined);
      setValue('documento', response.documento || undefined);
      setValue('perfil', response.perfil);
      setValue('maiorDeIdade', response.maiorDeIdade);
    }
  }

  async function salvar() {
    setSalvarLoading(true);
    try {
      const estaEditando = !!pessoa.id;
      const operacao = estaEditando ? "editado" : "criado";
      let tmp_pessoa = pessoa
      tmp_pessoa.perfil = getValues('perfil')
      if (edicao) {
        await editarPessoa(pessoa.id!, tmp_pessoa);
      } else {
        await cadastrarNovoUsuarioBackoffice({
          dadosUsuario: tmp_pessoa,
          senha: senha
        })
      }

      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      onClose();
    } catch {
      console.log('erro')
    } finally {
      setSalvarLoading(false);
    }
  }

  const onClose = () => { history.push(Rotas.Usuarios.Principal); };
  const pessoaForm = useForm<PessoaFormModel>({ resolver: yupResolver(CadastroPessoaSchema) });
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = pessoaForm;

  useEffect(() => {
    setSalvarLoading(true);
    (async () => {
      await verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente]);
      await carregarPerfis();
      await carregarTiposDeDocumento();
      await carregarTiposSexo();
      if (idPessoa) {
        await carregarPessoa(idPessoa);
      }
      setSalvarLoading(false);
    })()
  }, []);

  return (
    <form>
      {
        loading ? (
          <OptBackdrop open={loading} />
        ) : (
          <>
            <div className={styles.CabecalhoContainer}>
              <OptActionButton
                onClick={onClose}
                startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
              >
                <span className={styles.SpanText}>
                  {edicao ? "Editar usuário" : "Cadastro de usuário"}
                </span>
              </OptActionButton>
            </div>
            <Divider />
            <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
              <Grid className={styles.GridItem} item xs={2}>
                <Controller
                  name="perfil"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <Autocomplete
                        id='select-label-perfil'
                        className={styles.TextFieldCustom}
                        options={listaPerfil}
                        disabled={pessoa.perfil === PerfilUsuario.Cliente}
                        getOptionLabel={(option: any) => option}
                        onChange={(e, value) => handlePerfil(PerfilUsuario[value])}
                        defaultValue={() => (Object.entries(PerfilUsuario).find(([_, v]) => v === pessoa.perfil) || [])[0] as string}
                        renderInput={(params) => <TextField {...params} label="Perfil" required />}
                      />
                    </FormControl>
                  )}
                />
                <ErrorMessage error={errors.perfil} />
              </Grid>
              <Grid className={styles.GridItem} item xs={5}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={styles.TextFieldCustom}
                      required={true}
                      label="Nome"
                      variant="outlined"
                      onChange={handleNome}
                      value={pessoa.nome}
                    />
                  )}
                />
                <ErrorMessage error={errors.nome} />
              </Grid>

              <Grid className={styles.GridItem} item xs={5}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={styles.TextFieldCustom}
                      required={true}
                      label="E-mail"
                      variant="outlined"
                      onChange={handleEmail}
                      value={pessoa.email}
                    />
                  )}
                />
                <ErrorMessage error={errors.email} />
              </Grid>
              <Grid className={styles.GridItem} item xs={2}>
                <Controller
                  name="tipoDocumento"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <Autocomplete
                        id='select-label-tipo-documento'
                        className={styles.TextFieldCustom}
                        options={listaTipoDocumento}
                        getOptionLabel={(option: any) => option}
                        onChange={(e, value) => handleTipoDocumento(TipoDocumento[value])}
                        defaultValue={() => (Object.entries(TipoDocumento).find(([_, v]) => v === pessoa.tipoDocumento) || [])[0] as string}
                        renderInput={(params) => <TextField {...params} label="Tipo de Documento" />}
                      />
                    </FormControl>
                  )}
                />
                <ErrorMessage error={errors.documento} />
              </Grid>

              <Grid className={styles.GridItem} item xs={5}>
                <Controller
                  name="documento"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={styles.TextFieldCustom}
                      label="Documento"
                      required={pessoa.tipoDocumento && pessoa.tipoDocumento > 0}
                      variant="outlined"
                      onChange={handleDocumento || ''}
                      value={pessoa.documento}
                    />
                  )}
                />
                <ErrorMessage error={errors.documento} />
              </Grid>
              <Grid className={styles.GridItem} item xs={5}>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={styles.TextFieldCustom}
                      label="Cpf"
                      variant="outlined"
                      onChange={handleCpf}
                      value={pessoa.cpf}
                    />
                  )}
                />
                <ErrorMessage error={errors.cpf} />
              </Grid>
              {!idPessoa && pessoa.perfil != PerfilUsuario.Promotor && <Grid className={styles.GridItem} item xs={4}>
                <FormControl sx={{ width: 1 }} size="medium">
                  <InputLabel htmlFor="outlined-adornment-password">Senha *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleSenha}
                    error={erroSenha}
                    className={styles.input}
                    label="Senha"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((show) => !show)}
                          edge="end"
                        >
                          {showPassword ? <Icon path={mdiEyeOff} size={0.7} /> : <Icon path={mdiEye} size={0.7} />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <p style={{ textAlign: 'left', margin: '10px 0 20px 0' }}>A senha deve ter ao menos 8 caracteres e conter letras maiúsculas e minúsculas, números e um caractere não alfanumérico.</p>

                </FormControl>
              </Grid>}
              <Grid className={styles.GridItem} item xs={4}>
                <Controller
                  name="telefone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={styles.TextFieldCustom}
                      label="Telefone"
                      variant="outlined"
                      disabled={pessoa.perfil === PerfilUsuario.Cliente}
                      onChange={handleTelefone}
                      value={pessoa.telefone}
                    />
                  )}
                />
                <ErrorMessage error={errors.telefone} />
              </Grid>
              <Grid className={styles.GridItem} item xs={4}>
                <Controller
                  name="sexo"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <Autocomplete
                        id='select-label-sexo'
                        className={styles.TextFieldCustom}
                        options={listaTipoSexo}
                        getOptionLabel={(option: any) => option}
                        onChange={(e, value) => handleTipoSexo(TipoSexo[value])}
                        defaultValue={() => (Object.entries(TipoSexo).find(([_, v]) => v === pessoa.sexo) || [])[0] as string}
                        renderInput={(params) => <TextField {...params} label="Sexo" />}
                      />
                    </FormControl>
                  )}
                />
                <ErrorMessage error={errors.sexo} />
              </Grid>
            </Grid>

            <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
              <Button size="large" onClick={onClose} sx={sxButtonCancelConfig} >
                Cancelar
              </Button>
              <Button
                size="large"
                onClick={handleSubmit(salvar)}
                disabled={!idPessoa && (pessoa.perfil != PerfilUsuario.Promotor && (!senha || erroSenha))}
                sx={sxButtonSaveConfig}
              >
                Salvar
              </Button>
            </FooterToolbar>
          </>
        )
      }
    </form>
  );
};
