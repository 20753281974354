import { OcorrenciaFormModel } from "../modelos/ocorrencia/ocorrenciaFormModel";

export const OCORRENCIA_FORM_INITIAL_STATE: OcorrenciaFormModel = {
    data: "",
    horaInicio: null,
    horaFim: null,
    horaChegadaPrevista: null,
    idEvento: "", 
    aberto: undefined
  };
  