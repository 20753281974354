import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Autocomplete, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { COMBO_FORM_INITIAL_STATE } from "../../../constantes/ComboFormInitialState";
import { MASCARA_DINHEIRO } from "../../../constantes/mascaras/MascaraDinheiro";
import { ComboFormModel } from "../../../modelos/rota/comboFormModel";
import { RotaFormModel } from "../../../modelos/rota/rotaFormModel";
import { useCombosService } from "../../../servicos/combos.service";
import { useRotasService } from "../../../servicos/rotas.service";
import { FormularioComboSchema } from "./formularioCombo.validation";
import styles from "./styles/formularioRota.module.scss";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";

export const FormularioCombo = () => {
  
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const loading = false;
  const { idOcorrencia } = useParams<{idOcorrencia: string}>();
  const { idCombo } = useParams<{idCombo?: string}>();
  const [, setSalvarLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [combo, setCombo] = useState<ComboFormModel>(COMBO_FORM_INITIAL_STATE);
  const { salvarCombo, editarCombo, carregarComboPorId } = useCombosService();
  const { carregarRotas } = useRotasService();
  const [ rotas, setRotas] = useState<Array<any>>([]);
  const edicao = Boolean(idCombo);
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();
  
  function handleNome (event: any) {
    const nome = event?.target.value;
    setCombo({
      ...combo,
      nome
    });
    setValue('nome', nome);
  }

  function handleValor (event: any) {
    const valorStr = MASCARA_DINHEIRO(event.target.value);
    const valor = Number(valorStr.replace(",","."));
    setCombo({
      ...combo,
      valor,
      valorStr,
    });
    setValue('valor', valor);
    setValue('valorStr', valorStr);
  }

  function handleRotas (value: any) {
    let arr: any = []
    if (value && value.length > 0) {
      value.map((rota: any) => {
        arr.push(rota.id)
      })
    }
    
    setCombo({
      ...combo,
      idsRotas: arr
    });
    setValue('idsRotas', arr);
  }

  async function carregarCombo(idCombo: string) {
    const response = await carregarComboPorId(idCombo) as unknown as ComboFormModel;
    if (response) {
      const valorStr = MASCARA_DINHEIRO(response.valor.toFixed(2));
      setCombo({...response, valorStr });
      setValue('id', response.id);
      setValue('nome', response.nome);
      setValue('valor', response.valor);
      setValue('valorStr', valorStr);
      setValue('idsRotas', response.idsRotas);
      setValue('idOcorrencia', response.idOcorrencia);
    }
  }

  async function carregarComboRotas() {
    const response = await carregarRotas() as unknown as Array<RotaFormModel>;
    if (response) {
      setRotas(response);
    }
  }
  
  useEffect(() => {
    if (idCombo) {
        carregarCombo(idCombo);
    }
  }, [idCombo]);

  useEffect(() => {
    carregarComboRotas();
    setCombo({
        ...combo,
        idOcorrencia
    });
    setValue('idOcorrencia', idOcorrencia);
  }, [idOcorrencia]);
  

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente]);
    })()
  }, []);

  async function salvar() {
    setSalvarLoading(true);
    try {
      const estaEditando = !!combo.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (edicao) {
        await editarCombo(combo.id!, combo);
      } else {
        await salvarCombo(combo);
      }

      setSalvarLoading(false);
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      setSalvarLoading(false);
    }
  }

  const onClose = () => {history.push(`/ocorrencias/ver/${idOcorrencia}`);};
  const rotaForm = useForm<ComboFormModel>({
    resolver: yupResolver(FormularioComboSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = rotaForm;

  return (
    <form>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              {edicao ? "Editar combo" : "Cadastro de combo"}
            </span>
          </OptActionButton>
        </div>
        <Divider />
        <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
          
          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="nome"
              control={control}
              render={({ field: { onChange } }) => (
                <TextField
                  className={styles.TextFieldCustom}
                  label="Nome"
                  variant="outlined"
                  onChange={handleNome}
                  value={combo.nome}
                />
              )}
            />
           
            <ErrorMessage error={errors.nome} />
          </Grid>
          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="valorStr"
              control={control}
              render={({ field: { onChange } }) => (
                <TextField
                  className={styles.TextFieldCustom}
                  inputProps={{  maxLength: 14 }}
                  label="Valor"
                  variant="outlined"
                  onChange={handleValor}
                  value={combo.valorStr || ''}
                />
              )}
            />
           
            <ErrorMessage error={errors.valorStr} />
          </Grid>
          <Grid className={styles.GridItem} item xs={6}>
            <Controller
              name="idsRotas"
              control={control}
              render={({ field: { onChange } }) => (
                <FormControl sx={{minWidth: 120, width:1 }} size="medium">
                  <Autocomplete
                    multiple
                    id='select-label-empresa'
                    className={styles.TextFieldCustom}
                    options={rotas}
                    getOptionLabel={(option: any) => option.nome}
                    onChange={(e, value) => handleRotas(value)}
                    defaultValue={combo.idsRotas && combo.idsRotas.length > 0 ? [combo.idsRotas.map((id) => {return id})] : undefined}
                    renderInput={(params) => <TextField {...params} label="Empresa" required />}
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        

        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose}  sx={sxButtonCancelConfig} >
            Cancelar
          </Button>
          <Button
            size="large"
            onClick={handleSubmit(salvar)}
            sx={sxButtonSaveConfig}
          >
            Salvar
          </Button>
        </FooterToolbar>
    </form>
  );
};
