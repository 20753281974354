import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop, OptSelectionOption } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { MASCARA_NUMEROS } from "../../../constantes/mascaras/MascaraNumeros";
import { OCORRENCIA_FORM_INITIAL_STATE } from "../../../constantes/OcorrenciaFormInitialState";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";
import { OcorrenciaFormModel } from "../../../modelos/ocorrencia/ocorrenciaFormModel";
import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { CadastroOcorrenciaSchema } from "./cadastroOcorrencia.validation";
import styles from "./styles/cadastroOcorrencia.module.scss";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { addHours, format, parseISO, subHours } from "date-fns";
import { theme } from "../../../compartilhado/theme";
import Select from "react-select";


export const CadastroOcorrencia = () => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const { idOcorrencia } = useParams<{ idOcorrencia?: string }>();
  const { idEvento } = useParams<{ idEvento?: string }>();
  const [, setSalvarLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [disableSalvar, setDisableSalvar] = useState<boolean>(false);
  const [ocorrenciasEvento, setOcorrenciasEvento] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [ocorrencia, setOcorrencia] = useState<OcorrenciaFormModel>(OCORRENCIA_FORM_INITIAL_STATE);
  const { salvarOcorrencia, editarOcorrencia, carregarOcorrenciaPorId, carregarOcorrenciasPorEvento } = useOcorrenciasService();

  const [mudeiDataInicio, setMudeiDataInicio] = useState<boolean>(false);
  const [mudeiDataTermino, setMudeiDataTermino] = useState<boolean>(false);
  const [mudeiDataChegadaPrevista, setMudeiDataChegadaPrevista] = useState<boolean>(false);

  const edicao = Boolean(idOcorrencia);
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  const optionsDisponibilidadeOcorrencia: any = [
    {
      value: true,
      label: 'Disponível'
    },
    {
      value: false,
      label: 'Indisponível'
    },
  ]

  function handleData(event: any) {
    if (event != 'Invalid Date') {
      const fullDate = event;
      const date = event.toISOString().split('T')[0];

      let fullHour;
      if(ocorrencia.horaInicio){
        fullHour = parseISO(ocorrencia.horaInicio.replace(' ', 'T'))
        fullHour.setDate(+date.split('-')[2]);
        fullHour.setMonth(+date.split('-')[1]-1);
        fullHour.setFullYear(+date.split('-')[0]);
      }
  
      if (ocorrenciasEvento.some((x) => x.data.split('T')[0] === date && x.id !== idOcorrencia)) {
        setDisableSalvar(true)
        enqueueSnackbar(`Já existe uma ocorrêcia para esta data, favor alterar. Não é possível criar ocorrências com datas repetidas`, { variant: "error", autoHideDuration: 3000 });
      } else {
        setOcorrencia({ ...ocorrencia, data: format(fullDate, 'yyyy-MM-dd HH:mm:ss'), horaInicio: fullHour ? format(fullHour, 'yyyy-MM-dd HH:mm:ss') : null});
        setValue('data', fullDate);
        setMudeiDataInicio(true)
        setDisableSalvar(false);
      }
    }
  }

  function handleHorarioInicio(event: any) {
    if (!event){
      setOcorrencia({ ...ocorrencia, horaInicio: null });
      setMudeiDataInicio(true)
      setDisableSalvar(false)
    }
    else if (event !== 'Invalid Date') {
      const fullDate = event;
      let dataAtual = ocorrencia.data.split(' ')[0].split('T')[0];
      fullDate.setDate(+dataAtual.split('-')[2]);
      fullDate.setMonth(+dataAtual.split('-')[1]-1);
      fullDate.setFullYear(+dataAtual.split('-')[0]);

      setOcorrencia({ ...ocorrencia, horaInicio: format(fullDate, 'yyyy-MM-dd HH:mm:ss') });
      setValue('horaInicio', fullDate.toISOString());
      setMudeiDataInicio(true)
      setDisableSalvar(false)
    }
  }

  function handleHoraFim(event: any) {
    const horaFim = event;
    setOcorrencia({
      ...ocorrencia,
      horaFim
    });
    setMudeiDataTermino(true);
    setValue('horaFim', horaFim);
  }

  function handleChegadaPrevista(event: any) {
    const horaChegadaPrevista = event;
    setOcorrencia({
      ...ocorrencia,
      horaChegadaPrevista
    });
    setMudeiDataChegadaPrevista(true);
    setValue('horaChegadaPrevista', horaChegadaPrevista);
  }

  async function carregarOcorrenciasEvento() {
    if (idEvento) {
      const response = await carregarOcorrenciasPorEvento(idEvento) as unknown as Array<OcorrenciaFormModel>;
      if (response) {
        setOcorrenciasEvento(response);
      }
    }
  }

  async function carregarOcorrencia(idOcorrencia: string) {
    const response = await carregarOcorrenciaPorId(idOcorrencia) as unknown as OcorrenciaFormModel;
    if (response) {
      let data = new Date(response.data)
      data = addHours(data, 3)
      response.data = data.toISOString();

      if (response.horaInicio) {
        let horaInicio = new Date(response.horaInicio);
        horaInicio = subHours(horaInicio, 3);
        response.horaInicio = horaInicio.toISOString().replace(/T/g, " ").replace(/Z/g, "");
        response.data = response.horaInicio;
      }
      if (response.horaFim) {
        let horaFim = new Date(response.horaFim);
        horaFim = subHours(horaFim, 3);
        response.horaFim = horaFim.toISOString().replace(/T/g, " ").replace(/Z/g, "");
      }
      if (response.horaChegadaPrevista) {
        let horaChegadaPrevista = new Date(response.horaChegadaPrevista);
        horaChegadaPrevista = subHours(horaChegadaPrevista, 3);
        response.horaChegadaPrevista = horaChegadaPrevista.toISOString().replace(/T/g, " ").replace(/Z/g, "");
      }

      setOcorrencia(response);
      setValue('id', response.id);
      setValue('data', response.data);
      setValue('horaInicio', response.horaInicio);
      setValue('horaFim', response.horaFim);
      setValue('horaChegadaPrevista', response.horaChegadaPrevista);
      setValue('passagensDisponiveis', undefined);
      setValue('passagensVendidas', undefined);
      setValue('idEvento', response.idEvento);
      setValue('aberto', response.aberto)
    }
  }

  async function salvar() {
    setSalvarLoading(true);
    try {
      const estaEditando = !!ocorrencia.id;
      const operacao = estaEditando ? "editado" : "criado";

      let novaOcorrencia = { ...ocorrencia };
      
      if (novaOcorrencia.horaInicio)
        novaOcorrencia.horaInicio = new Date(novaOcorrencia.horaInicio).toISOString()
      if (!mudeiDataTermino && novaOcorrencia.horaFim)
        novaOcorrencia.horaFim = new Date(novaOcorrencia.horaFim).toISOString()
      if (!mudeiDataChegadaPrevista && novaOcorrencia.horaChegadaPrevista)
        novaOcorrencia.horaChegadaPrevista = new Date(novaOcorrencia.horaChegadaPrevista).toISOString()
      if (edicao) {
        await editarOcorrencia(novaOcorrencia.id!, novaOcorrencia);
      } else {
        await salvarOcorrencia(novaOcorrencia);
      }

      setSalvarLoading(false);
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      onClose();
    } catch (error) {
      console.log(error);
      setSalvarLoading(false);
    }
  }

  const onClose = () => {
    idOcorrencia ? history.push(`/ocorrencias/ver/${idOcorrencia}`) : history.push(`/eventos/ver/${idEvento}`);
  };

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor]);

      carregarOcorrenciasEvento();
      if (idOcorrencia) {
        carregarOcorrencia(idOcorrencia);
      }
      if (idEvento) {
        setValue('idEvento', idEvento);
      }

      setValue('data', new Date().toString());
      setValue('horaInicio', new Date().toString());

      let novaOcorrencia = {
        ...ocorrencia,
        data: new Date().toISOString()
      };
      if (idEvento) {
        novaOcorrencia.idEvento = idEvento;
      }
      setOcorrencia(novaOcorrencia);
    })()
  }, []);

  useEffect(() => {
    if (idOcorrencia && ocorrencia && ocorrencia.aberto != undefined) {
      setLoading(false)
    } else if (!idOcorrencia) {
      setLoading(false)
    }
  }, [ocorrencia]);


  const { control, handleSubmit, setValue, formState: { errors } } = useForm<OcorrenciaFormModel>({ resolver: yupResolver(CadastroOcorrenciaSchema) });

  if (loading) {
    return (<div>Carregando...</div>)
  }

  return (
    <form>
      <OptBackdrop open={loading} />
      <div className={styles.CabecalhoContainer}>
        <OptActionButton
          onClick={onClose}
          startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
        >
          <span className={styles.SpanText}>
            {edicao ? "Editar Data do Evento" : "Cadastro de Nova Data"}
          </span>
        </OptActionButton>
      </div>
      <Divider />
      <Grid className={styles.GridContent} container rowSpacing={1} columns={12} px={1.5}>
        <Grid className={styles.GridItem} item xs={4}>
          <Controller
            name="data"
            control={control}
            render={({ field: { onChange } }) => (
              <DateTimePicker
                label="Data do Evento"
                views={['year', 'day']}
                inputFormat="dd/MM/yyyy"
                value={ocorrencia.data ?? null}
                onChange={handleData}
                renderInput={(params) => <TextField style={{ color: 'black' }} fullWidth {...params} />}
              />
            )}
          />
          <ErrorMessage error={errors.data} />
        </Grid>

        <Grid className={styles.GridItem} item xs={4}>
          <Controller
            name="horaInicio"
            control={control}
            render={({ field: { onChange } }) => (
              <DateTimePicker
                label="Hora de Início"
                views={['hours', 'minutes']}
                inputFormat="HH:mm"
                value={ocorrencia.horaInicio ?? null}
                onChange={handleHorarioInicio}
                renderInput={(params) => <TextField style={{ color: 'black' }} fullWidth {...params} />}
              />
            )}
          />
          <ErrorMessage error={errors.data} />
        </Grid>
        <Grid className={styles.GridItem} item xs={4}>
          <Controller
            name="aberto"
            control={control}
            render={({ field: { onChange } }) => (
              <Select className={styles.StyledAsyncSelect}
                placeholder="Disponibilidade da ocorrência"
                options={optionsDisponibilidadeOcorrencia}
                defaultValue={optionsDisponibilidadeOcorrencia.filter((x: any) => x.value === ocorrencia.aberto)[0]}
                onChange={(e: any) => {
                  setValue('aberto', e.value)
                  setOcorrencia({ ...ocorrencia, aberto: e.value })
                  onChange()
                }}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray10,
                    boxShadow: state.isFocused
                      ? `0 0 0 1px ${theme.light?.primary}`
                      : "",
                    "&:hover": {
                      borderColor: state.isFocused
                        ? theme.light?.primary
                        : Colors.gray8,
                    },
                    height: '57px'
                  }),
                }}
              />
            )}
          />
          <ErrorMessage error={errors.aberto} />
        </Grid>

        <Grid className={styles.GridItem} item xs={4}>
          <Controller
            name="horaFim"
            control={control}
            render={({ field: { onChange } }) => (
              <DateTimePicker
                label="Horário de Término do Evento"
                value={ocorrencia.horaFim ?? null}
                onChange={handleHoraFim}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            )}
          />
          <ErrorMessage error={errors.horaFim} />
        </Grid>

        <Grid className={styles.GridItem} item xs={4}>
          <Controller
            name="horaChegadaPrevista"
            control={control}
            render={({ field: { onChange } }) => (
              <DateTimePicker
                label="Hora de Chegada Prevista no Evento"
                value={ocorrencia.horaChegadaPrevista ?? null}
                onChange={handleChegadaPrevista}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            )}
          />
          <ErrorMessage error={errors.horaChegadaPrevista} />
        </Grid>
      </Grid>

      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig} >
          Cancelar
        </Button>
        <Button
          size="large"
          onClick={handleSubmit(salvar)}
          sx={sxButtonSaveConfig}
          disabled={disableSalvar}
        >
          Salvar
        </Button>
      </FooterToolbar>
    </form>
  );
};
