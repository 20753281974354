import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OptBackdrop, OptSelectionOption } from "@optsol/react";
import { Button, Grid, TextField } from "@mui/material"
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";
import { useSeoService } from "../../../servicos/seo.service";
import { SeoPaginaModel } from "../../../modelos/seo";
import { OPTIONS_PAGINAS } from "../../../constantes/Seo";

const validationSeoPaginaSchema = Yup.object({
    title: Yup.string(),
    description: Yup.string(),
    canonical: Yup.string(),
}).required();

export const SeoPagina = () => {
    const { carregarInfosPagina, salvarInfosPagina } = useSeoService();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, setValue } = useForm({ resolver: yupResolver(validationSeoPaginaSchema) });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [paginaSelecionada, setPaginaSelecionada] = useState<OptSelectionOption>();

    const handleSelecionarPagina = async (e: OptSelectionOption) => {
        try {
            let pagina = await carregarInfosPagina(e.value) as unknown as SeoPaginaModel;
            setValue('title', pagina.title ?? "")
            setValue('description', pagina.description ?? "")
            setValue('canonical', pagina.canonical ?? "")
            setPaginaSelecionada(e)
        } finally {

        }
    }

    const onSubmit = async (formData: any) => {
        setLoadingSubmit(true);
        formData['pagina'] = paginaSelecionada?.value;

        try {
            await salvarInfosPagina(formData);
            enqueueSnackbar(`SEO da página atualizado com sucesso!`, {variant: "success" });
        } finally {
            setLoadingSubmit(false);
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [])
    
    return (
        <>
            <OptBackdrop open={isLoading} />
            <div className="col">
                <Grid className="mt-2" container rowSpacing={1} columns={12}>
                    <Grid xs={12}>
                        <Select 
                            placeholder="Selecione a página que deseja configurar"
                            noOptionsMessage={() => "Sem opções pré-definidas"}
                            classNamePrefix="react-select"
                            value={paginaSelecionada}
                            options={OPTIONS_PAGINAS}
                            onChange={(e: any) => handleSelecionarPagina(e)}
                            styles={{
                                control: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                height: '50px',
                                borderColor: state.isFocused
                                    ? theme.light?.primary
                                    : "#0000003b",
                                boxShadow: state.isFocused
                                    ? `0 0 0 1px ${theme.light?.primary}`
                                    : "",
                                "&:hover": {
                                    borderColor: state.isFocused
                                    ? theme.light?.primary
                                    : Colors.gray8,
                                },
                                }),
                            }}
                        />

                        {
                            paginaSelecionada &&
                            <form onSubmit={handleSubmit(onSubmit)} className=" mt-3">
                                <Grid className="mt-5" container rowGap={2} rowSpacing={5} columns={12}>
                                    <Grid xs={12} md={6} paddingRight={1}>
                                        <TextField
                                            className="w-100"
                                            label="Título"
                                            variant="outlined"
                                            inputProps={{ maxLength: 255 }}
                                            {...register("title")}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            className="w-100"
                                            label="Descrição"
                                            variant="outlined"
                                            inputProps={{ maxLength: 500 }}
                                            {...register("description")}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <TextField
                                            className="w-100"
                                            label="Canônico"
                                            multiline
                                            rows={4}
                                            {...register("canonical")}
                                        />
                                    </Grid>

                                    {
                                        loadingSubmit ? (
                                            <Spinner />
                                        ) : (
                                            <Button 
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="mt-3"
                                            >
                                                Salvar
                                            </Button>
                                        )
                                    }

                                </Grid>
                            </form>
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    )
}