import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { EventoSearchResponse } from "../modelos/dtos/EventoSearchResponse";
import { CopiarEventoFormModel } from "../modelos/evento/copiarEventoFormModel";
import { EventoFormModel } from "../modelos/evento/eventoFormModel";
import { protectedResources } from "../authConfig";
import { PerfilUsuario } from "../enums/PerfilUsuario";
import { useHistory } from "react-router-dom";
import { PessoaModel } from "../modelos/pessoa/pessoaModel";
import { ImportarPassageirosEvento } from "../modelos/evento/importarPassageirosEventoModel";

interface PassageirosListProps {
  idEvento: string; 
  idOcorrencia?: string;
  idsPontosEmbarque?: string[];
}

export const useEventosService = () => {
  const { get, post, remove, put, gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Evento`;

  const router = useHistory();

  const obterUsuarioLogado = async () => {
    return get(`gestao/Usuario/logado`);
  };
  
  const importarPassageirosEvento = async (data: ImportarPassageirosEvento) => {
    return post(`/gestao/Usuario/importarUsuariosEvento`, data);
  };

  const getDadosPlanilha = async (idEvento: string) => {
    return get(`/gestao/PontoRota/relacaoIdsImportacao/${idEvento}`);
  };

  const obterListaClientes = async () => {
    return get(`gestao/Usuario/clientes`);
  };
  
  const verificarAutorizacao = async (perfis: PerfilUsuario[]) => {
    let usuarioLogado = await obterUsuarioLogado() as unknown as PessoaModel;
    if(!usuarioLogado || perfis.indexOf(usuarioLogado.perfil) == -1)
      router.push('/');
  };

  const salvarEvento = async (data: EventoFormModel) => {
    return post(`${baseApi}`, data);
  };

  const salvarRecorrenciaEvento = async (data: EventoFormModel) => {
    return post(`${baseApi}/recorrencia`, data);
  };

  const removerEvento = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarEvento = async (itemId: string, data: EventoFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridEventoPaginated = async (data: SearchRequest<{}>) => {
    return gridPagedListSearch<EventoSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  const carregarEventoPorId = async (id :string) => {
    return get<EventoFormModel>(`${baseApi}/${id}`);
  };

  const carregarEventos = async () => {
    return get<EventoFormModel>(`${baseApi}/todos`);
  };

  const copiarEvento = async (data: CopiarEventoFormModel) => {
    return post(`${baseApi}/copiar`, data);
  }

  const obterPassageiros = async (data: PassageirosListProps) => {
    return post(`gestao/Sms/obterPassageirosFiltro`, data);
  };

  return {
    getDadosPlanilha,
    importarPassageirosEvento,
    salvarEvento,
    salvarRecorrenciaEvento,
    removerEvento,
    editarEvento,
    buscarGridEventoPaginated,
    carregarEventoPorId,
    carregarEventos,
    copiarEvento,
    obterUsuarioLogado, 
    verificarAutorizacao,
    obterPassageiros,
    obterListaClientes
  };
};