import React, { useEffect, useState } from "react";
import { Tabs, Tab, Button, Box } from "@mui/material";
import { FormularioEmpresa } from "./FormularioEmpresa";
import { CadastroMotorista } from "../../motorista/Cadastro/CadastroMotorista";
import { CadastroFrota } from "../../frota/Cadastro/CadastroFrota";
import { Colors } from "../../../compartilhado/colors";
import { Rotas } from "../../../rotas";
import { useHistory } from "react-router-dom";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { GridEmpresas } from "../ListarEmpresas/GridEmpresas";
import { GridMotoristas } from "../../motorista/Cadastro/ListarMotoristas/GridMotoristas";
import { GridFrotas } from "../../frota/ListarFrota/GridFrotas";

const sxButtonCancelConfig = {
  backgroundColor: Colors.gray10,
  color: Colors.gray4,
  height: "38px",
  "&:hover": {
    backgroundColor: Colors.gray9,
  },
};

function ContainerCadastro() {
  const [currentTab, setCurrentTab] = useState(0);
  const [dadosEmpresa, setDadosEmpresa] = useState<any>();
  const [updateMotoristaGrid, setUpdateMotoristaGrid] = useState(false);
  const [updateFrotaGrid, setUpdateFrotaGrid] = useState(false);
  const [motoristaId, setMotoristaId] = useState<any>("");
  const [frotaId, setFrotaId] = useState<any>("");

  const history = useHistory();

  useEffect(() => {
    console.log({frotaId})
  }, [frotaId])
  
  const handleTabChange = (event: any, newValue: any) => {
    setMotoristaId("");
    setFrotaId("");
    setCurrentTab(newValue);
  };

  function updateMotorista(bol: boolean) {
    setUpdateMotoristaGrid(bol);
    setMotoristaId("");
  }

  function updateFrota(bol: boolean) {
    setUpdateFrotaGrid(bol);
    setFrotaId("");
  }

  let content;
  switch (currentTab) {
    case 0:
      content = (
        <>
          <FormularioEmpresa
            isFromCadastroEmpresa
            idFromParent={dadosEmpresa?.id}
            obterDadosEmpresa={(dados: any) => setDadosEmpresa(dados)}
          />
        </>
      );
      break;
    case 1:
      content = (
        <>
          <CadastroMotorista
            isFromCadastroEmpresa
            handleUpdateGrid={updateMotorista}
            limparCampos={updateMotorista}
            nomeEmpresa={dadosEmpresa?.nome}
            idEmpresa={dadosEmpresa?.id}
            idPessoa={motoristaId}
          />
          <Box width="100%">
            <GridMotoristas
              isFromCadastroEmpresa
              needToUpdateGrid={updateMotoristaGrid}
              idEmpresa={dadosEmpresa?.id}
              dadosParaEditar={setMotoristaId}
            />
          </Box>
        </>
      );
      break;
    case 2:
      content = (
        <>
          <CadastroFrota
            isFromCadastroEmpresa
            handleUpdateGrid={updateFrota}
            nomeEmpresa={dadosEmpresa?.nome}
            idEmpresa={dadosEmpresa?.id}
            idFrota={frotaId}
          />
          <Box width="100%">
            <GridFrotas
              isFromCadastroEmpresa
              needToUpdateGrid={updateFrotaGrid}
              idEmpresa={dadosEmpresa?.id}
              dadosParaEditar={setFrotaId}
            />
          </Box>
        </>
      );
      break;
    default:
      content = null;
  }
  const onClose = () => {
    history.push(Rotas.Empresas.Principal);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        height: "fit-content",
      }}
    >
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Cadastro de Empresa" />
        <Tab disabled={!dadosEmpresa?.id} label="Cadastro de Motorista" />
        <Tab disabled={!dadosEmpresa?.id} label="Cadastro de Frota" />
      </Tabs>

      {content}
      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
          Voltar
        </Button>
      </FooterToolbar>
    </div>
  );
}

export default ContainerCadastro;
