import * as Yup from "yup";
import { OcorrenciaFormModel } from "../../../modelos/ocorrencia/ocorrenciaFormModel";

export const CadastroOcorrenciaSchema: Yup.SchemaOf<OcorrenciaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    data: Yup.string().required("Item obrigatório"),
    horaInicio: Yup.string().notRequired().nullable(),
    horaFim: Yup.string().notRequired().nullable(),
    aberto: Yup.boolean(),
    horaChegadaPrevista: Yup.string().notRequired().nullable(),
    passagensDisponiveis: Yup.number().notRequired(),
    passagensVendidas: Yup.number().notRequired(),
    idEvento: Yup.string().required("Item obrigatório"),
    empresasContratadas: Yup.mixed().notRequired(),
    rotas: Yup.mixed().notRequired(),
    pontosRota: Yup.mixed().notRequired(),
  });