import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { CATEGORIA_FORM_INITIAL_STATE } from "../../../constantes/CategoriaFormInitialState";
import { CategoriaFormModel } from "../../../modelos/categoria/categoriaFormModel";
import { Rotas } from "../../../rotas";
import { useCategoriasService } from "../../../servicos/categorias.service";
import { CadastroCategoriaSchema } from "./CadastroCategoria.validation";

import AdicionarImagem from "../../../componentes/adicionarImagem";
import styles from "./styles/CadastroCategoria.module.scss";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";

export const CadastroCategoria = () => {
  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const { idCategoria } = useParams<{ idCategoria?: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileDesktop, setFileDesktop] = useState<string | undefined>("");
  const [fileMobile, setFileMobile] = useState<string>("");
  const [errorFileDesktop, setErrorFileDesktop] = useState<string>("");
  const [errorFileMobile, setErrorFileMobile] = useState<string>("");
  const [imagem, setImagem] = useState("");
  const [imagemMobile, setImagemMobile] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const CATEGORIA_FORM_INITIAL_STATE_FALLBACK: any = {
    nome: "",
    descricao: "",
    blobImagemMobilePadrao: "",
    blobImagemMobilePadraoName: "",
    imagemPadrao: "",
    imagemMobilePadrao: "",
  };

  const [categoria, setCategoria] = useState<any>(
    CATEGORIA_FORM_INITIAL_STATE_FALLBACK
  );
  const { salvarCategoria, editarCategoria, carregarCategoriaPorId } =
    useCategoriasService();
  const edicao = Boolean(idCategoria);
  const history = useHistory();
  const { verificarAutorizacao } = useEventosService();

  function handdleNome(event: any) {
    setCategoria({
      ...categoria,
      nome: event?.target.value,
    });
    setValue("nome", event.target.value);
  }

  function handdleDescricao(event: any) {
    setCategoria({
      ...categoria,
      descricao: event?.target.value,
    });
    setValue("descricao", event.target.value);
  }

  const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function validarArquivo(file: any): string {
    if (!file.type.match(imageMimeType)) {
      return "Formato de imagem não suportado";
    }

    return "";
  }

  async function handdleImagemPadrao(event: any) {
    const file = event.target.files[0];

    const erro = validarArquivo(file);
    if (erro) {
      setErrorFileDesktop(erro);
      return;
    }
    setErrorFileDesktop("");

    let value = (await convertToBase64(file)) as string;
    value = value.slice(value.indexOf("base64,") + 7, value.length);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setFileDesktop(reader.result as string);
    };

    try {
      setCategoria({
        ...categoria,
        blobImagemPadrao: value,
        blobImagemPadraoName: file.Name,
      });
      setValue("blobImagemPadrao", value);
      setValue("blobImagemPadraoName", file.Name);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImage(s: any) {
    try {
      setCategoria({
        ...categoria,
        blobImagemPadrao: s,
      });
      setValue("blobImagemPadrao", s);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (categoria.imagemPadrao) {
      setFileDesktop(categoria.imagemPadrao);
      setValue("blobImagemPadrao", categoria.imagemPadrao);
      setValue("blobImagemPadraoName", categoria.imagemPadrao);
    }
    if (categoria.imagemMobilePadrao) {
      setFileMobile(categoria.imagemMobilePadrao);
      setValue("blobImagemMobilePadrao", categoria.imagemPadrao);
      setValue("blobImagemMobilePadraoName", categoria.imagemPadrao);
    }
  }, [categoria.imagemPadrao, categoria.imagemMobilePadrao]);

  async function handleImagemMobile(s: any) {
    try {
      setCategoria({
        ...categoria,
        blobImagemMobilePadrao: s,
      });
      setValue("blobImagemMobilePadrao", s);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (idCategoria) {
      carregarCategoria(idCategoria);
    }
  }, []);

  async function carregarCategoria(idCategoria: string) {
    const response = (await carregarCategoriaPorId(
      idCategoria
    )) as unknown as CategoriaFormModel;
    if (response) {
      setCategoria(response);
      setValue("id", response.id);
      setValue("nome", response.nome);
      setValue("descricao", response.descricao);
      setImagem(`${response.imagemPadrao}?t=${new Date().getTime()}`);
      setImagemMobile(
        `${response.imagemMobilePadrao}?t=${new Date().getTime()}`
      );
    }
  }

  async function salvar() {
    setLoading(true);
    try {
      categoria.AlterarImagemPadrao = Boolean(categoria.blobImagemPadrao);
      categoria.AlterarImagemMobilePadrao = Boolean(
        categoria.blobImagemMobilePadrao
      );

      const estaEditando = !!categoria.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (edicao) {
        await editarCategoria(categoria.id!, categoria);
      } else {
        await salvarCategoria(categoria);
      }

      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      onClose();
      if (idCategoria) {
        carregarCategoria(idCategoria);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const onClose = () => {
    history.push(Rotas.Categorias.Principal);
  };
  const categoriaForm = useForm<CategoriaFormModel>({
    resolver: yupResolver(CadastroCategoriaSchema),
  });

  useEffect(() => {
    (async () => {
      verificarAutorizacao([
        PerfilUsuario.Administrador,
        PerfilUsuario.Operacao,
        PerfilUsuario.Gerente,
        PerfilUsuario.Consultor,
      ]);
    })();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = categoriaForm;

  return (
    <form>
      <OptBackdrop open={loading} />
      <div className={styles.CabecalhoContainer}>
        <OptActionButton
          onClick={onClose}
          startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
        >
          <span className={styles.SpanText}>
            {edicao ? "Editar categoria" : "Cadastro de categoria"}
          </span>
        </OptActionButton>
      </div>
      <Divider />
      <Grid
        className={styles.GridContent}
        container
        rowSpacing={1}
        columns={12}
        px={1.5}
      >
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Nome"
                required={true}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                onChange={handdleNome}
                value={categoria.nome}
              />
            )}
          />
          <ErrorMessage error={errors.nome} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="descricao"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Descrição"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                onChange={handdleDescricao}
                value={categoria.descricao}
              />
            )}
          />
          <ErrorMessage error={errors.descricao} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <AdicionarImagem
            label="Imagem Desktop"
            handleUpdateState={handleImage}
            image={imagem || ""}
            isDesktop
          />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <AdicionarImagem
            label="Imagem Mobile"
            handleUpdateState={handleImagemMobile}
            image={imagemMobile || ""}
          />
        </Grid>
      </Grid>
      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
          Cancelar
        </Button>
        <Button
          size="large"
          onClick={handleSubmit(salvar)}
          sx={sxButtonSaveConfig}
        >
          Salvar
        </Button>
      </FooterToolbar>
    </form>
  );
};
