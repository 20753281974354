import { useApi } from "../compartilhado/hooks/api";
import { AlterarSenhaRequest } from "../modelos/alterarSenha/AlterarSenhaRequest";
import { UsuarioLoginResponse } from "../modelos/dtos/UsuarioLoginResponse";
import { LoginFormModel } from "../modelos/login/loginFormModel";
import { UsuarioInsertRequest } from "../modelos/viagem/UsuarioInsertRequest";

export const useUsuariosService = () => {
  const { post, put } = useApi();

  const baseApi = `/v1/usuarios`;

  const salvarUsuario = async (data: UsuarioInsertRequest) => {
    return post(`${baseApi}`, data);
  };

  const alterarSenhaUsuario = async (data: AlterarSenhaRequest) => {
    return put(`${baseApi}/alterar-senha`, data);
  };

  const resetarSenhaUsuario = async (data: AlterarSenhaRequest) => {
    return put(`${baseApi}/resetar-senha`, data);
  };

  const getAuth = async (
    data: LoginFormModel
  ): Promise<UsuarioLoginResponse> => {
    const { data: response } = await post<UsuarioLoginResponse>(
      `${baseApi}/authenticate`,
      data
    );
    return response;
  };

  const gerarSenha = (): string => {
    return randPassword(2, 3, 3);
  };

  function randPassword(letters: number, numbers: number, either: number) {
    var chars = [
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      "0123456789",
      "abcdefghijklmnopqrstuvwxyz",
    ];

    return [letters, numbers, either]
      .map(function (len, i) {
        return Array(len)
          .fill(chars[i])
          .map(function (x) {
            return x[Math.floor(Math.random() * x.length)];
          })
          .join("");
      })
      .concat()
      .join("")
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
  }

  return {
    salvarUsuario,
    resetarSenhaUsuario,
    getAuth,
    alterarSenhaUsuario,
    gerarSenha,
  };
};
