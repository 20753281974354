import {
  mdiViewList,
  mdiDomain,
  mdiEmail,
  mdiAccount,
  mdiBagSuitcase,
  mdiMessageProcessing,
  mdiShape,
  mdiCalendar,
  mdiChartBoxPlusOutline,
  mdiAccountGroup,
  mdiStoreCogOutline
} from "@mdi/js";
import { OptMenuSection, OptSideLayout } from "@optsol/react";
import { useEffect, useState } from "react";
import { chatSquareIcon, squadIcon } from "../../compartilhado/CustomIcons";
import { rotas } from "../../rotas";
import "./App.css";

import { MsalProvider, AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from '../../authConfig';
import { PerfilUsuario } from "../../enums/PerfilUsuario";
import { useEventosService } from "../../servicos/eventos.service";

const Layout = () => {
  const { obterUsuarioLogado } = useEventosService();
  const [sessoes, setSessoes] = useState<OptMenuSection[]>([
    {
      title: "",
      items: [
        {
          icon: squadIcon,
          path: "",
          title: "",
        },
      ],
    },
  ]);

  const { instance, inProgress } = useMsal();
  let activeAccount: AccountInfo | null = null;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleProfileEdit = () => {
    if (inProgress === InteractionStatus.None) {
      let req: RedirectRequest = {
        authority: b2cPolicies.authorities.editProfile.authority,
        scopes: [] // can also set to [ "openid", "profile" ]
      }
      instance.acquireTokenRedirect(req);
    }
  };

  useEffect(() => {
    if (!activeAccount) {
      instance.loginRedirect().catch((error) => console.log(error));
    }

  }, [activeAccount, instance])

  useEffect(() => {
    (async () => {
      let usuarioLogado = await obterUsuarioLogado() as unknown as any;
      setSessoes(await getSections(usuarioLogado.perfil));
    })()
  }, []);

  const getSections = async (perfil: PerfilUsuario) => {

    const sections: OptMenuSection[] = [
      {
        title: "",
        items: [
          {
            icon: squadIcon,
            path: "",
            title: "",
          },
        ],
      },
    ];

    const protectedItems: OptMenuSection = {
      title: "Protected",
      items: [],
    };

    if ([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor].indexOf(perfil) != -1)
      protectedItems.items.push({
        icon: mdiShape,
        path: "/categorias",
        title: "Categorias",
      });

    if ([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente, PerfilUsuario.Consultor].indexOf(perfil) != -1)
      protectedItems.items.push({
        icon: mdiCalendar,
        path: "/eventos",
        title: "Eventos",
      });

    if ([PerfilUsuario.Administrador].indexOf(perfil) != -1)
      protectedItems.items.push({
        icon: mdiViewList,
        path: "/itens",
        title: "Cupom",
      });

    if ([PerfilUsuario.Administrador, PerfilUsuario.Operacao].indexOf(perfil) != -1)
      protectedItems.items.push({
        icon: mdiDomain,
        path: "/empresas",
        title: "Empresas",
      });

    if ([PerfilUsuario.Administrador, PerfilUsuario.Operacao].indexOf(perfil) != -1)
      protectedItems.items.push({
        icon: mdiAccountGroup,
        path: "/passageiros",
        title: "Passageiros",
      });

    if ([PerfilUsuario.Administrador, PerfilUsuario.Operacao].indexOf(perfil) != -1)
      protectedItems.items.push({
        icon: mdiStoreCogOutline,
        path: "/seo",
        title: "Configurações de SEO",
      });

    if ([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente].indexOf(perfil) != -1)
      protectedItems.items.push({
        icon: mdiAccount,
        path: "/usuarios",
        title: "Usuários",
      });

    if (protectedItems.items.length) {
      protectedItems.items.forEach((item) => {
        sections[0].items.push(item);
      });
    }
    return sections;
  };

  return (
    <AuthenticatedTemplate>
      <OptSideLayout
        sections={sessoes}
        routes={rotas}
        onLogout={() => {
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }}
        onManageProfile={() => { handleProfileEdit() }}
        profile={{
          name: activeAccount?.name || activeAccount?.username || 'Desconhecido',
          email: activeAccount?.username || 'Desconhecido',
          avatarSrc: undefined,
        }}
        appBarConfig={{
          hideLinkDescription: true,
          actions: [],
        }}
        version="v1.0"
      />
    </AuthenticatedTemplate>
  );
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: { instance: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={instance}>
      <Layout />
    </MsalProvider>
  );
};

export default App;