import { useEffect, useState } from "react";
import { Autocomplete, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Icon from "@mdi/react";
import { mdiPlus, mdiDeleteOutline } from "@mdi/js";
import { OptBackdrop } from "@optsol/react";
import { Colors } from "../../../compartilhado/colors";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import styles from "./styles/formularioRota.module.scss";
import { VeiculoFormModel } from "../../../modelos/veiculo/veiculoFormModel";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";
import { useMotoristasService } from "../../../servicos/motorista.service";
import { useVeiculosService } from "../../../servicos/veiculos.service";
import { useEventosService } from "../../../servicos/eventos.service";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { AssociacaoFormModel } from "../../../modelos/rota/associacaoFormModel";

interface Props {
  onSave: (associacoes: AssociacaoFormModel[]) => void;
  onClose: () => void;
}

export const FormularioAssociarVeiculosRota = ({onSave,  onClose}: Props) => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const loading = false;
  const [salvarLoading, setSalvarLoading] = useState<boolean>(false);
  const [associacoes, setAssociacoes] = useState<AssociacaoFormModel[]>([{
    id: "",
    idMotorista: "",
    idVeiculo: "",
    motorista: {},
    veiculo: {},
  }]);
  const [idsAssociacoes, setIdsAssociacoes] = useState<string[]>([Math.random().toString(36).substring(2, 12)]);
  const [comboMotorista, setComboMotorista] = useState<Array<any>>([]);
  const [comboVeiculo, setComboVeiculo] = useState<Array<any>>([]);
  const { verificarAutorizacao } = useEventosService();
  const { carregarVeiculos } = useVeiculosService();
  const { carregarMotoristas } = useMotoristasService();

  async function carregarComboMotorista() {
    const comboMotoristas = await carregarMotoristas() as unknown as Array<PessoaFormModel>;
    setComboMotorista(comboMotoristas);
  }

  async function carregarComboVeiculo() {
    const comboVeiculos = await carregarVeiculos() as unknown as Array<VeiculoFormModel>;
    setComboVeiculo(comboVeiculos);
  }

  function adicionarAssociacao() {
    let arr = associacoes;
    let novaAssociacao: any = {
      id: "",
      idMotorista: "",
      idVeiculo: "",
      motorista: {},
      veiculo: {},
    }

    arr.push(novaAssociacao)
    setAssociacoes([...arr]);
    setIdsAssociacoes(idsAtuais => [...idsAtuais,  Math.random().toString(36).substring(2, 12)]);
  }

  function removerAssociacao(associacaoIndex: number) {
    let arr = associacoes;
    let ids = idsAssociacoes;
    arr = arr.filter((_: any, index: number) => index !== associacaoIndex)
    ids = ids.filter((_: any, index: number) => index !== associacaoIndex)
    setAssociacoes([...arr]);
    setIdsAssociacoes([...ids]);
  }

  function handleMotorista(event: any, index: number) {
    let arr = associacoes;
    const idMotorista = event;
    const objMotoristaNovo = comboMotorista?.filter((x) => x.id === idMotorista)[0];

    arr[index] = {...arr[index], idMotorista, motorista: objMotoristaNovo}

    setAssociacoes([...arr]);
  }

  function handleVeiculo(event: any, index: number) {
    let arr = associacoes;
    const idVeiculo = event;
    const objVeiculoNovo = comboVeiculo?.filter((x) => x.id === idVeiculo)[0];

    arr[index] = {...arr[index], idVeiculo, veiculo: objVeiculoNovo}

    setAssociacoes([...arr]);
  }

  useEffect(() => {
    carregarComboMotorista();
    carregarComboVeiculo();
  }, []);

  async function salvar() {
    setSalvarLoading(true);
    try {
      onSave(associacoes);
    } catch (error) {
      console.log(error);
      setSalvarLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao, PerfilUsuario.Gerente]);
    })()
  }, []);

  return (
    <form>
      <OptBackdrop open={loading} />
      <Divider />
      {
        associacoes.map((associacao,index) => (
          <Grid key={idsAssociacoes[index]} className={`${styles.GridContent} align-items-center`} container rowSpacing={1} columns={12} px={1.5} mb={2}>
            <Grid className={styles.GridItem} item xs={5}>
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  id='select-label-motorista'
                  className={styles.TextFieldCustom}
                  options={comboMotorista}
                  getOptionLabel={(option: any) => option.nome}
                  onChange={(e, value) => handleMotorista(value.id, index)}
                  renderInput={(params) => <TextField {...params} label="Motorista" required/>}
                />
              </FormControl>
            </Grid>
    
            <Grid className={styles.GridItem} item xs={5}>
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  id='select-label-veiculo'
                  className={styles.TextFieldCustom}
                  options={comboVeiculo}
                  getOptionLabel={(option: any) => option.placa}
                  onChange={(e, value) => handleVeiculo(value.id, index)}
                  renderInput={(params) => <TextField {...params} label="Veículo" required/>}
                />
              </FormControl>
            </Grid>

            {
              associacoes.length > 1 &&
              <Grid xs={2}>
                  <Button variant="contained" color="inherit" onClick={() => removerAssociacao(index)}>
                      <Icon path={mdiDeleteOutline} size={1} color={Colors.red} />
                  </Button>
              </Grid>
            }
          </Grid>
        ))
      }

      <Grid className={`${styles.GridContent} d-flex justify-content-end flex-row`} container rowSpacing={1} mt={3} mb={4} columns={12} px={1.5}>                                                        
        <Button
          variant="contained"
          color="inherit"
          style={{ marginRight: "5px" }}
          onClick={() => adicionarAssociacao()}
        >
          Adicionar associação <Icon path={mdiPlus} size={1} color={Colors.green1} />
        </Button>
      </Grid>

      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig} >
          Cancelar
        </Button>
        <Button
          size="large"
          onClick={() =>salvar()}
          sx={sxButtonSaveConfig}
        >
          Salvar
        </Button>
      </FooterToolbar>
    </form>
  );
};
