import { mdiDeleteForever, mdiFileEdit } from "@mdi/js";
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { Colors } from "../../../compartilhado/colors";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { VeiculoSearchResponse } from "../../../modelos/dtos/VeiculoSearchResponse";

interface Props {
  carregar: (
    data: OptGridRequest
  ) => Promise<OptGridResponse<VeiculoSearchResponse>>;
  abrirDrawerFormulario: () => void;
  onClickDelete: (rowData: VeiculoSearchResponse) => void;
  onClickEdit: (rowData: VeiculoSearchResponse) => void;
  gridRef: React.MutableRefObject<OptGridRef | null>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridFrotasView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      carregar,
      onClickDelete,
      onClickEdit,
      gridRef,
    },
    ref
  ) => {
    const columns: OptGridColumn<VeiculoSearchResponse>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "Placa",
        field: "placa",
        align: "left",
        hidden: false,
        width: 200,
      },
      {
        title: "Número de Assentos",
        field: "numeroAssentos",
        align: "left",
        hidden: false,
        width: 200,
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={gridRef}
            columns={columns}
            data={carregar}
            options={options}
            actionsPosition={"end"}
            actions={[
              (rowData) => ({
                icon: {
                  path: mdiFileEdit,
                  size: 1,
                  color: Colors.gray,
                },
                tooltip: "Editar veículo",
                onClick: () => onClickEdit(rowData),
                disabled: false,
              }),
              (rowData) => ({
                icon: {
                  path: mdiDeleteForever,
                  size: 1,
                  color: Colors.red2,
                },
                tooltip: "Excluir veículo",
                onClick: () => onClickDelete(rowData),
                disabled: false,
              }),
            ]}
          />
        </OptGridContainer>
      </>
    );
  }
);
