export enum PerfilUsuario {
  'Administrador' = 1,
  'Operacao' = 2,
  'Gerente' = 3,
  'Consultor' = 4,
  'Cliente' = 5,
  'Acompanhante' = 6,
  'Motorista' = 7,
  'Sistema' = 8,
  'Promotor' = 10
}