import * as Yup from "yup";
import { RotaFormModel } from "../../../modelos/rota/rotaFormModel";

export const FormularioRotaSchema: Yup.SchemaOf<RotaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    passagensDisponiveis: Yup.number().notRequired().nullable(),
    passagensVendidas: Yup.number().notRequired().nullable(),
    horarioIdaChegada: Yup.date().required("Item obrigatório").test({
      test: (data) => validaData(data),
      message: "Data anterior a data atual",
    }),
    horarioIdaPartida: Yup.date().required("Item obrigatório").test({
      test: (data) => validaData(data),
      message: "Data anterior a data atual",
    }),
    horarioVoltaChegada: Yup.date().required("Item obrigatório").test({
      test: (data) => validaData(data),
      message: "Data anterior a data atual",
    }),
    horarioVoltaPartida: Yup.date().required("Item obrigatório").test({
      test: (data) => validaData(data),
      message: "Data anterior a data atual",
    }),
    idOcorrencia: Yup.string().required("Item obrigatório"),
    trechos: Yup.array().notRequired(),
  });

  function validaData(data?: Date): boolean {
    if (data) {
      const agora = new Date();
      return  agora < data;
    } else {
      return false;
    }
  }