import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { PaginatedRotaRequest } from "../modelos/search/PaginatedRotaRequest";
import { protectedResources } from "../authConfig";
import { ReservaSearchResponse } from "../modelos/dtos/ReservaSearchResponse";

export const useReservasService = () => {
  const { gridPagedListSearch } = useApi({scopes: protectedResources.apiGestao.scopes});

  const baseApi = `gestao/Reserva`;

  const buscarGridReservasPaginated = async (data: SearchRequest<PaginatedRotaRequest>) => {
    return gridPagedListSearch<ReservaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };

  return {
    buscarGridReservasPaginated
  };
};