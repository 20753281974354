import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { format } from "date-fns";
import { formatarHora } from "../../../utils/claimUtils";

interface Props {
  data: any[];
}

export const GridBagagesView = React.forwardRef<OptGridRef | undefined, Props>(
  ({ data }, ref) => {
    const options: OptGridOptions = {
      search: true,
      pageSize: 100,
    };

    const columns: OptGridColumn<any>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "Nº de Embarque",
        field: "passageiro.numeroPassageiro",
        align: "center",
      },
      {
        title: "Nome do Passageiro",
        field: "passageiro.usuario.nome",
        align: "left",
      },
      {
        title: "Horários de Embarque",
        field: "passageiro",
        align: "left",
        render: (row) => {   
          return (
            <span>
              {row.passageiro.reserva.pontoEmbarque.nome} {formatarHora(row.passageiro.reserva.pontoEmbarque)} 
            </span>
          );
        },
      },
      {
        title: "Bagagem",
        field: "codigo",
        align: "center",
      },
      {
        title: "Ponto de Embarque",
        field: "passageiro.reserva.pontoEmbarque.nome",
        align: "center",
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            columns={columns}
            data={data}
            options={options}
            actionsPosition={"end"}
          />
        </OptGridContainer>
      </>
    );
  }
);
