import { useHistory } from "react-router-dom";
import { Card, CardContent, Divider, Grid } from "@mui/material"
import Icon from "@mdi/react";
import {
    mdiEmail,
    mdiBagSuitcase,
    mdiMessageProcessing,
    mdiChartBoxPlusOutline,
    mdiAccountCheck,
  } from "@mdi/js";

export const Passageiros = () => {
    const history = useHistory();
    
    return (
        <>
            <div className="col">
                <Grid className="mt-5 px-5" container rowSpacing={1} columns={12} px={1.5}>
                    <Grid xs={12}>
                        <div>
                            <h4 className="text-black"><b>Passageiros</b></h4>
                        </div>
                    </Grid>
                    <Grid xs={12} className="mb-4">
                        <div>
                            <h6>Escolha um dos itens para ser redirecionado</h6>
                        </div>
                    </Grid>
                    <Divider />
                    <Grid container gap={1}>
                        <Grid className="card-select-item-redirect" item xs={12} md={6} lg={4}>
                            <Card onClick={() => history.push('/gerencia-complemento')}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiChartBoxPlusOutline} size={1.7} />
                                        <h4 className="mb-0"><b>Gerência de complemento</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid className="card-select-item-redirect" item xs={12} md={6} lg={4}>
                            <Card onClick={() => history.push('/sms')}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiMessageProcessing} size={1.7} />
                                        <h4 className="mb-0"><b>Enviar SMS</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid className="card-select-item-redirect" item xs={12} md={6} lg={4}>
                            <Card onClick={() => history.push('/email')}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiEmail} size={1.7} />
                                        <h4 className="mb-0"><b>Enviar E-mail</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid className="card-select-item-redirect" item xs={12} md={6} lg={4}>
                            <Card onClick={() => history.push('/bagagens')}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiBagSuitcase} size={1.7} />
                                        <h4 className="mb-0"><b>Relatório de Bagagens</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid className="card-select-item-redirect" item xs={12} md={6} lg={4}>
                            <Card onClick={() => history.push('/rsvp')}>
                                <CardContent>
                                    <div className="d-flex align-items-center gap-3">
                                        <Icon path={mdiAccountCheck} size={1.7} />
                                        <h4 className="mb-0"><b>Relatório RSVP</b></h4>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}