import { useHistory, useParams } from "react-router-dom";
import { mdiArrowLeft } from "@mdi/js";
import { Box, Button, TextField } from "@mui/material";
import {
  OptActionButton,
  OptBackdrop,
  OptGridRef,
  OptSelectionOption,
} from "@optsol/react";
import { utils, writeFileXLSX } from 'xlsx';
import { useEffect, useRef, useState } from "react";
import { GridRsvpView } from "./GridRsvpView";
import styles from "./styles/gridRsvp.module.scss";
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";
import { useSnackbar } from "notistack";
import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { format } from "date-fns";
import Select from "react-select";
import { formatarHora, formatarTelefone } from "../../../utils/claimUtils";
import { useRsvpService } from "../../../servicos/rsvp.service";
import { RsvpRelatorioModel } from "../../../modelos/rsvp";
import { RsvpStatus } from "../../../enums/RsvpStatus";
import { PaginatedRsvpRequest } from "../../../modelos/search/PaginatedRsvpRequest";

export const GridRsvp = () => {
  const history = useHistory();
  const { idEvento, idOcorrencia, idPontoEmbarque } = useParams<{ idEvento?: string, idOcorrencia?: string, idPontoEmbarque?: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [eventoSelected, setEventoSelected] = useState<OptSelectionOption>();
  const [ocorrenciaSelected, setOcorrenciaSelected] = useState<OptSelectionOption | null>(null);
  const [pontosEmbarqueSelected, setPontosEmbarqueSelected] = useState<OptSelectionOption[]>([]);
  const [statusSelected, setStatusSelected] = useState<OptSelectionOption | null>(null);
  const [buscaTabela, setBuscaTabela] = useState<string>("");
  const [listaStatusRsvp, setListaStatusRsvp] = useState(new Array<any>());
  const [filtros, setFiltros] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [optionsEvento, setOptionsEvento] = useState<OptSelectionOption[]>([]);
  const [optionsOcorrencia, setOptionsOcorrencia] = useState<OptSelectionOption[]>([]);
  const [optionsPontoEmbarque, setOptionsPontoEmbarque] = useState<OptSelectionOption[]>([]);
  const [eventosCarregados, setEventosCarregados] = useState<boolean>(false);
  const [carregandoExcel, setCarregandoExcel] = useState<boolean>(false);

  const { buscarGridRsvpPaginated, obterExcel } = useRsvpService();
  const { carregarEventos } = useEventosService();
  const { carregarOcorrenciasPorEvento, carregarOcorrenciaPorId } = useOcorrenciasService();
  const { enqueueSnackbar } = useSnackbar();

  const gridRef = useRef<OptGridRef>(null);

  function recarregarGrid() {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
    setLoading(false);
  }

  const converterParaExcel = (dados: RsvpRelatorioModel[]) => {
    setCarregandoExcel(true);
    return dados.map(r => {
      return {
        'Nome do Passageiro': r.nomePassageiro,
        'Nº de Embarque': r.numeroPassageiro,
        'Telefone do Passageiro': formatarTelefone(r.telefonePassageiro),
        'Ponto de Embarque': r.pontoEmbarque,
        'RSVP': r.rsvp
      }
    });
  }

  const exportarParaExcel = async () => {
    let result = await obterExcel(filtros);
    const ws = utils.json_to_sheet(converterParaExcel(result));
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Relatório RSVP");

    writeFileXLSX(wb, `Relatório RSVP - ${format(new Date(), "dd-MM-yyyy")}.xlsx`);
    enqueueSnackbar(`Relatório gerado com sucesso! Seu download iniciará em instantes...`, { variant: "success" });
    setCarregandoExcel(false);
  }

  // Listar opções para EventosSelect
  async function obterSelectEventos() {
    if (!eventosCarregados) {
      let data: any = []

      await carregarEventos().then((response: any) => {
        response.map((x: any) => {
          if (idEvento === x.id) {
            setEventoSelected({
              value: x.id,
              label: `${x.nome}`,
            })

            obterSelectOcorrecias(x.id)
          }

          data.push(
            {
              value: x.id,
              label: `${x.nome}`,
            }
          )
        });
      })

      setOptionsEvento(data);
      setEventosCarregados(true);
    }
  }

  // Listar opções para OcorrenciasSelect
  async function obterSelectOcorrecias(idEvento: string) {
    if (idEvento) {
      let data: any[] = []

      await carregarOcorrenciasPorEvento(idEvento).then((response: any) => {
        response.sort((a: any, b: any) => (new Date(a.data.replace('Z', ''))).getTime() - (new Date(b.data.replace('Z', ''))).getTime()).map((x: any) => {
          if (idOcorrencia === x.id) {
            setOcorrenciaSelected({
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            })
          }

          data.push(
            {
              value: x.id,
              label: `${format(new Date(x.data.replace('Z', '')), 'dd/MM/yyy')}`,
            }
          )
        });
      })

      setOptionsOcorrencia(data)
    }
  }

  // Listar opções para PontosEmbarqueSelect
  async function obterSelectPontos(idOcorrencia: string) {
    let arr: any = []
    let arrPontosSelecionados: any = []

    optionsOcorrencia.map((ocorrencia) => {
      if (ocorrencia.value === idOcorrencia) {
        carregarOcorrenciaPorId(idOcorrencia).then((response: any) => {
          response.pontosRota.map((x: any) => {
            if (idPontoEmbarque === x.id) {
              arrPontosSelecionados.push({
                value: x.id,
                label: x.nome,
              })
            }

            arr.push(
              {
                value: x.id,
                label: `${x.nome} ${formatarHora(x)}`,
              }
            )
          })
        }).finally(() => {
          setOptionsPontoEmbarque(arr)
          setPontosEmbarqueSelected(arrPontosSelecionados)
        })
      }
    })
  }

  function carregarRsvpStatus() {
    let arr: OptSelectionOption[] = []

    Object.keys(RsvpStatus).forEach(function (key: any, index) {
      if (typeof RsvpStatus[key] === 'string') {
        arr.push({
          label: RsvpStatus[key],
          value: key,
        })
      }
    });

    setListaStatusRsvp(arr);
  }

  useEffect(() => {
    if (ocorrenciaSelected) {
      obterSelectPontos(ocorrenciaSelected.value)
    }
  }, [ocorrenciaSelected]);

  useEffect(() => {
    obterSelectEventos();
    carregarRsvpStatus();
  }, [optionsEvento]);

  useEffect(() => {
    setLoading(true);
    if (eventoSelected && ocorrenciaSelected) {
      let params: any = {
        filtro: {
          idEvento: eventoSelected.value,
          idOcorrencia: ocorrenciaSelected.value,
          termoBusca: buscaTabela
        },
        page: page,
        pageSize: pageSize,
      }

      if (pontosEmbarqueSelected) {
        let pontosEmbarque: string[] = []

        pontosEmbarqueSelected.map((pontoEmbarque) => {
          pontosEmbarque.push(pontoEmbarque.value)
        })

        params.filtro.idsPontoEmbarque = pontosEmbarque
      }

      if (statusSelected) {
        params.filtro.status = statusSelected.value
      }

      setFiltros(params.filtro)

      buscarGridRsvpPaginated(params).then((response) => {
        setData(response.lista)
        recarregarGrid();
        setLoading(false);
        return
      })
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventoSelected, ocorrenciaSelected, pontosEmbarqueSelected, statusSelected, buscaTabela]);

  return (
    <>
      <Box width={'98%'} flexWrap={'wrap'}>
        <OptBackdrop open={loading} />
        <div className={styles.CabecalhoContainer}>
          <OptActionButton
            onClick={() => history.goBack()}
            startIcon={{ path: mdiArrowLeft, color: Colors.black1 }}
          >
            <span className={styles.SpanText}>
              Relatório RSVP
            </span>
          </OptActionButton>
        </div>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <div className={styles.ButtonSection}>
            <Select className={styles.StyledAsyncSelect}
              placeholder="Evento"
              noOptionsMessage={() => "Sem opções pré-definidas"}
              value={eventoSelected}
              options={optionsEvento}
              onChange={(e: any) => {
                setEventoSelected(e);
                setOcorrenciaSelected(null);
                setPontosEmbarqueSelected([]);
                obterSelectOcorrecias(e.value);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelect}
              placeholder="Ocorrência"
              noOptionsMessage={() => "Nenhuma ocorrência encontrada"}
              isDisabled={!eventoSelected?.value.length ? true : false}
              value={ocorrenciaSelected}
              options={optionsOcorrencia}
              isClearable
              onChange={(e: any) => {
                setOcorrenciaSelected(e);
                setPontosEmbarqueSelected([]);
                obterSelectPontos(e.value);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelectPontoEmbarque}
              placeholder="Pontos de Embarque"
              noOptionsMessage={() => "Nenhum ponto de embarque encontrado"}
              isMulti
              isDisabled={!ocorrenciaSelected?.value.length ? true : false}
              isClearable
              value={pontosEmbarqueSelected}
              options={optionsPontoEmbarque}
              onChange={(e: any) => {
                setPontosEmbarqueSelected(e as OptSelectionOption[]);
              }}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <Select className={styles.StyledAsyncSelect}
              placeholder="Status"
              noOptionsMessage={() => "Nenhum status encontrado"}
              isDisabled={!ocorrenciaSelected?.value.length ? true : false}
              value={statusSelected}
              options={listaStatusRsvp}
              isClearable
              onChange={(e: any) => setStatusSelected(e)}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? theme.light?.primary
                    : Colors.gray10,
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${theme.light?.primary}`
                    : "",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray8,
                  },
                }),
              }}
            />

            <TextField
              className={`mb-3 ${styles.StyledAsyncSelect}`}
              label="Busca por nome, número do passageiro"
              variant="outlined"
              onChange={((e) => setBuscaTabela(e.target.value))}
              disabled={!ocorrenciaSelected?.value.length ? true : false}
            />
          </div>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <div className={styles.ButtonSection}>
            <Button
              variant="contained"
              color="info"
              style={{ margin: "10px 10px 10px 13px" }}
              onClick={exportarParaExcel}
              disabled={carregandoExcel || !ocorrenciaSelected?.value.length}
            >
              {carregandoExcel ? 'Exportando...' : 'Exportar para Excel'}
            </Button>
          </div>
        </Box>

        <GridRsvpView
          ref={gridRef}
          data={data}
        />
      </Box>
    </>
  );
};
