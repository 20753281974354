import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OptBackdrop, OptSelectionOption } from "@optsol/react";
import { Button, Grid, TextField } from "@mui/material"
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import styles from '../../importar-passageiros/styles/importarPassageiros.module.scss'
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";
import { useSeoService } from "../../../servicos/seo.service";
import { SeoArquivoModel } from "../../../modelos/seo";
import { OPTIONS_ARQUIVO } from "../../../constantes/Seo";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { useDropzone } from 'react-dropzone'

export const SeoGlobal = () => {
    const { carregarInfosArquivo, salvarInfosArquivo } = useSeoService();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit } = useForm();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [conteudoArquivo, setConteudoArquivo] = useState<string>('');
    const [arquivoSelecionado, setArquivoSelecionado] = useState<OptSelectionOption>();
    const [errosDropzone, setErrosDropzone] = useState<string[]>([]);

    const onDrop = useCallback((acceptedFiles) => {
        setErrosDropzone([])

        acceptedFiles.forEach((file: any) => {
            var reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = function (e: any) {
                const content = e.target.result;
                setConteudoArquivo(content)
            };
            reader.readAsText(file)
        })
    }, [])

    const onDropRejected = useCallback((errors) => {
        if (errors.length > 0) {
            errors.map((err: any) => {
                err.errors.map((erro: any) => {
                    if (erro.code === 'too-many-files') {
                        setErrosDropzone([...errosDropzone, 'Muitos arquivos selecionados. Máximo: 1'])
                    } else if (erro.code === 'file-invalid-type') {
                        setErrosDropzone([...errosDropzone, 'Tipo do arquivo inválido. Tipos permitidos: .txt, .xml'])
                    }
                })
            })
        }
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        maxFiles: 1,
        accept: ['.xml', '.txt']
    })

    const handleSelecionarPagina = async (e: OptSelectionOption) => {
        try {
            let arquivo = await carregarInfosArquivo(e.value) as unknown as SeoArquivoModel;
            setConteudoArquivo(arquivo.conteudo ?? "")
            setArquivoSelecionado(e)
        } finally { }
    }

    const onSubmit = async () => {
        setLoadingSubmit(true);
        let params: SeoArquivoModel = {
            conteudo: conteudoArquivo,
            arquivo: arquivoSelecionado?.value ?? ""
        }

        try {
            await salvarInfosArquivo(params);
            enqueueSnackbar(`SEO do arquivo atualizado com sucesso!`, { variant: "success" });
        } finally {
            setLoadingSubmit(false);
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <>
            <OptBackdrop open={isLoading} />
            <div className="col">
                <Grid className="mt-2" container rowSpacing={1} columns={12}>
                    <Grid xs={12}>
                        <Select
                            placeholder="Selecione o arquivo que deseja configurar"
                            noOptionsMessage={() => "Sem opções pré-definidas"}
                            classNamePrefix="react-select"
                            value={arquivoSelecionado}
                            options={OPTIONS_ARQUIVO}
                            onChange={(e: any) => handleSelecionarPagina(e)}
                            styles={{
                                control: (baseStyles: any, state: any) => ({
                                    ...baseStyles,
                                    height: '50px',
                                    borderColor: state.isFocused
                                        ? theme.light?.primary
                                        : "#0000003b",
                                    boxShadow: state.isFocused
                                        ? `0 0 0 1px ${theme.light?.primary}`
                                        : "",
                                    "&:hover": {
                                        borderColor: state.isFocused
                                            ? theme.light?.primary
                                            : Colors.gray8,
                                    },
                                }),
                            }}
                        />

                        {
                            arquivoSelecionado &&
                            <form onSubmit={handleSubmit(onSubmit)} className=" mt-3">
                                <Grid className="mt-3" container rowGap={2} rowSpacing={5} columns={12}>
                                    <Grid xs={12}>
                                        <div className={`${styles.DropzonePassageiros} dropzone mt-3`} {...getRootProps()}>
                                            <input {...getInputProps()} accept=".txt, .xml" />
                                            <p className="mb-0">Arraste e solte o arquivo aqui ou clique para selecionar o arquivo</p>
                                        </div>
                                        {errosDropzone.length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                {errosDropzone.join(', ')}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid xs={12}>
                                        <CodeMirror
                                            value={conteudoArquivo}
                                            onChange={(e: any) => setConteudoArquivo(e)}
                                            extensions={[javascript({ jsx: true })]}
                                            height="300px"
                                        />
                                    </Grid>


                                    {
                                        loadingSubmit ? (
                                            <Spinner />
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="mt-3"
                                            >
                                                Salvar
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </form>
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    )
}