import { mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { OptBackdrop, OptConfirmationDialog, OptDialog, OptGridRef } from "@optsol/react";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Colors } from "../../../compartilhado/colors";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { OcorrenciaEmpresaSearchResponse } from "../../../modelos/dtos/OcorrenciaEmpresaSearchResponse";
import { OcorrenciaEmpresaFormModel } from "../../../modelos/ocorrenciaEmpresa/ocorrenciaEmpresaFormModel";
import { useOcorrenciaEmpresasService } from "../../../servicos/ocorrenciaEmpresas.service";
import { FormularioEmpresaPorOcorrencia } from "../Cadastro/FormularioEmpresaPorOcorrencia";
import styles from "./styles/gridEmpresas.module.scss";

interface Props {
  idOcorrencia?: string;
  items?: Array<OcorrenciaEmpresaFormModel>;
  onDelete: Function;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: "1px solid #ccc",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const GridEmpresasPorOcorrenciasView = React.forwardRef<
  OptGridRef | undefined,
  Props
>(({ idOcorrencia, items, onDelete }, ref) => {
  const [ocorrenciasEmpresa, setOcorrenciasEmpresa] = useState<
    Array<OcorrenciaEmpresaSearchResponse>
  >(items || new Array());
  const [confirmandoExclusao, setConfirmandoExclusao] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [item, setItem] = useState<OcorrenciaEmpresaSearchResponse>();
  const { removerOcorrenciaEmpresa } = useOcorrenciaEmpresasService();
  const [visivel, setVisivel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  function cadastrarEmpresa() {
    setVisivel(true);
  }

  function onCloseCadastro() {
    setVisivel(false);
  }
  function removerEmpresa(
    event: any,
    rowData: OcorrenciaEmpresaSearchResponse
  ) {
    setConfirmandoExclusao(true);
    setItem(rowData);
  }

  function adicionarEmpresa(
    novasEmpresas: Array<OcorrenciaEmpresaSearchResponse>
  ) {
    setOcorrenciasEmpresa(empresasAtuais => [...empresasAtuais, ...novasEmpresas]);
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setLoading(true);
    try {
      await removerOcorrenciaEmpresa(item?.id!);
      //setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      const novoValor = ocorrenciasEmpresa!.filter(
        (ocorrencia) => ocorrencia.id != item?.id
      );
      setOcorrenciasEmpresa(novoValor);
      onDelete();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }
  
  return (
    <>
      <OptGridContainer>
        <div className={styles.ButtonSection}>
          <Tooltip title={"Cadastrar empresa"}>
            <Button
              variant="contained"
              color="inherit"
              style={{ marginRight: "5px", marginBottom: "11px" }}
              placeholder="Cadastrar empresa"
              size="large"
              onClick={cadastrarEmpresa}
            >
              <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
            </Button>
          </Tooltip>
        </div>
      </OptGridContainer>

      <OptDialog open={visivel} title={"Cadastro de empresa contratada"}>
        <FormularioEmpresaPorOcorrencia
          idOcorrencia={idOcorrencia}
          adicionarEmpresa={adicionarEmpresa}
          onClose={onCloseCadastro}
          vinculadas={
            ocorrenciasEmpresa
              ? ocorrenciasEmpresa.map((obj) => obj.empresa.id)
              : []
          }
          visivel={visivel}
        />
      </OptDialog>

      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir Data do Evento"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão da data do evento ? <br /> Essa operação não poderá ser desfeita.
      </OptConfirmationDialog>
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Empresa</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ocorrenciasEmpresa?.filter((item) => item.ativo).length == 0 ? (
              <StyledTableRow>
                <StyledTableCell colSpan={2} align="center">
                  {" "}
                  Não há registros a serem exibidos{" "}
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow></StyledTableRow>
            )}
            {ocorrenciasEmpresa
              ?.filter((item) => item.ativo)
              .map((item) => {
                return (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell align="left" className="col-11">
                      {item.empresa?.nome}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="col-1">
                      <Tooltip title={"Remover Empresa"}>
                        <Button
                          variant="contained"
                          color="inherit"
                          style={{ marginRight: "5px", marginBottom: "11px" }}
                          placeholder="Remover Empresa"
                          size="large"
                          onClick={(event) => {
                            removerEmpresa(event, item);
                          }}
                        >
                          <Icon
                            path={mdiDeleteOutline}
                            size={1}
                            color={Colors.red}
                          />
                        </Button>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});
