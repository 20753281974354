import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { PessoaSearchResponse } from "../modelos/dtos/PessoaSearchResponse";
import { PessoaFormModel } from "../modelos/pessoa/pessoaFormModel";
import { PaginatedPessoaRequest } from "../modelos/search/PaginatedPessoaRequest";
import { protectedResources } from "../authConfig";

export const useMotoristasService = () => {
  const { get, post, remove, put, gridPagedListSearch } = useApi({ scopes: protectedResources.apiGestao.scopes });

  const baseApi = `gestao/Motorista`;

  const salvarMotorista = async (data: PessoaFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerMotorista = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarMotorista = async (itemId: string, data: PessoaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridMotoristasPaginated = async (data: SearchRequest<PaginatedPessoaRequest>, idEmpresa: any) => {
    return gridPagedListSearch<PessoaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, { ...data.filtro, idEmpresa });
  };

  const carregarMotoristaPorId = async (id: string) => {
    return get<PessoaFormModel>(`${baseApi}/${id}`);
  };

  const carregarMotoristas = async () => {
    return get<PessoaFormModel>(`${baseApi}/todos`);
  };

  return {
    salvarMotorista,
    removerMotorista,
    editarMotorista,
    buscarGridMotoristasPaginated,
    carregarMotoristaPorId,
    carregarMotoristas,
  };

};
