import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OptBackdrop, OptSelectionOption } from "@optsol/react";
import { Button, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
import Select from "react-select";
import { useEventosService } from "../../../servicos/eventos.service";
import { Colors } from "../../../compartilhado/colors";
import { theme } from "../../../compartilhado/theme";
import { useSeoService } from "../../../servicos/seo.service";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { SeoEventoModel } from "../../../modelos/seo";

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const validationSeoEventSchema = Yup.object({
    title: Yup.string(),
    description: Yup.string(),
    canonical: Yup.string(),
}).required();

var formatter = require('html-formatter');

export const SeoEvento = () => {
    const { carregarEventos } = useEventosService();
    const { carregarInfosEvento, salvarInfosEvento } = useSeoService();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, setValue } = useForm({ resolver: yupResolver(validationSeoEventSchema) });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [usarTituloEventoH1, setUsarTituloEventoH1] = useState<boolean>(false);
    const [showHtml, setShowHtml] = useState<boolean>(false);
    const [eventos, setEventos] = useState<OptSelectionOption[]>([]);
    const [eventoSelecionado, setEventoSelecionado] = useState<OptSelectionOption>();
    const [conteudo, setConteudo] = useState<string>("");
    const [conteudoHtml, setConteudoHtml] = useState<string>("");

    const ViewSourceButton = () => (
        <Button
            type="button"
            variant="outlined"
            color="info"
            className="mb-3"
            onClick={() => setShowHtml(!showHtml)}
        >
            {showHtml ? 'Esconder HTML' : 'Ver HTML'}
        </Button>
    );

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'color': [] }, { 'font': [] }, { 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'code-block'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        },
    }

    const formats = [
        'header',
        'font',
        'size',
        'align',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'code-block',
        'color',
        'source',
    ]

    const handleSelecionarEvento = async (e: OptSelectionOption) => {
        try {
            let evento = await carregarInfosEvento(e.value) as unknown as SeoEventoModel;
            setValue('title', evento.title ?? "")
            setValue('description', evento.description ?? "")
            setValue('canonical', evento.canonical ?? "")
            setUsarTituloEventoH1(evento.usarTituloEventoH1 ?? false)
            setConteudo(evento.conteudo ? formatter.render(evento.conteudo) : "")
            setConteudoHtml(evento.conteudo ? formatter.render(evento.conteudo) : "")
            setEventoSelecionado(e)
        } finally { }
    }

    const onSubmit = async (formData: any) => {
        setLoadingSubmit(true);
        formData['idEvento'] = eventoSelecionado?.value;
        formData['usarTituloEventoH1'] = usarTituloEventoH1;
        formData['conteudo'] = conteudo;

        try {
            await salvarInfosEvento(formData);
            enqueueSnackbar(`SEO do evento atualizado com sucesso!`, { variant: "success" });
        } finally {
            setLoadingSubmit(false);
        }
    }

    function handleAlterarConteudo(event: string) {
        const conteudoFormatado = formatter.render(event);
        setConteudo(event);

        if (conteudoFormatado !== conteudoHtml) {
            setConteudoHtml(conteudoFormatado);
        }
    }

    useEffect(() => {
        console.log('conteúdo', conteudo)
    }, [conteudo])

    useEffect(() => {
        console.log('conteudoHtml', conteudoHtml)
    }, [conteudoHtml])

    useEffect(() => {
        (async () => {
            let response = await carregarEventos() as unknown as any
            let data: any = []

            response.map((x: any) => {
                data.push(
                    {
                        value: x.id,
                        label: `${x.nome}`,
                    }
                )
            });

            setEventos(data)
            setIsLoading(false)
        })()
    }, [])

    return (
        <>
            <OptBackdrop open={isLoading} />
            <div className="col">
                <Grid className="mt-2" container rowSpacing={1} columns={12}>
                    <Grid xs={12}>
                        <Select
                            placeholder="Selecione o evento que deseja configurar"
                            noOptionsMessage={() => "Sem opções pré-definidas"}
                            classNamePrefix="react-select"
                            value={eventoSelecionado}
                            options={eventos}
                            onChange={(e: any) => handleSelecionarEvento(e)}
                            styles={{
                                control: (baseStyles: any, state: any) => ({
                                    ...baseStyles,
                                    height: '50px',
                                    borderColor: state.isFocused
                                        ? theme.light?.primary
                                        : "#0000003b",
                                    boxShadow: state.isFocused
                                        ? `0 0 0 1px ${theme.light?.primary}`
                                        : "",
                                    "&:hover": {
                                        borderColor: state.isFocused
                                            ? theme.light?.primary
                                            : Colors.gray8,
                                    },
                                }),
                            }}
                        />

                        {
                            eventoSelecionado &&
                            <form onSubmit={handleSubmit(onSubmit)} className="w-100 mt-3">
                                <Grid className="mt-5" container rowGap={2} rowSpacing={5} columns={12}>
                                    <Grid xs={12} md={6} paddingRight={1}>
                                        <TextField
                                            className="w-100"
                                            label="Título"
                                            variant="outlined"
                                            inputProps={{ maxLength: 255 }}
                                            {...register("title")}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            className="w-100"
                                            label="Descrição"
                                            variant="outlined"
                                            inputProps={{ maxLength: 500 }}
                                            {...register("description")}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <TextField
                                            className="w-100"
                                            label="Canônico"
                                            multiline
                                            rows={4}
                                            {...register("canonical")}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <FormControlLabel
                                            sx={{ marginBottom: "6px" }}
                                            label="Usar nome do evento como H1"
                                            control={
                                                <Switch
                                                    color="warning"
                                                    checked={usarTituloEventoH1}
                                                    id={"switch-usarTituloEventoH1"}
                                                    onChange={(e) => {
                                                        setUsarTituloEventoH1(!usarTituloEventoH1);
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <div className="text-editor">
                                            <ViewSourceButton />
                                            <ReactQuill
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                value={conteudo}
                                                onChange={(e) => handleAlterarConteudo(e)}
                                            />

                                            {
                                                showHtml &&
                                                <CodeMirror
                                                    value={conteudoHtml}
                                                    extensions={[html()]}
                                                    readOnly
                                                    height="200px"
                                                    maxWidth="92.4vw"
                                                    className="mt-3"
                                                />
                                            }

                                        </div>
                                    </Grid>

                                    {
                                        loadingSubmit ? (
                                            <Spinner />
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="mt-3"
                                            >
                                                Salvar
                                            </Button>
                                        )
                                    }

                                </Grid>
                            </form>
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    )
}