import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { GridPromotores } from "../paginas/promotores/ListarPromotores/GridPromotores";
import { AssociarPontoEmbarque } from "../paginas/promotores/AssociarPontoEmbarque/AssociarPontoEmbarque";
import { AssociarEvento } from "../paginas/promotores/AssociarEvento/AssociarEvento";

export const PromotoresRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Promotores.Principal}>
            <GridPromotores />
          </Route>
          <Route exact path={Rotas.Promotores.AssociarAoEvento}>
            <AssociarEvento />
          </Route>
          <Route exact path={Rotas.Promotores.AssociarAoPontoDeEmbarque}>
            <AssociarPontoEmbarque />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
