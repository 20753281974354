import * as Yup from "yup";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";

export const CadastroEventoSchema: Yup.SchemaOf<EventoFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    descricao: Yup.string().notRequired(),
    url: Yup.string().required("Item obrigatório"),
    imagem: Yup.string().notRequired(),
    imagemMobile: Yup.string().notRequired(),
    imagem2: Yup.string().notRequired(),
    imagemMobile2: Yup.string().notRequired(),
    imagem3: Yup.string().notRequired(),
    imagemMobile3: Yup.string().notRequired(),
    blobImagem: Yup.string().nullable().notRequired(),
    blobImagem2: Yup.string().nullable().notRequired(),
    blobImagem3: Yup.string().nullable().notRequired(),
    blobImagemMobile: Yup.string().nullable().notRequired(),
    blobImagemMobile2: Yup.string().nullable().notRequired(),
    blobImagemMobile3: Yup.string().nullable().notRequired(),
    blobImagemNome: Yup.string().notRequired(),
    blobImagemMobileNome: Yup.string().notRequired(),
    tipo: Yup.mixed().notRequired(),
    owner: Yup.mixed().notRequired(),
    capturaAutomatica: Yup.mixed().notRequired(),
    fechamentoAutomatico: Yup.mixed().notRequired(),
    rsvp: Yup.mixed().notRequired(),
    categoria: Yup.mixed().notRequired(),
    status: Yup.mixed().notRequired(),
    idClienteDono: Yup.string().nullable().notRequired(),
    idOwner: Yup.string().required("Item obrigatório"),
    idCategoria: Yup.string().required("Item obrigatório"),
    nomeDestino: Yup.string().required("Item obrigatório"),
    idDestino: Yup.string().notRequired(),
    destino: Yup.mixed().notRequired(),
    datasOcorrencias: Yup.array().notRequired(),
    recorrencia: Yup.mixed().notRequired(),
    tipoOcorrencia: Yup.mixed().notRequired(),
    AlterarImagem: Yup.boolean().notRequired().nullable(),
    AlterarImagemMobile: Yup.boolean().notRequired().nullable(),
    AlterarImagem2: Yup.boolean().notRequired().nullable(),
    AlterarImagemMobile2: Yup.boolean().notRequired().nullable(),
    AlterarImagem3: Yup.boolean().notRequired().nullable(),
    AlterarImagemMobile3: Yup.boolean().notRequired().nullable(),
    complementos: Yup.array().notRequired(),
  });