export class Endereco {
    nome: string = '';
    rua: string = '';
    numero: string = '';
    bairro: string = '';
    cidade: string = '';
    estado: string = '';
    pontoReferencia: string = '';
    complemento: string = '';
    lat: number = 0;
    lon: number = 0;
    horarioIdaPartida?: Date| null | undefined;
    horarioIdaChegada?: Date| null | undefined;
    horarioVoltaPartida?: Date| null | undefined;
    horarioVoltaChegada?: Date| null | undefined;
    pontoEmbarque?: boolean| null | undefined;
    precoPassagem?: number | null | undefined;
    passagensDisponiveis?: number | null | undefined;
    passagensVendidas?: number| null | undefined;
  }
  