import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import InputLabel from "@mui/material/InputLabel";
import {
  Button,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { CadastroMotoristaSchema } from "./CadastroMotorista.validation";
import styles from "./Styles/cadastroMotorista.module.scss";
import { Colors } from "../../../compartilhado/colors";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";
import { Rotas } from "../../../rotas";
import { MASCARA_CPF } from "../../../constantes/mascaras/MascaraCpf";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { useEmpresasService } from "../../../servicos/empresas.service";
import { EmpresaFormModel } from "../../../modelos/empresa/empresaFormModel";
import { PESSOA_FORM_INITIAL_STATE } from "../../../constantes/PessoaFormInitialState";
import { MASCARA_TELEFONE } from "../../../constantes/mascaras/MascaraTelefone";
import { useMotoristasService } from "../../../servicos/motorista.service";
import { IGeneric } from "../../empresa/Cadastro/FormularioEmpresa";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { useEventosService } from "../../../servicos/eventos.service";

export const CadastroMotorista = ({
  isFromCadastroEmpresa,
  handleUpdateGrid,
  nomeEmpresa,
  idEmpresa,
  idPessoa,
  limparCampos,
}: IGeneric) => {
  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  const { enqueueSnackbar } = useSnackbar();
  const { carregarEmpresas } = useEmpresasService();
  const { verificarAutorizacao } = useEventosService();
  const { salvarMotorista, editarMotorista, carregarMotoristaPorId } = useMotoristasService();
  const [loading, setSalvarLoading] = useState<boolean>(false);
  const [empresas, setEmpresas] = useState<Array<any>>(new Array<any>());
  const [motorista, setMotorista] = useState<PessoaFormModel>(PESSOA_FORM_INITIAL_STATE);

  useEffect(() => {
    carregarComboEmpresas();
    if (idPessoa) {
      carregarMotorista(idPessoa);
    }
  }, [idPessoa]);

  function handleNome(event: any) {
    const value = event.target.value;
    setMotorista({
      ...motorista,
      nome: value,
    });
    setValue("nome", value);
  }

  function handleCpf(event: any) {
    const value = MASCARA_CPF(event.target.value);
    setMotorista({
      ...motorista,
      cpf: value,
    });
    setValue("cpf", value);
  }

  function handleTelefone(event: any) {
    const value = MASCARA_TELEFONE(event.target.value);
    setMotorista({
      ...motorista,
      telefone: value,
    });
    setValue("telefone", value);
  }

  function handleEmail(event: any) {
    const value = event.target.value;
    setMotorista({
      ...motorista,
      email: value,
    });
    setValue("email", value);
  }

  function handleEmpresa(value: any) {
    setMotorista({
      ...motorista,
      idEmpresa: value,
    });
    setValue("idEmpresa", value);
  }

  useEffect(() => {
    setMotorista({
      ...motorista,
      idEmpresa: idEmpresa,
    });
    setValue("idEmpresa", idEmpresa);
  }, [idEmpresa]);

  async function carregarMotorista(idMotorista: string) {
    const response = (await carregarMotoristaPorId(
      idMotorista
    )) as unknown as PessoaFormModel;
    if (response) {
      setMotorista(response);
      setValue("id", response.id);
      setValue("nome", response.nome);
      setValue("cpf", response.cpf);
      setValue("telefone", response.telefone);
      setValue("email", response.email);
      setValue("idEmpresa", response.idEmpresa);
    }
  }

  async function carregarComboEmpresas() {
    const response =
      (await carregarEmpresas()) as unknown as Array<EmpresaFormModel>;
    if (response) {
      setEmpresas(response);
    }
  }

  const limpeza = () => {
    setMotorista({
      ...motorista,
      nome: "",
      cpf: "",
      email: "",
      documento: "",
      id: "",
      telefone: "",
    });
  };

  function incluirValorEmpresa() {
    setMotorista({
      ...motorista,
      idEmpresa: idEmpresa,
    });
    setValue("idEmpresa", idEmpresa);
  }

  async function salvar(value: PessoaFormModel) {
    setSalvarLoading(true);
    try {
      const estaEditando = !!value.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (idPessoa) {
        await editarMotorista(value.id!, value);
      } else {
        await salvarMotorista(value);
      }

      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      handleUpdateGrid(true);
      limpeza();
    } catch {
      console.log('erro')
    } finally {
      setSalvarLoading(false);
      handleUpdateGrid(false);
    }
  }

  const pessoaForm = useForm<PessoaFormModel>({
    resolver: yupResolver(CadastroMotoristaSchema),
  });

  useEffect(() => {
    (async () => {verificarAutorizacao([PerfilUsuario.Administrador, PerfilUsuario.Operacao])})()
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = pessoaForm;

  return (
    <form>
      <OptBackdrop open={loading} />
      <Divider />
      <Grid
        className={styles.GridContent}
        container
        rowSpacing={1}
        columns={12}
        px={1.5}
      >
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                required={true}
                label="Nome"
                variant="outlined"
                onChange={handleNome}
                value={motorista.nome}
              />
            )}
          />
          <ErrorMessage error={errors.nome} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="cpf"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                required={false}
                label="Cpf"
                variant="outlined"
                onChange={handleCpf}
                value={motorista.cpf}
              />
            )}
          />
          <ErrorMessage error={errors.cpf} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="telefone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                required={true}
                label="Telefone"
                variant="outlined"
                onChange={handleTelefone}
                value={motorista.telefone}
              />
            )}
          />
          <ErrorMessage error={errors.telefone} />
        </Grid>

        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="E-mail"
                variant="outlined"
                onChange={handleEmail}
                value={motorista.email}
              />
            )}
          />
          <ErrorMessage error={errors.email} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="idEmpresa"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ width: 1 }} size="medium">
                <Autocomplete
                  disabled
                  id='select-label-empresa'
                  className={styles.TextFieldCustom}
                  options={empresas}
                  getOptionLabel={(option: any) => option.nome || nomeEmpresa}
                  onChange={(e, value) => handleEmpresa(value.id)}
                  defaultValue={idEmpresa}
                  renderInput={(params) => <TextField {...params} label="Empresa" required />}
                />
              </FormControl>
            )}
          />
          <ErrorMessage error={errors.idEmpresa} />
        </Grid>
      </Grid>
      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button
          size="large"
          onClick={handleSubmit(salvar)}
          sx={sxButtonSaveConfig}
        >
          {idPessoa ? "Atualizar" : "Cadastrar motorista"}
        </Button>
      </FooterToolbar>
    </form>
  );
};
