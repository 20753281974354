import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { VendaSearchResponse } from "../modelos/dtos/VendaSearchResponse";
import { protectedResources } from "../authConfig";
import { VendaFormModel } from "../modelos/venda/vendaFormModel";
import { PaginatedVendaRequest } from "../modelos/search/PaginatedVendaRequest";

export const useVendasService = () => {
  const { get, post, remove, put, gridPagedListSearch } = useApi({scopes: protectedResources.apiVenda.scopes});

  const baseApi = `venda/Venda`;

  const buscarGridVendasPaginated = async (data: SearchRequest<PaginatedVendaRequest>) => {
    return gridPagedListSearch<VendaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, {...data.filtro});
  };
  
  const carregarVendasPorOcorrencia = async (idOcorrencia :string) => {
    return post<VendaSearchResponse[]>(`${baseApi}/obterPorOcorrencia/${idOcorrencia}`, {});
  };
  
  const capturarVendas = async (idsVendas: string[]) => {
    return post<VendaSearchResponse[]>(`${baseApi}/capturarVendas`, { IdsVendas: idsVendas });
  };
  
  const capturarVendasAutorizadasOcorrencia = async (idOcorrencia: string) => {
    return post<VendaSearchResponse[]>(`${baseApi}/capturarOcorrencia/${idOcorrencia}`, { });
  };
  
  const cancelarVendas = async (idsVendas: string[]) => {
    return post<VendaSearchResponse[]>(`${baseApi}/cancelarVendas`, { IdsVendas: idsVendas });
  };
  
  const reembolsarVendas = async (idsVendas: string[]) => {
    return post<VendaSearchResponse[]>(`${baseApi}/reembolsarVendas`, { IdsVendas: idsVendas });
  };

  return {
    buscarGridVendasPaginated,
    carregarVendasPorOcorrencia,
    capturarVendas,
    capturarVendasAutorizadasOcorrencia,
    cancelarVendas,
    reembolsarVendas
  };
};