export const MASCARA_DINHEIRO = (valor: string) => {
  
  let valorEmInt = parseInt(valor.replace(/[\D]+/g, ''))
  if (isNaN(valorEmInt)) valorEmInt = 0;
  
  valor = valorEmInt.toString();
  if (valor.length == 1) valor = "00" + valor; 
  if (valor.length == 2) valor = "0" + valor; 
  
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  
  return valor;
}