import { Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { DiaSemana } from "../../enums/DiaSemana";
import { MesAno } from "../../enums/MesAno";
import { DataRecorrencia } from "../../modelos/dtos/DataRecorrencia";
import { EventoFormModel } from "../../modelos/evento/eventoFormModel";

interface Props {
  control: Control<EventoFormModel, any>
  setEvento: Function,
  evento: EventoFormModel,
  setValue: Function
}

export const RecorrenciaSemanalGrid = ({ control, evento, setEvento, setValue }: Props) => {

  const [listaDiaSemana, setListaDiaSemana] = useState(new Array<any>());
  const [listaMesAno, setListaMesAno] = useState(new Array<any>());
  const [recorrencia, setRecorrencia] = useState<DataRecorrencia>(evento.recorrencia!);
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 5);
  const minDateString = minDate.toJSON().split('T')[0];
  const maxDateString = maxDate.toJSON().split('T')[0];

  useEffect(() => {
    carregarListaDiaSemana();
    carregarListaMesAno();
  }, []);

  function carregarListaDiaSemana() {
    const itens = Object.keys(DiaSemana).filter((item) => {
      return isNaN(Number(item));
    });
    setListaDiaSemana(itens);
  }


  function carregarListaMesAno() {
    const itens = Object.keys(MesAno).filter((item) => {
      return isNaN(Number(item));
    });
    setListaMesAno(itens);
  }

  function atualizarRecorrencia(propriedade: string, valor: any) {

    switch (propriedade) {
      case "inicio":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, inicio: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, inicio: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, inicio: valor }));
        break;
      case "intervalo":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, intervalo: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, intervalo: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, intervalo: valor }));
        break;
      case "fim":
        setEvento((eventoAtual: any) => {
          setValue('recorrencia', { ...eventoAtual.recorrencia, fim: valor });
          return {
            ...eventoAtual,
            recorrencia: { ...eventoAtual.recorrencia, fim: valor },
          }
        });
        setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, fim: valor }));
        break;
    }

    return recorrencia;
  }

  function handleRecorrencia(event: any, propriedade: string) {
    const valor = event.target.value;
    atualizarRecorrencia(propriedade, valor);
  }

  function handleRecorrenciaDias(event: any, listaDiasDaSemana: number[]) {
    setEvento((eventoAtual: any) => {
      setValue('recorrencia', { ...eventoAtual.recorrencia, listaDiasDaSemana });
      return {
        ...eventoAtual,
        recorrencia: { ...eventoAtual.recorrencia, listaDiasDaSemana },
      }
    });
    setRecorrencia(recorrenciaAtual => ({ ...recorrenciaAtual, listaDiasDaSemana }));
  }

  return (
    <>
      <Grid item xs={6} marginBottom={2} >
        <Controller
          name="recorrencia.inicio"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              style={{ marginRight: 5 }}
              type="date"
              label="Início"
              InputLabelProps={{ shrink: true }}
              value={recorrencia.inicio}
              onChange={event => (handleRecorrencia(event, 'inicio'))}
              inputProps={{ min: minDateString, max: maxDateString }}
            />
          )}
        />

        <Controller
          name="recorrencia.fim"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              type="date"
              label="Final"
              InputLabelProps={{ shrink: true }}
              value={recorrencia.fim}
              onChange={event => (handleRecorrencia(event, 'fim'))}
              inputProps={{ min: minDateString, max: maxDateString }}
            />
          )}
        />

      </Grid>
      <Grid item xs={6} marginBottom={2}>
        <Controller
          name="recorrencia.intervalo"
          control={control}
          render={({ field: { onChange } }) => (
            <TextField
              style={{ marginRight: 10 }}
              type="number"
              inputProps={{ min: 0, max: 100, maxLength: 3 }}
              value={recorrencia.intervalo}
              label="A cada"
              onChange={event => (handleRecorrencia(event, 'intervalo'))}
            />
          )}
        />
        <div className="mt-3 d-inline-block"> Semana(s)</div>
      </Grid>
      <Grid item xs={6} marginBottom={2}>
        <ToggleButtonGroup
          color="info"
          value={recorrencia?.listaDiasDaSemana}
          onChange={handleRecorrenciaDias}
          aria-label="Platform">

          {listaDiaSemana.map(dia => {
            return (
              <ToggleButton key={DiaSemana[dia]} value={DiaSemana[dia]}>
                {dia}
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      </Grid>
    </>
  );
};