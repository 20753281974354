import { Route, Switch } from "react-router-dom";
import Home from "../paginas/home/Home";
import { CategoriasRoutes } from "./CategoriasRoutes";
import { ComboRoutes } from "./ComboRoutes";
import { EmpresasRoutes } from "./EmpresasRoutes";
import { EventosRoutes } from "./EventosRoutes";
import { FrotasRoutes } from "./FrotasRoutes";
import { ItensRoutes } from "./Itens.routes";
import { MotoristasRoutes } from "./MotoristasRoutes";
import { OcorrenciaRoutes } from "./OcorrenciaRoutes";
import { RotasRoutes } from "./RotasRoutes";
import { SmsRoutes } from "./Sms.routes";
import { TransferirPassageirosRoutes } from "./TransferirPassageiros.routes";

import { UsuariosRoutes } from "./UsuariosRoutes";
import { ViagensRoutes } from "./Viagens.routes";
import { VendasRoutes } from "./VendasRoutes";
import { ImportacaoRoutes } from "./ImportacaoRoutes";
import { BagagensRoutes } from "./Bagagens.routes";
import { EmailRoutes } from "./Email.routes";
import { GerenciaComplementoRoutes } from "./GerenciaComplemento.routes";
import { PromotoresRoutes } from "./Promotores.routes";
import { PassageirosRoutes } from "./PassageirosRoutes";
import { SeoRoutes } from "./SeoRoutes";
import { RsvpRoutes } from "./Rsvp.routes";

export const Rotas = {
  Home: "/",

  Itens: {
    Principal: "/itens",
    Cadastro: "/itens/cadastro/:idCupom?/:tipoCupom?"
  },
  Viagens: {
    Principal: "/viagens",
  },
  GerenciaComplemento: {
    Principal: "/gerencia-complemento",
  },
  Promotores: {
    Principal: "/promotores/:idEvento",
    AssociarAoEvento: "/promotores/associar-evento/:idEvento",
    AssociarAoPontoDeEmbarque: "/promotores/associar-ponto-embarque/:idEvento",
  },
  Sms: {
    Principal: "/sms/:idEvento?/:idOcorrencia?/:idPontoEmbarque?",
  },
  TransferirPassageiros: {
    Principal: "/transferir-passageiros/:idEvento/:idOcorrencia",
  },
  Email: {
    Principal: "/email/:idEvento?/:idOcorrencia?/:idPontoEmbarque?",
  },
  Bagagens: {
    Principal: "/bagagens",
  },
  Rsvp: {
    Principal: "/rsvp",
  },
  Empresas: {
    Principal: "/empresas",
    Cadastro: "/empresas/cadastro/:idEmpresa?",
    VincularMotorista: "/empresas/:idEMpresa/vincularMotorista"
  },
  Usuarios: {
    Principal: "/usuarios",
    Cadastro: "/usuarios/cadastro/:idPessoa?"
  },
  Frotas: {
    Principal: "/frotas",
    Cadastro: "/frotas/cadastro/:idVeiculo?"
  },
  Eventos: {
    Principal: "/eventos",
    Cadastro: "/eventos/cadastro/:idEvento?",
    Ver: "/eventos/ver/:idEvento"
  },
  Vendas: {
    Ver: "/vendas/ver/:idEvento/:idOcorrencia"
  },
  Passageiros: {
    Index: "/passageiros"
  },
  Seo: {
    Index: "/seo"
  },
  Categorias: {
    Principal: "/categorias",
    Cadastro: "/categorias/cadastro/:idCategoria?"
  },
  Rotas: {
    Principal: "/rotas/:idEvento?",
    Cadastro: "/rotas/cadastro/:idOcorrencia/:idRota?",
    Ver: "/rotas/ver/:idOcorrencia/:idRota",
    AssociarPassageiros: "/rotas/associar-passageiros/:idRota"
  },
  Ocorrencias: {
    Principal: "/ocorrencias/:idEvento?",
    Cadastro: "/ocorrencias/cadastro/:idEvento?/:idOcorrencia?",
    Ver: "/ocorrencias/ver/:idOcorrencia",
    Relatorio: '/ocorrencias/relatorio/:idOcorrencia/:idEvento'
  },
  Combos: {
    Cadastro: "/combos/cadastro/:idOcorrencia/:idCombo?",
  },
  Importacao: {
    Importar: "/importar-passageiros/:idOcorrencia/:idPontoEmbarque?",
  },
  ImportacaoPassageirosEvento: {
    Importar: "/importar-passageiros-evento/:idEvento",
  }
} as const;

export const rotas = (
  <Switch>
    <Route exact path={Rotas.Home} component={Home} />
    <Route exact path={Rotas.Itens.Principal} component={ItensRoutes} />
    <Route exact path={Rotas.Itens.Cadastro} component={ItensRoutes} />
    <Route exact path={Rotas.Viagens.Principal} component={ViagensRoutes} />
    <Route exact path={Rotas.Promotores.Principal} component={PromotoresRoutes} />
    <Route exact path={Rotas.Promotores.AssociarAoEvento} component={PromotoresRoutes} />
    <Route exact path={Rotas.Promotores.AssociarAoPontoDeEmbarque} component={PromotoresRoutes} />
    <Route exact path={Rotas.Sms.Principal} component={SmsRoutes} />
    <Route exact path={Rotas.GerenciaComplemento.Principal} component={GerenciaComplementoRoutes} />
    <Route exact path={Rotas.TransferirPassageiros.Principal} component={TransferirPassageirosRoutes} />
    <Route exact path={Rotas.Email.Principal} component={EmailRoutes} />
    <Route exact path={Rotas.Rsvp.Principal} component={RsvpRoutes} />
    <Route exact path={Rotas.Bagagens.Principal} component={BagagensRoutes} />
    <Route exact path={Rotas.Empresas.Principal} component={EmpresasRoutes} />
    <Route exact path={Rotas.Empresas.Cadastro} component={EmpresasRoutes} />
    <Route exact path={Rotas.Empresas.VincularMotorista} component={EmpresasRoutes} />
    <Route exact path={Rotas.Usuarios.Principal} component={UsuariosRoutes} />
    <Route exact path={Rotas.Usuarios.Cadastro} component={UsuariosRoutes} />
    <Route exact path={Rotas.Eventos.Principal} component={EventosRoutes} />
    <Route exact path={Rotas.Eventos.Cadastro} component={EventosRoutes} />
    <Route exact path={Rotas.Eventos.Ver} component={EventosRoutes} />
    <Route exact path={Rotas.Vendas.Ver} component={VendasRoutes} />
    <Route exact path={Rotas.Categorias.Principal} component={CategoriasRoutes} />
    <Route exact path={Rotas.Categorias.Cadastro} component={CategoriasRoutes} />
    <Route exact path={Rotas.Rotas.Principal} component={RotasRoutes} />
    <Route exact path={Rotas.Rotas.Cadastro} component={RotasRoutes} />
    <Route exact path={Rotas.Rotas.Ver} component={RotasRoutes} />
    <Route exact path={Rotas.Rotas.AssociarPassageiros} component={RotasRoutes} />
    <Route exact path={Rotas.Ocorrencias.Principal} component={OcorrenciaRoutes} />
    <Route exact path={Rotas.Ocorrencias.Principal} component={OcorrenciaRoutes} />
    <Route exact path={Rotas.Ocorrencias.Cadastro} component={OcorrenciaRoutes} />
    <Route exact path={Rotas.Ocorrencias.Ver} component={OcorrenciaRoutes} />
    <Route exact path={Rotas.Ocorrencias.Relatorio} component={OcorrenciaRoutes} />
    <Route exact path={Rotas.Combos.Cadastro} component={ComboRoutes} />
    <Route exact path={Rotas.Importacao.Importar} component={ImportacaoRoutes} />
    <Route exact path={Rotas.ImportacaoPassageirosEvento.Importar} component={ImportacaoRoutes} />
    <Route exact path={Rotas.Passageiros.Index} component={PassageirosRoutes} />
    <Route exact path={Rotas.Seo.Index} component={SeoRoutes} />
    <Route path="*" component={Home} />
  </Switch>
);
