import { EmpresaFormModel } from "../modelos/empresa/empresaFormModel";

export const EMPRESA_FORM_INITIAL_STATE: EmpresaFormModel = {
    telefone: "",
    cnpj: "",
    nome: "",
    descricao: "",
    cep: "",
    endereco: "",
    email: "",
  };
  