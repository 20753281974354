import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Rotas } from ".";
import { CadastroCategoria } from "../paginas/categoria/Cadastro/CadastroCategoria";
import { GridCategorias } from "../paginas/categoria/ListarCategoria/GridCategoria";
export const CategoriasRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Rotas.Categorias.Principal}>
          <GridCategorias />
          </Route>
          <Route exact path={Rotas.Categorias.Cadastro}>
            <CadastroCategoria />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
