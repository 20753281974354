import * as Yup from "yup";
import { TransferirPassageirosSubmitModel } from "../../../modelos/transferirPassageiros/submitModel";

export const FormularioTransferirPassageirosSchema: Yup.SchemaOf<TransferirPassageirosSubmitModel> =
  Yup.object().shape({
    idEvento: Yup.string().required("Campo obrigatório"),
    idOcorrencia: Yup.string().required("Campo obrigatório"),
    idPontoEmbarque: Yup.string().required("Campo obrigatório"),
    idsReservas: Yup.array().required("Campo obrigatório"),
  });
