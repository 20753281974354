import { TipoRecorrencia } from "../../enums/TipoRecorrencia";

export class DataRecorrencia {
    tipo: TipoRecorrencia = TipoRecorrencia.Diaria;
    inicio: string = "";
    fim: string = "";
    intervalo: number = 0;
    dia: number = 0;
    mes: string = "";
    diaDaSemana: string = "";
    posicaoDaSemana: string = "";
    listaDiasDaSemana: Array<number> = new Array();
    padrao: boolean = true;
  }