import * as Yup from "yup";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";


export const CadastroPessoaSchema: Yup.SchemaOf<PessoaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    email: Yup.string().required("Item obrigatório"),
    telefone: Yup.string().notRequired(),
    cpf: Yup.string().notRequired(),
    documento: Yup.string().notRequired(),
    tipoDocumento: Yup.mixed().notRequired(),
    sexo: Yup.mixed().notRequired(),
    perfil: Yup.mixed().required("Item obrigatório"),
    idEmpresa: Yup.string().notRequired(),
    idAcompanhante: Yup.string().notRequired(),
    maiorDeIdade: Yup.boolean().notRequired(),
  });