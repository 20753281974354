import * as Yup from "yup";
import { PontoRotaFormModel } from "../../../modelos/rota/PontoRotaFormModel";

export const FormularioPontoRotaSchema: Yup.SchemaOf<PontoRotaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    cidade: Yup.string().required("Item obrigatório"),
    estado: Yup.string().required("Item obrigatório"),
    lat: Yup.number().required("Item obrigatório"),
    lon: Yup.number().required("Item obrigatório"),
    bairro: Yup.string().nullable().notRequired(),
    numero: Yup.string().nullable().notRequired(),
    pontoReferencia: Yup.string().notRequired(),
    rua: Yup.string().required("Item obrigatório"),
    complemento: Yup.string().notRequired(),
    idOcorrencia: Yup.string().required("Item obrigatório"),
    pontoEmbarque: Yup.boolean().notRequired(),
    idaEVolta: Yup.boolean().notRequired(),
    destaque: Yup.boolean().notRequired(),
    aberto: Yup.boolean().notRequired(),
    horarioIdaPartida: Yup.date().notRequired(),
    horarioIdaChegada: Yup.date().notRequired(),
    horarioVoltaPartida: Yup.date().notRequired(),
    horarioVoltaChegada: Yup.date().notRequired(),
    precoPassagem: Yup.number().typeError("Valor inválido! Forneça um número.").when("pontoEmbarque", {
      is: true,
      then: Yup.number().required("Item obrigatório").typeError("Valor inválido! Forneça um número.")
    }).when("pontoEmbarque", {
      is: false,
      then: Yup.number().typeError("Valor inválido! Forneça um número.").notRequired()
    }),
    passagensDisponiveis: Yup.number().nullable().notRequired(),
    passagensVendidas: Yup.number().nullable().notRequired(),
  });
