import { CategoriaFormModel } from "../modelos/categoria/categoriaFormModel";

export const CATEGORIA_FORM_INITIAL_STATE: CategoriaFormModel = {
    nome: "",
    descricao: "",
    blobImagemMobilePadrao: '',
    blobImagemMobilePadraoName: '',
    blobImagemPadrao: '',
    blobImagemPadraoName: ''
  };
  