import * as Yup from "yup";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";

export const VincularMotoristaSchema: Yup.SchemaOf<PessoaFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required("Item obrigatório"),
    email: Yup.string().notRequired(),
    telefone: Yup.string().required("Item obrigatório"),
    cpf: Yup.string().required("Item obrigatório"),
    tipoDocumento: Yup.mixed().notRequired(),
    sexo: Yup.mixed().notRequired(),
    documento: Yup.string().required("Item obrigatório"),
    perfil: Yup.mixed().notRequired(),
    idEmpresa: Yup.mixed().required("Item obrigatório"),
    idAcompanhante: Yup.mixed().notRequired(),
    maiorDeIdade: Yup.bool().required("Motorista deve ser maior de idade"),
  });