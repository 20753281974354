import { yupResolver } from "@hookform/resolvers/yup";
import { mdiPlus, mdiMinus, mdiChevronLeft } from "@mdi/js";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { Controller, useForm } from "react-hook-form";
import Icon from "@mdi/react";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Colors } from "../../../compartilhado/colors";
import { ErrorMessage } from "../../../componentes/errorMessage/ErrorMessage";
import { FooterToolbar } from "../../../componentes/footerToolbar";
import { ItemFormModel } from "../../../modelos/item/ItemFormModel";
import styles from "./styles/formularioItem.module.scss";
import { FormularioItemSchema } from "./FormularioItem.validation";
import { Rotas } from "../../../rotas";
import { ITEM_FORM_INITIAL_STATE } from "../../../constantes/ItemFormInitialState";
import { useItensService } from "../../../servicos/itens.service";
import { MASCARA_DINHEIRO } from "../../../constantes/mascaras/MascaraDinheiro";
import { MASCARA_NUMEROS } from "../../../constantes/mascaras/MascaraNumeros";
import { usePessoasService } from "../../../servicos/pessoas.service";
import { PessoaFormModel } from "../../../modelos/pessoa/pessoaFormModel";
import { CupomUsuario } from "../../../modelos/dtos/CupomUsuario";
import { useCategoriasService } from "../../../servicos/categorias.service";
import { CategoriaFormModel } from "../../../modelos/categoria/categoriaFormModel";
import { CupomEvento } from "../../../modelos/dtos/CupomEvento";
import { useEventosService } from "../../../servicos/eventos.service";
import { useOcorrenciasService } from "../../../servicos/ocorrencias.service";
import { OcorrenciaModel } from "../../../modelos/ocorrencia/ocorrenciaModel";
import { EventoFormModel } from "../../../modelos/evento/eventoFormModel";
import { useRotasService } from "../../../servicos/rotas.service";
import { RotaFormModel } from "../../../modelos/rota/rotaFormModel";
import { TipoCupom } from "../../../enums/TipoCupom";
import { PerfilUsuario } from "../../../enums/PerfilUsuario";
import { boolean } from "yup";
import { OcorrenciaFormModel } from "../../../modelos/ocorrencia/ocorrenciaFormModel";
import { PontoRotaSearchResponse } from "../../../modelos/dtos/PontoRotaSearchResponse";

export const FormularioItem = () => {
  const { idCupom } = useParams<{ idCupom?: string }>();
  const { tipoCupom } = useParams<{ tipoCupom?: string }>();
  const edicao = Boolean(idCupom);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    salvarItem,
    editarItem,
    carregarCupomPorEvento,
    carregarCupomPorUsuario,
  } = useItensService();
  const onClose = () => {
    history.push(Rotas.Itens.Principal);
  };
  const [loading, setSalvarLoading] = useState<boolean>(false);
  const [cupom, setCupom] = useState<ItemFormModel>(ITEM_FORM_INITIAL_STATE);
  const { carregarPessoas } = usePessoasService();
  const { carregarCategorias } = useCategoriasService();
  const { carregarOcorrenciasPorEvento, carregarOcorrenciaPorId } = useOcorrenciasService();
  const { carregarEventos } = useEventosService();
  const [pessoas, setPessoas] = useState<Array<PessoaFormModel>>(new Array<PessoaFormModel>());
  const [clientes, setClientes] = useState<Array<PessoaFormModel>>(new Array<PessoaFormModel>());
  const [categorias, setCategorias] = useState<Array<CategoriaFormModel>>(new Array<CategoriaFormModel>());
  const [ocorrenciasUsuario, setOcorrenciasUsuario] = useState<Array<OcorrenciaModel>>(new Array<OcorrenciaModel>());
  const [ocorrenciasEvento, setOcorrenciasEvento] = useState<Array<OcorrenciaModel>>(new Array<OcorrenciaModel>());
  const [rotas, setRotas] = useState<Array<RotaFormModel>>(new Array<RotaFormModel>());
  const [rotasEvento, setRotasEvento] = useState<Array<PontoRotaSearchResponse>>(new Array<PontoRotaSearchResponse>());
  const [eventos, setEventos] = useState<Array<EventoFormModel>>(new Array<EventoFormModel>());
  const [desabilitaBotaoSalvar, setDesabilitaBotaoSalvar] = useState<boolean>(true);
  const { verificarAutorizacao } = useEventosService();

  const useParamForm = useForm<ItemFormModel>({
    resolver: yupResolver(FormularioItemSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useParamForm;

  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  async function carregarComboPessoas() {
    const response =
      (await carregarPessoas()) as unknown as Array<PessoaFormModel>;
    if (response) {
      setPessoas(response);

      const clientes = response.filter(
        (p) => p.perfil === PerfilUsuario.Cliente
      );
      setClientes(clientes);
    }
  }

  async function carregarComboCategorias() {
    const response =
      (await carregarCategorias()) as unknown as Array<CategoriaFormModel>;
    if (response) {
      setCategorias(response);
    }
  }

  async function carregarComboEventos() {
    const response =
      (await carregarEventos()) as unknown as Array<EventoFormModel>;
    if (response) {
      setEventos(response);
    }
  }

  async function carregarComboOcorrenciasUsuario(idsEventos: string[]) {
    let ocorr: OcorrenciaModel[] = [];

    for (const id of idsEventos) {
      if (id) {
        const response = (await carregarOcorrenciasPorEvento(id)) as unknown as Array<OcorrenciaModel>;
        if (response) {
          ocorr = ocorr.concat(response);
        }
      }
    }

    setOcorrenciasUsuario(ocorr);
  }

  async function carregarComboOcorrenciasEvento(idEvento: string) {
    if (idEvento) {
      const response = (await carregarOcorrenciasPorEvento(idEvento)) as unknown as Array<OcorrenciaModel>;
      if (response) {
        setOcorrenciasEvento(response);
      }
    }
  }

  async function carregarComboRotas(idsOcorencias: string[]) {
    let rotas: any[] = [];

    for (const id of idsOcorencias) {
      if (id) {
        const response = (await carregarOcorrenciaPorId(id)) as unknown as OcorrenciaFormModel;
        if (response && response.pontosRota) {
          response.pontosRota.map((rota) => {
            rotas.push(rota)
          })
        }
      }
    }

    setRotas(rotas);
  }

  async function carregarComboRotasEvento(idOcorencia: string) {

    if (idOcorencia) {
      const response = (await carregarOcorrenciaPorId(idOcorencia)) as unknown as OcorrenciaFormModel;
      if (response && response.pontosRota) {
        setRotasEvento(response.pontosRota);
      }
    }

  }

  async function carregarCupom(idCupom: string, tipoCupom: string) {
    let response = null;
    if (Number(tipoCupom) === TipoCupom.PorUsuario) {
      response = (await carregarCupomPorUsuario(idCupom)) as unknown as ItemFormModel;
    } else {
      response = (await carregarCupomPorEvento(idCupom)) as unknown as ItemFormModel;
    }
    response.valorPercentual = (response.valorPercentual??0)*100;

    response.valorTotalStr = "0,00";
    response.valorPercentualStr = "0,00";
    if (response) {
      if (response.valorTotal) {
        response.valorTotalStr = MASCARA_DINHEIRO(response.valorTotal.toFixed(2));
      }

      if (response.valorPercentual) {
        response.valorPercentualStr = MASCARA_DINHEIRO(response.valorPercentual.toFixed(2));
      }

      if (response.dataInicio && response.dataFim) {
        response.dataInicio = new Date(response.dataInicio)
        response.dataFim = new Date(response.dataFim)
      }

      setCupom(response);
      setValue("id", response.id);
      setValue("codigo", response.codigo);
      setValue("descricao", response.descricao);
      setValue("dataFim", response.dataFim);
      setValue("dataInicio", response.dataInicio);
      setValue("eventosPermitidos", response.eventosPermitidos);
      setValue("usuariosPermitidos", response.usuariosPermitidos);
      setValue("limiteReservas", response.limiteReservas);
      setValue("limiteResgate", response.limiteResgate);
      setValue("limiteResgateUsuario", response.limiteResgateUsuario);
      setValue("minimoReservas", response.minimoReservas);
      setValue("valorPercentual", response.valorPercentual);
      setValue("valorPercentualStr", response.valorPercentualStr);
      setValue("valorTotal", response.valorTotal);
      setValue("valorTotalStr", response.valorTotalStr);
    }
  }

  useEffect(() => {
    carregarComboPessoas();
    carregarComboCategorias();
    carregarComboEventos();

    if (idCupom && tipoCupom) {
      carregarCupom(idCupom, tipoCupom);
    }
  }, [idCupom, tipoCupom]);

  useEffect(() => {
    console.log(errors)
  }, [errors]);

  useEffect(() => {
    const desabilitaBotaoSalvar = podeSalvar() == false;
    setDesabilitaBotaoSalvar(desabilitaBotaoSalvar);
  }, [cupom, cupom.eventosPermitidos, cupom.usuariosPermitidos]);

  useEffect(() => {
    (async () => {
      verificarAutorizacao([PerfilUsuario.Administrador]);
    })()
  }, []);

  async function salvar() {
    setSalvarLoading(true);
    const estaEditando = !!cupom.id;
    const operacao = estaEditando ? "editado" : "criado";

    if (cupom.usuariosPermitidos)
      cupom.usuariosPermitidos = cupom.usuariosPermitidos.map(u => {
        return {
          idUsuario: !u.idUsuario ? undefined : u.idUsuario,
          categoriasPermitidas: u.categoriasPermitidas.filter(x => !!x),
          eventosPermitidos: u.eventosPermitidos.filter(x => !!x),
          ocorrenciasPermitidas: u.ocorrenciasPermitidas.filter(x => !!x),
          pontosRotaPermitidos: u.pontosRotaPermitidos.filter(x => !!x)
        };
      });

    if (cupom.eventosPermitidos)
      cupom.eventosPermitidos = cupom.eventosPermitidos.map(e => {
        return {
          idCategoria: !e.idCategoria ? undefined : e.idCategoria,
          idEvento: !e.idEvento ? undefined : e.idEvento,
          idOcorrencia: !e.idOcorrencia ? undefined : e.idOcorrencia,
          idPontoRota: !e.idPontoRota ? undefined : e.idPontoRota,
          usuariosPermitidos: e.usuariosPermitidos.filter(x => !!x)
        };
      });

    try {
      if (edicao) {
        await editarItem(cupom.id!, {...cupom, valorPercentual: (cupom.valorPercentual??0)/100});
      } else {
        await salvarItem({...cupom, valorPercentual: (cupom.valorPercentual??0)/100});
      }

      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(`Registro ${operacao} com erro!`, {
        variant: "error",
      });
    } finally {
      setSalvarLoading(false);
    }
  }

  function handleCodigo(event: any) {
    const codigo = event.target.value.toUpperCase();
    setCupom({ ...cupom, codigo });
    setValue("codigo", codigo);
  }

  function handleDescricao(event: any) {
    const descricao = event.target.value;
    setCupom({ ...cupom, descricao });
    setValue("descricao", descricao);
  }

  function handleValorTotal(event: any) {
    const valorTotalStr = MASCARA_DINHEIRO(event.target.value);
    const valorTotal = Number(valorTotalStr.replace(",", "."));
    setCupom({ ...cupom, valorTotal, valorTotalStr });
    setValue("valorTotal", valorTotal);
    setValue("valorTotalStr", valorTotalStr);
  }

  function handleValorPercentual(event: any) {
    let valor = event.target.value
    if(valor[valor.length] === '0'){
      valor = `${valor}0`
    }
    let valorPercentualStr = MASCARA_DINHEIRO(valor);
    let valorPercentual = Number(valorPercentualStr.replace(",", "."));

    if(valorPercentual > 100){
      valorPercentual = 10000
      valorPercentualStr = MASCARA_DINHEIRO(valorPercentual.toString());
    }
    else if(valorPercentual < 0){
      valorPercentual = 0
      valorPercentualStr = MASCARA_DINHEIRO(valorPercentual.toString());
    }

    setCupom({ ...cupom, valorPercentual, valorPercentualStr });
    setValue("valorPercentual", valorPercentual);
    setValue("valorPercentualStr", valorPercentualStr);
  }

  function handleDataInicio(event: any) {
    const dataInicio = event;
    setCupom({ ...cupom, dataInicio });
    setValue("dataInicio", dataInicio);
  }

  function handleDataFim(event: any) {
    const dataFim = event;
    setCupom({ ...cupom, dataFim });
    setValue("dataFim", dataFim);
  }

  function handleLimiteResgate(event: any) {
    const limiteResgate = Number(MASCARA_NUMEROS(event.target.value));
    setCupom({ ...cupom, limiteResgate });
    setValue("limiteResgate", limiteResgate);
  }

  function handleLimiteResgateUsuario(event: any) {
    const limiteResgateUsuario = Number(MASCARA_NUMEROS(event.target.value));
    setCupom({ ...cupom, limiteResgateUsuario });
    setValue("limiteResgateUsuario", limiteResgateUsuario);
  }

  function handleMinimoReservas(event: any) {
    const minimoReservas = Number(MASCARA_NUMEROS(event.target.value));
    setCupom({ ...cupom, minimoReservas });
    setValue("minimoReservas", minimoReservas);
  }

  function handleLimiteReservas(event: any) {
    const limiteReservas = Number(MASCARA_NUMEROS(event.target.value));
    setCupom({ ...cupom, limiteReservas });
    setValue("limiteReservas", limiteReservas);
  }

  function handleUsuarioPermitidoIdUsuario(event: any, usuarioPermitido: CupomUsuario) {
    usuarioPermitido.idUsuario = event.target.value;
    setValue("usuariosPermitidos", cupom.usuariosPermitidos);
    setCupom({ ...cupom });
  }

  function handleUsuarioPermitidoCategoriasPermitidas(event: any, usuiarioPermitido: CupomUsuario, index: number) {
    usuiarioPermitido.categoriasPermitidas = event.target.value;
    setValue(`usuariosPermitidos.${index}.categoriasPermitidas`, cupom.usuariosPermitidos![index].eventosPermitidos);
    setCupom({ ...cupom });
  }

  function handleUsuarioPermitidoEventosPermitidas(event: any, usuarioPermitido: CupomUsuario, index: number) {
    usuarioPermitido.eventosPermitidos = event.target.value;
    setValue(`usuariosPermitidos.${index}.eventosPermitidos`, cupom.usuariosPermitidos![index].eventosPermitidos);
    carregarComboOcorrenciasUsuario(usuarioPermitido.eventosPermitidos);
    setCupom({ ...cupom });
  }

  function handleUsuarioPermitidoOcorrenciasPermitidas(event: any, usuarioPermitido: CupomUsuario, index: number) {
    usuarioPermitido.ocorrenciasPermitidas = event.target.value;
    setValue(`usuariosPermitidos.${index}.ocorrenciasPermitidas`, cupom.usuariosPermitidos![index].ocorrenciasPermitidas);
    carregarComboRotas(event.target.value);
    setCupom({ ...cupom });
  }

  function handleUsuarioPermitidopontosRotaPermitidos(event: any, usuarioPermitido: CupomUsuario, index: number) {
    usuarioPermitido.pontosRotaPermitidos = event.target.value;
    setValue(`usuariosPermitidos.${index}.pontosRotaPermitidos`, cupom.usuariosPermitidos![index].pontosRotaPermitidos);
    setCupom({ ...cupom });
  }

  function handleEventoPermitidoIdEvento(event: any, eventoPermitido: CupomEvento, index: number) {
    eventoPermitido.idEvento = event.target.value;
    setValue(`eventosPermitidos.${index}.idEvento`, cupom.eventosPermitidos![index].idEvento);
    carregarComboOcorrenciasEvento(event.target.value);
    setCupom({ ...cupom });
  }

  function handleEventoPermitidoIdCategoria(event: any, eventoPermitido: CupomEvento, index: number) {
    eventoPermitido.idCategoria = event.target.value;
    setValue(`eventosPermitidos.${index}.idCategoria`, cupom.eventosPermitidos![index].idCategoria);
    setCupom({ ...cupom });
  }

  function handleEventoPermitidoIdOcorrencia(event: any, eventoPermitido: CupomEvento, index: number) {
    eventoPermitido.idOcorrencia = event.target.value;
    setValue(`eventosPermitidos.${index}.idOcorrencia`, cupom.eventosPermitidos![index].idOcorrencia);
    carregarComboRotasEvento(event.target.value);
    setCupom({ ...cupom });
  }

  function handleEventoPermitidoIdPontoRota(event: any, eventoPermitido: CupomEvento, index: number) {
    eventoPermitido.idPontoRota = event.target.value;
    setValue(`eventosPermitidos.${index}.idPontoRota`, cupom.eventosPermitidos![index].idPontoRota);
    setCupom({ ...cupom });
  }

  function handleEventoPermitidoUsuariosPermitidos(event: any, eventoPermitido: CupomEvento, index: number) {
    eventoPermitido.usuariosPermitidos = event.target.value;
    setValue(`eventosPermitidos.${index}.usuariosPermitidos`, cupom.eventosPermitidos![index].usuariosPermitidos);
    setCupom({ ...cupom });
  }

  function adicionarEvento() {
    const eventosPermitidos = {
      idCategoria: "",
      idEvento: "",
      idOcorrencia: "",
      idPontoRota: "",
      usuariosPermitidos: new Array<string>(),
    };
    setCupom({ ...cupom, eventosPermitidos: [...cupom.eventosPermitidos!, eventosPermitidos] });
    setValue("eventosPermitidos", cupom.eventosPermitidos);
  }

  function removerEvento(index: number) {
    cupom.eventosPermitidos?.splice(index, 1);
    setCupom({ ...cupom, eventosPermitidos: cupom.eventosPermitidos });
    setValue("eventosPermitidos", cupom.eventosPermitidos);
  }

  function adicionarUsuario() {
    const novoUsuario: CupomUsuario = {
      idUsuario: "",
      categoriasPermitidas: new Array<string>(),
      eventosPermitidos: new Array<string>(),
      ocorrenciasPermitidas: new Array<string>(),
      pontosRotaPermitidos: new Array<string>(),
    };
    setCupom({
      ...cupom,
      usuariosPermitidos: [...cupom.usuariosPermitidos!, novoUsuario],
    });
    setValue("usuariosPermitidos", cupom.usuariosPermitidos);
  }

  function removerUsuario(index: number) {
    cupom.usuariosPermitidos?.splice(index, 1);
    setCupom({ ...cupom, usuariosPermitidos: cupom.usuariosPermitidos });
    setValue("usuariosPermitidos", cupom.usuariosPermitidos);
  }

  function podeSalvar(): boolean {
    let eventosValidos = true;
    let usuariosValidos = true;
    if (cupom.eventosPermitidos) {
      eventosValidos = cupom.eventosPermitidos.every((value: CupomEvento, index: number) => {
        return (value.idCategoria || value.idEvento || value.idOcorrencia || value.idPontoRota);
      })
    }

    if (cupom.usuariosPermitidos) {
      usuariosValidos = cupom.usuariosPermitidos.every((value: CupomUsuario, index: number) => {
        return (!!value.idUsuario);
      })
    }

    return Boolean(cupom && cupom.codigo && cupom.dataInicio && cupom.dataFim && eventosValidos && usuariosValidos);
  }

  return (
    <form>
      <OptBackdrop open={loading} />
      <div className={styles.CabecalhoContainer}>
        <OptActionButton
          onClick={onClose}
          startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
        >
          <span className={styles.SpanText}>
            {edicao ? "Editar cupom" : "Cadastro de cupom"}
          </span>
        </OptActionButton>
      </div>
      <Divider />
      <Grid
        className={styles.GridContent}
        container
        rowSpacing={1}
        columns={12}
        px={1.5}
      >
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="codigo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Código *"
                variant="outlined"
                onChange={handleCodigo}
                value={cupom.codigo}
              />
            )}
          />
          <ErrorMessage error={errors.codigo} />
        </Grid>
        <Grid className={styles.GridItem} item xs={6}>
          <Controller
            name="descricao"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                label="Descrição"
                variant="outlined"
                onChange={handleDescricao}
                value={cupom.descricao}
              />
            )}
          />
          <ErrorMessage error={errors.descricao} />
        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            name="valorTotal"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                inputProps={{ maxLength: 14 }}
                label="Valor Total"
                variant="outlined"
                onChange={handleValorTotal}
                value={cupom.valorTotalStr || ""}
              />
            )}
          />
          <ErrorMessage error={errors.valorTotal} />
        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            name="valorPercentual"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                className={styles.TextFieldCustom}
                inputProps={{ maxLength: 6 }}
                label="Percentual"
                variant="outlined"
                onChange={handleValorPercentual}
                value={cupom.valorPercentualStr || ""}
              />
            )}
          />
          <ErrorMessage error={errors.valorPercentual} />
        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            control={control}
            name="dataInicio"
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label="Data Início *"
                value={cupom.dataInicio ?? null}
                onChange={handleDataInicio}
                maxDate={watch("dataFim") ?? undefined}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            )}
          />
          <ErrorMessage error={errors.dataInicio} />
        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            control={control}
            name="dataFim"
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label="Data Fim *"
                value={cupom.dataFim ?? null}
                onChange={handleDataFim}
                minDate={watch("dataInicio")}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            )}
          />
          <ErrorMessage error={errors.dataFim} />
        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            control={control}
            name="limiteResgate"
            render={({ field: { onChange, value } }) => (
              <TextField
                aria-describedby="descricao-limite-resgate"
                className={styles.TextFieldCustom}
                inputProps={{ maxLength: 3 }}
                label="Limite de Resgate"
                variant="outlined"
                onChange={handleLimiteResgate}
                value={cupom.limiteResgate || ""}
              />
            )}

          />
          <ErrorMessage error={errors.limiteResgate} />
          <FormHelperText id="descricao-limite-resgate">Quantidade limite de cupons que podem ser resgatados.</FormHelperText>
        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            control={control}
            name="limiteResgateUsuario"
            render={({ field: { onChange, value } }) => (
              <TextField
                aria-describedby="descricao-limite-resgate-usuario"
                className={styles.TextFieldCustom}
                inputProps={{ maxLength: 3 }}
                label="Limite Resgate por Usuário"
                variant="outlined"
                onChange={handleLimiteResgateUsuario}
                value={cupom.limiteResgateUsuario || ""}
              />
            )}
          />
          <ErrorMessage error={errors.limiteResgateUsuario} />
          <FormHelperText id="descricao-limite-resgate-usuario">Quantidade limite de cupons que podem ser resgatados por usuário.</FormHelperText>

        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            control={control}
            name="minimoReservas"
            render={({ field: { onChange, value } }) => (
              <TextField
                aria-label="descricao-minimo-reservas"
                className={styles.TextFieldCustom}
                inputProps={{ maxLength: 3 }}
                label="Minimo Reservas"
                variant="outlined"
                onChange={handleMinimoReservas}
                value={cupom.minimoReservas || ""}
              />
            )}
          />
          <ErrorMessage error={errors.minimoReservas} />
          <FormHelperText id="descricao-minimo-reservas">Quantidade Minima de Reservas para o cupom.</FormHelperText>

        </Grid>

        <Grid className={styles.GridItem} item xs={3}>
          <Controller
            control={control}
            name="limiteReservas"
            render={({ field: { onChange, value } }) => (
              <TextField
                aria-label="descricao-limite-reservas"
                className={styles.TextFieldCustom}
                inputProps={{ maxLength: 3 }}
                label="Limite Reservas"
                variant="outlined"
                onChange={handleLimiteReservas}
                value={cupom.limiteReservas || ""}
              />
            )}
          />
          <ErrorMessage error={errors.limiteReservas} />
          <FormHelperText id="descricao-minimo-reservas">Quantidade Limite de Reservas para o cupom.</FormHelperText>
        </Grid>

        <Grid className={styles.GridItem} item xs={12}>
          <span>Por Usuário</span>
        </Grid>
        {cupom.usuariosPermitidos?.map((usuarioPermitido, index) => {
          return (
            <Grid className="d-flex flex-row" key={index} item xs={12}>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`usuariosPermitidos.${index}.idUsuario`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-usuarios-usuario"
                        required={true}
                      >
                        Usuário
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-usuarios-usuario"
                        label="Usuário"
                        onChange={(event) =>
                          handleUsuarioPermitidoIdUsuario(
                            event,
                            usuarioPermitido
                          )
                        }
                        variant="outlined"
                        value={usuarioPermitido.idUsuario || ""}
                      >
                        {clientes.map((pessoa) => {
                          return (
                            <MenuItem key={pessoa.id} value={pessoa.id}>
                              {pessoa.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`usuariosPermitidos.${index}.categoriasPermitidas`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-usuarios-categoria"
                      >
                        Categorias
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-usuarios-categoria"
                        label="Categoria"
                        multiple={true}
                        onChange={(event) =>
                          handleUsuarioPermitidoCategoriasPermitidas(
                            event,
                            usuarioPermitido,
                            index
                          )
                        }
                        variant="outlined"
                        value={usuarioPermitido.categoriasPermitidas}
                      >
                        {categorias.map((categoria) => {
                          return (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`usuariosPermitidos.${index}.eventosPermitidos`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-usuarios-eventos"
                      >
                        Eventos
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-usuarios-eventos"
                        label="Eventos"
                        multiple={true}
                        onChange={(event) =>
                          handleUsuarioPermitidoEventosPermitidas(
                            event,
                            usuarioPermitido,
                            index
                          )
                        }
                        variant="outlined"
                        value={usuarioPermitido.eventosPermitidos || ""}
                      >
                        {eventos.map((evento) => {
                          return (
                            <MenuItem key={evento.id} value={evento.id}>
                              {evento.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`usuariosPermitidos.${index}.ocorrenciasPermitidas`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-usuarios-ocorencias"
                      >
                        Datas do Evento
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-usuarios-ocorrencias"
                        label="Owner"
                        variant="outlined"
                        multiple={true}
                        onChange={(event) =>
                          handleUsuarioPermitidoOcorrenciasPermitidas(
                            event,
                            usuarioPermitido,
                            index
                          )
                        }
                        value={usuarioPermitido.ocorrenciasPermitidas || ""}
                      >
                        {ocorrenciasUsuario.map((ocorrencia) => {
                          return (
                            <MenuItem key={ocorrencia.id} value={ocorrencia.id}>
                              {ocorrencia?.nomeEvento}{" - "}
                              {format(
                                new Date(ocorrencia?.data.replace("Z", "")),
                                "dd/MM/yyyy"
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`usuariosPermitidos.${index}.pontosRotaPermitidos`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-usuarios-rotas"
                      >
                        Pontos de Embarque
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-usuarios-rotas"
                        label="Owner"
                        variant="outlined"
                        multiple={true}
                        onChange={(event) =>
                          handleUsuarioPermitidopontosRotaPermitidos(
                            event,
                            usuarioPermitido,
                            index
                          )
                        }
                        value={usuarioPermitido.pontosRotaPermitidos || ""}
                      >
                        {rotas.map((rota) => {
                          return (
                            <MenuItem key={rota.id} value={rota.id}>
                              {rota.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid>
                <Button
                  disabled={false}
                  variant="contained"
                  color="error"
                  style={{ marginRight: "2px" }}
                  onClick={() => removerUsuario(index)}
                  placeholder="Remover Usuário"
                >
                  <Icon path={mdiMinus} size={1} color={Colors.white} />
                </Button>
              </Grid>
            </Grid>
          );
        })}

        {edicao == false ||
          (tipoCupom && Number(tipoCupom) === TipoCupom.PorUsuario) ? (
          <Grid className="d-flex flex-row" item xs={12}>
            <Grid className={styles.GridItem} item xs={3}>
              <Button
                variant="contained"
                color="success"
                style={{ marginRight: "2px" }}
                onClick={adicionarUsuario}
                placeholder="Adicionar Usuário"
              >
                Adicionar Usuário{" "}
                <Icon path={mdiPlus} size={1} color={Colors.white} />
              </Button>
            </Grid>
          </Grid>
        ) : (
          <span></span>
        )}

        <Grid className={styles.GridItem} item xs={12}>
          <span>Por Evento</span>
        </Grid>
        {cupom.eventosPermitidos?.map((eventoPermitido, index) => {
          return (
            <Grid className="d-flex flex-row" key={index} item xs={12}>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`eventosPermitidos.${index}.idCategoria`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-eventos-categoria"
                      >
                        Categoria
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-eventos-categoria"
                        label="Owner"
                        variant="outlined"
                        onChange={(event) =>
                          handleEventoPermitidoIdCategoria(
                            event,
                            eventoPermitido,
                            index
                          )
                        }
                        value={eventoPermitido.idCategoria || ""}
                      >
                        {categorias.map((categoria) => {
                          return (
                            <MenuItem key={categoria.id} value={categoria.id}>
                              {categoria.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`eventosPermitidos.${index}.idEvento`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel id="select-label-evento">
                        Evento
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-evento"
                        label="Evento"
                        variant="outlined"
                        onChange={(event) =>
                          handleEventoPermitidoIdEvento(
                            event,
                            eventoPermitido,
                            index
                          )
                        }
                        value={eventoPermitido.idEvento || ""}
                      >
                        {eventos.map((evento) => {
                          return (
                            <MenuItem key={evento.id} value={evento.id}>
                              {evento.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`eventosPermitidos.${index}.idOcorrencia`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-eventos-ocorrencia"
                      >
                        Data do Evento
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-eventos-ocorrencia"
                        label="Owner"
                        variant="outlined"
                        onChange={(event) =>
                          handleEventoPermitidoIdOcorrencia(
                            event,
                            eventoPermitido,
                            index
                          )
                        }
                        value={eventoPermitido.idOcorrencia || ""}
                      >
                        {ocorrenciasEvento.map((ocorrencia) => {
                          return (
                            <MenuItem key={ocorrencia.id} value={ocorrencia.id}>
                              {ocorrencia?.nomeEvento}{" - "}
                              {format(
                                new Date(ocorrencia?.data.replace("Z", "")),
                                "dd/MM/yyyy"
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`eventosPermitidos.${index}.idPontoRota`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-eventos-rota"
                      >
                        Ponto de Embarque
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-eventos-eventos"
                        label="Rota"
                        variant="outlined"
                        onChange={(event) =>
                          handleEventoPermitidoIdPontoRota(
                            event,
                            eventoPermitido,
                            index
                          )
                        }
                        value={eventoPermitido.idPontoRota || ""}
                      >
                        {rotasEvento.map((rota) => {
                          return (
                            <MenuItem key={rota.id} value={rota.id}>
                              {rota.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid className={styles.GridItem} item xs={6}>
                <Controller
                  name={`eventosPermitidos.${index}.usuariosPermitidos`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: 1 }} size="medium">
                      <InputLabel
                        id="select-label-eventos-usuarios"
                      >
                        Usuários Permitidos
                      </InputLabel>
                      <Select
                        className={styles.TextFieldCustom}
                        labelId="select-label-eventos-usuarios"
                        multiple={true}
                        onChange={(event) =>
                          handleEventoPermitidoUsuariosPermitidos(
                            event,
                            eventoPermitido,
                            index
                          )
                        }
                        label="Usuários Permitidos"
                        variant="outlined"
                        value={eventoPermitido.usuariosPermitidos || ""}
                      >
                        {clientes.map((pessoa) => {
                          return (
                            <MenuItem key={pessoa.id} value={pessoa.id}>
                              {pessoa.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid>
                <Button
                  disabled={false}
                  variant="contained"
                  color="error"
                  style={{ marginRight: "2px" }}
                  onClick={() => removerEvento(index)}
                  placeholder="Remover Evento"
                >
                  <Icon path={mdiMinus} size={1} color={Colors.white} />
                </Button>
              </Grid>
            </Grid>
          );
        })}

        {edicao == false ||
          (tipoCupom && Number(tipoCupom) === TipoCupom.PorEvento) ? (
          <Grid className="d-flex flex-row" item xs={12}>
            <Grid className={styles.GridItem} item xs={3}>
              <Button
                variant="contained"
                color="success"
                style={{ marginRight: "2px" }}
                onClick={adicionarEvento}
                placeholder="Adicionar Evento"
              >
                Adicionar Evento{" "}
                <Icon path={mdiPlus} size={1} color={Colors.white} />
              </Button>
            </Grid>
          </Grid>
        ) : (
          <span></span>
        )}
      </Grid>

      <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
        <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
          Cancelar
        </Button>
        <Button
          size="large"
          disabled={desabilitaBotaoSalvar}
          onClick={handleSubmit(salvar)}
          sx={sxButtonSaveConfig}
        >
          Salvar
        </Button>
      </FooterToolbar>
    </form>
  );
};
