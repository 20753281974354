import { mdiContentCopy } from "@mdi/js";
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { Colors } from "../../../compartilhado/colors";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { EventoSearchResponse } from "../../../modelos/dtos/EventoSearchResponse";

interface Props {
  onRowClick: (rowData?: EventoSearchResponse) => void;
  carregar: (
    data: OptGridRequest
  ) => Promise<OptGridResponse<EventoSearchResponse>>;
  onClickCopiarEvento: (rowData: EventoSearchResponse) => void;
  gridRef: React.MutableRefObject<OptGridRef | null>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10,
};

export const GridEventosView = React.forwardRef<OptGridRef | undefined, Props>(
  (
    {
      carregar,
      onRowClick,
      onClickCopiarEvento,
      gridRef,
    },
    ref
  ) => {
    const columns: OptGridColumn<EventoSearchResponse>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "Nome",
        field: "nome",
        align: "left",
        hidden: false,
        width: 15,
      },
      {
        title: "Descrição",
        field: "descricao",
        align: "left",
        hidden: false,
        width: 300,
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={gridRef}
            columns={columns}
            data={carregar}
            options={options}
            onRowClick={onRowClick}
            actionsPosition={"end"}
            actions={[
              (rowData) => ({
                icon: {
                  path: mdiContentCopy,
                  size: 1,
                  color: Colors.gray,
                },
                tooltip: "Copiar Evento",
                onClick: () => onClickCopiarEvento(rowData),
                disabled: false,
                }),
            ]}
          />
        </OptGridContainer>
      </>
    );
  }
);
