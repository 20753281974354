import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { ListarPassageirosSearchResponse } from "../../../modelos/dtos/ListarPassageirosSearchResponse";
import { ListaDePassageirosSearchRequest } from "../../../modelos/search/ListaDePassageirosSearchRequest";
import { Passageiro } from "../../../modelos/sms/Passageiro";
import { SmsPontosDeEmbarqueSubmit } from "../../../modelos/sms/SmsPontosDeEmbarqueSubmit";
import { format } from "date-fns";
import { formatarHora } from "../../../utils/claimUtils";

interface Props {
  atualizarSelecoesDoGrid: (passageiros: string[]) => void;
  data: any[];
  selecionarTodos: boolean;
}

export const GridSmsView = React.forwardRef<OptGridRef | undefined, Props>(
  ({ atualizarSelecoesDoGrid, data, selecionarTodos }, ref) => {
    const options: OptGridOptions = {
      search: true,
      selection: !selecionarTodos ? true : false,
      pageSize: 100,
    };

    const columns: OptGridColumn<ListarPassageirosSearchResponse>[] = [
      {
        title: "Id",
        field: "id",
        align: "left",
        hidden: true,
      },
      {
        title: "Nome do Passageiro",
        field: "usuario.nome",
        align: "left",
      },
      {
        title: "Horários de Embarque",
        field: "reserva",
        align: "left",
        render: (row) => {    
          return (
            <span>
              {row.reserva.pontoEmbarque.nome} {formatarHora(row.reserva.pontoEmbarque)} 
            </span>
          );
        },
      },
      {
        title: "Nº de Embarque",
        field: "numeroPassageiro",
        align: "center",
      },
      {
        title: "Ponto de Embarque",
        field: "reserva.pontoEmbarque.nome",
        align: "center",
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            columns={columns}
            data={data}
            options={options}
            onRowClick={() => { }}
            actionsPosition={"end"}
            onSelect={(rowData) => {
              let passageiros = rowData.map((obj) => (obj.id));
              atualizarSelecoesDoGrid(passageiros);
            }}
          />
        </OptGridContainer>
      </>
    );
  }
);
