import { useApi } from "../compartilhado/hooks/api";
import { SearchRequest } from "../compartilhado/types/SearchRequest";
import { RotaSearchResponse } from "../modelos/dtos/RotaSearchResponse";
import { CopiarRotaFormModel } from "../modelos/rota/copiarRotaFormModel";
import { RotaFormModel } from "../modelos/rota/rotaFormModel";
import { PaginatedRotaRequest } from "../modelos/search/PaginatedRotaRequest";
import { protectedResources } from "../authConfig";

interface AssociarPassageirosProps {
  idsPassageiros: Array<string>;
  idRota: string;
}

export const useRotasService = () => {
  const { post, remove, put, gridSearch, get, gridPagedListSearch } = useApi({ scopes: protectedResources.apiGestao.scopes });

  const baseApi = `gestao/Rota`;

  const salvarRota = async (data: RotaFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerRota = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarRota = async (itemId: string, data: RotaFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridRotasPaginated = async (data: SearchRequest<PaginatedRotaRequest>) => {
    return gridPagedListSearch<RotaSearchResponse>(`${baseApi}/paginado/${data.page + 1}/${data.pageSize}`, { ...data.filtro });
  };

  const carregarRotaPorId = async (id: string) => {
    return get<RotaFormModel>(`${baseApi}/${id}`);
  };

  const carregarRotas = async () => {
    return get<RotaFormModel>(`${baseApi}/todos`);
  };

  const copiarRota = async (data: CopiarRotaFormModel) => {
    return post(`${baseApi}/copiar`, data);
  }

  const buscarPassageirosRota = async (idRota: string) => {
    return get<any>(`${baseApi}/passageiros/${idRota}`);
  };

  const associarPassageiros = async (data: AssociarPassageirosProps) => {
    return post(`${baseApi}/associarPassageiros`, data);
  }

  return {
    salvarRota,
    removerRota,
    editarRota,
    buscarGridRotasPaginated,
    carregarRotaPorId,
    carregarRotas,
    copiarRota,
    buscarPassageirosRota,
    associarPassageiros
  };
};