import { PerfilUsuario } from "../enums/PerfilUsuario";
import { TipoDocumento } from "../enums/tipoDocumento";
import { PessoaFormModel } from "../modelos/pessoa/pessoaFormModel";

export const PESSOA_FORM_INITIAL_STATE: PessoaFormModel = {
    telefone: "",
    cpf: "",
    nome: "",
    email: "",
    documento: "",
    maiorDeIdade: true,
    perfil: PerfilUsuario.Motorista
  };
  