import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
} from "@optsol/react";
import React from "react";
import { OptGridContainer } from "../../../componentes/OptGridContainer";
import { ListarReservasResponse } from "../../../modelos/transferirPassageiros/submitModel";

interface Props {
  atualizarSelecoesDoGrid: (passageiros: string[]) => void;
  data: any[];
}

export const GridTransferirPassageirosView = React.forwardRef<OptGridRef | undefined, Props>(
  ({ atualizarSelecoesDoGrid, data }, ref) => {
    const options: OptGridOptions = {
      search: true,
      selection: true,
      pageSize: 10,
    };

    const columns: OptGridColumn<ListarReservasResponse>[] = [
      {
        title: "Id",
        field: "idReserva",
        align: "left",
        hidden: true,
      },
      {
        title: "Dono da Reserva",
        field: "dono",
        align: "left",
      },
      {
        title: "Número da Reserva",
        field: "numeroReserva",
        align: "center",
      },
      {
        title: "Números dos Passageiros",
        field: "numerosPassageiros",
        align: "center",
      },
      {
        title: "Ponto de Embarque",
        field: "pontoEmbarque",
        align: "center",
      },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            columns={columns}
            data={data}
            options={options}
            onRowClick={() => { }}
            actionsPosition={"end"}
            onSelect={(rowData) => {
              let passageiros = rowData.map((obj) => (obj.id));
              atualizarSelecoesDoGrid(passageiros);
            }}
          />
        </OptGridContainer>
      </>
    );
  }
);